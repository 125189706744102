import { create } from 'zustand'
import {
  getFollowingTopics,
  getTopicCategoriesWithTopics,
} from '../../../services/topic'

export const useSidebarStore = create((set) => ({
  currentAccordion: 'following',
  setCurrentAccordion: (id: string) => set({ currentAccordion: id }),

  categories: [],
  fetchCategories: () => {
    getTopicCategoriesWithTopics().then((data) => {
      set({ categories: data })
    })
  },

  followingTopics: [],
  fetchFollowingTopics: () => {
    getFollowingTopics().then((data) => {
      set({ followingTopics: data })
    })
  },
}))
