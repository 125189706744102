import './index.css'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import Badge from '@mui/material/Badge'
import camera from '../../assets/icons/camera.svg'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'

import Spacer from '../../components/Spacer'
import InputBox from '../../components/InputBox'
import InputBoxDropDown from '../../components/InputBoxDropDown'
import InputBoxTextArea from '../../components/InputBoxTextArea'
import OnBoardButton from '../../components/OnBoardButton'

import { updateOrganizationDetails } from '../../redux/organizations/actions'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpload } from '../../utils/uploadImage'
import UploadProgress from '../../components-v2/UploadProgress'
import wrongBlueCircle from '../../assets/icons/blue-wrong-around-circle.svg'

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `0.3px solid #9D9D9D`,
}))

const userTypes = [
  { name: 'Government', key: 1 },
  { name: 'Political organization', key: 2 },
  { name: 'International organization', key: 3 },
  { name: 'Non-governmental organization', key: 4 },
  { name: 'Educational institution', key: 5 },
  { name: 'Government linked company/org', key: 6 },
  { name: 'Non-profit media', key: 7 },
  { name: 'For-profit media', key: 8 },
  { name: 'Government linked media', key: 9 },
  { name: 'Private company/org', key: 10 },
]

const EditOrganizationPage = ({
  organizationDetails,
  updateOrganizationDetails,
  updateOrganizationDetailsLoading,
}: any) => {
  const {
    upload,
    // url: image,
    // setUrl: setImage,
    progress,
    transferedBytes,
    totalBytes,
  } = useUpload()

  const navigate = useNavigate()
  const params = useParams()
  const { orgId } = params
  const [orgIdState, setOrgIdState] = useState(Number(orgId)) //id from param

  const inputImage = useRef(document.createElement('input'))
  const [organizationDetail, setOrganizationDetail] = useState(
    organizationDetails?.find((org: any) => org.id === orgIdState)
  ) //sort the details
  const [image, setImage] = useState(organizationDetail?.imageUrl)

  const [name, setName] = useState(organizationDetail?.name)
  const [type, setType] = useState(organizationDetail?.type)
  const [orgLink, setOrgLink] = useState(organizationDetail?.orgLink)
  const [email, setEmail] = useState(organizationDetail?.email)
  const [phone, setPhone] = useState(organizationDetail?.phoneNumber)
  const [description, setDescription] = useState(
    organizationDetail?.description
  )
  const [imageUrl, setImageUrl] = useState(organizationDetail?.imageUrl)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [orgErrorMessage, setOrgErrorMessage] = useState('')

  const onSubmit = () => {
    //submit function
    setIsSubmitted(true)
    updateOrganizationDetails(
      name,
      description,
      imageUrl,
      type,
      orgLink,
      email,
      organizationDetail?.id,
      phone
    )
  }

  const submitDetails = () => {
    if (!name) {
      setNameErrorMessage('Name Required')
    }
    if (!type) {
      setOrgErrorMessage('Organisation Required')
    }
    if (name && type) {
      onSubmit()
    }
  }

  useEffect(() => {
    if (name) {
      setNameErrorMessage('')
    }
    if (type) {
      setOrgErrorMessage('')
    }
  }, [name, type])

  useEffect(() => {
    if (
      navigate &&
      orgIdState &&
      isSubmitted &&
      !updateOrganizationDetailsLoading
    ) {
      navigate(`/organizationdetails/${orgIdState}`)
    }
  }, [updateOrganizationDetailsLoading, isSubmitted, navigate, orgIdState])

  const onUpdateImage = (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    if (URL.createObjectURL(e.target.files[0]) == null) return

    const image = e.target.files?.[0]
    if (image) {
      upload(image, 'user-images', (url) => {
        setImageUrl(url)
      })
    }

    // const imageRef = ref(
    //   storage,
    //   `user-images/${e?.target?.files[0]['name'] + uuidv4()}`
    // )
    // uploadBytes(imageRef, e.target.files[0]).then((e) => {
    //   getDownloadURL(e.ref).then((url) => {
    //     setImageUrl(url)
    //   })
    // })
  }

  const uploadImage = () => {
    inputImage.current.click()
  }

  return (
    <div className={'main-div-edit-org'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {/*Organization name section*/}
        <div style={{ width: '20px' }}></div>
        <div>
          <text className={'check-text-edit-org'}>
            {organizationDetail?.name}
          </text>
        </div>
        <div style={{ width: '20px' }}></div>
      </div>

      <div
        className="logo-edit-edit-org"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* organization logo div */}
        <Badge
          overlap="circular"
          style={{ cursor: 'pointer' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <div className={'h-[345px]'}>
              <div>
                <img
                  className={'h-[20px] w-[20px] ml-[33px]'}
                  src={wrongBlueCircle}
                  onClick={() => {
                    setImage(null)
                    setImageUrl(null)
                  }}
                  alt="clear the profile picture"
                />
              </div>
              <div className={'mt-36'}>
                <SmallAvatar
                  alt="Remy Sharp"
                  src={camera}
                  onClick={uploadImage}
                />
              </div>
            </div>
          }
        >
          <Avatar
            alt="Remy Sharp"
            src={image || ''}
            sx={{
              width: 200,
              height: 200,
              cursor: 'pointer',
              bgcolor: '#FBFBFD',
              color: '#000',
              fontSize: '11px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              border: '1px solid #9D9D9D',
            }}
            onClick={uploadImage}
          >
            <div className={'avatar-text-width-edit-org'}>
              Add profile picture
            </div>
          </Avatar>
        </Badge>
        <input ref={inputImage} type="file" hidden onChange={onUpdateImage} />
      </div>

      {progress > 0 && (
        <UploadProgress
          progress={progress}
          transferedBytes={transferedBytes}
          totalBytes={totalBytes}
        />
      )}

      <Spacer height={40} />
      <div>
        {/*Name input*/}
        <InputBox
          disable={updateOrganizationDetailsLoading}
          setValue={setName}
          defaultValue={organizationDetail?.name}
          label={'Name'}
          required={true}
        />
        <text className={'text-red-600 font-semibold text-xs'}>
          {nameErrorMessage}
        </text>

        {/*org type drop down*/}
        <Spacer height={20} />
        <InputBoxDropDown
          disable={updateOrganizationDetailsLoading}
          defaultValue={organizationDetail?.type}
          dropDownItems={userTypes}
          label={'Organization type'}
          required={true}
          enteredItem={type}
          setEnteredItem={setType}
        />
        <text className={'text-red-600 font-semibold text-xs'}>
          {orgErrorMessage}
        </text>

        {/*web site input*/}
        <Spacer height={20} />
        <InputBox
          disable={updateOrganizationDetailsLoading}
          setValue={setOrgLink}
          defaultValue={organizationDetail?.orgLink}
          label={'Website'}
          required={false}
        />

        {/*email input*/}
        <Spacer height={20} />
        <InputBox
          disable={updateOrganizationDetailsLoading}
          setValue={setEmail}
          defaultValue={organizationDetail?.email}
          label={'Email'}
          required={false}
        />

        {/*phone number input*/}
        <Spacer height={20} />
        <InputBox
          disable={updateOrganizationDetailsLoading}
          defaultValue={organizationDetail?.phoneNumber}
          label={'Phone number'}
          setValue={setPhone}
          required={false}
        />

        {/*phone number input*/}
        <Spacer height={20} />
        <InputBoxTextArea
          disable={updateOrganizationDetailsLoading}
          defaultValue={organizationDetail?.description}
          label={'Description'}
          setValue={setDescription}
          required={false}
        />

        <Spacer height={25} />
        {/*confirm button*/}
        <div>
          <OnBoardButton
            loading={updateOrganizationDetailsLoading}
            width={'300px'}
            disabled={updateOrganizationDetailsLoading}
            onClick={() => {
              submitDetails()
            }}
            buttonName={'Confirm'}
          />
        </div>
        <Spacer height={35} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    organizationDetails: state?.login?.user?.organizationUsers,
    updateOrganizationDetailsLoading:
      state?.organization?.updateOrganizationDetailsLoading,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateOrganizationDetails: (
      name: any,
      description: any,
      imageUrl: any,
      type: any,
      orgLink: any,
      email: any,
      organizationId: any,
      phone: any
    ) => {
      dispatch(
        updateOrganizationDetails(
          name,
          description,
          imageUrl,
          type,
          orgLink,
          email,
          organizationId,
          phone
        )
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganizationPage)
