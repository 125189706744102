import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CheckLogoSVG from '../../../assets/icons/check-logo.svg'
import Button from '../../../components-v2/Button'

type Props = {}

export default function InitialTab({}: Props) {
  return (
    <div className="font-inter min-h-screen bg-primary text-white">
      <div className="mx-auto mb-20 mt-[94px] flex max-w-[300px] flex-col">
        <img src={CheckLogoSVG} className="mx-auto w-[200px] text-[200px]" />
        <h1 className="mx-auto mt-8 text-3xl font-bold">niooze</h1>
        <p className="mt-[94px] text-base font-bold">
          Create an account to
          <br /> unlock the best of Niooze.
        </p>
        <Link to={'/signup/email'} className="w-full">
          <Button className="mt-10 w-full">Continue with email</Button>
        </Link>
      </div>
    </div>
  )
}
