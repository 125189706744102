import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/dialog'
import { Textarea } from '../shadcn/textarea'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  trigger?: React.ReactNode

  title?: string
  description?: string

  onClickFinish?: (text: string) => void
}

export default function ReasonDialog({
  isOpen,
  setIsOpen,
  trigger,
  title = 'Reason',
  description,
  onClickFinish,
}: Props) {
  const [text, setText] = useState('')

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {trigger && <DialogTrigger>{trigger}</DialogTrigger>}
      <DialogContent className="max-w-[332px] max-h-screen overflow-auto">
        <div className="flex flex-col">
          <h2 className="text-base font-bold uppercase text-center">{title}</h2>

          {description && <p className="text-xs mt-4">{description}</p>}

          <Textarea
            className="mt-5 bg-line placeholder:text-xs placeholder:text-border"
            placeholder="Write reason. Please do not use for jokes."
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <button
            className="h-[29px] bg-line hover:bg-line/80 transition-all rounded-full text-xs font-bold mt-5"
            onClick={() => {
              onClickFinish && onClickFinish(text)
              setText('')
              setIsOpen(false)
            }}
          >
            Finish
          </button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
