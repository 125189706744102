import React, { useCallback, useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/dialog'
import debounce from '../../utils/debounce'
import {
  fetchLocations,
  fetchLocationsAndTopics,
} from '../CreateContrastDialog/helper'
import { Input } from '../shadcn/input'
import Loading from '../Loading'

type Props = {
  trigger?: React.ReactNode
  onSelectLocation?: (obj: any) => void
}

export default function LocationSearchDialog({
  trigger,
  onSelectLocation,
}: Props) {
  const [openEntry, setOpenEntry] = useState(false)
  const [locations, setLocation] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  const debouncedOnChange = useCallback(
    debounce((value) => {
      // console.log('Input value:', value)
      if (value) {
        setLoading(true)
        fetchLocations(value)
          .then((data) => {
            setLocation(data)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, 300),
    []
  )

  return (
    <Dialog open={openEntry} onOpenChange={setOpenEntry}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className=" max-w-[400px] min-h-[300px] flex flex-col">
        <Input
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
            debouncedOnChange(e.target.value)
          }}
        />

        {loading && (
          <div className="mx-auto">
            <Loading />
          </div>
        )}

        {locations.map((x: any, i: number) => (
          <li
            key={i}
            className="hover:bg-gray-100 transition-all p-1 rounded-sm cursor-pointer list-none"
            onClick={() => {
              onSelectLocation && onSelectLocation(x)
            }}
          >
            {x.label}
            <div key={i} className={'mt-0.5'}>
              <span
                className={
                  'bg-gray-200 p-0.5 rounded font-normal text-gray-500'
                }
              >
                {x.type}
              </span>
            </div>
          </li>
        ))}
      </DialogContent>
    </Dialog>
  )
}
