import Spacer from '../Spacer'
import ToggleButton from '../ToggleButton'
import { useState, useEffect, useLayoutEffect } from 'react'

import './index.css'
import ArticlePost from '../ArticlePost'
import PostComment from '../PostComment'
import back from '../../assets/icons/back.svg'
import { useNavigate } from 'react-router-dom'
import Post from '../Post'
import plus from '../../assets/icons/plus-black.svg'
import {
  MAX_PHONE_BREAK_POINT,
  MIN_TABLET_BREAK_POINT,
} from '../../utils/constants'

const PostScreen = ({
  setPost,
  setArticle,
  setPostModalVisible,
  selectedPost = {},
  selectedTopic = {},
  setClickedPost,
  setClickedTopic,
  deletePost,
  setPageArticle,
  voteToggle,
  alarmToggle,
  user,
  addPostsToRedux,
}: any) => {
  const navigate = useNavigate()

  const { postTypeId = '', con = [], article, topicId } = selectedPost || {}

  const [comments, setComments] = useState(true) //comments for future improvements
  const [page, setPage] = useState(0)
  useEffect(() => {
    if (con.length > 0 && windowSize.innerWidth <= 1300) {
      setPage(2)
    } else if (article != null) {
      setPage(1)
    } else {
      setPage(0)
    }
  }, [con, article])

  const navigateToPostDetail = (postId: string) => {
    navigate(`/topic/${topicId}/post/${postId}`)
  }

  const goBack = () => {
    navigate(-1)
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const navigateToTopic = () => {
    navigate(`/topic/${topicId}`)
    setPageArticle(1)
  }

  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'stretch' }}>
      <div
        style={{
          display: 'flex',
          flex: 58,
          alignItems: 'center',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <div className="third_div_phone_style third_div_desktop_style third_div_tab_style">
          {windowSize.innerWidth > 1300 ? (
            <div
              className={'back-button'}
              style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}
              onClick={() => goBack()}
            >
              <img style={{ height: 21, width: 21 }} src={back} alt="" />
            </div>
          ) : null}

          {windowSize.innerWidth < 1300 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                alignContent: 'center',
              }}
            >
              <div
                onClick={() => goBack()}
                style={{ flex: 10, justifyContent: 'flex-end' }}
              >
                <img style={{ height: 21, width: 21 }} src={back} alt="" />
              </div>
              <div style={{ flex: 80 }}>
                <text
                  style={{
                    fontSize: windowSize.innerWidth > 1300 ? 22 : 18,
                    fontWeight: 600,
                  }}
                >
                  {postTypeId.toUpperCase()}
                </text>
              </div>
              <div style={{ flex: 10 }}></div>
            </div>
          ) : (
            <text
              style={{
                flex: 1,
                fontSize: windowSize.innerWidth > 1300 ? 22 : 15,
                fontWeight: 600,
                marginBottom: windowSize.innerWidth < 1300 ? 0 : 15,
                marginRight: 45,
              }}
            >
              {postTypeId.toUpperCase()}
            </text>
          )}
        </div>
        <div
          style={{
            flex: 1,
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ overflowY: 'scroll', overflowX: 'hidden', width: 600 }}>
            <Spacer height={54} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                zIndex: 1000,
              }}
            >
              <Post
                onClick={() => {}}
                hideType
                post={selectedPost}
                alarmToggle={alarmToggle}
                deletePost={deletePost}
                voteToggle={voteToggle}
                addPostsToRedux={addPostsToRedux}
              />
            </div>
            {windowSize.innerWidth > 1300 && <Spacer height={20} />}
            <div className={'select-header'}>
              <ToggleButton
                toggle={page === 0}
                title={'Comments'}
                width={70}
                onClick={() => setPage(0)}
              />
              <Spacer width={20} />
              {windowSize.innerWidth < 1300 && (
                <>
                  {
                    <ToggleButton
                      toggle={page === 2}
                      title={'Contrasts'}
                      width={70}
                      onClick={() => setPage(2)}
                    />
                  }
                  <Spacer width={20} />
                </>
              )}
              <ToggleButton
                toggle={page === 1}
                title={'Articles'}
                width={70}
                onClick={() => setPage(1)}
              />
            </div>

            {page === 1 ? ( //article
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {article && (
                  <ArticlePost
                    selectedTopic={selectedTopic}
                    article={article}
                    setArticle={setArticle}
                  />
                )}

                <Spacer height={80} />

                <text
                  style={{
                    fontWeight: 'bold',
                    alignSelf: 'center',
                    color: '#2443B2',
                  }}
                  onClick={() => {
                    navigateToTopic()
                  }}
                >
                  See more articles about this topic
                </text>
                <Spacer height={100} />
              </div>
            ) : page === 2 ? ( //Contrasts
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {windowSize.innerWidth < 1300 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {user?.userTypeId !== 'opinion maker' && (
                      <div
                        onClick={() => {
                          setClickedTopic(selectedTopic)
                          setClickedPost(selectedPost)
                          setPostModalVisible(true)
                        }}
                      >
                        <img
                          className={'contrast-button'}
                          style={{ height: 27, width: 27, marginBottom: 1 }}
                          src={plus}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
                {windowSize.innerWidth < 1300 &&
                  con.map((contrast: any) => {
                    return (
                      <Post
                        post={contrast}
                        onClick={() => navigateToPostDetail(contrast.id)}
                        addPostsToRedux={addPostsToRedux}
                      />
                    )
                  })}
              </div>
            ) : (
              <div style={{ flex: 1, overflow: 'hidden' }}>
                {/*Comments*/}
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PostComment />
                  <PostComment />
                  <PostComment />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {windowSize.innerWidth > 1300 && (
        <div
          style={{
            flex: 42,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: -25,
            borderLeft: '1px #d9d9d9 solid',
          }}
        >
          <div
            style={{
              marginTop: 35,
              marginBottom: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <text
              style={{
                fontSize: 22,
                fontWeight: 'bold',
                textAlign: 'center',
                marginLeft: 15,
                marginTop: -5,
              }}
            >
              Contrasts
            </text>

            <Spacer width={20} />
            {user?.userTypeId !== 'opinion maker' && (
              <div
                onClick={() => {
                  setClickedTopic(selectedTopic)
                  setClickedPost(selectedPost)
                  setPostModalVisible(true)
                }}
              >
                <img
                  className={'contrast-button'}
                  style={{ height: 27, width: 27, marginBottom: 1 }}
                  src={plus}
                  alt=""
                />
              </div>
            )}
          </div>

          <div
            style={{
              flex: 1,
              overflowY: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                overflow: 'scroll',
                paddingLeft: '30px',
                paddingRight: '20px',
                overflowX: 'hidden',
              }}
            >
              {con.map((contrast: any) => {
                return (
                  <Post
                    contrast
                    post={contrast}
                    onClick={() => navigateToPostDetail(contrast.id)}
                    addPostsToRedux={addPostsToRedux}
                  />
                )
              })}
              <Spacer height={60} />
              <text
                style={{
                  marginLeft: 20,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  color: '#2443B2',
                }}
              >
                See contrasts of contrasts
              </text>
              <Spacer height={50} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PostScreen
