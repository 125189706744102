import React from 'react'
import {
  getHowLongAgo,
  parsePostObjToProps,
} from '../../components-v2/Post/helpers'
import PostCard from '../../components-v2/Post/PostCard'
import { cn } from '../../utils/cn'

type Props = {
  event: any
}

export default function DeletedEvent({ event }: Props) {
  const time = getHowLongAgo(event?.createdAt)
  return (
    <div className="max-w-[362px] w-full">
      <p className="">
        <span className="font-bold">{event?.actingUser?.name}</span>{' '}
        {event?.eventType} your post{' '}
        {time === 'Just now' ? time : `${time} ago`}.
      </p>

      {event?.activity?.reason && <p>Reason is "{event?.activity?.reason}"</p>}

      <PostCard
        {...parsePostObjToProps(event?.activity?.currentPost)}
        className={cn('mt-4 pointer-events-none opacity-30 grayscale')}
      />
    </div>
  )
}
