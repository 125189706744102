import React, { useState } from 'react'

import './index.css'
import lens from '../../assets/icons/lens.svg'

const InputBoxDropDown = ({
  disable,
  defaultValue,
  label,
  required,
  dropDownItems,
  setEnteredItem,
  enteredItem,
}: any) => {
  const [openDropDown, setOpenDropDown] = useState(false)

  const onSelectItem = (e: any) => {
    setEnteredItem(e)
    setOpenDropDown(!openDropDown)
  }
  return (
    <div style={{ position: 'relative' }}>
      <label className={'label-text-input-box-drop-down-non-location'}>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="text"
        onClick={(e) => {
          setOpenDropDown(!openDropDown)
        }}
        defaultValue={defaultValue}
        value={enteredItem}
        disabled={disable}
        style={{
          width: '287px',
          backgroundColor: disable ? '#D9D9D9' : 'white', //change the color when disable
          borderRadius: '6px',
          paddingLeft: '10px',
          height: '29px',
          flexShrink: 0,
          fontStyle: 'normal',
          zIndex: 1000,
          borderColor: '#D9D9D9',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: disable ? '#9D9D9D' : '#000',
        }}
      />
      <br />
      {openDropDown ? (
        <div className={'drop-down-list-non-location'}>
          {dropDownItems?.map((dropDownItem: any) => (
            <p
              key={dropDownItem?.key}
              className={'list-item-non-location list-align-non-location'}
              onClick={() => {
                onSelectItem(dropDownItem.name)
              }}
            >
              {dropDownItem?.name}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default InputBoxDropDown
