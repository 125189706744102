import React, { useCallback, useEffect, useState } from 'react'
import AddSVG from '../../../assets/icons/add.svg'
import { useParams } from 'react-router-dom'
import { createAComment, getCommentsOfAPost } from '../services'
import PostWithContrast from '../../../components-v2/Post/PostWithContrast'
import CreateContrastDialog from '../../../components-v2/CreateContrastDialog/CreateContrastDialog'
import EntryDialog from '../EntryDialog'
import { getAllowedPostTypes } from '../../../utils/permissions'
import { useSelector } from 'react-redux'
import { useNotifications } from '../../NotificationsPage/notifications.store'

type Props = {
  post: any
}

export default function CommentsTab({ post }: Props) {
  const { postId, topicId } = useParams<{ postId: string; topicId: string }>()
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [entryOpen, setEntryOpen] = useState(false)
  const [postOpen, setPostOpen] = useState(false)
  const [defaultType, setDefaultType] = useState<string>()

  const loadSummary = useNotifications((state: any) => state.loadSummary)

  const pageLoad = useCallback(() => {
    if (postId) {
      setIsLoading(true)
      getCommentsOfAPost(postId)
        .then((data) => {
          setComments(data?.com || [])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [postId])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const currentUser = useSelector((state: any) => state.login.user)

  const allowedPostTypes = getAllowedPostTypes(
    currentUser?.roles?.permissions?.postCreation,
    ['Opinion', 'Rumor', 'Humor']
  )

  return (
    <>
      <div
        className="flex h-[25px] w-[364px] cursor-pointer items-center gap-[6px] rounded-full bg-[#F5F5F5] p-[6px] mx-mobile:w-full"
        onClick={() => {
          setEntryOpen(true)
        }}
      >
        <img src={AddSVG} className="h-4 w-4" />
        <p className="text-[11px] text-border">Post a comment</p>
      </div>

      <CreateContrastDialog
        parentPostType={post?.postTypeId}
        parentPostId={post?.id}
        parentTopicId={topicId || ''}
        postTypes={allowedPostTypes}
        dialogType="comment"
        defaultType={defaultType}
        onClickPost={(values) => {
          return createAComment({
            description: values.description,
            postTypeId: values.postType,
            mediaId: values.postImageUrl,
            organizationId: values.organizationId,
            parentPostId: values.parentPostId,
            topicId: values.parentTopicId,
          }).then(() => {
            pageLoad()
            loadSummary()
          })
        }}
        isOpen={postOpen}
        setIsOpen={setPostOpen}
        // trigger={
        //   <div className="h-[25px] w-[364px] mx-mobile:w-full bg-[#F5F5F5] rounded-full flex items-center p-[6px] gap-[6px] cursor-pointer">
        //     <img src={AddSVG} className="h-4 w-4" />
        //     <p className="text-[11px] text-border">Post a comment</p>
        //   </div>
        // }
      />

      {isLoading && (
        <p className="mt-6 text-center text-xs text-border">Loading</p>
      )}

      {!isLoading && comments.length < 1 && (
        <p className="mt-6 text-center text-xs text-border">No Comments</p>
      )}

      <div className="mb-16 mt-6 flex w-full flex-col gap-6">
        {comments.map((comment: any, i: number) => {
          return <PostWithContrast post={comment} key={i} pageLoad={pageLoad} />
        })}
      </div>

      <EntryDialog
        postTypes={allowedPostTypes}
        setOpen={setEntryOpen}
        open={entryOpen}
        onTypeSelect={(type) => {
          setDefaultType(type)
          setEntryOpen(false)
          setPostOpen(true)
        }}
      />
    </>
  )
}
