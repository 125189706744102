import React, { useState, useRef, useEffect } from 'react'

const Image = ({
  src,
  alt,
  width,
  height,
  circle,
  onClick,
  style,
  cropType = 'contain',
}: any) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const updateContainerSize = () => {
      if (imageRef.current) {
        setContainerSize({ width, height })
      }
    }

    window.addEventListener('resize', updateContainerSize)
    updateContainerSize()

    return () => {
      window.removeEventListener('resize', updateContainerSize)
    }
  }, [])

  return (
    <div
      onClick={onClick}
      style={{
        position: 'relative',
        width: width,
        height: height,
        overflow: 'hidden',
        padding: 1,
      }}
    >
      <img
        ref={imageRef}
        src={src}
        alt={alt}
        style={{
          ...{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 'none',
            maxHeight: 'none',
            width: width,
            height: height,
            objectFit: cropType,
            borderRadius: circle ? 50 : 0,
          },
          ...style,
        }}
      />
    </div>
  )
}

export default Image
