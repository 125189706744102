import {
  GET_TOPIC_ARTICLE_REQUEST,
  GET_TOPIC_ARTICLE_SUCCESS,
  GET_TOPIC_ARTICLE_FAILURE,
  GET_ARTICLE_REQUEST,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILURE,
} from './types'

const initialState = {
  topicArticles: null,
  topicArticlesLoading: false,
  topicArticlesError: false,

  article: null,
  articleLoading: false,
  articleError: false,
}

const postsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ARTICLE_REQUEST:
      return {
        ...state,
        article: null,
        articleLoading: true,
        articleError: false,
      }
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload,
        articleLoading: false,
        articleError: false,
      }
    case GET_ARTICLE_FAILURE:
      return {
        ...state,
        article: null,
        articleLoading: false,
        articleError: true,
      }

    case GET_TOPIC_ARTICLE_REQUEST:
      return {
        ...state,
        topicArticles: null,
        topicArticlesLoading: true,
        topicArticlesError: false,
      }
    case GET_TOPIC_ARTICLE_SUCCESS:
      return {
        ...state,
        topicArticles: action.payload,
        topicArticlesLoading: false,
        topicArticlesError: false,
      }
    case GET_TOPIC_ARTICLE_FAILURE:
      return {
        ...state,
        topicArticles: false,
        topicArticlesLoading: false,
        topicArticlesError: true,
      }
    default:
      return state
  }
}

export default postsReducer
