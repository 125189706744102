import React, { useEffect } from 'react'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import scrollToDiv from '../../utils/scrollToDiv'
import { Post } from '../../types'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'
import CommentSVG from '../../assets/icons/comment.svg'
import PostCard from '../../components-v2/Post/PostCard'
import { parsePostObjToProps } from '../../components-v2/Post/helpers'
import { alarmToggle, upvoteToggle } from '../../components-v2/Post/services'
import { addPostsToRedux } from '../../redux/analytics/actions'

type Props = {
  trailPosts: Post[]
  cb: () => void
  onNoAuthCallback?: () => void
}

export default function ConversationTrail({
  trailPosts,
  cb,
  onNoAuthCallback,
}: Props) {
  const parentPost = trailPosts[0]
  const trails = trailPosts.filter((x, i) => i !== 0)

  useEffect(() => {
    scrollToDiv('trail-start', {
      smooth: false,
    })
  }, [trailPosts])

  return (
    <div className="w-full">
      <PostCard
        addPostsToRedux={addPostsToRedux}
        postTypeForAnalytics={'home'}
        userLocation={null}
        {...parsePostObjToProps(parentPost)}
        cb={cb}
        className="mx-auto"
        onClickVote={() => {
          upvoteToggle(parentPost.id).then(() => {
            cb()
          })
        }}
        onClickAlarm={() => {
          alarmToggle(parentPost.id).then(() => {
            cb()
          })
        }}
        onNoAuthCallback={onNoAuthCallback}
      />
      {trails.map((post, i: number) => {
        return (
          <div className="flex justify-center" key={i}>
            <div className="mt-3 flex mx-mobile:w-full">
              <div className="flex flex-col items-center pr-[10px]">
                <div className="h-[70px] w-[1px] bg-line" />
                {/* <div className="h-5 w-5 bg-line rounded-full"></div> */}
                {post.type === 'contrast' && (
                  <img src={ContrastSVG} className="h-4 w-4" />
                )}

                {post.type === 'comment' && (
                  <img src={CommentSVG} className="h-4 w-4" />
                )}
              </div>
              <div className="w-[335px] mx-mobile:w-full">
                <PostCard
                  addPostsToRedux={addPostsToRedux}
                  postTypeForAnalytics={'home'}
                  userLocation={null}
                  {...parsePostObjToProps(post)}
                  cb={cb}
                  onClickVote={() => {
                    // if (onNoAuthCallback) {
                    //   onNoAuthCallback()
                    //   return
                    // }
                    upvoteToggle(post.id).then(() => {
                      cb()
                    })
                  }}
                  onClickAlarm={() => {
                    // if (onNoAuthCallback) {
                    //   onNoAuthCallback()
                    //   return
                    // }
                    alarmToggle(post.id).then(() => {
                      cb()
                    })
                  }}
                  onNoAuthCallback={onNoAuthCallback}
                />
              </div>
            </div>
          </div>
        )
      })}

      <div className="mx-auto mb-2 w-[364px] mx-mobile:w-full">
        <div className="ml-[10px] h-5 w-[1px] bg-line" />
      </div>
    </div>
  )
}
