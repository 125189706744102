import { useState, useEffect } from 'react'
import HomePostMoibleAndTab from '../HomePostMoibleAndTab'
import './index.css'
import Spacer from '../Spacer'
import heart from '../../assets/icons/heart.svg'
import text from '../../assets/icons/text.svg'
import PostEmpty from '../PostEmpty'
import {
  MAX_PHONE_BREAK_POINT,
  MAX_TABLET_BREAK_POINT,
} from '../../utils/constants'

const HomeTopicMobileAndTab = ({
  contrastTitleShown,
  setContrastTitleShown,
  setPostModalVisible,
  onClick,
  topic,
  country,
  state,
  navigateToPostDetail,
  setClickedTopic,
  deletePost,
  deletePostLoading,
  deletePostError,
  voteToggle,
  alarmToggle,
  setSelectTopicPostCreateModalVisible,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  user,
  postEditRules,
  ref,
  inView,
  addPostsToRedux,
}: any) => {
  const { id, title, posts = [], locationId } = topic

  const navigateToPostDetailWithPost = (clickedPost: any) => {
    navigateToPostDetail(topic, clickedPost)
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      className={''}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 58, display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              maxWidth: 470,
            }}
          >
            <div
              onClick={onClick}
              className={'topic-container topic-styles-tab'}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <text
                  style={{
                    textAlign: 'start',
                    fontWeight: 600,
                    lineHeight: 0.9,
                    fontSize:
                      windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 16 : 18,
                    flex: 1,
                  }}
                >
                  {title ? title : 'Dock workers special status'}
                </text>

                <Spacer width={10} />
                <img style={{ height: 21, width: 21 }} src={heart} alt="" />
              </div>

              {windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? null : (
                <Spacer height={3} />
              )}
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <text
                  style={{
                    fontSize:
                      windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 15 : 17,
                    textAlign: 'start',
                  }}
                >
                  {locationId}
                </text>
              </div>
            </div>
            <div
              onClick={() => {
                setClickedTopic(topic)
                if (id === 'local') {
                  setSelectTopicPostCreateModalVisible(true)
                } else {
                  setPostModalVisible(true)
                }
              }}
              className={
                'post-button create-post-style-mob create-post-style-tab'
              }
            >
              <text
                style={{
                  fontStyle: 'italic',
                  textAlign: 'start',
                  color: '#D9D9D9',

                  fontSize:
                    windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 14 : 16,
                }}
              >
                What do you want to share about this topic?
              </text>
            </div>
          </div>
        </div>
      </div>

      <Spacer height={15} />
      {posts.length > 0 ? (
        posts.map((post: any) => {
          return (
            <HomePostMoibleAndTab
              user={user}
              selectPostDetails={selectPostDetails}
              deletePostLoading={deletePostLoading}
              deletePostError={deletePostError}
              setEditPostModalVisible={setEditPostModalVisible}
              editPostModalVisible={editPostModalVisible}
              selectedTopic={topic}
              onClick={() => navigateToPostDetail(topic, post)}
              navigateToPostDetailWithPost={navigateToPostDetailWithPost}
              post={post}
              postLocation={post.postLocation}
              deletePost={deletePost}
              userId={post?.author?.id}
              voteToggle={() => voteToggle(post.id)}
              alarmToggle={() => alarmToggle(post.id)}
              postEditRules={postEditRules}
              ref={ref}
              inView={inView}
              addPostsToRedux={addPostsToRedux}
            />
          )
        })
      ) : (
        <PostEmpty />
      )}

      {posts && posts.length > 1 && (
        <div
          style={{ cursor: 'pointer' }}
          onClick={onClick}
          className="flex justify-center items-center"
        >
          <text
            style={{
              flex: 58,
              fontWeight: 'bold',
              marginTop: 10,
              alignSelf: 'center',
              color: '#2443B2',
              textAlign: 'center',
            }}
          >
            Read more content about this topic
          </text>
        </div>
      )}

      <Spacer height={40} />
    </div>
  )
}

export default HomeTopicMobileAndTab
