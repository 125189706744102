import './index.css'

const ButtonComponent = ({
  children,
  padding = 2,
  radius = 4,
  onClick,
}: any) => {
  return (
    <div
      style={{ padding, borderRadius: radius }}
      className={'button-container'}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default ButtonComponent
