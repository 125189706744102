import { connect } from 'react-redux'
import './index.css'

import { updateUserDetails } from '../../redux/login/actions'
import camera from '../../assets/icons/camera.svg'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import InputBox from '../../components/InputBox'
import Spacer from '../../components/Spacer'
import InputBoxDropDownLocation from '../../components/InputBoxDropDownLocation'
import InputBoxDropDown from '../../components/InputBoxDropDown'
import InputBoxTextArea from '../../components/InputBoxTextArea'
import OnBoardButton from '../../components/OnBoardButton'
import { useNavigate } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase'
import { v4 as uuidv4 } from 'uuid'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components-v2/shadcn/popover'
import { cn } from '../../utils/cn'
import LocationSelect from '../../components-v2/LocationSelect/LocationSelect'
import { useUpload } from '../../utils/uploadImage'
import UploadProgress from '../../components-v2/UploadProgress'
import wrongBlueCircle from '../../assets/icons/blue-wrong-around-circle.svg'
import Button from '../../components-v2/Button'
import RequestUserTypeDialog from '../UserDetails/RequestUserTypeDialog'

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `0.3px solid #9D9D9D`,
}))

const userTypes = [
  { name: 'Professional', key: 1 },
  { name: 'Citizen', key: 2 },
  { name: 'Opinion maker', key: 3 },
  { name: 'Commentator', key: 4 },
  { name: 'Reader', key: 5 },
  { name: 'Check', key: 6 },
]

const EditUserDetails = ({
  userDetails,
  updateUserDetails,
  updateUserDetailsLoading,
  updateUserDetailsError,
}: any) => {
  const navigate = useNavigate()
  const inputImage = useRef(document.createElement('input'))

  const [locationDropDown, setLocationDropDown] = useState<any[]>()
  const [searchLocation, setSearchLocation] = useState('')

  const [location, setLocation] = useState({
    label: userDetails?.userLocations?.address,
    id: userDetails?.userLocations?.id,
  })

  const [userType, setUserType] = useState(userDetails?.roles?.name)
  const [aboutYourSelf, setAboutYourSelf] = useState(userDetails?.description)

  const fullName = userDetails?.name.split(' ')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [locationLoading, setLocationLoading] = useState(false)

  const [topicOpen, setTopicOpen] = useState<boolean>(false)
  // const [locationObj, setLocationObj] = useState(location?.address)

  const [phoneNumber, setPhoneNumber] = useState()

  const onSubmit = () => {
    setIsSubmitted(true)
    updateUserDetails(
      location,
      userType,
      aboutYourSelf,
      imageURL === null ? image : imageURL,
      phoneNumber
    )
  }

  useEffect(() => {
    if (navigate && isSubmitted && !updateUserDetailsLoading) {
      navigate('/profiledetails')
    }
  }, [updateUserDetailsLoading, isSubmitted, navigate])

  const [image, setImage] = useState<any | null>(userDetails?.imageUrl)
  // const [imageURL, setImageUrl] = useState(userDetails?.imageUrl)

  const {
    upload,
    url: imageURL,
    progress,
    transferedBytes,
    totalBytes,
  } = useUpload()

  const handleImage = (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    if (URL.createObjectURL(e.target.files[0]) == null) return

    const image = e.target.files?.[0]
    if (image) {
      upload(image, 'user-images')
    }

    // const imageRef = ref(
    //   storage,
    //   `user-images/${e?.target?.files[0]['name'] + uuidv4()}`
    // )
    // uploadBytes(imageRef, e.target.files[0]).then((e) => {
    //   getDownloadURL(e.ref).then((url) => {
    //     setImageUrl(url)
    //   })
    // })
  }

  const addLocation = (result: any) => {
    setLocationDropDown(JSON.parse(result))
    setLocationLoading(false)
  }

  const onSearchLocation = (location: any) => {
    setLocationLoading(true)
    fetch(
      'https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&addressdetails=1&accept-language=us&q=' +
        location,
      {
        method: 'GET',
        redirect: 'follow',
      }
    )
      .then((response) => response.text())
      .then((result) => addLocation(result))
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    if (navigate && isSubmitted && !updateUserDetailsLoading) {
      navigate('/profiledetails')
    }
  }, [updateUserDetailsLoading, locationLoading, navigate])

  useEffect(() => {
    setTimeout(() => {
      //make delay 2 seconds
      onSearchLocation(searchLocation)
    }, 1000)
  }, [searchLocation])

  useEffect(() => {
    onSearchLocation('')
  }, [location])

  const uploadImage = () => {
    inputImage.current.click()
  }
  return (
    <div className="main-div-edit-user-details">
      {/*main div*/}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '20px' }}></div>
        <div>
          <text className={'check-text-edit-user-details first-letter-capital'}>
            {userDetails?.name}
          </text>
        </div>
        <div style={{ width: '20px' }}></div>
      </div>

      <div
        className={'logo-edit-user-details flex justify-center items-center'}
      >
        {/*check logo div*/}
        {/*<img className={"image-size-edit-user-details"} src={userDetails?.imageUrl} alt=""/>/!*check logo*!/*/}
        <Badge
          overlap="circular"
          style={{ cursor: 'pointer' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <div className={'h-[345px]'}>
              <div>
                <img
                  className={'h-[20px] w-[20px] ml-[33px]'}
                  src={wrongBlueCircle}
                  onClick={() => {
                    setImage(null)
                  }}
                  alt="clear the profile picture"
                />
              </div>
              <div className={'mt-36'}>
                <SmallAvatar
                  alt="Remy Sharp"
                  src={camera}
                  onClick={uploadImage}
                />
              </div>
            </div>
          }
        >
          <Avatar
            alt="Remy Sharp"
            src={image}
            sx={{
              width: 200,
              height: 200,
              cursor: 'pointer',
              bgcolor: '#FBFBFD',
              color: '#000',
              fontSize: '11px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              border: '1px solid #9D9D9D',
              objectFit: 'contain',
            }}
            onClick={uploadImage}
          >
            <div className={'avatar-text-width-profile-screen'}>
              {' '}
              Add profile picture
            </div>
          </Avatar>
        </Badge>
        <input ref={inputImage} type="file" hidden onChange={handleImage} />
      </div>

      {progress > 0 && (
        <UploadProgress
          progress={progress}
          transferedBytes={transferedBytes}
          totalBytes={totalBytes}
        />
      )}

      <Spacer height={40} />
      <div className={'flex flex-col items-center'}>
        {/*Login email address input*/}
        <InputBox
          disable={true}
          defaultValue={userDetails?.email}
          label={'Login email address'}
          required={true}
        />

        <Spacer height={20} />
        {/*First name input*/}
        <InputBox
          disable={true}
          defaultValue={fullName ? fullName[0] : null}
          label={'First name'}
          required={true}
        />

        <Spacer height={20} />
        {/*Last name input*/}
        <InputBox
          disable={true}
          defaultValue={fullName ? fullName[1] : null}
          label={'Last name'}
          required={true}
        />

        {/*Location drop down*/}
        <Spacer height={20} />
        <div style={{ width: '285px' }}>
          <text
            style={{
              fontWeight: 'bold',
              fontSize: '12px',
              color: 'black',
            }}
          >
            Hometown
            <span style={{ fontSize: '13px' }}>*</span>
          </text>
          <Spacer height={3} />
          <Popover open={topicOpen} onOpenChange={setTopicOpen}>
            <PopoverTrigger asChild>
              <button className={cn('cursor-pointer w-full text-left')}>
                {/*{!topic && !locationObj && (*/}
                <div className="border-2 border-slate-300p-1 rounded-md text-black text-sm p-1">
                  {location?.label
                    ? location?.label
                    : 'Write your city here and wait to select the...'}
                </div>
              </button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-[500px] mx-sm:w-screen">
              <LocationSelect
                // onSelectLocation={(value) => {
                //   setLocation(value)
                //   setTopicOpen(false)
                // }}
                locationFilterNeeded={true}
                setLocation={(value: any) => {
                  setLocation(value)
                  setTopicOpen(false)
                }}
                // setLocationObj={setLocationObj}
              />
            </PopoverContent>
          </Popover>
        </div>

        {/*user type drop down*/}
        <Spacer height={20} />

        <InputBoxDropDown
          disable={true}
          defaultValue={userDetails?.roles?.name}
          dropDownItems={userTypes}
          label={'User type'}
          required={true}
          enteredItem={userType}
          setEnteredItem={setUserType}
        />

        <RequestUserTypeDialog
          trigger={
            <Button className="w-[287px] mt-2">Request a new User type</Button>
          }
        />

        <Spacer height={20} />
        {/*First name input*/}
        <InputBox
          disable={false}
          defaultValue={userDetails?.phoneNumber}
          label={'Phone Number'}
          setValue={setPhoneNumber}
          required={true}
        />

        <Spacer height={20} />
        {/*First name input*/}
        <InputBox
          disable={true}
          defaultValue={
            userDetails?.dateOfBirth
              ? new Date(userDetails.dateOfBirth).toISOString().slice(0, 10)
              : ''
          }
          label={'Date Of Birth'}
          required={true}
        />

        <Spacer height={20} />
        {/*about your self input*/}
        <InputBoxTextArea
          disable={updateUserDetailsLoading}
          defaultValue={userDetails?.description}
          label={'About yourself'}
          setValue={setAboutYourSelf}
          required={false}
        />

        <Spacer height={25} />
        {/*confirm button*/}
        <div>
          <OnBoardButton
            onClick={() => onSubmit()}
            width={'300px'}
            disabled={updateUserDetailsLoading}
            buttonName={'Confirm'}
            loading={updateUserDetailsLoading}
          />
        </div>
      </div>

      <Spacer height={60} />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.login.user,
    updateUserDetailsLoading: state.login.updateUserDetailsLoading,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserDetails: (
      homeTown: any,
      userType: any,
      aboutYourSelf: any,
      imageURL: any,
      phoneNumber: any
    ) => {
      dispatch(
        updateUserDetails(
          homeTown,
          userType,
          aboutYourSelf,
          imageURL,
          phoneNumber
        )
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserDetails)
