// import { useState, useEffect } from "react";
// import HomePostDesktop from "../HomePostDesktop";
// import './index.css'
// import Spacer from "../Spacer";
// import heart from "../../assets/icons/heart.svg";
// import text from "../../assets/icons/text.svg";
// import PostEmpty from "../PostEmpty";
// import { MAX_PHONE_BREAK_POINT, MAX_TABLET_BREAK_POINT } from "../../utils/constants"
//
// const HomeTopicDesktop = ({
//     contrastTitleShown,
//     setContrastTitleShown,
//     setPostModalVisible,
//     onClick,
//     topic,
//     country,
//     state,
//     navigateToPostDetail,
//     setClickedTopic,
//     deletePost,
//     voteToggle,
//     setSelectTopicPostCreateModalVisible
// }: any) => {
//     const { id, title, posts = [], locationId } = topic
//
//     const navigateToPostDetailWithPost = (clickedPost: any) => {
//         navigateToPostDetail(topic, clickedPost)
//     }
//
//     function getWindowSize() {
//         const { innerWidth, innerHeight } = window;
//         return { innerWidth, innerHeight };
//     }
//
//     const [windowSize, setWindowSize] = useState(getWindowSize());
//
//     useEffect(() => {
//         function handleWindowResize() {
//             setWindowSize(getWindowSize());
//         }
//
//         window.addEventListener('resize', handleWindowResize);
//
//         return () => {
//             window.removeEventListener('resize', handleWindowResize);
//         };
//     }, []);
//
//     return (
//         <div className={""} style={{
//             display: 'flex',
//             flex: 1,
//             flexDirection: 'column'
//         }}>
//             <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <div style={{ flex: 58, display: 'flex', justifyContent: 'center' }}>
//                     <div style={{
//                         maxWidth: 460
//                     }}>
//                         <div onClick={onClick} className={"topic-container topic-styles-mob topic-styles-des topic-styles-tab"}>
//                             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
//                                 <text style={{
//                                     textAlign: 'start',
//                                     fontWeight: 600,
//                                     lineHeight:0.9,
//                                     fontSize: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 16 : 18,
//                                     flex:1,
//
//                                 }}>{title ? title : "Dock workers special status"}
//                                 </text>
//
//                                 <Spacer width={10} />
//                                 <img style={{ height: 21, width: 21 }} src={heart} alt="" />
//
//                             </div>
//
//                             {windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? null :<Spacer height={3} />}
//                             <div style={{ flexDirection: 'row', display: 'flex' }}>
//                                 <text style={{ fontSize: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 15 : 17, textAlign: 'start' }}>{locationId}</text>
//                             </div>
//
//
//                         </div>
//                         <div
//                             onClick={() => {
//                                 setClickedTopic(topic)
//                                 if (id === "local") {
//                                     setSelectTopicPostCreateModalVisible(true)
//                                 } else {
//                                     setPostModalVisible(true)
//                                 }
//                             }}
//                             className={"post-button create-post-style-mob create-post-style-des create-post-style-tab"}
//                         >
//                             <text style={{
//                                 fontStyle: 'italic',
//                                 textAlign: 'start',
//                                 color: '#D9D9D9',
//
//                                 fontSize:windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 14 : 16
//                             }}>What do you want to share about
//                                 this topic?
//                             </text>
//                         </div>
//                     </div>
//                 </div>
//                 {!(windowSize.innerWidth < MAX_PHONE_BREAK_POINT) && <div style={{ flex: 42 }}></div>}
//             </div>
//
//             <Spacer height={15} />
//             {posts.length > 0 ? (
//                 posts.map((post: any) => {
//                     return <HomePostDesktop
//                         selectedTopic={topic}
//                         onClick={() => navigateToPostDetail(topic, post)}
//                         navigateToPostDetailWithPost={navigateToPostDetailWithPost}
//                         post={post}
//                         postLocation={post.postLocation}
//                         deletePost={deletePost}
//                         userId={post?.author?.id}
//                         voteToggle={() => voteToggle(post.id)} />
//
//                 })
//             ) : (<PostEmpty />)}
//
//             {(posts && posts.length > 1) &&
//                 <div style={{ display: 'flex', cursor: 'pointer' }} onClick={onClick}>
//                     <text style={{ flex: 58, fontWeight: 'bold', marginTop: 10, alignSelf: "center", color: '#2443B2' }}>
//                         Read more content about this topic
//                     </text>
//                     {(windowSize.innerWidth > MAX_PHONE_BREAK_POINT) && <div style={{ flex: 42 }} />}
//                 </div>
//             }
//
//             <Spacer height={40} />
//
//         </div>)
// }
//
// export default HomeTopicDesktop

import HomePost from '../HomePostDesktop'
import './index.css'
import Spacer from '../Spacer'
import heart from '../../assets/icons/heart.svg'
import text from '../../assets/icons/text.svg'
import PostEmpty from '../PostEmpty'

const HomeTopicDesktop = ({
  contrastTitleShown,
  setContrastTitleShown,
  setPostModalVisible,
  onClick,
  topic,
  country,
  state,
  navigateToPostDetail,
  setClickedTopic,
  deletePost,
  voteToggle,
  setSelectTopicPostCreateModalVisible,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  user,
  postEditRules,
  updateAnalytics,
  addPostsToRedux,
  alarmToggle,
}: any) => {
  const { id, title, posts = [], locationId } = topic

  const navigateToPostDetailWithPost = (clickedPost: any) => {
    navigateToPostDetail(topic, clickedPost)
  }

  return (
    <div
      className={''}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 58, display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: 470 }}>
            <div
              style={{
                flex: 1,
                background: '#D9D9D9',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 40,
                paddingRight: 40,
              }}
              onClick={onClick}
              className={'topic-container'}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <text
                  style={{
                    textAlign: 'start',
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                >
                  {title ? title : 'Dock workers special status'}
                </text>
                <Spacer width={10} />
                <img style={{ height: 21, width: 21 }} src={heart} alt="" />
              </div>

              <Spacer height={3} />
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <text style={{ fontSize: 17, textAlign: 'start' }}>
                  {locationId}
                </text>
              </div>
            </div>
            <div
              onClick={() => {
                setClickedTopic(topic)
                if (id === 'local') {
                  setSelectTopicPostCreateModalVisible(true)
                } else {
                  setPostModalVisible(true)
                }
              }}
              className={'post-button'}
              style={{
                display: 'flex',
                border: '1px #D9D9D9 solid',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 40,
                paddingLeft: 38,
              }}
            >
              <text
                style={{
                  fontStyle: 'italic',
                  textAlign: 'start',
                  color: '#D9D9D9',
                }}
              >
                What do you want to share about this topic?
              </text>
            </div>
          </div>
        </div>
        <div style={{ flex: 42 }}></div>
      </div>

      <Spacer height={15} />
      {posts.length > 0 ? (
        posts.map((post: any) => {
          return (
            <HomePost
              user={user}
              selectPostDetails={selectPostDetails}
              editPostModalVisible={editPostModalVisible}
              setEditPostModalVisible={setEditPostModalVisible}
              selectedTopic={topic}
              onClick={() => navigateToPostDetail(topic, post)}
              navigateToPostDetailWithPost={navigateToPostDetailWithPost}
              post={post}
              postLocation={post.postLocation}
              deletePost={deletePost}
              userId={post?.author?.id}
              voteToggle={() => voteToggle(post?.id)}
              postEditRules={postEditRules}
              updateAnalytics={updateAnalytics}
              addPostsToRedux={addPostsToRedux}
              alarmToggle={() => alarmToggle(post?.id)}
            />
          )
        })
      ) : (
        <PostEmpty />
      )}

      {posts && posts.length > 1 && (
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={onClick}
          className="flex justify-center items-center"
        >
          <text
            style={{
              flex: 58,
              fontWeight: 'bold',
              marginTop: 10,
              alignSelf: 'center',
              color: '#2443B2',
              textAlign: 'center',
            }}
          >
            Read more content about this topic
          </text>
          <div style={{ flex: 42 }} />
        </div>
      )}

      <Spacer height={40} />
    </div>
  )
}

export default HomeTopicDesktop
