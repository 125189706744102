import React, { useEffect } from 'react'
import { Topic } from './types'
import TopicFollowButton from '../../../components-v2/TopicFollowButton'
import { locationToDisplay } from '../../../components-v2/Post/helpers'
import EntryDialog from '../../../components-v2/EntryDialog/EntryDialog'
import { useNavigate } from 'react-router-dom'
import AddSVG from '../../../assets/icons/add.svg'
import { useDispatch, useSelector } from 'react-redux'
import PostWithContrast from '../../../components-v2/Post/PostWithContrast'
import { filterConsecutiveCons } from '../../../utils/postHelper'
import { useQuery } from '@tanstack/react-query'
import { getPostsOfATopic } from '../helper'
import Loading from '../../../components-v2/Loading'
import { Skeleton } from '../../../components-v2/shadcn/skeleton'
import { cn } from '../../../utils/cn'
import { ChevronRight } from 'lucide-react'

type Props = {
  topic: Topic
  cb?: () => void
  addPostsToRedux: (userId: string) => void
  filterPostType: string
  filterOrganization: string
  setTopicData: any
}

export default function TopicComponent({
  topic,
  cb,
  addPostsToRedux,
  filterOrganization,
  filterPostType,
  setTopicData,
}: Props) {
  const navigate = useNavigate()
  const currentUser = useSelector((state: any) => state.login.user)
  const analyticsPosts = useSelector(
    (state: any) => state.analytics.viewed_posts
  )
  const permissions = currentUser?.roles?.permissions

  let {
    isLoading,
    isFetching,
    error,
    data: posts,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: [
      'getPosts',
      topic.id,
      topic.dimensions,
      filterOrganization,
      filterPostType,
    ],
    queryFn: getPostsOfATopic,
    initialData: [],
    refetchOnWindowFocus: false,
  })
  // console.log('🚀 ~ isFetching:', isFetching)

  // posts = []
  useEffect(() => {
    // if (posts?.length > 0) {
    setTopicData((topics: any) => {
      return {
        ...topics,
        [topic.id]: {
          posts: posts?.length > 0 ? posts : [],
          isFetching: isFetching,
        },
      }
    })
    // }
  }, [posts, isFetching, topic])

  // posts = []

  // if (isFetching) {
  //   return null
  // }

  // if (!isFetching && posts.length <= 0) {
  //   return null
  // }

  const hide = isFetching || (!isFetching && posts.length <= 0)

  // console.log(posts?.length)

  return (
    <div
      className={cn('mt-7 w-full', {
        hidden: hide,
      })}
    >
      <div className="w-[390px] mx-auto">
        <div
          className="flex cursor-pointer justify-between rounded-t-sm bg-line px-[12px] py-[10px] transition-all hover:bg-[#bfbfbf]"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            navigate(
              `/topic/${
                topic.title !== 'Local news' ? topic?.id : 'local/' + topic?.id
              }`
            )
          }}
        >
          <div className="w-full">
            <div className="flex items-center justify-between text-[14px] font-semibold leading-4">
              {topic.title}

              <TopicFollowButton
                // cb={getAllTopics}
                cb={cb}
                topicId={topic.id}
              />
            </div>
            <div className="flex items-center justify-between gap-4 pt-[3px]">
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                {/*{x?.address?.isObject()*/}
                {topic?.address
                  ? locationToDisplay(topic?.address)
                  : topic?.locationId}
              </p>
              <div className="mt-1 flex h-[12px] flex-shrink-0 items-end whitespace-nowrap text-[12px] font-semibold text-border underline underline-offset-4">
                See all posts
              </div>
            </div>
          </div>
        </div>
        <EntryDialog
          trigger={
            <div className="flex h-[28px] w-full max-w-[390px] cursor-pointer items-center gap-[6px] rounded-b-lg border border-line bg-[#F5F5F5] px-[12px] transition-all hover:bg-line/50 mx-mobile:w-full">
              <img src={AddSVG} className="h-4 w-4" />
              <p className="text-[11px] text-border">Create content</p>
            </div>
          }
          disableTopic={permissions?.topicCreation}
          articleAllow={permissions?.articleCreation}
          quotesAllow={permissions?.quotesCreation}
          parentTopicId={topic.id}
          cb={() => {
            // getAllTopics()
            refetch()
            cb && cb()
          }}
        />

        {isFetching && (
          <div className="flex justify-center mt-2">
            <Loading />
          </div>
        )}

        <div
          className={cn({
            'min-h-[400px]': posts?.length <= 0,
          })}
        >
          {/* {posts.length >= 0 && (
            <Skeleton className="w-full h-[200px] rounded-md" />
          )} */}
        </div>
      </div>

      {posts?.length > 0 && (
        <div className="mt-6 flex w-full flex-col gap-6 mx-mobile:px-5">
          {filterConsecutiveCons(posts)?.map((p: any, i: number) => {
            return (
              <PostWithContrast
                analyticsPosts={analyticsPosts}
                addPostsToRedux={addPostsToRedux}
                userLocation={currentUser?.userLocations?.address}
                isLocationModerator={p?.isLocationModerator}
                post={p}
                key={i}
                pageLoad={() => {
                  //   refetch()
                  cb && cb()
                }}
              />
            )
          })}
        </div>
      )}

      <p
        className={
          'mx-auto mb-[15px] mt-[20px] flex w-fit cursor-pointer items-center rounded-md p-1 text-center font-inter text-xs font-semibold leading-[14.52px] text-[#2443B2] transition-all hover:bg-line'
        }
        onClick={() => {
          navigate('/topic/' + topic.id)
        }}
      >
        Read more <ChevronRight size={16} />
      </p>
    </div>
  )
}
