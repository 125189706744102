import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

//redux
import { createPosts } from '../../redux/posts/actions'
import { getAllOrganizations } from '../../redux/organizations/actions'
import { searchTopics } from '../../redux/topics/actions'
import './index.css'

//components
import SelectTopicPostCreateContent from '../../components/SelectTopicPostCreateContent'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'

const SelectTopicPostCreateModal = (prop: any) => {
  const {
    user,
    setSelectTopicPostCreateModalVisible,
    createPosts,
    selectedTopics,
    clickedPost,
    postLoadIndicator,
    organizations,
    getAllOrganizations,
  } = prop

  const [postTypeId, setpostTypeid] = useState('') //post type id
  const [description, setDescription] = useState() //description
  const [org, setOrg] = useState() //organization
  const [imageUrl, setImageUrl] = useState('') //image url
  const [locationsData, setLocationsData] = useState('') //location data from API
  const [locations, setLocations] = useState('')
  const [selectlocation, setSelectLocation] = useState<any>()

  const [topic, setTopic] = useState<any>()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData(locations)
      searchTopics(locations != '' ? locations : '`')
        .then((data) => {
          // Handle the data
          setTopic(data)
        })
        .catch((error) => {
          // Handle the error
        })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [locations])

  const fetchData = async (location: any) => {
    try {
      const response = await axios.get(
        'https://nominatim.openstreetmap.org/search?q=' +
          location +
          '&format=json&addressdetails=1&countrycodes=us&accept-language=us&polygon_geojson=1'
      ) // Replace with your API endpoint
      const data = response.data // Extract the data from the response
      const newLocationData = data.map((location: any) => {
        return {
          id: location.place_id,
          name: location.display_name,
          ...location,
        }
      })
      setLocationsData(newLocationData)
    } catch (error) {
      console.error(error)
    }
  }

  const [image, setImgae] = useState(null) //image

  const handleTextWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setDescription(textWithLineBreaks)
  }

  useEffect(() => {}, [selectlocation])

  const [selectTopicFromModal, setSelectTopicFromModal] = useState()

  const clickSubmit = () => {
    if (image == null) {
      createPosts(
        selectTopicFromModal,
        postTypeId,
        description,
        null,
        clickedPost ? clickedPost.id : null,
        org ? org : null,
        selectlocation?.id,
        selectlocation?.name,
        selectlocation?.geojson,
        selectlocation?.address,
        selectlocation?.addresstype
      )
      setSelectTopicPostCreateModalVisible(false)
    } else {
      const imageRef = ref(storage, `post-images/${image['name'] + uuidv4()}`)
      uploadBytes(imageRef, image).then((e) => {
        getDownloadURL(e.ref).then((url) => {
          setImageUrl(url)
          createPosts(
            selectTopicFromModal,
            postTypeId,
            description,
            url,
            clickedPost ? clickedPost.id : null,
            org ? org : null,
            selectlocation?.id,
            selectlocation?.name,
            selectlocation?.geojson,
            selectlocation?.address,
            selectlocation?.addresstype
          )
          setSelectTopicPostCreateModalVisible(false)
        })
      })
    }
  }

  useEffect(() => {
    if (image == null) return
  }, [image])

  useEffect(() => {
    getAllOrganizations()
    listAll(ref(storage, 'post-images/')).then((e) => {})
  }, [])

  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 50,
      }}
      onClick={() => {
        setSelectTopicPostCreateModalVisible(false)
      }}
    >
      <SelectTopicPostCreateContent
        onCancel={() => setSelectTopicPostCreateModalVisible(false)}
        user={user}
        setPostModalVisible={setSelectTopicPostCreateModalVisible}
        setpostTypeid={setpostTypeid}
        description={description}
        handleTextWithLineBreaks={handleTextWithLineBreaks}
        clickSubmit={clickSubmit}
        selectedTopic={selectedTopics}
        clickedPost={clickedPost}
        organizationsData={organizations}
        setOrg={setOrg}
        org={org}
        setImgae={setImgae}
        image={image}
        showOrganizationSelection
        setLocations={setLocations}
        setSelectLocation={setSelectLocation}
        locationsData={locationsData}
        topicData={topic}
        setSelectTopicFromModal={setSelectTopicFromModal}
      />
      {/* {postLoadIndicator && <Progress/>} */}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userLoginError: state.login.userLoginError,
    // topicsID:state.topics.topicsID,
    postLoadIndicator: state.posts.postLoadIndicator,

    selectedTopics: state.topics.topicsID,
    clickedPost: state.posts.clickedPost,
    organizations: state.organization.organizations,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createPosts: (
      topicId: string,
      postTypeId: string,
      description: string,
      mediaId?: any,
      contrasts?: string,
      organizationId?: string,
      placeID?: any,
      locationName?: any,
      geoJson?: any,
      address?: any,
      addressType?: any
    ) => {
      dispatch(
        createPosts(
          topicId,
          postTypeId,
          description,
          mediaId,
          contrasts,
          organizationId,
          placeID,
          locationName,
          geoJson,
          address,
          addressType
        )
      )
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectTopicPostCreateModal)
