import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import './index.css'

import {
  deleteUserFromOrganization,
  updateUserTypeForOrganization,
} from '../../redux/organizations/actions'

import Spacer from '../../components/Spacer'
import OnBoardButton from '../../components/OnBoardButton'
import ProfileBadgeWithLocationAndOrg from '../../components/ProfileBadgeWithLocationAndOrg'

import dropDown from '../../assets/icons/drop-down.svg'
import icon from '../../assets/icons/icon.svg'
import lens from '../../assets/icons/lens.svg'
import DeleteConfirmModal from '../../components/DeleteConfirmModal'

const popover = (
  <Popover style={{ width: '300px' }}>
    <Popover.Header as="h3">Popover right</Popover.Header>
    <Popover.Body>
      <b>Admin</b>
      <ul>
        <li>Can edit the profile of an organization</li>
        <li>Can add/remove members of an organization</li>
        <li>Can leave an organization him/herself;</li>
        <li>Can contribute content as a member of that organization.</li>
      </ul>
    </Popover.Body>
  </Popover>
)

const DeleteMemberToOrgModal = ({
  deleteUserFromOrganization,
  organizationId,
  setDeleteMemberToOrgModalVisible,
  deleteMemberToOrgModalVisible,
  deleteUserFromOrganizationLoading,
  selectedUserDeleteFromOrganization,
  updateUserTypeForOrganization,
  deleteUserFromOrganizationErrorMessage,
}: any) => {
  const [showRoleList, setShowRoleList] = useState(false)
  const [showOverlay, setShowOverLay] = useState(false)
  const [selectRole, setSelectRole] = useState('Admin') //selected role for organization
  const roleSelector = useRef(null)

  const [deleteAlert, setDeleteAlert] = useState(false)

  const onSelectRole = (role: any) => {
    //this will fire when use click on drop down
    setSelectRole(role)
    setShowRoleList(!showRoleList)
  }

  const onDeleteUserFromOrganization = () => {
    deleteUserFromOrganization(
      selectedUserDeleteFromOrganization?.id,
      organizationId
    )
    // if(deleteUserFromOrganizationErrorMessage?.error){
    alert(deleteUserFromOrganizationErrorMessage?.error)
    setDeleteMemberToOrgModalVisible(!deleteMemberToOrgModalVisible)
    // }
  }

  const cancelDelete = () => {
    setDeleteAlert(!deleteAlert)
    setDeleteMemberToOrgModalVisible(!deleteMemberToOrgModalVisible)
  }

  const onUpdateUserForOrganization = () => {
    updateUserTypeForOrganization(
      selectedUserDeleteFromOrganization?.id,
      selectRole,
      organizationId
    )
    setDeleteMemberToOrgModalVisible(!deleteMemberToOrgModalVisible)
    setDeleteAlert(!deleteAlert)
  }

  return (
    <div className={'main-div-delete-member'}>
      {deleteAlert && (
        <DeleteConfirmModal
          deleteEvent={onDeleteUserFromOrganization}
          cancelEvent={() => cancelDelete()}
        />
      )}
      <div className={'second-main-div-delete-member'}>
        <div style={{ background: '#FBFBFD' }}>
          <div className={'flex-style-heading-delete-member'}>
            <p className={'body-text-style-delete-member'}>Associated Press</p>
          </div>
          <div style={{ marginTop: '40px', marginBottom: '50px' }}>
            <div
              style={{
                overflowX: 'hidden',
                maxWidth: '100%',
                position: 'relative',
              }}
            >
              <div>
                <div className={'main-div-profile-badge-delete-member'}>
                  {/*user profile*/}
                  <div style={{ display: 'flex' }}>
                    <img
                      src={selectedUserDeleteFromOrganization?.imageUrl}
                      className={'profile-badge-delete-member'}
                      alt={''}
                    />

                    <Spacer width={10} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <text className={'text-name-delete-member'}>
                        {selectedUserDeleteFromOrganization?.name}
                      </text>
                      <text className={'text-email-delete-member'}>
                        {selectedUserDeleteFromOrganization?.userTypeId}
                      </text>
                    </div>
                    <Spacer width={15} />
                  </div>
                </div>
                <div className={'drop-down-role-div-delete-member'}>
                  <div>
                    <span className={'make-bold-letter-delete-member'}>
                      Role
                    </span>
                  </div>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                  >
                    <div style={{ marginLeft: '1px', marginTop: '7px' }}>
                      <img
                        id="overlay"
                        src={icon}
                        alt="icon"
                        onClick={() => {
                          setShowOverLay(!showOverlay)
                        }}
                      />
                      {showOverlay && (
                        <div className={'overlay-div-delete-member'}>
                          <h5 style={{ paddingLeft: '13px' }}>Admin</h5>
                          <ul className={'padding-left-delete-member'}>
                            <li>Can edit the profile of an organization;</li>
                            <li>Can add/remove members of an organization;</li>
                            <li>Can leave an organization him/herself;</li>
                            <li>
                              Can contribute content as a member of that
                              organization.
                            </li>
                          </ul>
                          <br />
                          <h5 style={{ paddingLeft: '13px' }}>Member</h5>
                          <ul className={'padding-left-delete-member'}>
                            <li>
                              Can contribute content as a member of that
                              organization.
                            </li>
                            <li>Can leave an organization</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
                <label>
                  {' '}
                  {/*disapear when user select item from drop down*/}
                  <input
                    placeholder="Search User"
                    name="copy-button"
                    className={'role-add-drop-down-delete-member'}
                    onClick={(e) => setShowRoleList(!showRoleList)}
                    ref={roleSelector}
                    value={selectRole}
                  />
                  <img id="icon" src={dropDown} alt="icon" />
                </label>
                {showRoleList && (
                  <div className={'drop-down-list-style-delete-member'}>
                    {/*disapear when user select item from drop down*/}
                    {['Admin', 'Member']?.map((role: any) => {
                      return (
                        <p
                          style={{ textAlign: 'start', paddingLeft: '5px' }}
                          onClick={() => onSelectRole(role)}
                          className={'drop-down-hover-list-style-delete-member'}
                        >
                          {role}
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <OnBoardButton
              onClick={() => {
                onUpdateUserForOrganization()
              }}
              width={'296px'}
              buttonName={'Confirm'}
              loading={deleteUserFromOrganizationLoading}
              disabled={false}
            />
            <Spacer height={8} />
            <OnBoardButton
              onClick={() => setDeleteAlert(!deleteAlert)}
              width={'296px'}
              buttonName={
                <span className={'font-black text-red-500'}>
                  Remove user from organization
                </span>
              }
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    deleteUserFromOrganizationLoading:
      state.organization.deleteUserFromOrganizationLoading,
    deleteUserFromOrganizationError:
      state.organization.deleteUserFromOrganizationError,
    organizationId: state.organization.organization?.id,
    selectedUserDeleteFromOrganization:
      state?.organization?.selectedUserDeleteFromOrganization,
    deleteUserFromOrganizationErrorMessage:
      state?.organization?.deleteUserFromOrganizationErrorMessage,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteUserFromOrganization: (memberId: any, organizationId: any) => {
      dispatch(deleteUserFromOrganization(memberId, organizationId))
    },
    updateUserTypeForOrganization: (
      userIdToBeUpdate: any,
      roleToBeChanged: any,
      organizationId: any
    ) => {
      dispatch(
        updateUserTypeForOrganization(
          userIdToBeUpdate,
          roleToBeChanged,
          organizationId
        )
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteMemberToOrgModal)
