import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { notEmpty } from '../../utils/formHelpers'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '../../components-v2/shadcn/input'
import Button from '../../components-v2/Button'
import { getTokenDetails, setNewPassword } from './helpers'
import { toast } from 'sonner'

type Props = {}

const schema = z
  .object({
    password: z.string().min(6).pipe(notEmpty),
    confirm: z.string().pipe(notEmpty),
  })
  .refine((data) => data.password === data.confirm, {
    message: "Passwords don't match",
    path: ['confirm'],
  })

type SchemaType = z.infer<typeof schema>

export default function ResetPasswordPage({}: Props) {
  const [searchParams] = useSearchParams()
  const [tokenData, setTokenData] = useState(null)
  const [success, setSuccess] = useState(false)

  const navigate = useNavigate()

  const token = searchParams.get('token')
  // console.log(tokenData)

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (token) {
      getTokenDetails(token)
        .then((data) => {
          setTokenData(data)
          setSuccess(true)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err?.response?.data?.message || 'Something went wrong')
          navigate('/')
        })
    }
  }, [token])

  return (
    <div>
      <PublicHeader />
      {success && (
        <form className="flex flex-col mt-5 pb-5 max-w-[450px] mx-auto px-5">
          <h1 className="text-2xl font-bold mt-5">Set a new Password</h1>

          <div className="mt-6">
            <label className="text-xs font-bold">Password*</label>
            <Input
              type="password"
              className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
              placeholder="Password"
              {...register('password')}
            />
            {errors?.password && (
              <p className="text-xs text-red-500 font-semibold">
                {errors?.password.message}
              </p>
            )}
          </div>

          <div className="mt-6">
            <label className="text-xs font-bold">Confirm Password*</label>
            <Input
              type="password"
              className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
              placeholder="Confirm Passsord"
              {...register('confirm')}
            />
            {errors?.confirm && (
              <p className="text-xs text-red-500 font-semibold">
                {errors?.confirm.message}
              </p>
            )}
          </div>

          <div className="flex items-center justify-end mt-5">
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit(async (value) => {
                // console.log('🚀 ~ onClick={handleSubmit ~ value:', value)
                return setNewPassword({ ...value, token: token })
                  .then((data) => {
                    toast.success('Password reset, Please Login.')
                    navigate('/login')
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })}
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
