import React, { useEffect, useState } from 'react'
import {
  updateAnalytics,
  addImpressionsToRedux,
} from '../../redux/analytics/actions'

import { ANALYTICS_API_CALL_TIME } from '../../utils/constants'
import { connect } from 'react-redux'

const AnalyticsTrackerWrapper = ({
  children,
  inView,
  viewed_posts,
  updateAnalytics,
  addImpressionsToRedux,
  impressed_posts,
}: any) => {
  const [oldSelectedArray, setOldSelectedArray] = useState(viewed_posts)
  const [impressedPosts, setImpressedPosts] = useState(null)

  const addMissingElements = (firstArray: any[], secondArray: any) => {
    for (const element of secondArray) {
      const postIdExists = firstArray.some(
        (item) => item.postId === element.postId
      )
      if (!postIdExists) {
        firstArray.push(element)
      }
    }
    return firstArray
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const oldArray = oldSelectedArray

      const differenceArray = viewed_posts?.filter(
        (item: any) => !oldArray?.includes(item)
      )

      if (differenceArray?.length > 0) {
        //if there is a difference
        updateAnalytics(differenceArray) //fire the api

        //setImpressedPosts(differenceArray)
      } else {
        return
      }
      setOldSelectedArray(viewed_posts) //update the old array
    }, ANALYTICS_API_CALL_TIME)

    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [viewed_posts, oldSelectedArray])

  // window.addEventListener('beforeunload', async (event) => {//API call before page reload
  //     // Cancel the event as stated by the standard.
  //
  //     try {
  //
  //         updateAnalytics(viewed_posts)//fire the api~
  //     } catch (error) {
  //         console.error("Error calling API:", error);
  //     }
  //
  //     const confirmationMessage = "Changes you made may not be saved.";
  //
  //     // Standard for most browsers
  //     event.returnValue = confirmationMessage;
  //
  //     // For some older browsers
  //     return confirmationMessage;
  //
  // });

  return <>{children}</>
}

const mapStateToProps = (state: any) => {
  return {
    //analytics
    viewed_posts: state.analytics.viewed_posts,
    impressed_posts: state.analytics.impressed_posts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAnalytics: (postIds: any) => {
      dispatch(updateAnalytics(postIds))
    },
    addImpressionsToRedux: (impressionedPosts: any) => {
      dispatch(addImpressionsToRedux(impressionedPosts))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsTrackerWrapper)
