import React, { useEffect, useState } from 'react'
import './index.css'
import AutoCompleteTwoText from '../../components/AutoCompleteTwoText'

import axios from 'axios'

import { TextField } from '@mui/material'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import 'react-datepicker/dist/react-datepicker.css'
import PostCreateContent from '../../components/PostCreateContent'
import { connect } from 'react-redux'
import Post from '../../components/Post'
import ArticlePostPreview from '../../components/ArticlePostPreview'
import { BeatLoader } from 'react-spinners'
import { addArticles } from '../../redux/articles/action'
import { getAllOrganizations } from '../../redux/organizations/actions'
import { searchTopics } from '../../redux/topics/actions'
import plus from '../../assets/icons/plus-black.svg'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase'
import { v4 as uuidv4 } from 'uuid'
import wrongBlue from '../../assets/icons/blue-wrong.svg'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'
import ChannelPicker from '../ChannelPicker'

const ArticleCreateModal = ({
  setPostModalVisible,
  setArticleModalVisible,
  user,
  article,
  addArticles,
  topic: selectedTopic,
  getAllOrganizations,
  organizations,
  paramForArticleModal,
}: any) => {
  const [createPostModal, setCreatePostModal] = useState(false)
  const [posts, setPosts] = useState<any[]>([])

  const [articleUrl, setArticleUrl] = useState('') //article link

  const [articleContent, setArticleContent] = useState(null)
  const [articleContentLoading, setArticleContentLoading] = useState(false)
  const [scrapImgIndex, setScrapImgIndex] = useState(0)
  const [locationsData, setLocationsData] = useState('') //location data from API

  const [postTypeId, setpostTypeid] = useState('') //post type id
  const [description, setDescription] = useState() //description
  const [title, setTitle] = useState() //title
  const [imgUrl, setImgUrl] = useState('') //image url
  const [articleObj, setArticleObj] = useState({}) //article obj
  const [locations, setLocations] = useState('')

  const [org, setOrg] = useState(null)
  const [image, setImgae] = useState(null) //image
  const [topic, setTopic] = useState<any>()
  // const [postOrg, setPostOrg] = useState(null);

  const [selectedItemToDisplay, setSelectedItemToDisplay] = useState<any>()
  const [countryToDisplay, setCountryToDisplay] = useState<any>()
  const [selectedLocationFrmAutoComp, setSelectedLocationFrmAutoComp] =
    useState<any>()
  const [selectLocation, setSelectLocation] = useState<any>()
  const [selectedTopicFrmAutoComp, setSelectedTopicFrmAutoComp] =
    useState<any>()
  const [selectTopicFromModal, setSelectTopicFromModal] = useState()

  const [validateArticle, setValidateArticle] = useState(false)

  //loading location and topic
  const [locationLoading, setLocationLoading] = useState(false)
  const [topicLoading, setTopicLoading] = useState(false)

  const {
    title: topicTitle = '',
    description: topicDescription = '',
    locationId = '',
  } = selectedTopic || {}

  const fetchData = async (location: any) => {
    setLocationLoading(true)
    try {
      const response = await axios.get(
        'https://nominatim.openstreetmap.org/search?q=' +
          location +
          '&format=json&countrycodes=us&addressdetails=1&polygon_geojson=1&accept-language=us'
      ) // Replace with your API endpoint
      const data = response.data // Extract the data from the response
      const newLocationData = data.map((location: any) => {
        return {
          id: location.place_id,
          name: location.display_name,
          ...location,
        }
      })
      setLocationLoading(false)
      setLocationsData(newLocationData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {}, [selectedLocationFrmAutoComp, selectedTopicFrmAutoComp])

  const getTopicForPost = () => {
    if (selectedLocationFrmAutoComp) {
      return { title: selectedLocationFrmAutoComp }
    } else if (selectedTopicFrmAutoComp) {
      return { title: selectedTopicFrmAutoComp }
    } else {
      return selectedTopic
    }
  }

  useEffect(() => {
    setTopicLoading(true)
    const delayDebounceFn = setTimeout(() => {
      fetchData(locations)
      searchTopics(locations != '' ? locations : '`')
        .then((data) => {
          // Handle the data
          setTopic(data)
          setTopicLoading(false)
        })
        .catch((error) => {
          // Handle the error
        })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [locations])

  useEffect(() => {
    getAllOrganizations()
  }, [])

  useEffect(() => {
    if (articleUrl === '') {
      setArticleContent(null)
    }
  }, [articleUrl])

  useEffect(() => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      setImgUrl(imageUrls[scrapImgIndex])
    }
  }, [scrapImgIndex])

  useEffect(() => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      setTitle(titles[0])
    }
  }, [articleContent])

  const makeNull = () => {
    setSelectedItemToDisplay(null)
    setCountryToDisplay(null)
  }

  const saveArticle = () => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      const obj = {
        url: articleUrl,
        title: title,
        topicId: selectLocation ? null : selectTopicFromModal,
        imageUrl: imgUrl ? imgUrl : imageUrls[scrapImgIndex],
        posts: posts,
        organizationId: org !== 0 ? org : null,
        selectLocation: selectLocation,
      }
      setArticleObj(obj)
      addArticles(obj)
    } else {
      //if article obj null

      const obj = {
        // "topicId":selectedTopics.id,
        url: articleUrl,
        title: title,
        topicId: selectedTopic?.id,
        imageUrl: imgUrl,
        posts: posts,
        organizationId: org !== 0 ? org : null,
        selectLocation: selectLocation,
      }
      setArticleObj(obj)
      addArticles(obj)
    }
    closeModal()
  }

  const closeModal = () => {
    setArticleModalVisible(false)
  }

  const addPost = (post: any) => {
    const newPosts = [...posts]

    if (org) {
      post.organizationId = org
    }
    newPosts.push(post)
    setPosts(newPosts)
  }

  const crawlArticleUrl = async () => {
    setArticleContentLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/common/scrape?url=${articleUrl}`
      )
      const content = await response.json()
      setArticleContent(content)
      setArticleContentLoading(false)
    } catch (error) {
      setArticleContent(null)
      setArticleContentLoading(false)
    }
  }

  //validate article use effect
  useEffect(() => {
    let validate = false
    if (!articleContent && posts.length == 0 && !org) {
      validate = false
    } else if (articleContent && posts.length > 0 && org) {
      validate = true
    }
    setValidateArticle(validate)
  }, [articleContent, posts, org])

  const handleTextWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setDescription(textWithLineBreaks)
  }

  const clickSubmit = async () => {
    const postObject: any = {
      title: title,
      description: description,
      topicId: selectedTopic?.id,
      postTypeId: postTypeId,
    }

    if (image) {
      const imageRef = ref(storage, `post-images/${image['name'] + uuidv4()}`)
      await uploadBytes(imageRef, image).then((e) => {
        getDownloadURL(e.ref)
          .then((url) => {
            postObject.mediaId = url
            addPost(postObject)
            setCreatePostModal(false)
          })
          .catch((e) => {
            return
          })
      })
    } else {
      addPost(postObject)
      setCreatePostModal(false)
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      style={{
        zIndex: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000B2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
      onClick={() => {
        setArticleModalVisible(false)
      }}
    >
      <div
        className="second-div-mobile second-div-des"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={'text_header'}>
          <text>Create new</text>
          <text style={{ fontWeight: 'bold' }}> ARTICLE</text>
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div className="div-detail-box-des div-detail-box-mobile">
            <div
              style={{
                background: '#FBFBFD',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
              }}
            >
              {/*<text style={{textAlign: 'start', fontWeight: 'bold'}}>{topicTitle}</text>*/}
              {/* <div style={{flexDirection: 'row', display: 'flex'}}> */}
              {/* <text style={{textAlign: 'start'}}>{locationId}</text> */}
              {selectedItemToDisplay ? (
                <div
                  style={{
                    background: '#D9D9D9',
                    display: 'flex',
                    padding: 12,
                    borderRadius: 18,
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    {windowSize.innerWidth > MAX_PHONE_BREAK_POINT ? (
                      <text
                        style={{
                          textAlign: 'start',
                          fontWeight: 'bold',
                        }}
                      >
                        {selectedItemToDisplay}
                      </text>
                    ) : (
                      <p
                        style={{
                          textAlign: 'start',
                          fontWeight: 'bold',
                          fontSize: '15px',
                        }}
                      >
                        {selectedItemToDisplay}
                      </p>
                    )}
                    <p style={{ textAlign: 'start' }}>
                      {countryToDisplay ? countryToDisplay : null}
                    </p>
                  </div>
                  <div>
                    <img
                      style={{
                        height: 18,
                        width: 18,
                        marginRight: 5,
                        marginTop: 2,
                      }}
                      src={wrongBlue}
                      onClick={() => {
                        makeNull()
                      }}
                      alt="ksdsd"
                    />
                  </div>
                </div>
              ) : (
                <AutoCompleteTwoText
                  firstDataListTopic="Locations..." //topic for first data list
                  firstDataList={locationsData} //data for the first data list
                  secondDataListTopic="Topics..." //topic for second data list
                  secondDataList={topic} //data for the second data list
                  InputPlaceHolder="Select location or topic..."
                  setFirstDataItem={setSelectedLocationFrmAutoComp}
                  setSelectLocation={setSelectLocation}
                  setCountryToDisplay={setCountryToDisplay}
                  setSecondDataItem={setSelectedTopicFrmAutoComp}
                  searchText={setLocations}
                  clearTheSelections={
                    selectedTopicFrmAutoComp && selectedLocationFrmAutoComp
                      ? true
                      : false
                  }
                  setSelectTopicFromModal={setSelectTopicFromModal}
                  setSelectedItemToDisplay={setSelectedItemToDisplay}
                  place="article"
                  firstDataListloading={locationLoading}
                  secondDataListloading={topicLoading}
                />
              )}
            </div>

            <Spacer height={40} />

            <div style={{ maxHeight: '290px', overflowX: 'scroll' }}>
              <ChannelPicker
                setOrg={setOrg}
                articlePicker={true}
                width={'360px'}
              />
            </div>

            <div style={{ overflow: 'scroll' }}>
              {!articleContent && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 20,
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      style={{
                        width: '100%',
                        fontSize: 20,
                        color: '#000000',
                      }}
                      size={'small'}
                      label="Article link"
                      variant="standard"
                      className={'TextField'}
                      sx={{
                        width: 300,
                        color: 'success.main',
                      }}
                      onChange={(e: any) => {
                        setArticleUrl(e.target.value)
                      }}
                    />

                    {articleContentLoading && (
                      <BeatLoader color="#303655" size={10} />
                    )}
                  </div>
                  <Spacer height={10} />
                  <div style={{ width: 170 }}>
                    <Button
                      title={'Load RSS Data'}
                      onClick={() => crawlArticleUrl()}
                    />
                  </div>
                </>
              )}

              {articleContent && (
                <div style={{ marginLeft: '2%' }}>
                  <Spacer height={20} />
                  {/*{(<SimpleDropdown*/}
                  {/*    organizationsData={organizations}*/}
                  {/*    setOrg={setOrg}*/}
                  {/*/>)}*/}

                  <Spacer height={40} />
                  {/*{org && (<OrganisationSelector*/}
                  {/*    organization={organizations.filter((o: any) => o.id === org)[0]}/>)}*/}
                  <Spacer height={40} />
                  <ArticlePostPreview
                    setImgUrl={setImgUrl}
                    content={articleContent}
                    setScrapImgIndex={setScrapImgIndex}
                  />
                </div>
              )}

              {windowSize.innerWidth < MAX_PHONE_BREAK_POINT && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: '70px',
                    }}
                  >
                    <text className={'text_header_bold'}>Posts</text>
                    {posts.length > 0 && (
                      <text className={'text_header_bold'}>
                        ({posts.length})
                      </text>
                    )}
                    <Spacer width={10} />
                    <div
                      onClick={() => {
                        // setPostOrg(null)
                        setImgae(null)
                        setCreatePostModal(true)
                      }}
                    >
                      <img
                        className={'contrast-button'}
                        style={{ height: 27, width: 27, marginBottom: -3 }}
                        src={plus}
                        alt=""
                      />
                    </div>
                  </div>

                  <div style={{ marginLeft: '2%' }}>
                    {posts.map((post: any) => {
                      const { organizationId } = post
                      const organizationObject = organizations.filter(
                        (o: any) => o.id === organizationId
                      )
                      post.organization =
                        organizationObject && organizationObject.length > 0
                          ? organizationObject[0]
                          : null
                      post.author = user

                      return (
                        <div>
                          <Post deletePost={() => {}} post={post} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {windowSize.innerWidth > MAX_PHONE_BREAK_POINT ? (
            <>
              <div style={{ width: 1, background: '#000000' }}></div>

              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 50,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <text className={'text_header_bold'}>Posts</text>
                  {posts.length > 0 && (
                    <text className={'text_header_bold'}>({posts.length})</text>
                  )}
                  <Spacer width={10} />
                  <div
                    onClick={() => {
                      // setPostOrg(null)
                      setImgae(null)
                      setCreatePostModal(true)
                    }}
                  >
                    <img
                      className={'contrast-button'}
                      style={{ height: 27, width: 27, marginBottom: -3 }}
                      src={plus}
                      alt=""
                    />
                  </div>
                </div>

                <div style={{ overflow: 'scroll' }}>
                  {posts.map((post: any) => {
                    const { organizationId } = post
                    const organizationObject = organizations.filter(
                      (o: any) => o.id === organizationId
                    )
                    post.organization =
                      organizationObject && organizationObject.length > 0
                        ? organizationObject[0]
                        : null
                    post.author = user

                    return (
                      <div>
                        <Post deletePost={() => {}} post={post} />
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>

        {windowSize.innerWidth > MAX_PHONE_BREAK_POINT ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button title={'Cancel'} onClick={() => closeModal()} />
            <div style={{ display: 'flex' }}>
              <Button title={'Save for later'} />
              <Spacer width={10} />
              <Button
                title={'Create'}
                disable={!validateArticle}
                onClick={() => {
                  saveArticle()
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button title={'Cancel'} onClick={() => closeModal()} />

            <Button title={'Save for later'} />
            <Button
              title={'Create'}
              disable={validateArticle}
              onClick={() => {
                saveArticle()
              }}
            />
          </div>
        )}
        {createPostModal && (
          <div
            style={{
              zIndex: 300,
              position: 'absolute',
              background: '#000000bb',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => {
              setCreatePostModal(false)
            }}
          >
            <PostCreateContent
              selectedTopic={getTopicForPost()}
              onCancel={() => {
                setCreatePostModal(false)
              }}
              onPost={(post: any) => {
                setCreatePostModal(false)
                addPost(post)
              }}
              handleTextWithLineBreaks={handleTextWithLineBreaks}
              user={user}
              setpostTypeid={setpostTypeid}
              setDescription={setDescription}
              description={description}
              clickSubmit={clickSubmit}
              organizationsData={organizations}
              setOrg={setOrg}
              org={org}
              setImgae={setImgae}
              image={image}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userLoginError: state.login.userLoginError,
    article: state.article.article,
    selectedTopics: state.topics.selectedTopics,
    topic: state.topics.topic,
    organizations: state.organization.organizations,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addArticles: (obj: any) => {
      dispatch(addArticles(obj))
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCreateModal)
