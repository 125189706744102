import {
  GET_POST_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
} from '../posts/types'
import { Dispatch } from 'react'
import { getAuth } from '../../utils/auth'
import axios from 'axios'
import { getOrganizationArticles } from '../organizations/actions'
import {
  GET_ARTICLE_FAILURE,
  GET_ARTICLE_REQUEST,
  GET_ARTICLE_SUCCESS,
  GET_TOPIC_ARTICLE_FAILURE,
  GET_TOPIC_ARTICLE_REQUEST,
  GET_TOPIC_ARTICLE_SUCCESS,
} from './types'

const transport = axios.create({
  withCredentials: false,
})

export const getArticleRequest = () => {
  return {
    type: GET_ARTICLE_REQUEST,
  }
}

export const getArticleSuccess = (articles: any) => {
  return {
    type: GET_ARTICLE_SUCCESS,
    payload: articles,
  }
}

export const getArticleFailure = () => {
  return {
    type: GET_ARTICLE_FAILURE,
  }
}

export const getArticle = (articleId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getArticleRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/article/${articleId}`, getAuth())
      .then((res) => {
        dispatch(getArticleSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getArticleFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getTopicArticlesRequest = () => {
  return {
    type: GET_TOPIC_ARTICLE_REQUEST,
  }
}

export const getTopicArticlesSuccess = (articles: any) => {
  return {
    type: GET_TOPIC_ARTICLE_SUCCESS,
    payload: articles,
  }
}

export const getTopicArticlesFailure = () => {
  return {
    type: GET_TOPIC_ARTICLE_FAILURE,
  }
}

export const getTopicArticles = (topicId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getTopicArticlesRequest())
    transport
      .get(
        `${process.env.REACT_APP_BASE_URL}/article/topic/${topicId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(getTopicArticlesSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getTopicArticlesFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const deleteArticlesForTopic = (artcileId: any, topicId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .delete(
        `${process.env.REACT_APP_BASE_URL}/article/${artcileId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(getTopicArticles(topicId))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const deleteArticlesForOrganization = (artcileId: any, orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .delete(
        `${process.env.REACT_APP_BASE_URL}/article/${artcileId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(getOrganizationArticles(orgId))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}
