import React, { useEffect, useState } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '../../../shadcn/avatar'
import { X } from 'lucide-react'
import { cn } from '../../../../utils/cn'
import NetworkSVG from '../../../../assets/icons/fi-rr-chart-network.svg'
import LocationSVG from '../../../../assets/icons/fi-rr-location-alt.svg'
import { getOne } from '../../../../services/user'
import VerifiedIconSVG from '../../../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../../../assets/icons/public-yellow.svg'

type Props = {
  userId?: string
  action?: React.ReactNode
  name?: string
  roleName?: string
  profileImageUrl?: string
  location?: string
  organization?: string
  onClickCard?: () => void
  isVerified?: boolean
  isPublic?: boolean
  forLocationPurpose?: boolean
}

export default function PartyCard({
  action,
  name = 'John Doe',
  roleName,
  profileImageUrl,
  userId,
  location,
  organization,
  onClickCard,
  isVerified = true,
  isPublic = true,
  forLocationPurpose,
}: Props) {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (userId) {
      getOne(userId).then((data) => {
        setData({
          name: data.name,
          roleName: data.roles?.name,
          profileImageUrl: data?.imageUrl,
          location: data?.userLocation?.city,
          organization: data?.organizationUsers?.[0]?.name,
          isVerified: data?.verified,
          isPublic: data?.public,
        })
      })
    }
  }, [userId])

  // console.log({ userId })
  useEffect(() => {
    setData({
      name,
      roleName,
      profileImageUrl,
      location,
      organization,
    })
  }, [name, roleName, profileImageUrl, location, organization])

  return (
    <div
      className={cn('flex w-full items-center transition-all', {
        'cursor-pointer rounded p-1 hover:bg-gray-100 ': !!onClickCard,
        'pointer-events-none bg-gray-300': forLocationPurpose && !isVerified,
      })}
      onClick={onClickCard}
    >
      <Avatar className="mr-[22px]">
        <AvatarImage
          src={data?.profileImageUrl}
          className="h-full w-full object-cover"
        />
        {/* TODO: Render 2 letters of the name */}
        <AvatarFallback>AB</AvatarFallback>
      </Avatar>

      <div className="flex-grow">
        <div className={'flex flex-row'}>
          <p className="mr-2 text-xs font-semibold">{data?.name}</p>
          {isVerified && (
            <img
              src={VerifiedIconSVG}
              className="h-[15px] w-[15px]"
              title="Verified"
            />
          )}

          {isPublic && (
            <img
              src={PublicIconSVG}
              className="h-[15px] w-[15px]"
              title="Public"
            />
          )}
        </div>
        <p className="text-xs font-normal text-border">{data?.roleName}</p>
      </div>

      <div className="flex items-center gap-2">
        <div className="flex-grow">
          {data?.location && (
            <p className="flex items-center justify-end gap-[1px] text-end text-xs text-border">
              <img src={LocationSVG} className="h-[10px] w-[10px]" />{' '}
              {data?.location}
            </p>
          )}
          {data?.organization && (
            <p className="flex items-center justify-end gap-[1px] text-end text-xs text-border">
              <img src={NetworkSVG} className="h-[10px] w-[10px]" />
              {data?.organization}
            </p>
          )}
        </div>

        {action && action}
      </div>
      {/* <div className="">{action}</div> */}
    </div>
  )
}
