import authAxios from '../../utils/authAxios'

export async function getContrastsOfAPost(id: string) {
  const res = await authAxios.post('/post/post/getContrasts', {
    postId: id,
  })

  return res.data
}

export async function getCommentsOfAPost(id: string) {
  const res = await authAxios.post('/post/post/getComments', {
    postId: id,
  })

  return res.data
}

export async function getTopic(id: string) {
  const res = await authAxios.get(`/topic/${id}`)

  return res.data
}

export async function createAContrast(data: any) {
  const res = await authAxios.post(`/v2/post/createAContrast`, data)

  return res.data
}

export async function createAComment(data: any) {
  const res = await authAxios.post(`/v2/post/createAComment`, data)

  return res.data
}
