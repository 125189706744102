import authAxios from '../utils/authAxios'
import { URLMetadata } from './article.types'

export async function addDailyPost(postId: string) {
  const res = await authAxios.post(`/v2/emailDigest/addDailyPost`, {
    postId,
  })

  return res.data
}

export async function addLocalPicks(postId: string, locationId: string) {
  const res = await authAxios.post(`/v2/emailDigest/addLocalPicks`, {
    postId,
    locationId,
  })

  return res.data
}

export async function addSchoolPicks(
  postId: string,
  schoolTopicId: string | undefined
) {
  const res = await authAxios.post(`/v2/emailDigest/addSchoolPicks`, {
    postId,
    schoolTopicId,
  })

  return res.data
}
