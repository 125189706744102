import { combineReducers } from 'redux'
import loginReducer from './login/reducer'
import topicsReducer from './topics/reducer'
import postsReducer from './posts/reducer'
import articlesReducer from './article/reducer'
import articleReducer from './articles/reducer'
import organizationsReducer from './organizations/reducers'
import analyticsReducer from './analytics/reducer'
import trustFeedReducer from './trustFeed/reducer'

const rootReducer = combineReducers({
  login: loginReducer,
  topics: topicsReducer,
  posts: postsReducer,
  articles: articlesReducer,
  article: articleReducer,
  organization: organizationsReducer,
  analytics: analyticsReducer,
  trustFeed: trustFeedReducer,
})

export default rootReducer
