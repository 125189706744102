export const TRUST_FEED_CHANGES_LOADING = 'TRUST_FEED_CHANGES_LOADING'
export const TRUST_FEED_CHANGES_SUCCESS = 'TRUST_FEED_CHANGES_SUCCESS'
export const TRUST_FEED_CHANGES_ERROR = 'TRUST_FEED_CHANGES_ERROR'

export const TRUST_FEED_CONTRASTS_LOADING = 'TRUST_FEED_CONTRASTS_LOADING'
export const TRUST_FEED_CONTRASTS_SUCCESS = 'TRUST_FEED_CONTRASTS_SUCCESS'
export const TRUST_FEED_CONTRASTS_ERROR = 'TRUST_FEED_CONTRASTS_ERROR'

export const TRUST_FEED_SEEN = 'TRUST_FEED_SEEN'
export const TRUST_FEED_AUTO_LOAD = 'TRUST_FEED_AUTO_LOAD'
