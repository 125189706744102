import React from 'react'
import { cn } from '../../../../utils/cn'
import { XIcon } from 'lucide-react'

type Props = {
  imageSRC?: string
  title?: string
  domainName?: string
  onClickClose?: () => void
}

export default function URLPreview({
  imageSRC,
  title,
  domainName,
  onClickClose,
}: Props) {
  return (
    <div
      className={cn('mt-[36px] relative h-52 rounded-lg flex justify-center', {
        'bg-primary': !imageSRC,
      })}
    >
      <XIcon
        className="-top-[16px] right-0 absolute cursor-pointer hover:text-black/70 transition-all"
        size={16}
        onClick={onClickClose}
      />
      {imageSRC && (
        <img
          src={imageSRC}
          alt="Metadata"
          className="rounded-lg absolute top-0 left-0 w-full h-full -z-[1] object-cover"
        />
      )}

      {!imageSRC && (
        <div className="text-[11px] font-semibold text-border w-full p-2">
          No image detected
        </div>
      )}

      <div className="z-[1] px-[9px] absolute bottom-[9px] w-full">
        <div className="bg-white rounded-lg p-[8px]">
          <p className="text-[11px] font-semibold">{title}</p>
          <p className="text-[10px] font-normal">{domainName}</p>
        </div>
      </div>
    </div>
  )
}
