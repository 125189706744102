import axios from 'axios'
import authAxios from '../../utils/authAxios'

export async function sendForgotEmail(email: string) {
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v2/auth/sendForgotEmail`,
    {
      email,
    }
  )

  return res.data
}
