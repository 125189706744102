import React, { useEffect } from 'react'

type Props = {
  to: string
}

export default function ExternalRedirect({ to }: Props) {
  useEffect(() => {
    window.location.href = to
  }, [])

  return null
}
