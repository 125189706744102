import './index.css'

import { useState } from 'react'
import plusBlack from '../../assets/icons/plus-black.svg'
import Spacer from '../Spacer'

const InputToggle = ({ title = 'Add', children, onClick }: any) => {
  const [toggle, setToggle] = useState(false)
  return (
    <div>
      {(!toggle || children === undefined) && (
        <div
          className={'button'}
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={onClick ? onClick : () => setToggle(true)}
        >
          <img
            style={{ height: 18, width: 18, marginBottom: 1 }}
            src={plusBlack}
            alt=""
          />
          <Spacer width={5} />
          <text style={{ fontSize: 14 }}>{title}</text>
        </div>
      )}
      {toggle && children}
    </div>
  )
}

export default InputToggle
