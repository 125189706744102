export const termsOfServiceHTML = `<p style="text-align: start; font-size: 12px;"><strong><span style="font-size: 16px;">Terms of&nbsp;</span></strong><strong><span style="font-size: 16px;">Service</span></strong></p>
<p style="text-align: start; font-size: 12px;">Effective April 15, 2024</p>
<p style="text-align: justify; font-size: 12px;">&nbsp;</p>
<p style="text-align: justify; font-size: 12px;"><strong><span style="font-size: 12px;">PLEASE READ THIS DOCUMENT CAREFULLY BEFORE USING OUR SERVICES</span></strong><strong><span style="font-size: 12px;">.</span></strong><strong><span style="font-size: 12px;">THESE TERMS CONTAIN</span></strong><strong><span style="font-size: 12px;">&nbsp;</span></strong><strong><span style="font-size: 12px;">A WAIVER OF CLASS ACTION RIGHTS</span></strong><strong><span style="font-size: 12px;">,&nbsp;</span></strong><strong><span style="font-size: 12px;">EXPLICIT&nbsp;</span></strong><strong><span style="font-size: 12px;">DISCLAIMER</span></strong><strong><span style="font-size: 12px;">S</span></strong><strong><span style="font-size: 12px;">&nbsp;</span></strong>OF WARRANTIES, AND&nbsp;<strong><span style="font-size: 12px;">EXPLICIT&nbsp;</span></strong><strong><span style="font-size: 12px;">LIMITATION</span></strong><strong><span style="font-size: 12px;">S</span></strong><strong><span style="font-size: 12px;">&nbsp;</span></strong>OF LIABILITY<strong><span style="font-size: 12px;">.</span></strong><strong><span style="font-size: 12px;">IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, YOU&nbsp;</span></strong><strong><span style="font-size: 12px;">ARE ADVISED NOT TO&nbsp;</span></strong><strong><span style="font-size: 12px;">CONTINUE</span></strong><strong><span style="font-size: 12px;">&nbsp;</span></strong><strong><span style="font-size: 12px;">US</span></strong><strong><span style="font-size: 12px;">ING</span></strong><strong><span style="font-size: 12px;">&nbsp;</span></strong>OUR SERVICES<strong><span style="font-size: 12px;">.&nbsp;</span></strong></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<p style="text-align: start; font-size: 12px;">These Terms of Service (or &ldquo;Terms&rdquo;)&nbsp;constitute an agreement between you and&nbsp;NIOOZE, INC.(or&nbsp;&ldquo;niooze&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo;), a corporation duly incorporated in&nbsp;and existing under the laws of the State of Delaware,&nbsp;with registered agent&nbsp;Legalinc&nbsp;Corporate Services Inc.</p>
<p style="text-align: start; font-size: 12px;">It constitutes a&nbsp;legally binding contract that&nbsp;governs&nbsp;your&nbsp;access to and&nbsp;use of&nbsp;any of&nbsp;our services, including but not limited to the niooze platform, software, website, apps,&nbsp;and its Content&nbsp;(collectively referred to as&nbsp;the &ldquo;Services&rdquo;), except where we expressly state that separate&nbsp;terms (and not these) apply.&nbsp;By visiting or otherwise using&nbsp;our&nbsp;Services in any&nbsp;manner,&nbsp;you&nbsp;agree to&nbsp;be bound by these&nbsp;Terms, or&nbsp;by&nbsp;the&nbsp;then&nbsp;posted&nbsp;Terms, whichever&nbsp;was&nbsp;made available last.</p>
<p style="text-align: start; font-size: 12px;"><span style="">Any&nbsp;</span><span style="">content</span><span style="">&nbsp;that appears, is uploaded, or is downloaded from&nbsp;</span><span style="">or through&nbsp;</span><span style="">our Services, whether in the form of&nbsp;</span><span style="">text, link</span><span style="">s</span><span style="">, graphi</span><span style="">c</span><span style="">s</span><span style="">, image</span><span style="">s</span><span style="">, photo</span><span style="">s</span><span style="">, audio</span><span style="">s</span><span style="">, video</span><span style="">s</span><span style="">, or&nbsp;</span><span style="">any&nbsp;</span><span style="">other&nbsp;</span><span style="">type of information&nbsp;</span><span style="">(collectively referred to as &ldquo;Content&rdquo;)</span><span style="">,</span><span style="">&nbsp;</span><span style="">is</span><span style="">&nbsp;</span><span style="">also governed by these Terms.&nbsp;</span></p>
<p style="text-align: start; font-size: 12px;">If you accept these Terms and utilize the Services on behalf of a corporation, organization, governmental body, or any other legal entity, you affirm and&nbsp;warrant&nbsp;that you possess the necessary authorization to act on its behalf and have the power to legally obligate said entity to adhere to these Terms. Consequently, the terms "you" and "your" within these Terms shall pertain to such entity.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">1.&nbsp;<strong><span style="font-size: 14px;">Who?</span></strong></div>
<p style="text-align: start; font-size: 12px;">You may use our Services only:</p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If&nbsp;you agree to enter into a binding agreement with us.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you are at least 13 years old.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you&nbsp;have your&nbsp;parents&rsquo;&nbsp;permission when you&rsquo;re&nbsp;under the age of 18&nbsp;(which we will assume you have&nbsp;received).</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you are not prohibited from receiving&nbsp;any aspect of our Services under the laws of the applicable jurisdiction.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you were not previously&nbsp;barred already by us from using our Services.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you are not using any other person&rsquo;s account to access our Services.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;If you are&nbsp;providing us with your correct contact information (first name, last name,&nbsp;email address, date of birth, telephone number)&nbsp;and if you keep these updated.</div>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">2.&nbsp;<strong><span style="font-size: 14px;">Content p</span></strong><strong><span style="font-size: 14px;">ermissions</span></strong></div>
<p style="text-align: start; font-size: 12px;">We do not claim ownership of&nbsp;any&nbsp;Content&nbsp;you add&nbsp;on or through our&nbsp;Services.&nbsp;You retain your rights.</p>
<p style="text-align: start; font-size: 12px;">However, for any Content you add on or through our Services, you grant&nbsp;us a&nbsp;worldwide,&nbsp;non-exclusive, royalty-free, transferable license (with the right to&nbsp;sublicense)&nbsp;to use,&nbsp;host,&nbsp;copy, distribute, reproduce,&nbsp;modify, process, publish, run, transmit, translate,&nbsp;curate,&nbsp;transform,&nbsp;publicly perform,&nbsp;create derivative works of, and display&nbsp;your Content, in&nbsp;any media or distribution methods presently known or that may be developed in the future.</p>
<p style="text-align: start; font-size: 12px;">This license grants us the&nbsp;right&nbsp;to share your Content&nbsp;(even modified or adapted)&nbsp;globally and permits&nbsp;others to do so as well. By accepting this license, you acknowledge that we have the right to provide, promote, and enhance the Services, as well as to distribute Content&nbsp;to&nbsp;other entities for syndication, broadcast, distribution, reposting, promotion, or publication on alternative media and services.</p>
<p style="text-align: start; font-size: 12px;">For clarity,&nbsp;under this license&nbsp;you also give us permission&nbsp;to&nbsp;host,&nbsp;display, share,&nbsp;distribute&nbsp;and&nbsp;publish&nbsp;your username, profile picture and other&nbsp;information about your&nbsp;profile, or any of your&nbsp;actions and&nbsp;relationships&nbsp;on&nbsp;our Services.&nbsp;</p>
<p style="text-align: start; font-size: 12px;">You&nbsp;expressly&nbsp;agree that&nbsp;you want to use our Services and that&nbsp;the above license&nbsp;is&nbsp;therefore&nbsp;provided without&nbsp;any&nbsp;compensation&nbsp;whatsoever, not for the license, nor for the Content.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">3.&nbsp;<strong><span style="font-size: 14px;">Content w</span></strong><strong><span style="font-size: 14px;">arranties</span></strong></div>
<p style="text-align: start; font-size: 12px;">You are solely responsible for any Content you provide, including compliance with applicable laws, rules, and regulations.&nbsp;</p>
<p style="text-align: start; font-size: 12px;">You represent and warrant that you own or have obtained all necessary rights to the&nbsp;Content you add on or through our Services.&nbsp;You agree that you cannot add Content&nbsp;that violates someone else&rsquo;s rights,&nbsp;including but not limited to any applicable intellectual property rights, privacy rights, publicity rights and contractual rights.</p>
<p style="text-align: start; font-size: 12px;">You are also responsible for safeguarding&nbsp;and maintaining the confidentiality of&nbsp;your accountlogin information,&nbsp;including using a&nbsp;unique and&nbsp;strong password, to be used&nbsp;for&nbsp;our Services alone.&nbsp;</p>
<p style="text-align: start; font-size: 12px;">Any&nbsp;loss or damage&nbsp;of any&nbsp;kind&nbsp;arising from your failure to comply with the above&nbsp;will be at your sole risk and liability.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">4.&nbsp;<strong><span style="font-size: 14px;">Content&nbsp;</span></strong><strong><span style="font-size: 14px;">consumption</span></strong><strong><span style="font-size: 14px;">&nbsp;</span></strong>and use of our Services</div>
<p style="text-align: start; font-size: 12px;">You are responsible for your&nbsp;own&nbsp;use of our Services, and for the way you consume its Content.</p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Even though we try&nbsp;to create an environment that&nbsp;conduces&nbsp;reliability, any use or reliance&nbsp;on any Content&nbsp;on our Services&nbsp;is&nbsp;solely and completely&nbsp;at your own risk&nbsp;and liability.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We do not endorse, support,&nbsp;recommend,&nbsp;verify, warrant,&nbsp;represent or guarantee the&nbsp;validity,&nbsp;completeness, truthfulness, accuracy,&nbsp;safety,&nbsp;legality,&nbsp;reliability, quality, appropriateness, decency, timeliness, nature, or any other aspect&nbsp;of any Content&nbsp;on&nbsp;our&nbsp;Services.&nbsp;We&nbsp;only&nbsp;provide&nbsp;a&nbsp;labeling service to our users, according to a&nbsp;community-guided and&nbsp;ever-evolving set of definitions and principles for each label, to be followed by every user&nbsp;on our Services.&nbsp;In case of inaccuracies&nbsp;and/or non-compliance,&nbsp;we provide a set of tools to mitigate&nbsp;itsperverse&nbsp;effects, without claiming&nbsp;valid,&nbsp;complete, truthful, accurate, safe,&nbsp;legal,&nbsp;reliable, qualitative, appropriate, decent, or timely&nbsp;representation&nbsp;at any time.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;You understand that by using&nbsp;our&nbsp;Services, you may be exposed to Content that might be offensive,&nbsp;insulting,&nbsp;harmful,&nbsp;inaccurate,&nbsp;mislabeled, deceptive,&nbsp;and/or inappropriate.&nbsp;All Content is the sole responsibility of the party&nbsp;who&nbsp;added&nbsp;such Content. We may not monitor or control the Content&nbsp;added through our&nbsp;Services and we cannot take responsibility for such Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;In case you receive moderating or admin rights on our Services, you agree that this is a voluntary and unofficial&nbsp;engagement, and&nbsp;you agree&nbsp;that we are not responsible for any action taken by you as a moderator or admin. Having moderator or admin rights does notallow&nbsp;you to&nbsp;represent authority&nbsp;on behalf of niooze, or act on behalf of niooze.&nbsp;We reserve the right to&nbsp;remove&nbsp;or limit&nbsp;any moderator or admin rights, at any time and for any or no reason.</div>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">5.&nbsp;<strong><span style="font-size: 14px;">Rights we&nbsp;</span></strong><strong><span style="font-size: 14px;">retain</span></strong></div>
<p style="text-align: start; font-size: 12px;">In practice we will be guided by several internal (ever-evolving) policies, but any decisions we take remain at our sole discretion, to the extent permitted by law.</p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to delete, modify, edit, or update any Content on or through our Services, whether they are deemed to be in violation of our Terms or policies, or if we deem them, at our sole discretion, unacceptable or not beneficial&nbsp;to&nbsp;our Services.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to delete, modify, edit, or update user profiles, including but not limited to changing its content, its visualization, and/or its reading,&nbsp;contributing, moderator,&nbsp;and/or admin&nbsp;rights.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to change our internal policies at any time.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to refuse access to our Services to any party.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to&nbsp;stop or suspend&nbsp;(permanently or temporarily)&nbsp;providing the Services orany features within the Services to&nbsp;you or to any set of users.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to suspend or terminate your account.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to create limits on use and&nbsp;storage&nbsp;for you or for any set of users.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We retain the right to limit distribution or visibility of any Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to take ad-hoc decisions in the benefit of niooze, even if these deem not to be aligned with our (ever-evolving and ever-lacking) policies.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;We reserve the right to access, save, and disclose any information we reasonably believe is necessary to run our operations, including but not limited to:</div>
<div style="text-align: start; font-size: 12px;">o&nbsp;satisfying legal, regulatory or support requests, both from users and third parties such as governments.</div>
<div style="text-align: start; font-size: 12px;">o&nbsp;detecting, preventing, or addressing fraud, security, legal, or technical issues.</div>
<div style="text-align: start; font-size: 12px;">o&nbsp;enforcing these Terms.</div>
<p style="text-align: start; font-size: 12px;">All provisions of these Terms, which by nature should survive&nbsp;the&nbsp;execution of&nbsp;the above&nbsp;rights, shall survive, including, without limitation, intellectual property ownership and warranty disclaimers.</p>
<p style="text-align: start; font-size: 12px;">For each of the above rights,&nbsp;whether executed with or without notice,&nbsp;there&nbsp;shall&nbsp;be&nbsp;no liability,&nbsp;responsibility,&nbsp;or compensation&nbsp;by us&nbsp;towards&nbsp;you&nbsp;or any party, in any manner,&nbsp;whatsoever.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">6.&nbsp;<strong><span style="font-size: 14px;">How&nbsp;</span></strong><strong><span style="font-size: 14px;">(not)&nbsp;</span></strong><strong><span style="font-size: 14px;">to use our&nbsp;</span></strong><strong><span style="font-size: 14px;">Services</span></strong></div>
<p style="text-align: start; font-size: 12px;">You agree not to&nbsp;misuse our Services,&nbsp;including but not limited to the following ways:</p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not&nbsp;use or&nbsp;access, or attempt to use or access,&nbsp;our Services&nbsp;by any means&nbsp;other than through our interface and/or instructions we provide.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not breach or circumvent, or attempt to breach or circumvent, any security or authentication measures.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not use&nbsp;or access&nbsp;our Services in any way or take any action that may, intentionally or unintentionally,&nbsp;influence,&nbsp;<span style="">undermine, disrupt,&nbsp;</span><span style="">damage,</span><span style="">&nbsp;</span><span style="">interfere with</span><span style="">,&nbsp;</span><span style="">or manipulate&nbsp;</span><span style="">the</span><span style="">safety,&nbsp;</span><span style="">functionality, performance,&nbsp;</span><span style="">availability,</span><span style="">&nbsp;or accessibility</span><span style="">&nbsp;of our Services</span><span style="">, including but not limited to virus sending, spamming, mail-bombing, overloading, flooding</span><span style="">, or scripting anything that might create an undue burden on our Services.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">Do not&nbsp;</span><span style="">reverse engineer,&nbsp;</span><span style="">disassemble, copy, decompile,&nbsp;</span><span style="">modify, create derivative works of</span><span style="">, attempt to derive the source code of, or work around any technical limitations of</span><span style="">&nbsp;</span><span style="">our Services</span><span style="">, platform, software</span><span style="">, and/or any part thereof</span><span style="">.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not use or access our Services&nbsp;in any manner, for any purpose, or for any&nbsp;activity&nbsp;that might be&nbsp;illegal, fraudulent,&nbsp;unlawful, or harmful.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">Do not plagiarize</span><span style="">&nbsp;or violate</span><span style="">&nbsp;the rights of third parties, including&nbsp;</span><span style="">but not limited to&nbsp;</span><span style="">copyright, trademark, trade secret, privacy,&nbsp;</span><span style="">publicity,</span><span style="">&nbsp;or other proprietary rights.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not conceal your identity, impersonate any person&nbsp;or organization,&nbsp;use&nbsp;false IP addresses or headers,&nbsp;or&nbsp;misrepresent any affiliations&nbsp;you may have&nbsp;with other persons or organizations.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not share or publish any confidential or personal information belonging to any person or organization without obtaining prior consent.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do&nbsp;not&nbsp;use,&nbsp;or access,&nbsp;or tamper with,&nbsp;non-public areas of our Services.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not test,&nbsp;probe,&nbsp;or scan&nbsp;the vulnerability of our Services.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not scrape or crawl our Services in any form&nbsp;and for any purpose, without our prior written consent.&nbsp;Training AI models based on our Services, or&nbsp;by&nbsp;using our data or Content, is expressly prohibited&nbsp;without our prior written consent.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not use any data collected from our Services for any direct marketing activities,</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;Do not assist,&nbsp;facilitate,&nbsp;or encourage&nbsp;others in misusing our Services,&nbsp;either&nbsp;by personal actions,&nbsp;nor by developing or distributing&nbsp;such&nbsp;products or services.</div>
<p style="text-align: start; font-size: 12px;">Please review our internal, ever-evolving, and probably ever-lacking internal policies for additional guidance on how to use and not use our Services. These policies can normally be accessed through our Services. Typically, our actions will be guided by our Terms and by our internal policies, but we reserve the right for discretionary ad-hoc decisions to the benefit of niooze.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">7.&nbsp;<strong><span style="font-size: 14px;">Our license to you</span></strong><strong><span style="font-size: 14px;">, including</span></strong><strong><span style="font-size: 14px;">&nbsp;</span></strong>its&nbsp;<strong><span style="font-size: 14px;">limitations</span></strong></div>
<p style="text-align: start; font-size: 12px;">To use and access our Services, we provide you with a personal, royalty-free, non-exclusive, and worldwide license, which is non-assignable and non-transferable. The sole purpose of this license is to allow and enable you to use our Services according to these Terms, and we reserve the right to retract this license at any time at our discretion.</p>
<p style="text-align: start; font-size: 12px;">Our Services and its Content are protected by intellectual property rights, including but not limited to copyright,&nbsp;trademark, and patent&nbsp;rights, as well as other laws in the United States and other countries.&nbsp;We do not give you the right to use&nbsp;any of&nbsp;our brand names, trademarks, logos, domain names, designs,&nbsp;look &amp; feels,&nbsp;distinctive features,&nbsp;distinctive processes &amp; protocols, code, architecture, or other elements that can be categorized as Intellectual Property, whether registered or unregistered.&nbsp;</p>
<p style="text-align: start; font-size: 12px;"><span style="">All right, title, and interest in and to&nbsp;</span><span style="">our</span><span style="">&nbsp;Services are and will remain our and our licensors' exclusive property.&nbsp;</span><span style="">W</span>e disclaim all rights and claims to the intellectual property rights over&nbsp;Content provided by third parties.</p>
<p style="text-align: start; font-size: 12px;">We operate the Services from the USA and make no representation that the Services are appropriate or available for use beyond the USA. If you use our Services from other locations, you are doing so on your own initiative, and at your own risk and liability. You are solely responsible for compliance with applicable local laws and regulations.</p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">8.&nbsp;<strong><span style="font-size: 14px;">Advertisement, third-party providers,</span></strong><strong><span style="font-size: 14px;">&nbsp;</span></strong>and<strong><span style="font-size: 14px;">&nbsp;</span></strong><strong><span style="font-size: 14px;">partners</span></strong></div>
<p style="text-align: start; font-size: 12px;"><span style="">You agree that</span><span style="">&nbsp;we, and</span><span style="">&nbsp;third-party providers or partners</span><span style="">,</span><span style="">&nbsp;</span><span style="">may&nbsp;</span><span style="">provide adverti</span><span style="">sements</span><span style="">, external&nbsp;</span><span style="">links,</span><span style="">&nbsp;affiliated&nbsp;</span><span style="">C</span><span style="">ontent</span><span style="">, affiliated services, or affiliated products (collectively referred to as &ldquo;Advertising&rdquo;)&nbsp;</span><span style="">on and through our Services.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">We are not associated with such Advertising, unless expressly stated otherwise, and we do not&nbsp;</span>endorse, support, recommend, verify, warrant, represent or guarantee the validity, completeness, truthfulness, accuracy, safety, legality,&nbsp;reliability, quality, appropriateness, decency, timeliness,nature, or any other aspect of&nbsp;any such third-party Advertising in any way.</p>
<p style="text-align: start; font-size: 12px;"><span style="">We accept no responsibility for third-party Advertising on or through our Services,&nbsp;</span><span style="">and consuming, clicking through, using, or purchasing any (elements of) such Advertising in any way, is solely at your own risk and responsibility. If you agree to consume, clickthrough, use or purchase goods and/or services from any third-party, you do so at your own risk and these Terms do not apply anymore for such third-party goods and/or services.&nbsp;</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">You expressly agree that you want to use our Services and that the above&nbsp;</span><span style="">Advertising is</span><span style="">&nbsp;provided</span><span style="">without&nbsp;</span><span style="">any&nbsp;</span><span style="">compensation</span><span style="">&nbsp;</span><span style="">to</span><span style="">&nbsp;you whatsoever,&nbsp;</span><span style="">even if</span><span style="">&nbsp;the above Advertising is&nbsp;</span><span style="">consumed</span><span style="">&nbsp;by you</span><span style="">in any way</span><span style="">.</span></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">9.&nbsp;<strong><span style="font-size: 14px;">Ad</span></strong><strong><span style="font-size: 14px;">ditional services</span></strong></div>
<p style="text-align: start; font-size: 12px;"><span style="">We may offer specific functionalities, features</span><span style="">, products, Content,</span><span style="">&nbsp;</span><span style="">processes,&nbsp;</span><span style="">or services</span><span style="">(collectively referred to as &ldquo;Additional Services&rdquo;</span><span style="">)</span><span style="">&nbsp;to specific sets of users that may or may not have to pay for those Additional Services. These Terms remain applicable, but you agree that there may be additional terms &amp; conditions for such Additional Services, and you agree that those additional terms &amp; conditions become part of our agreement with you.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">In case of conflicts, the additional terms &amp; conditions will prevail, but only&nbsp;</span><span style="">to</span><span style="">&nbsp;the Additional Services to which they apply.</span></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">10.&nbsp;<strong><span style="font-size: 14px;">Communications</span></strong><strong><span style="font-size: 14px;">&nbsp;</span></strong>and feedback</div>
<p style="text-align: start; font-size: 12px;"><span style="">You agree that any communication between you and niooze uses electronic means, and that all such electronic communications satisfy&nbsp;</span><span style="">any</span><span style="">&nbsp;and all</span><span style="">&nbsp;legal requirement</span><span style="">s</span><span style="">&nbsp;as if it were to be in hardcopy writing</span><span style="">, or</span><span style="">&nbsp;with your written signature</span><span style="">, including communications for contractual purposes such as these Terms or any other agreement, notice, or disclosure.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">You agree that you shall not challenge the validity, enforceability, or admissibility of these Terms, or any other electronic communication, based on the grounds that it was electronically transmitted or authorized.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">You agree that any&nbsp;</span><span style="">ideas, suggestions, or other feedback&nbsp;</span><span style="">you provide&nbsp;</span><span style="">about&nbsp;</span><span style="">niooze or our Services,&nbsp;</span><span style="">whether solicited or unsolicited,&nbsp;</span><span style="">is not confidential&nbsp;</span><span style="">and may be used by us without restriction and without compensation to you.&nbsp;</span><span style="">We do not waive any rights to use similar or related ideas previously known to us, developed by our employees, or obtained from other sources.</span></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">11.&nbsp;<strong><span style="font-size: 14px;">Disclaimer of warranties</span></strong></div>
<p style="text-align: start; font-size: 12px;"><strong><span style="">To the maximum extent permitted by applicable law, you understand and agree that</span></strong><strong><span style="">&nbsp;</span></strong>the Services and Content are provided to you on an &ldquo;AS IS&rdquo;<strong><span style="">,&nbsp;</span></strong><strong><span style="">&ldquo;AS AVAILABLE&rdquo;</span></strong><strong><span style="">&nbsp;</span></strong>and &ldquo;WITH ALL FAULTS&rdquo;<strong><span style="">&nbsp;</span></strong>basis<strong><span style="">.&nbsp;</span></strong><strong><span style="">You&nbsp;</span></strong><strong><span style="">expressly&nbsp;</span></strong><strong><span style="">agree that you use and access our Services</span></strong><strong><span style="">&nbsp;</span></strong>at your own risk and liability.&nbsp;</p>
<p style="text-align: start; font-size: 12px;"><strong><span style="">To the maximum extent permitted by&nbsp;</span></strong><strong><span style="">applicable law,</span></strong><strong><span style="">&nbsp;</span></strong>niooze and its direct and indirect parents, subsidiaries, affiliates, and each of their respective employees, directors, members,&nbsp;<strong><span style="">managers,</span></strong><strong><span style="">&nbsp;</span></strong>shareholders, agents, vendors, licensors, licensees, contractors, customers, successors, and assigns<strong><span style="">&nbsp;</span></strong>(collective<strong><span style="">ly referred to as</span></strong><strong><span style="">&nbsp;</span></strong>&ldquo;Collective Parties&rdquo;)<strong><span style="">,</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">make no</span></strong><strong><span style="">&nbsp;</span></strong>warrant<strong><span style="">y or representation</span></strong><strong><span style="">, in any way,</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">and we disclaim all&nbsp;</span></strong><strong><span style="">warranty,&nbsp;</span></strong><strong><span style="">responsibility</span></strong><strong><span style="">, endorsement,&nbsp;</span></strong><strong><span style="">promise, or&nbsp;</span></strong><strong><span style="">liability</span></strong><strong><span style="">, whether express or implied,</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">for</span></strong><strong><span style="">&nbsp;</span></strong>all warranties related to, but not limited to<strong><span style="">:</span></strong></p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Merchantability</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Fitness for a particular purpose</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Satisfactory quality.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Title</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Non-infringement</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong>The validity, completeness, truthfulness, accuracy, safety, reliability, quality, appropriateness, decency,&nbsp;</strong><strong>availability,&nbsp;</strong><strong>timeliness,</strong><strong>&nbsp;</strong><strong>security,</strong><strong>&nbsp;</strong>or&nbsp;<strong>nature</strong><strong>&nbsp;</strong>of our Services&nbsp;<strong>and/</strong><strong>or Content.</strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Whether&nbsp;</span></strong><strong><span style="">our Services and/or Content will meet your requirements</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Whether&nbsp;</span></strong><strong><span style="">our Services and/or Content will&nbsp;</span></strong><strong><span style="">be uninterrupted, secure, error-free, harmful-free</span></strong><strong><span style="">, or defect-free</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Whether&nbsp;</span></strong><strong><span style="">any&nbsp;</span></strong><strong><span style="">issues with</span></strong><strong><span style="">&nbsp;</span></strong>our Services and/or Content will be immediately corrected.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">Any harm to your&nbsp;</span></strong><strong><span style="">computer system, loss of data, or other harm that results from your&nbsp;</span></strong><strong><span style="">use</span></strong><strong><span style="">or access of our Services and/or Content.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">The failure to store</span></strong><strong><span style="">,&nbsp;</span></strong><strong><span style="">the&nbsp;</span></strong><strong><span style="">failure to transmit</span></strong><strong><span style="">, or the deletion of</span></strong><strong><span style="">&nbsp;</span></strong>any Content or data&nbsp;<strong><span style="">maintained by our Services.</span></strong></div>
<p style="text-align: start; font-size: 12px;"><strong><span style="">No oral or written communication obtained from us</span></strong><strong><span style="">&nbsp;</span></strong>or on or through our Services and/or&nbsp;<strong><span style="">Content,</span></strong><strong><span style="">&nbsp;</span></strong>will create any warranty or representation&nbsp;<strong><span style="">not expressly made herein.</span></strong></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">12.&nbsp;<strong><span style="font-size: 14px;">Limitation of liability</span></strong></div>
<p style="text-align: start; font-size: 12px;"><strong><span style="">To the maxi</span></strong><strong><span style="">mum extent permitted by applicable law, under no circumstances</span></strong><strong><span style="">&nbsp;</span></strong>any of<strong><span style="">&nbsp;</span></strong><strong><span style="">the Collective Parties</span></strong><strong><span style="">&nbsp;</span></strong>will be liable&nbsp;<strong><span style="">for</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">any indirect, incidental,&nbsp;</span></strong><strong><span style="">consequential,</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">special,&nbsp;</span></strong><strong><span style="">exemplary,&nbsp;</span></strong><strong><span style="">or punitive damages</span></strong><strong><span style="">, including but not limited to a</span></strong><strong><span style="">ny loss of profits</span></strong><strong><span style="">, savings</span></strong><strong><span style="">,</span></strong><strong><span style="">&nbsp;</span></strong>or revenues<strong><span style="">&nbsp;</span></strong>(<strong><span style="">whether incurred directly or indirectly</span></strong><strong><span style="">),&nbsp;</span></strong><strong><span style="">or any loss&nbsp;</span></strong><strong><span style="">of&nbsp;</span></strong><strong><span style="">content,&nbsp;</span></strong><strong><span style="">data</span></strong><strong><span style="">, use</span></strong><strong><span style="">,&nbsp;</span></strong><strong><span style="">goodwill</span></strong><strong><span style="">,</span></strong><strong><span style="">&nbsp;</span></strong>or other intangible losses,<strong><span style="">&nbsp;</span></strong><strong><span style="">arising</span></strong><strong><span style="">&nbsp;</span></strong>from:</p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">your use or access</span></strong><strong><span style="">&nbsp;</span></strong>of<strong><span style="">&nbsp;</span></strong>our Services and/or Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">your inability to use or access</span></strong><strong><span style="">&nbsp;</span></strong>our Services and/or Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">any delay in the capacity to use or access</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">our Services</span></strong><strong><span style="">&nbsp;</span></strong>and/or Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">any&nbsp;</span></strong><strong><span style="">failure, delay, or&nbsp;</span></strong><strong><span style="">reduction in&nbsp;</span></strong><strong><span style="">the&nbsp;</span></strong><strong><span style="">performance of&nbsp;</span></strong><strong><span style="">our Services and/or Content.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">any</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">events,&nbsp;</span></strong><strong><span style="">actions, or failure of actions, caused by events outside our reasonable control (force majeure events).</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">any unauthorized access, use or&nbsp;</span></strong><strong><span style="">modification</span></strong><strong><span style="">&nbsp;</span></strong>of your Content.</div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">any conduct or Content of any&nbsp;</span></strong><strong><span style="">user or&nbsp;</span></strong><strong><span style="">third party</span></strong><strong><span style="">&nbsp;</span></strong><strong><span style="">on our Services</span></strong><strong><span style="">.</span></strong></div>
<p style="text-align: start; font-size: 12px;"><strong><span style="">Under no circumstances shall the aggregate liability of niooze exceed the greater of one hundred US dollars ($ 100&nbsp;</span></strong><strong><span style="">USD</span></strong><strong><span style="">)</span></strong><strong><span style="">&nbsp;</span></strong>or the amount you paid us, if any, in the past six months for the services giving rise to the claim.</p>
<p style="text-align: start; font-size: 12px;"><strong><span style="">The&nbsp;</span></strong><strong><span style="">above limitations of liability shall apply to any theory of liability, including but not limited to:</span></strong></p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">warranty</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">contract</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">s</span></strong><strong><span style="">tatute</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">t</span></strong><strong><span style="">ort</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">n</span></strong><strong><span style="">egligence</span></strong><strong><span style="">.</span></strong></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<strong><span style="">s</span></strong><strong><span style="">trict liability.</span></strong></div>
<p style="text-align: start; font-size: 12px;"><strong><span style="">These limitations of liability shall apply e</span></strong><strong><span style="">ven if we may have been advised of the possibility of any such&nbsp;</span></strong><strong><span style="">liability or damage, and even if any remedy set forth in these Terms is found to have failed its essential purpose.</span></strong></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">13.&nbsp;<strong><span style="font-size: 14px;">Indemnification</span></strong></div>
<p style="text-align: start; font-size: 12px;"><span style="">You agree to indemnify,&nbsp;</span><span style="">defend,</span><span style="">&nbsp;and hold harmless&nbsp;</span><span style="">the Collective Parties</span><span style="">&nbsp;from and against all claims,&nbsp;</span><span style="">damages, suits, losses,&nbsp;</span><span style="">costs, judgments, investigations, liabilities, fines, penalties, settlements, interest, and expenses (including</span><span style="">&nbsp;litigation costs and reasonable</span><span style="">&nbsp;attorney&rsquo;s fees)&nbsp;</span><span style="">that directly or indirectly arise&nbsp;</span><span style="">from</span><span style="">, or in connection with:</span></p>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">your use or access of our Services and/or Content.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">your inability to use or access our Services and/or Content.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">your violation&nbsp;</span><span style="">or alleged violation&nbsp;</span><span style="">of any provision of these Terms</span><span style="">.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">your violation or alleged violation of any rights of a third party.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">your violation or alleged violation of any applicable laws, rules, regulations, codes, statutes, ordinances, or orders</span><span style="">.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">c</span><span style="">ontent transmitted through your device</span><span style="">, even if not submitted by you.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">a</span><span style="">ny misrepresentation&nbsp;</span><span style="">made&nbsp;</span><span style="">by you</span><span style="">.</span></div>
<div style="text-align: start; font-size: 12px;">&bull;&nbsp;<span style="">our use of the information that you submitted to us.</span></div>
<p style="text-align: start; font-size: 12px;"><span style="">You agree</span><span style="">&nbsp;to fully cooperate as required by&nbsp;</span><span style="">the Collective Parties</span><span style="">&nbsp;in the defense of any of the above claims and losses.&nbsp;</span><span style="">The Collective Parties</span><span style="">&nbsp;reserve the</span><span style="">&nbsp;exclusive</span><span style="">&nbsp;right</span><span style="">&nbsp;to&nbsp;</span><span style="">assume defense and control&nbsp;</span><span style="">of any matter otherwise subject to indemnification by you, including but not limited to settling,&nbsp;</span><span style="">compromising,</span><span style="">&nbsp;and paying&nbsp;</span><span style="">any and all</span><span style="">&nbsp;claims and losses.</span></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">14.&nbsp;<strong><span style="font-size: 14px;">General</span></strong></div>
<p style="text-align: start; font-size: 12px;"><span style="">These Terms shall be governed by and construed in accordance with the laws of the State of Del</span><span style="">a</span><span style="">ware</span><span style="">&nbsp;</span><span style="">without regard&nbsp;</span><span style="">to&nbsp;</span><span style="">conflict of law</span><span style="">&nbsp;provisions</span><span style="">, and all&nbsp;</span><span style="">disputes&nbsp;</span><span style="">related to these&nbsp;</span><span style="">Terms,</span><span style="">&nbsp;or the Services</span><span style="">,</span><span style="">&nbsp;will be&nbsp;</span><span style="">brought solely to the exclusive jurisdi</span><span style="">ction of the competent courts thereof.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">To the maximum extent permitted by applicable law</span><span style="">, you waive the right to participate as a plaintiff or class member in any purported class action, collective action, or representative action proceeding.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">Niooze reserves the right, at its sole discretion, to&nbsp;</span><span style="">update, revise, supplement and to otherwise&nbsp;</span><span style="">modify these Terms at any time,&nbsp;</span><span style="">and such modifications shall be effective immediately upon&nbsp;</span><span style="">availability</span><span style="">&nbsp;on&nbsp;</span><span style="">or through&nbsp;</span><span style="">our Services.</span><span style="">&nbsp;</span><span style="">You agree to review&nbsp;</span><span style="">the Terms</span><span style="">&nbsp;periodically</span><span style="">, ideally upon each visit,</span><span style="">&nbsp;to be aware of such&nbsp;</span><span style="">changes</span><span style="">. We will try&nbsp;</span><span style="">&ndash; at our sole discretion &ndash;&nbsp;</span><span style="">to notify you of&nbsp;</span><span style="">material revisions</span><span style="">, without this being a</span><span style="">&nbsp;requirement for&nbsp;</span><span style="">your&nbsp;</span><span style="">acceptance</span><span style="">&nbsp;of the&nbsp;</span><span style="">changes</span><span style="">.&nbsp;</span><span style="">Y</span><span style="">our&nbsp;</span><span style="">continued&nbsp;</span><span style="">use or access</span><span style="">&nbsp;of the&nbsp;</span><span style="">Services</span><span style="">&nbsp;shall be deemed as a conclusive acceptance of&nbsp;</span><span style="">any modified Terms</span><span style="">.&nbsp;</span><span style="">In case we provide these Terms in multiple languages, the English language version of the Terms shall take prec</span><span style="">edence in case of discrepancies or inconsistencies</span><span style="">.</span></p>
<p style="text-align: start; font-size: 12px;"><span style="">If</span><span style="">&nbsp;any provision of the Terms is found by a court of competent jurisdiction to be invalid or unenforceable,&nbsp;</span><span style="">then that provision will be limited or eliminated to the minimum extent&nbsp;</span><span style="">necessary,&nbsp;</span><span style="">and this will not affect</span><span style="">&nbsp;the enforceability of any other part or provision of these Terms.</span></p>
<p style="text-align: start; font-size: 12px;">&nbsp;</p>
<div style="text-align: start; font-size: 12px;">15.&nbsp;<strong><span style="font-size: 14px;">Contact&nbsp;</span></strong><strong><span style="font-size: 14px;">information</span></strong></div>
<p style="text-align: start; font-size: 12px;"><span style="">If you have questions about these Terms</span><span style="">,&nbsp;</span><span style="">our Services,&nbsp;</span><span style="">or its Content,&nbsp;</span><span style="">please contact us by email at&nbsp;</span><a href="mailto:legal@niooze.com"><u><span style="color: #467886;">legal@niooze.com</span></u></a><span style="">&nbsp;for legal questions, at&nbsp;</span><a href="mailto:hello@niooze.com"><u><span style="color: #467886;">hello@niooze.com</span></u></a><span style="">&nbsp;for general questions, or write to us at&nbsp;</span>936 SW 1<sup><span style="font-size: 7px;">st</span></sup> Ave, Unit 279, Miami, 33130, Florida, USA.</p>`
