import checkWhite from '../../assets/icons/check-white.svg'
import './index.css'
const SignUpHeader = () => {
  return (
    <div>
      <img
        src={checkWhite}
        alt=""
        className={'h-24'}
        style={{
          height: '24px',
          width: '24px',
          marginTop: '15px',
          marginLeft: '16px',
        }}
      />
      {/*check logo*/}
    </div>
  )
}

export default SignUpHeader
