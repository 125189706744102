import TopicDetailBox from '../../components/TopicDetailBox'
import { useLocation, useNavigate } from 'react-router-dom'
import ToggleButton from '../../components/ToggleButton'
import './index.css'
import Spacer from '../../components/Spacer'
import { useEffect, useState } from 'react'
import PostScreen from '../../components/PostScreen'
import {
  deletePost,
  getAllPosts,
  getPost,
  setClickedPost,
  voteTogglePost,
  alarmTogglePost,
} from '../../redux/posts/actions'
import { connect } from 'react-redux'
import FilterWheel from '../../components/FilterWheel'
import { getAllPostsForTopics } from '../../redux/posts/actions'
import { useParams } from 'react-router-dom'
import {
  addTopicID,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'
import { addPostsToRedux } from '../../redux/analytics/actions'
import AnalyticsTrackerWrapper from '../AnalyticsTrackerWrapper'

const TopicScreen = ({
  topic: selectedTopic = {},
  post: selectedPost = {},

  getPost,
  getTopic,
  deletePost,

  setPostModalVisible,
  setContrastsModalVisible,
  getAllPostsForTopics,

  setClickedPost,
  setClickedTopic,
  voteTogglePost,
  alarmTogglePost,
  setPageArticle,
  user,
  addPostsToRedux,
}: any) => {
  const navigate = useNavigate()

  const [post, setPost] = useState('')
  const [article, setArticle] = useState('')

  const params = useParams()
  const { topicId, postId } = params

  useEffect(() => {
    getTopic(topicId)
    getPost(postId)
  }, [getPost, getTopic, postId, topicId])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <AnalyticsTrackerWrapper>
      {windowSize.innerWidth > 1300 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'hidden',
          }}
        >
          <div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
            <div
              style={{
                flex: 3,
                justifyContent: 'start',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TopicDetailBox
                hideBack
                setClickedTopic={setClickedTopic}
                navigate={navigate}
                selectedTopic={selectedTopic}
                setPostModalVisible={setPostModalVisible}
                topicClickable={topicId != null && postId != null}
              />

              <Spacer height={50} />
            </div>

            <div style={{ flex: 7, display: 'flex' }}>
              <PostScreen
                deletePost={deletePost}
                setClickedTopic={setClickedTopic}
                setClickedPost={setClickedPost}
                selectedTopic={selectedTopic}
                selectedPost={selectedPost}
                setArticle={setArticle}
                setPost={setPost}
                voteToggle={() => voteTogglePost(postId, topicId)}
                alarmToggle={() => alarmTogglePost(postId)}
                setPostModalVisible={setPostModalVisible}
                user={user}
                addPostsToRedux={addPostsToRedux}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ overflowY: 'auto' }}>
          <div>
            <TopicDetailBox
              hideBack
              setClickedTopic={setClickedTopic}
              navigate={navigate}
              selectedTopic={selectedTopic}
              setPostModalVisible={setPostModalVisible}
              topicClickable={topicId != null && postId != null}
            />
          </div>

          <div>
            <PostScreen
              deletePost={deletePost}
              setClickedTopic={setClickedTopic}
              setClickedPost={setClickedPost}
              selectedTopic={selectedTopic}
              selectedPost={selectedPost}
              setArticle={setArticle}
              setPost={setPost}
              voteToggle={() => voteTogglePost(postId, topicId)}
              alarmToggle={() => alarmTogglePost(postId)}
              setPostModalVisible={setPostModalVisible}
              setPageArticle={setPageArticle}
              user={user}
              addPostsToRedux={addPostsToRedux}
            />
          </div>
        </div>
      )}
    </AnalyticsTrackerWrapper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,

    topic: state.topics.topic,
    post: state.posts.post,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },

    getPost: (postId: string) => {
      dispatch(getPost(postId))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },

    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    setClickedPost: (post: any) => {
      dispatch(setClickedPost(post))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    voteTogglePost: (postId: any, topicId: any) => {
      dispatch(voteTogglePost(postId, topicId))
    },
    alarmTogglePost: (postId: any) => {
      dispatch(alarmTogglePost(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicScreen)
