import axios from 'axios'

export async function saveWaitlistEmail(email: string) {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v2/onboarding/saveWaitlistEmail`,
    {
      email,
    }
  )
}
