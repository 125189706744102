import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const AutoCompleteTwoText = (props: any) => {
  const {
    firstDataList = [],
    firstDataListTopic,
    secondDataList = [],
    secondDataListTopic,
    setFirstDataItem,
    setSecondDataItem,
    searchText,
    setSelectLocation,
    setCountryToDisplay,
    InputPlaceHolder,
    clearTheSelections,
    setSelectTopicFromModal,
    setSelectedItemToDisplay,
    place,
    firstDataListloading,
    secondDataListloading,
  } = props

  const setSecondData = (list: any) => {
    setSelectTopicFromModal(list.id)
    setSecondDataItem(list.title)
    setSelectedItemToDisplay(list.title)
  }

  const setFirstData = (location: any) => {
    setSelectLocation(location)
    setCountryToDisplay(location.address.country)
    setFirstDataItem(location.display_name)
    setSelectedItemToDisplay(location.display_name)
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, zIndex: 3 }}>
        <TextField
          sx={{ width: '100%' }}
          onChange={(e: any) => {
            searchText(e.target.value)
          }}
          InputProps={{ sx: { borderRadius: 1 } }}
          id="outlined-basic"
          label={InputPlaceHolder}
          variant="outlined"
        />
      </div>
      {firstDataList.length > 0 || secondDataList.length > 0 ? (
        !clearTheSelections ? (
          <div
            style={{
              display: 'flex',
              maxHeight: windowSize.innerHeight * 0.9,
              overflow: 'auto',
              width:
                place == 'post'
                  ? windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                    ? '80%'
                    : '43%'
                  : place == 'article'
                    ? windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                      ? '80%'
                      : '30%'
                    : '26.5%',
              marginTop:
                windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                  ? windowSize.innerWidth * 0.14
                  : '3.3%',
              backgroundColor: 'white',
              zIndex: 100,
              position: 'absolute',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderTop: 'white',
              marginLeft: 7,
            }}
          >
            <div
              style={{
                flex: 1,
                overflowY: 'auto',
                borderRight: 'black',
                borderWidth: '0.1px',
                borderTop: 'white',
                borderLeft: 'white',
                borderBottom: 'white',
                borderStyle: 'solid',
              }}
            >
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <p
                  style={{
                    color: 'grey',
                    fontSize: '10px',
                    marginBottom: 7,
                    marginTop: 4,
                    textAlign: 'start',
                  }}
                >
                  {firstDataListTopic}
                </p>
                {firstDataList
                  ? firstDataList.map((list: any) => (
                      <div style={{ display: 'flex' }}>
                        <p
                          key={list.place_id}
                          style={{
                            marginTop: 10,
                            fontWeight: 'bold',
                            //fontSize:'12px',
                            textAlign: 'start',
                            fontSize: place == 'post' ? '15px' : '12px',
                          }}
                          onClick={() => {
                            setFirstData(list)
                          }}
                        >
                          {list.display_name}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <p
                  style={{
                    color: 'grey',
                    fontSize: '10px',
                    marginBottom: 7,
                    marginTop: 4,
                    textAlign: 'start',
                  }}
                >
                  {secondDataListTopic ? secondDataListTopic : null}
                </p>
                {secondDataList
                  ? secondDataList.map((list: any) => (
                      <p
                        key={list.place_id}
                        style={{
                          marginTop: 10,
                          fontWeight: 'bold',
                          fontSize: '12px',
                        }}
                        onClick={() => {
                          setSecondData(list)
                        }}
                      >
                        {list.title}
                      </p>
                    ))
                  : null}
              </div>
            </div>
          </div>
        ) : null
      ) : null}
      {(firstDataListloading || secondDataListloading) && (
        <div>
          <BeatLoader color="#303655" size={10} />
        </div>
      )}
    </div>
  )
}

export default AutoCompleteTwoText
