import React, { useEffect, useState } from 'react'
import Tabs from '../../components-v2/Tabs'
import CommentsTab from './tabs/CommentsTab'
import ContrastsTab from './tabs/ContrastsTab'
import EditsTab from './tabs/EditsTab'
import { useNotifications } from './notifications.store'
import { readFromSection } from '../../services/notifications'

type Props = {}

export default function NotificationsPage({}: Props) {
  const [currentTab, setCurrentTab] = useState('comments')

  const loadSummary = useNotifications((state: any) => state.loadSummary)
  const notifications = useNotifications((state: any) => state.notifications)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      readFromSection('upvotes').then(() => {
        loadSummary()
      })
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [])

  const commentsCount = notifications?.filter(
    (x: any) => x.section === 'comments'
  ).length

  const contrastsCount = notifications?.filter(
    (x: any) => x.section === 'contrasts'
  ).length

  const editsCount = notifications?.filter((x: any) => x.section === 'edits')
    .length

  const tabsToShow = [
    {
      value: 'comments',
      label: (
        <div className="flex items-center gap-[2px]">
          <span className="leading-[10px]">Comments</span>
          {commentsCount > 0 && (
            <div className="text-bold bg-red-500 min-w-[14px] h-[14px] rounded-full flex items-center justify-center text-white text-[9px] leading-[14px]">
              {commentsCount}
            </div>
          )}
        </div>
      ),
    },
    {
      value: 'contrasts',
      label: (
        <div className="flex items-center gap-[2px]">
          <span className="leading-[10px]">Contrasts</span>
          {contrastsCount > 0 && (
            <div className="text-bold bg-red-500 min-w-[14px] h-[14px] rounded-full flex items-center justify-center text-white text-[9px] leading-[14px]">
              {contrastsCount}
            </div>
          )}
        </div>
      ),
    },
    {
      value: 'edits',
      label: (
        <div className="flex items-center gap-[2px]">
          <span className="leading-[10px]">Edits</span>
          {editsCount > 0 && (
            <div className="text-bold bg-red-500 min-w-[14px] h-[14px] rounded-full flex items-center justify-center text-white text-[9px] leading-[14px]">
              {editsCount}
            </div>
          )}
        </div>
      ),
    },
  ]

  let tab = null
  switch (currentTab) {
    case 'contrasts':
      tab = <ContrastsTab />
      break

    case 'comments':
      tab = <CommentsTab />
      break

    case 'edits':
      tab = <EditsTab />
      break
  }

  return (
    <div className="pb-5">
      <Tabs
        className="mb-6 mt-6"
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        tabs={tabsToShow}
      />

      {tab}
    </div>
  )
}
