export const MAX_PHONE_BREAK_POINT = 420
export const MIN_PHONE_BREAK_POINT = 320

export const MAX_TABLET_BREAK_POINT = 1025
export const MIN_TABLET_BREAK_POINT = 420

export const MIN_DESKTOP_BREAK_POINT = 1025

export const NON_PROFIT_MEDIA = 'Non-profit media'
export const FOR_PROFIT_MEDIA = 'For-profit media'
export const GOVERNMENT_LINKED_MEDIA = 'Government linked company/org'

export const PROPERTIES_TO_DELETE = [
  'house_number',
  'road',
  'postcode',
  'hamlet',
  'residential',
]

export const CLASS_FOR_LOCATION = ['boundary', 'place', 'country']

export const TYPE_FOR_LOCATION = [
  'city',
  'borough',
  'suburb',
  'quarter',
  'neighbourhood',
  'town',
  'village',
  'islet',
  'administrative',
  'political',
  'place',
]

export const LOCATION_ADDRESS_TYPE_TO_AVOID = ['county', 'state', 'hamlet']

export const ANALYTICS_API_CALL_TIME = 6000
