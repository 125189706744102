import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useSignupFormData } from '../../signupFormData.store'
import Button from '../../../../components-v2/Button'
import Loading from '../../../../components-v2/Loading'
import Markdown from 'react-markdown'
import { termMd } from './content'
import { useRedirect } from '../../useRedirect'
import { signup } from '../../../../services/auth'
import { useDispatch } from 'react-redux'
import { getMe } from '../../../../redux/login/actions'
import axios from 'axios'

type Props = {}

const schema = z.object({
  termAccepted: z.boolean().refine((val) => val === true, {
    message: 'Please accept the terms of service',
  }),
})

type SchemaType = z.infer<typeof schema>

export default function TermTab({}: Props) {
  useRedirect()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })
  const setMultipleValues = useSignupFormData(
    (state: any) => state.setMultipleValues
  )

  const navigate = useNavigate()

  const data = useSignupFormData((state: any) => state.data)

  const dispatch = useDispatch()

  return (
    <div className="min-h-screen bg-primary font-inter text-white">
      <form
        className="mx-auto mt-[50px] max-w-[300px]"
        onSubmit={handleSubmit(async (values) => {
          try {
            setMultipleValues(values)
            const res = await signup(data)

            await axios
              .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
                email: data.email,
                password: data.password,
              })
              .then((res) => {
                localStorage.setItem('token', res.data.token)
                getMe()

                navigate('/')
              })
              .catch((err) => {
                console.log({ err })
              })

            // navigate('/signup/term')
          } catch (error) {
            console.log('🚀 ~ onSubmit={handleSubmit ~ error:', error)
          }
        })}
      >
        <h1 className="mb-3 text-center font-bold">
          Please read below terms of service
        </h1>

        <Markdown
          className={
            'prose h-[60vh] gap-2 overflow-y-auto text-xs text-white [&_a]:text-blue-500 [&_strong]:text-white'
          }
        >
          {termMd}
        </Markdown>

        <label className="mt-3 flex cursor-pointer justify-center">
          <input
            type="checkbox"
            {...register('termAccepted')}
            className="mr-2"
          />
          <span className="text-sm text-white">
            I’ve read and I accept the terms of service
          </span>
        </label>
        {errors?.termAccepted && (
          <p className="mt-1 text-center text-xs font-bold text-red-500">
            {errors?.termAccepted?.message}
          </p>
        )}

        <Button
          disabled={isSubmitting}
          type="submit"
          className="mt-3 flex w-full items-center justify-center gap-2 text-black transition-all"
        >
          Continue {isSubmitting && <Loading />}
        </Button>
      </form>
    </div>
  )
}
