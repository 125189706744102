import React from 'react'
import {
  getHowLongAgo,
  parsePostObjToProps,
} from '../../components-v2/Post/helpers'
import PostCard from '../../components-v2/Post/PostCard'
import { cn } from '../../utils/cn'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components-v2/shadcn/accordion'
import { diffToReactNode } from './helper'
import { CircleAlert } from 'lucide-react'

type Props = {
  event: any
}

export default function EditedEvent({ event }: Props) {
  const time = getHowLongAgo(event?.createdAt)
  const currentPost = event?.activity?.currentPost
  const archivePost = event?.activity?.archivePost

  const postTypeUpdated =
    archivePost?.postTypeId?.toLowerCase() !==
    currentPost?.postTypeId?.toLowerCase()

  const topicChanged = archivePost?.topicId !== currentPost?.topicId

  const channelChanged =
    archivePost?.organisationId !== currentPost?.organisationId

  const contentChanged = archivePost?.description !== currentPost?.description

  return (
    <div className="max-w-[362px] w-full">
      <p className="">
        <span className="font-bold">{event?.actingUser?.name}</span>{' '}
        {event?.eventType} your post{' '}
        {time === 'Just now' ? time : `${time} ago`}.
      </p>

      {event?.activity?.reason && <p>Reason is "{event?.activity?.reason}"</p>}

      <div className="text-border flex flex-col mt-1">
        {postTypeUpdated && (
          <div className="flex gap-[2px] items-center">
            <CircleAlert size={15} /> Type changed
          </div>
        )}
        {topicChanged && (
          <div className="flex gap-[2px] items-center">
            <CircleAlert size={15} /> Topic changed
          </div>
        )}
        {channelChanged && (
          <div className="flex gap-[2px] items-center">
            <CircleAlert size={15} /> Channel updated
          </div>
        )}
        {contentChanged && (
          <div className="flex gap-[2px] items-center">
            <CircleAlert size={15} /> Content updated
          </div>
        )}
      </div>

      <PostCard {...parsePostObjToProps(currentPost)} className={cn('mt-4 ')} />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1" className="text-xs">
          <AccordionTrigger className="flex p-0 py-2 text-border">
            See original post
          </AccordionTrigger>
          <AccordionContent className="text-xs flex flex-col gap-1">
            {topicChanged && (
              <div>
                Topic was changed to{' '}
                <span className="font-semibold">
                  {currentPost?.topic?.title}
                </span>
              </div>
            )}
            {/* {channelChanged && <div>Channel was updated</div>} */}

            <PostCard
              {...parsePostObjToProps(archivePost)}
              className="pointer-events-none opacity-50 "
              contentJSX={diffToReactNode(event?.diff || [])}
              disableActions
              postTypeLineThrough={postTypeUpdated}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
