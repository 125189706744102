export const GET_TOPIC_REQUEST = 'GET_TOPIC_REQUEST'
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS'
export const GET_TOPIC_FAILURE = 'GET_TOPIC_FAILURE'

export const GET_ALL_TOPICS = 'GET_ALL_TOPICS'
export const CREATE_TOPICS = 'CREATE_TOPICS'
export const ADD_TOPIC_ID = 'ADD_TOPIC_ID'

export const SHOW_CREATE_TOPIC_MODAL = 'SHOW_CREATE_TOPIC_MODAL'
export const CLOSE_CREATE_TOPIC_MODAL = 'CLOSE_CREATE_TOPIC_MODAL'
export const GET_SELECTED_TOPICS = 'GET_SELECTED_TOPICS'
