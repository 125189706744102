import React, { useEffect, useState } from 'react'
import LogoSVG from '../../assets/icons/check-logo.svg'
import PlusSVG from '../../assets/icons/plus.svg'
import MenuSVG from '../../assets/icons/fi-rr-menu-burger.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EntryDialog from '../EntryDialog/EntryDialog'
import DropdownNav from './DropdownNav'
import { cn } from '../../utils/cn'
import { getAllTopics } from '../../redux/topics/actions'
import { useDispatch, useSelector } from 'react-redux'
import FilterDialog from '../FilterWheel/postType/PostTypeWheelDialog'
import SidebarPopover from './Sidebar/SidebarPopover'
import { CircleAlert } from 'lucide-react'
import Button from '../Button'
import { resendEmailVerification } from '../../services/auth'
import { toast } from 'sonner'
import Loading from '../Loading'

type Props = {
  cb?: () => void
}

export default function Header({ cb }: Props) {
  const [sendingEmail, setSendingEmail] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state: any) => state.login.user)

  const permissions = user?.roles?.permissions
  const emailVerified = user?.emailVerified
  // console.log('🚀 ~ Header ~ emailVerified:', emailVerified)

  return (
    <div className="h-[43px]">
      <header className="flex items-center justify-between bg-primary h-[43px] px-[10px] fixed w-full z-50">
        {/* Logo */}

        {user && !emailVerified && (
          <div className="flex items-center text-yellow-400 mx-desktop:backdrop-blur-lg mx-desktop:bg-black/10 rounded-md px-1 mx-desktop:text-primary gap-1 absolute left-[45px] mx-desktop:left-[10px] top-[13px] mx-desktop:top-[50px]">
            <CircleAlert size={18} />
            <p className="text-xs leading-tight pt-[2px] flex gap-[3px]">
              Please verify your email
              <button
                disabled={sendingEmail}
                className={cn(
                  'text-xs px-[3px] py-0 min-h-fit bg-yellow-400 h text-primary transition-all rounded-md cursor-pointer italic font-bold hover:brightness-90',
                  {
                    'bg-yellow-400/50 text-primary/70 pointer-events-none':
                      sendingEmail,
                  }
                )}
                onClick={() => {
                  setSendingEmail(true)
                  resendEmailVerification()
                    .then(() => {
                      toast.success('Verification email is on the way.')
                    })
                    .finally(() => {
                      setSendingEmail(false)
                    })
                }}
              >
                {/* {sendingEmail ? <Loading /> : 'Resend email'} */}
                Resend email
              </button>
            </p>
          </div>
        )}

        <div className="flex gap-2">
          <SidebarPopover trigger={<img src={LogoSVG} className="w-6 h-6" />} />
        </div>

        {/* Middle */}
        <div className="flex gap-[29px] mx-mobile:gap-4 items-center">
          <Link
            to={'/'}
            className={cn(
              'min-w-28 mx-mobile:min-w-0 hover:font-bold transition-all text-end text-white mx-mobile:text-[14px]',
              {
                'font-bold': location.pathname === '/',
              }
            )}
          >
            Home feed
          </Link>
          <EntryDialog
            disableTopic={permissions?.topicCreation}
            articleAllow={permissions?.articleCreation}
            quotesAllow={permissions?.quotesCreation}
            trigger={
              <div className="cursor-pointer flex-shrink-0">
                <img src={PlusSVG} className="w-6 h-6 flex-shrink-0" />
              </div>
            }
            cb={() => {
              cb && cb()
              // getAllTopics()(dispatch)
            }}
          />
          <Link
            to={'/trust'}
            className={cn(
              'min-w-28 mx-mobile:min-w-0 hover:font-bold transition-all text-start text-white mx-mobile:text-[14px]',
              {
                'font-bold': location.pathname === '/trust',
              }
            )}
          >
            Trust feed
          </Link>
        </div>

        {/* Dropdown */}
        <div className="flex items-center">
          <DropdownNav trigger={<img src={MenuSVG} className="w-6 h-6" />} />
        </div>
      </header>

      {/*mobile view*/}
      <div
        className={
          'fixed ml-2 bottom-0 mb-2 bg-[#d3e6c6] border-r-2 rounded-lg  desktop:hidden cursor-pointer hover:bg-[#c7edad] z-50'
        }
        style={{
          boxShadow: ' 0px 0px 10px 10px rgba(255,255,255,1)',
        }}
      >
        <p
          style={{ fontWeight: 'bold' }}
          className={'text-[#2443B2] p-2'}
          onClick={() => {
            navigate(`/topic/1b5e08aa-162f-4fae-8916-0bce5d0d0d2a`)
          }}
        >
          Give Feedback
        </p>
      </div>

      {/*desktop view*/}
      <div
        className={
          'fixed mt-12 mr-3 right-0 bg-[#d3e6c6] border-r-2 rounded-lg mx-desktop:hidden cursor-pointer hover:bg-[#c7edad] z-50'
        }
        onClick={() => {
          navigate(`/topic/1b5e08aa-162f-4fae-8916-0bce5d0d0d2a`)
        }}
      >
        <p style={{ fontWeight: 'bold' }} className={'text-[#2443B2] p-2'}>
          {' '}
          Give Feedback
        </p>
      </div>
    </div>
  )
}
