const ContrastsUnderlay = ({ marginTop = 0, marginBottom = 0 }: any) => {
  return (
    <div
      style={{
        zIndex: 0,
        display: 'flex',
        position: 'absolute',
        top: marginTop,
        left: 0,
        right: 0,
        bottom: marginBottom,
      }}
    >
      <div style={{ flex: 3 }}></div>
      <div style={{ flex: 7, display: 'flex' }}>
        <div style={{ flex: 58 }}></div>
        <div
          style={{ flex: 42, borderLeft: '1px #d9d9d9 solid', marginLeft: -25 }}
        ></div>
      </div>
    </div>
  )
}

export default ContrastsUnderlay
