import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import astrickMarkRed from '../../assets/icons/astrick-mark-red.svg'
import React, { useState } from 'react'
import Popover from 'react-bootstrap/Popover'

const popover = (
  <Popover style={{ width: '300px' }}>
    <Popover.Header as="h3">Popover right</Popover.Header>
    <Popover.Body>
      <b>Admin</b>
      <ul>
        <li>Can edit the profile of an organization</li>
        <li>Can add/remove members of an organization</li>
        <li>Can leave an organization him/herself;</li>
        <li>Can contribute content as a member of that organization.</li>
      </ul>
    </Popover.Body>
  </Popover>
)

const OverlayError = ({ errorMessage, toggleFlag, modalClose }: any) => {
  const [showOverlay, setShowOverLay] = useState(false)
  const onClickFunction = () => {
    setShowOverLay(!showOverlay)
    modalClose()
  }
  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <div>
        <img
          id="overlay"
          src={astrickMarkRed}
          alt="icon"
          onClick={() => {
            onClickFunction()
          }}
        />
        {showOverlay && toggleFlag && (
          <div className={'overlay-div-post-create-content'}>
            <h5 style={{ padding: '8px' }}>{errorMessage}</h5>
          </div>
        )}
      </div>
    </OverlayTrigger>
  )
}

export default OverlayError
