import { XIcon } from 'lucide-react'
import React from 'react'
import {
  Control,
  Controller,
  useController,
  useFormContext,
} from 'react-hook-form'
import { Textarea } from '../../../shadcn/textarea'
import { PostSchemaType, QuoteSchemaType, SchemaType } from './helper'
import { Input } from '../../../shadcn/input'
import DateField from '../AddQuote/DateField'
import moment from 'moment'
import ImageUploadIcon from '../../../ImageUploadIcon'
import RenderFieldError from '../../../RenderFieldError'
import SearchUserField from '../AddQuote/SearchUserField'
import PartyCard from '../AddTopic/PartyCard'
import { cn } from '../../../../utils/cn'
import LandscapeSVG from '../../../../assets/icons/landscape.svg'
import UploadProgress from '../../../UploadProgress'
import { useUpload } from '../../../../utils/uploadImage'

type Props = {
  index: number
  // values: QuoteSchemaType
  // errors?: any
  // onChangeValues: (values: QuoteSchemaType) => void
  onClickClose: (index: number) => void
}

export default function QuoteInputField({ index, onClickClose }: Props) {
  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors: err },
  } = useFormContext<SchemaType>()

  const {
    upload,
    progress,
    url: postImageUrl,
    setUrl: setPostImageUrl,
    totalBytes,
    transferedBytes,
  } = useUpload()

  const imageUrl = watch(`posts.${index}.data.imageUrl`)
  const text = watch(`posts.${index}.data.text`)

  const customUserName = watch(`posts.${index}.data.customUserName`)
  const userId = watch(`posts.${index}.data.userId`)

  const errors = err?.posts?.[index] as any
  console.log('🚀 ~ QuoteInputField ~ errors:', errors)

  return (
    <div className="flex mt-5">
      <div className="w-5 text-xs font-semibold -ml-5">{index + 1}.</div>

      <div className="flex-1">
        <div className="flex justify-between">
          <div className="text-xs font-semibold uppercase flex gap-[3px]">
            <p className="text-border min-w-12*">Quote:</p>
            <p className="">Opinion</p>
          </div>

          <div
            className="w-[18px] h-[18px] hover:bg-line transition-all flex items-center justify-center rounded-md cursor-pointer"
            onClick={() => onClickClose(index)}
          >
            <XIcon size={16} />
          </div>
        </div>

        <div className="mt-[2px]">
          <SearchUserField
            trigger={
              <div>
                {customUserName && (
                  <div className="border border-line rounded-lg w-full h-10 text-[11px] flex items-center pl-3 cursor-pointer space-x-1">
                    <span>Custom User: </span>
                    <span className="font-bold">{customUserName}</span>
                  </div>
                )}

                {userId && (
                  <div className="border border-line rounded-lg w-full text-[11px] flex items-center cursor-pointer p-2">
                    <PartyCard userId={userId} />
                  </div>
                )}

                {!(customUserName || userId) && (
                  <div className="border border-line rounded-lg w-full h-10 text-[11px] text-line italic flex items-center pl-3 cursor-pointer">
                    Search User*
                  </div>
                )}
              </div>
            }
            onSelectUser={(userId) => {
              setValue(`posts.${index}.data.userId`, userId)
              setValue(`posts.${index}.data.customUserName`, '')
            }}
            onSetCustomUser={(name) => {
              setValue(`posts.${index}.data.customUserName`, name)
              setValue(`posts.${index}.data.userId`, '')
            }}
          />
        </div>
        <RenderFieldError error={errors?.userId} />

        <Textarea
          placeholder="Quote"
          className="h-[29px] bg-line border-line placeholder:text-border placeholder:text-[11px] placeholder:italic mt-2 text-xs"
          {...register(`posts.${index}.data.text`)}
        />
        <RenderFieldError error={errors?.text} />

        <Input
          className="h-[29px] border-line placeholder:text-line placeholder:text-[11px] placeholder:italic text-xs mt-2"
          placeholder="Role"
          {...register(`posts.${index}.data.role`)}
        />

        <Input
          className="h-[29px] border-line placeholder:text-line placeholder:text-[11px] placeholder:italic text-xs mt-2"
          placeholder="Source*"
          {...register(`posts.${index}.data.source`, { required: true })}
        />

        <RenderFieldError error={errors?.source} />

        <Controller
          control={control}
          name={`posts.${index}.data.date`}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <DateField
                trigger={
                  <div className="mt-2">
                    {value ? (
                      <div className="border border-line rounded-lg w-full h-[29px] text-[11px]  flex items-center pl-3 cursor-pointer">
                        {moment(value).format('YYYY-MM-DD')}
                      </div>
                    ) : (
                      <div className="border border-line rounded-lg w-full h-[29px] text-[11px] text-line italic flex items-center pl-3 cursor-pointer">
                        Date*
                      </div>
                    )}
                  </div>
                }
                date={value}
                setDate={onChange}
              />
            )
          }}
        />

        <RenderFieldError error={errors?.date} />

        {imageUrl && (
          <div className="flex flex-col gap-1 w-full">
            <div
              className="w-[18px] h-[18px] hover:bg-line transition-all flex items-center justify-center rounded-md cursor-pointer"
              onClick={() => setValue(`posts.${index}.data.imageUrl`, '')}
            >
              <XIcon size={16} />
            </div>
            <img src={imageUrl} className="object-cover rounded-md w-full" />
          </div>
        )}

        {progress > 0 && (
          <UploadProgress
            progress={progress}
            transferedBytes={transferedBytes}
            totalBytes={totalBytes}
          />
        )}

        {/* <div className="flex justify-between items-center mt-[16px]">
          <div>
            <Controller
              control={control}
              name={`posts.${index}.data.imageUrl`}
              render={({ field: { onChange, value } }) => {
                return (
                  <label
                    className={cn(
                      'p-[3px] rounded-md transition-all cursor-pointer hover:bg-line '
                    )}
                  >
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={(event) => {
                        const image = event.target.files?.[0]
                        if (image) {
                          upload(image, 'user-images', onChange)
                        }
                      }}
                    />
                    <img src={LandscapeSVG} className="w-[16px] h-[16px] " />
                  </label>
                )
              }}
            />
          </div>

          <p
            className={cn('text-[11px] text-border', {
              'text-red-500': text?.length > 300,
            })}
          >
            {text?.length || 0}/300
          </p>
        </div> */}
      </div>
    </div>
  )
}
