import { useEffect, useState } from 'react'
import { Dialog, DialogClose, DialogContent } from './shadcn/dialog'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  url?: string
  defaultOpen?: boolean
}

export default function NoAuthDialog(props: DialogProps) {
  const { open, setOpen, defaultOpen } = props

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        overlayLight
        className="p-0 border-0 bg-primary max-w-[1100px] h-[80vh] mx-sm:h-[100dvh] mx-sm:rounded-none [&>button]:text-white rounded-lg shadow-2xl"
      >
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

function Content({ url, defaultOpen, setOpen }: DialogProps) {
  return (
    <>
      <iframe
        src={url}
        // width="600"
        // height="400"
        className="w-full h-full rounded-lg"
      />
    </>
  )
}
