import Spacer from '../Spacer'
import { MenuItem, Select, TextField } from '@mui/material'
import landscape from '../../assets/icons/landscape.svg'
import contrastDark from '../../assets/icons/contrast-dark.svg'
import clip from '../../assets/icons/clip.svg'
import gifImg from '../../assets/icons/gifimg.svg'
import face from '../../assets/icons/face.svg'
import InputToggle from '../InputToggle'
import Button from '../Button'
import React, { useEffect, useState, useRef } from 'react'
import AutoCompleteTwoText from '../AutoCompleteTwoText'
import wrongBlue from '../../assets/icons/blue-wrong.svg'
import ChannelPicker from '../../containers/ChannelPicker'

const SelectTopicPostCreateContent = (props: any) => {
  const {
    user,
    onCancel,
    clickSubmit,
    setpostTypeid,
    handleTextWithLineBreaks,
    description = '',
    selectedTopic,
    clickedPost,
    locationsData, //locations from the api
    setLocations, //grab the location from the api
    setSelectLocation,
    setOrg,
    setImgae,
    image,
    org,
    topicData,
    setSelectTopicFromModal,

    showMediaUpload,
  }: any = props

  const { name } = user

  const [postTypes, setPostTypes] = useState(
    user?.roles?.permissions?.postCreation.map((name: any, id: any) => ({
      id,
      name,
    }))
  )
  const [type, setType] = useState(-1) //posts type
  const [desc, setDesc] = useState('Tell the world')
  const { title: topicTitle, locationId } = selectedTopic || {}
  const { postTypeId: parentPostTypeId } = clickedPost || {}

  //auto complete
  const [selectedLocationFrmAutoComp, setSelectedLocationFrmAutoComp] =
    useState<any>()
  const [selectedTopicFrmAutoComp, setSelectedTopicFrmAutoComp] =
    useState<any>()
  const [selectedItemToDisplay, setSelectedItemToDisplay] = useState<any>()
  const [countryToDisplay, setCountryToDisplay] = useState<any>()

  const savePost = () => {
    clickSubmit()
  }

  const closeModal = () => {
    onCancel()
  }

  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    switch (type) {
      case 0:
        setpostTypeid('Fact') //set the type
        setDesc('Share factual elements with the world')
        break
      case 1:
        setpostTypeid('Research')
        setDesc('Tell the world what info and conclusions you have gathered')
        break
      case 2:
        setpostTypeid('Opinion')
        setDesc('Tell the world what you believe in')
        break
      case 3:
        setpostTypeid('Rumor')
        setDesc('Tell the world what you heard')
        break
      case 4:
        setpostTypeid('Humor')
        setDesc('Hahahahaha')
        break
      default:
        setpostTypeid('-')
        setDesc('-')
    }
  }, [type])

  const makeNull = () => {
    setSelectedItemToDisplay(null)
    setCountryToDisplay(null)
  }

  return (
    <div
      style={{
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        background: '#FBFBFD',
        width: 800,
        overflow: 'hidden',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {type === -1 && (
        <div
          style={{
            textAlign: 'start',
            paddingRight: 80,
            paddingLeft: 80,
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <text style={{ fontSize: 24, fontWeight: 'bold' }}>Choose Type</text>
          <Spacer height={10} />
          <div style={{ height: 1, width: 130, background: '#000000' }}></div>
          <Spacer height={20} />
          <div style={{ flexDirection: 'column' }}>
            {postTypes?.map((type: any) => (
              <div
                className={'option'}
                onClick={() => {
                  setType(type.id)
                }}
              >
                <text>{type.name}</text>
                <Spacer height={20} />
              </div>
            ))}
          </div>
        </div>
      )}

      {type !== -1 && (
        <div
          style={{
            textAlign: 'start',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 30,
            paddingBottom: 30,
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex' }}>
            <text style={{ fontSize: 24 }}>Create new</text>
            <Spacer width={10} />
            <Select
              size={'small'}
              style={{ fontWeight: 'bold', fontSize: 24, marginTop: -5 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="City"
              variant="standard"
              onChange={(event) => {
                setType(Number(event.target.value))
              }}
            >
              {postTypes.map((type: any) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Spacer height={40} />

          <div>
            <div>
              {selectedItemToDisplay ? (
                <div
                  style={{
                    background: '#D9D9D9',
                    display: 'flex',
                    padding: 12,
                    borderRadius: 18,
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <text
                      style={{
                        textAlign: 'start',
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedItemToDisplay}
                    </text>
                    <p style={{ textAlign: 'start' }}>
                      {countryToDisplay ? countryToDisplay : null}
                    </p>
                  </div>
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <img
                      style={{
                        height: 18,
                        width: 18,
                        marginRight: 5,
                        marginTop: 2,
                      }}
                      src={wrongBlue}
                      onClick={() => {
                        makeNull()
                      }}
                      alt="ksdsd"
                    />
                  </div>
                </div>
              ) : (
                <AutoCompleteTwoText
                  firstDataListTopic="Locations..."
                  firstDataList={locationsData}
                  secondDataListTopic="Topics..."
                  secondDataList={topicData}
                  InputPlaceHolder="Select location or topic..."
                  setFirstDataItem={setSelectedLocationFrmAutoComp}
                  setSelectLocation={setSelectLocation}
                  setCountryToDisplay={setCountryToDisplay}
                  setSecondDataItem={setSelectedTopicFrmAutoComp}
                  searchText={setLocations}
                  clearTheSelections={
                    selectedTopicFrmAutoComp && selectedLocationFrmAutoComp
                      ? true
                      : false
                  }
                  setSelectTopicFromModal={setSelectTopicFromModal}
                  setSelectedItemToDisplay={setSelectedItemToDisplay}
                  place="post"
                />
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '15px',
            }}
          >
            {/*<text style={{textAlign: 'start', fontWeight: 'bold'}}>{name}</text>*/}
            {/*{!org && (<text style={{textAlign: 'start', fontWeight: 'bold'}}>Personal</text>)}*/}

            <ChannelPicker
              editPost={true}
              setOrg={setOrg}
              articlePicker={false}
              width={'330px'}
            />
            <Spacer height={10} />
          </div>

          <div style={{ flex: 1, overflow: 'scroll', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                style={{
                  fontSize: 20,
                  background: '#ffffff',
                  width: '100%',
                }}
                size={'small'}
                label={desc}
                rows={6}
                variant={'filled'}
                onChange={handleTextWithLineBreaks}
                multiline
              />
              <Spacer height={20} />
              <text
                style={{ color: description.length > 299 ? 'red' : 'black' }}
              >{`${description.length}/300`}</text>
              <Spacer height={20} />
              <div style={{ textAlign: 'center' }}>
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{ width: '100%' }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <Spacer height={10} />
          <div
            style={{ height: 1, width: '100%', background: '#000000' }}
          ></div>
          <Spacer height={3} />
          <div style={{ display: 'flex' }}>
            <Spacer width={4} />
            <div>
              <label htmlFor="upload-button">
                <>
                  <input
                    type="file"
                    onChange={(event: any) => {
                      setImgae(event.target.files[0])
                    }}
                    id="imgs"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <img
                    className={'img-button'}
                    style={{ height: 18, width: 18 }}
                    src={landscape}
                    alt="k"
                    onClick={handleClick}
                  />
                </>
              </label>
              <br />
            </div>

            <Spacer width={4} />
            <img
              className={'img-button'}
              style={{ height: 18, width: 18 }}
              src={contrastDark}
              alt=""
            />
            <Spacer width={4} />
            <img
              className={'img-button'}
              style={{ height: 18, width: 18 }}
              src={clip}
              alt=""
            />
            <Spacer width={4} />
            <img
              className={'img-button'}
              style={{ height: 18, width: 18 }}
              src={gifImg}
              alt=""
            />
            <Spacer width={4} />
            <img
              className={'img-button'}
              style={{ height: 18, width: 18 }}
              src={face}
              alt=""
            />
          </div>
          <Spacer height={4} />
          <div
            style={{ height: 1, width: '100%', background: '#000000' }}
          ></div>
          <Spacer height={40} />
          <InputToggle title={'Add Paragraph'} onClick={() => {}} />
          <Spacer height={40} />

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button title={'Cancel'} onClick={() => closeModal()} />
              <Button
                disable={description.length > 299}
                title={'Post'}
                onClick={() => savePost()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectTopicPostCreateContent
