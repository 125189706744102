import './index.css'

import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

import { addUserDetails } from '../../redux/login/actions'

import SignUpHeader from '../../components/SignUpHeader'
import InputText from '../../components/InputText'
import OnBoardButton from '../../components/OnBoardButton'

import camera from '../../assets/icons/camera.svg'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import UserTypeNoticeModal from '../../components/UserTypeNoticeModal'
import { Input } from '../../components-v2/shadcn/input'
import debounce from '../../utils/debounce'
import { fetchLocationsAndTopics } from '../../components-v2/CreateContrastDialog/helper'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components-v2/shadcn/popover'
import { cn } from '../../utils/cn'
import { XCircle } from 'lucide-react'
import TopicSelectDialogContent from '../../components-v2/CreateContrastDialog/TopicSelectDialogContent'
import LocationSelect from '../../components-v2/LocationSelect/LocationSelect'
import Spacer from '../../components/Spacer'
import { Controller, useForm } from 'react-hook-form'
import DateField from '../../components-v2/EntryDialog/dialogs/AddQuote/DateField'
import moment from 'moment/moment'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { notEmpty } from '../../utils/formHelpers'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components-v2/shadcn/dialog'
// import { LocalizationProvider } from '@mui/material'
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
}))

const ProfileSetup = ({
  setSelectUserTypeModalVisible,
  userTypeValue,
  usersTempId,
  addUserDetails,
}: any) => {
  const navigate = useNavigate()
  const [image, setImage] = useState<any | null>(null)
  const inputImage = useRef(document.createElement('input'))

  //form data begin
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [aboutYourSelf, setAboutYourSelf] = useState(null)
  const [userId, setUserId] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [location, setLocation] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [birthday, setBirthday] = useState<any>()
  // const [locationObj, setLocationObj] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  // const [selectedDate, setSelectedDate] = useState(dayjs('2022-04-17'))

  // Function to handle date change
  // const handleDateChange = (date) => {
  //   setSelectedDate(date)
  // }
  const [topicOpen, setTopicOpen] = useState<boolean>(false)
  const [userTypeNoticeModalVisible, setUserTypeNoticeModalVisible] =
    useState(false)

  const handleDateChange = (event: any) => {
    setBirthday(event.target.value)
  }
  const handleImage = (e: any) => {
    setImage(URL.createObjectURL(e?.target?.files[0]))
    if (URL.createObjectURL(e?.target?.files[0]) == null) return
    const imageRef = ref(
      storage,
      `user-images/${e?.target?.files[0]['name'] + uuidv4()}`
    )
    uploadBytes(imageRef, e?.target?.files[0]).then((e) => {
      getDownloadURL(e?.ref).then((url) => {
        setImageUrl(url)
      })
    })
  }

  const schema = z.object({
    firstName: z.string().pipe(notEmpty),
    lastName: z.string().pipe(notEmpty),
  })

  type SchemaType = z.infer<typeof schema>

  const {
    control,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  const submit = () => {
    if (userTypeValue !== 'Opinion maker' && userTypeValue !== '') {
      //if user select citizen or professional as user type
      setUserTypeNoticeModalVisible(true)
    } else {
      //if user select opinion maker as user type
      setUserId(usersTempId)
      addUserDetails(
        firstName,
        lastName,
        location,
        userTypeValue,
        aboutYourSelf,
        imageUrl,
        usersTempId,
        birthday,
        phoneNumber
      )
      navigate('/acceptterms')
    }
  }

  const submitNotOnOpinionMaker = () => {
    setUserTypeNoticeModalVisible(false)
    setUserId(usersTempId)
    addUserDetails(
      firstName,
      lastName,
      location,
      userTypeValue,
      aboutYourSelf,
      imageUrl,
      usersTempId,
      birthday,
      phoneNumber
    )
    navigate('/acceptterms')
  }

  const uploadImage = () => {
    inputImage.current.click()
  }

  return (
    <div className="main-div-profile-screen h-screen overflow-y-auto">
      {/*main div*/}
      {userTypeNoticeModalVisible && (
        <UserTypeNoticeModal
          setUserTypeNoticeModalVisible={setUserTypeNoticeModalVisible}
          submitNotOnOpinionMaker={submitNotOnOpinionMaker}
        />
      )}
      <SignUpHeader />
      {/*header with check logo*/}
      <div className={'margin-top-profile-screen text-center'}>
        {/*header*/}
        <text className={'check-text-profile-screen'}>Set up your profile</text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '25px' /*marginTop:'50px'*/,
        }}
      >
        <Badge
          overlap="circular"
          style={{ cursor: 'pointer' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar alt="Remy Sharp" src={camera} onClick={uploadImage} />
          }
        >
          <Avatar
            alt="Remy Sharp"
            src={image}
            sx={{
              width: 200,
              height: 200,
              cursor: 'pointer',
              bgcolor: '#FBFBFD',
              color: '#000',
              fontSize: '11px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
            }}
            onClick={uploadImage}
          >
            <div className={'avatar-text-width-profile-screen text-center'}>
              {' '}
              Add profile picture
            </div>
          </Avatar>
        </Badge>

        <input ref={inputImage} type="file" hidden onChange={handleImage} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
        }}
      >
        <div className={'middle-flex-profile'}>
          <div className={'text-left margin-top-inputs'}>
            <div>
              <InputText
                name={'First Name'}
                placeHolder={'First Name'}
                mandatory={true}
                inputType="text"
                setValue={setFirstName}
              />
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                name={'Last Name'}
                placeHolder={'Last Name'}
                mandatory={true}
                inputType="text"
                setValue={setLastName}
              />
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                name={'Phone Number'}
                placeHolder={'Phone Number'}
                mandatory={true}
                inputType="text"
                setValue={setPhoneNumber}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <text
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  color: '#FBFBFD',
                }}
              >
                Hometown
                <span style={{ fontSize: '13px' }}>*</span>
              </text>
              <Spacer height={3} />
              <Dialog open={topicOpen} onOpenChange={setTopicOpen}>
                <DialogTrigger asChild>
                  <button className={cn('cursor-pointer w-full text-left')}>
                    {/*{!topic && !locationObj && (*/}
                    <div className="border-2 border-slate-300p-1 rounded-md p-1 font-inter text-xs italic font-normal leading-3.326 text-left pl-3 text-[#9ca3af]">
                      {location.label
                        ? location.label
                        : 'Write your city here and wait to select the right one'}
                    </div>
                  </button>
                </DialogTrigger>
                <DialogContent className="p-0 w-[500px] mx-sm:w-screen">
                  <LocationSelect
                    // onSelectLocation={(value) => {
                    //   setLocation(value)
                    //   setTopicOpen(false)
                    // }}
                    // setLocationObj={setLocationObj}
                    // setLocation={setLocation}
                    locationFilterNeeded={true}
                    setLocation={(value: any) => {
                      setLocation(value)
                      setTopicOpen(false)

                      // setValue('location', value)
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                onFocus={setSelectUserTypeModalVisible}
                name={'User type'}
                placeHolder={'Choose'}
                mandatory={true}
                inputType="focusuText"
                value={userTypeValue}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <text
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  color: '#FBFBFD',
                }}
              >
                Date Of Birth
                <span style={{ fontSize: '13px' }}>*</span>
              </text>
              {/* 
              <div>
                <input
                  type="date"
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    backgroundColor: '#303655',
                    borderRadius: '6px',
                    height: '29px',
                    flexShrink: 0,
                    fontStyle: 'italic',
                    paddingLeft: '12px',
                    zIndex: 1000,
                    borderColor: '#D9D9D9',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    color: 'grey',
                    lineHeight: '29px', // Center vertically
                    alignItems: 'center', // Center text horizontally
                  }}
                  value={birthday}
                  onChange={handleDateChange} // Handle change event
                />
               
              </div> */}

              <DateField
                trigger={
                  <div className=" border-line border-2 rounded-md w-full h-[29px] text-[11px] leading-[10px] text-[#9ca3af] italic flex items-center pl-3 cursor-pointer">
                    {!birthday ? (
                      'Date*'
                    ) : (
                      <p className="text-sm font-medium text-white">
                        {moment(birthday).format('YYYY-MM-DD')}
                      </p>
                    )}
                  </div>
                }
                date={birthday}
                setDate={setBirthday}
              />
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                value={aboutYourSelf}
                name={'About yourself'}
                placeHolder={'Optional'}
                mandatory={false}
                inputType="textarea"
                setValue={setAboutYourSelf}
              />
            </div>
          </div>
          <div style={{ marginTop: '29px', cursor: 'pointer' }}>
            <OnBoardButton
              width={'300px'}
              buttonName={'Continue'}
              disabled={false}
              onClick={() => {
                submit()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    usersTempId: state.login.userTempId,
    userTempIdLoading: state.login.userTempIdLoading,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    // userCreateLocations: (firstName:any,lastName:any,location:any,userType:any,aboutYourSelf:any,imageUrl:any,userId:any) => {
    //     dispatch(userCreateLocations(firstName,lastName,location,userType,aboutYourSelf,imageUrl,userId))
    // },
    addUserDetails: (
      firstName: any,
      lastName: any,
      location: any,
      userTypeId: any,
      description: any,
      imageUrl: any,
      userId: any,
      birthday: any,
      phoneNumber: any
    ) => {
      dispatch(
        addUserDetails(
          firstName,
          lastName,
          location,
          userTypeId,
          description,
          imageUrl,
          userId,
          birthday,
          phoneNumber
        )
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetup)
