export function getHoverFillProps(hoverFill?: string, normalFill?: string) {
  return {
    onMouseEnter: (e: any) => {
      e.target.setAttribute('fill', hoverFill || '#d5e7c4')
    },
    onMouseLeave: (e: any) => {
      e.target.setAttribute('fill', normalFill || '#303655')
    },
  }
}

// export const timeFilterOptions = [
//   {
//     label: 'All posts',
//     value: 'all',
//   },
//   {
//     label: 'Last 24h',
//     value: 'last-24h',
//   },
//   {
//     label: 'Last 2 days',
//     value: 'last-2-days',
//   },
//   {
//     label: 'Last 7 days',
//     value: 'last-7-days',
//   },
// ]

// export const orderFilterOptions = [
//   {
//     label: 'New to Old',
//     value: 'new-to-old',
//   },
//   {
//     label: 'Old to New',
//     value: 'old-to-new',
//   },
//   {
//     label: 'Most Upvotes',
//     value: 'most-u-to-least-u',
//   },
// ]

export const userDimensionOptions = [
  {
    label: 'Niooze user selection',
    value: 'niooze',
    default: true,
  },
  {
    label: 'All users',
    value: 'all',
  },
  {
    label: 'Following users',
    value: 'following',
  },
  {
    label: 'Inverse Niooze user selection',
    value: 'inverse-niooze',
  },
]

export const periodDimensionOptions = [
  {
    label: 'Posted anytime',
    value: 'all',
    default: true,
  },
  {
    label: 'Last 24 hours',
    value: '24h',
  },
  {
    label: 'Last 2 days',
    value: '2d',
  },
  {
    label: 'Last 7 days',
    value: '7d',
  },
]

export const impressionsDimensionOptions = [
  {
    label: 'Unseen & seen',
    value: 'all',
    default: true,
  },
  {
    label: 'Unseen',
    value: 'unseen',
  },
  {
    label: 'Seen',
    value: 'seen',
  },
]

export const sortingDimensionOptions = [
  {
    label: 'New to old',
    value: 'newtoold',
    default: true,
  },
  {
    label: 'Old to new',
    value: 'oldtonew',
  },
  {
    label: 'Most upvotes',
    value: 'upvotes',
  },
]
