import { Camera, X } from 'lucide-react'
import React from 'react'
import { useUpload } from '../utils/uploadImage'
import UploadProgress from './UploadProgress'

type Props = {
  src?: string
  setSrc?: (src: string) => void
}

export default function ProfilePicUpload({ src, setSrc }: Props) {
  const {
    upload,
    progress,
    url: postImageUrl,
    setUrl: setPostImageUrl,
    totalBytes,
    transferedBytes,
  } = useUpload()
  // console.log('🚀 ~ ProfilePicUpload ~ postImageUrl:', postImageUrl)

  return (
    <div className="relative mx-auto w-[200px]">
      {src && (
        <div
          className="absolute right-3 top-3 z-50 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full border border-line bg-white"
          onClick={(e) => {
            console.log('test')

            setSrc && setSrc('')
            e.stopPropagation()
          }}
        >
          <X size={20} color="red" />
        </div>
      )}

      <label className="relative mx-auto flex h-[200px] w-[200px] cursor-pointer items-center justify-center rounded-full bg-white">
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={(event) => {
            const image = event.target.files?.[0]
            if (image) {
              upload(image, 'user-images', setSrc)
            }
          }}
        />

        {/* {!src && ( */}
        <div className="absolute bottom-3 right-3 flex h-[30px] w-[30px] items-center justify-center rounded-full border border-line bg-white">
          <Camera size={18} color="#D9D9D9" />
        </div>
        {/* )} */}

        {!src && (
          <p className="text-center text-xs text-black">
            Add profile <br /> picture
          </p>
        )}
        {src && (
          <img src={src} className="h-full w-full rounded-full object-cover" />
        )}
      </label>

      {progress > 0 && src && (
        <UploadProgress
          className="mt-2 [&>div]:bg-white"
          progress={progress}
          transferedBytes={transferedBytes}
          totalBytes={totalBytes}
        />
      )}
    </div>
  )
}
