import { useEffect } from 'react'
import { useSignupFormData } from './signupFormData.store'
import { useNavigate } from 'react-router-dom'

export function useRedirect() {
  const data = useSignupFormData((state: any) => state.data)
  const navigate = useNavigate()

  useEffect(() => {
    if (!data.email) {
      navigate('/signup/email')
    }
  }, [data])
}
