import React, { useEffect, useState } from 'react'

import './index.css'
import Spacer from '../Spacer'
import OnBoardButton from '../OnBoardButton'

const PostEditDeleteReasonModal = ({
  setPostEditDeleteReasonModalVisible,
  postEditDeleteReasonModalVisible,
  setPostDeleteEditMessage,
  onSubmit,
  loading,
  flag,
}: any) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const onSubmitClick = () => {
    setIsSubmit(true)
    onSubmit()
  }

  useEffect(() => {
    //loading
    if (postEditDeleteReasonModalVisible && isSubmit && !loading) {
      setPostEditDeleteReasonModalVisible(!postEditDeleteReasonModalVisible)
    }
  }, [
    loading,
    isSubmit,
    setPostEditDeleteReasonModalVisible,
    postEditDeleteReasonModalVisible,
  ])

  return (
    <div
      style={{
        zIndex: 1000,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={'main-div-pem'}>
        <Spacer height={19} />
        <p className={'main-heading-pem'}>REASON</p>
        <Spacer height={14} />
        {flag === 'delete' ? (
          <p className={'second-main-div-pem'}>
            {
              'You just deleted a post. If you want, you can explain here why you did this.'
            }
          </p>
        ) : (
          <p className={'second-main-div-pem'}>
            {
              'You just updated a post. If you want, you can explain here why you did this.'
            }
          </p>
        )}
        <Spacer height={20} />
        <textarea
          className={'text-area-placeholder-pem text-area-pem'}
          placeholder="Write reason. Please do not use for jokes."
          disabled={loading}
          rows={10}
          onChange={(e: any) => setPostDeleteEditMessage(e?.target?.value)}
        />
        <Spacer height={21} />
        <div className={'button-div-pem'}>
          <OnBoardButton
            disabled={false}
            loading={loading}
            onClick={() => {
              onSubmitClick()
            }}
            buttonName={'Finish'}
            borderRadius={'15px'}
            width={'248px'}
            backgroundColor={'#D9D9D9'}
          />
        </div>
        <Spacer height={23} />
      </div>
    </div>
  )
}

export default PostEditDeleteReasonModal
