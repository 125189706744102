import {
  ChevronDown,
  Heart,
  Landmark,
  PersonStanding,
  Earth,
  DollarSign,
  Cpu,
  CirclePlay,
  Sprout,
  FlaskConical,
  HeartPulse,
  GraduationCap,
  Palette,
  Shirt,
  Medal,
  University,
} from 'lucide-react'

const categoryIcons = [
  {
    id: 'PersonStanding',
    icon: PersonStanding,
  },
  {
    id: 'Heart',
    icon: Heart,
  },
  {
    id: 'Landmark',
    icon: Landmark,
  },
  {
    id: 'Earth',
    icon: Earth,
  },
  {
    id: 'DollarSign',
    icon: DollarSign,
  },
  {
    id: 'Cpu',
    icon: Cpu,
  },
  {
    id: 'CirclePlay',
    icon: CirclePlay,
  },
  {
    id: 'Sprout',
    icon: Sprout,
  },
  {
    id: 'FlaskConical',
    icon: FlaskConical,
  },
  {
    id: 'HeartPulse',
    icon: HeartPulse,
  },
  {
    id: 'GraduationCap',
    icon: GraduationCap,
  },
  {
    id: 'Palette',
    icon: Palette,
  },
  {
    id: 'Shirt',
    icon: Shirt,
  },
  {
    id: 'Medal',
    icon: Medal,
  },
  {
    id: 'University',
    icon: University,
  },
]

export function getIcon(iconName: string) {
  return categoryIcons.find((x) => x.id === iconName)?.icon
}
