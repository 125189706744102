import text from '../../assets/icons/text.svg'
import back from '../../assets/icons/back.svg'
import heart from '../../assets/icons/heart.svg'

import './index.css'
import Spacer from '../Spacer'
import { useState, useEffect } from 'react'
import ButtonComponent from '../ButtonComponent'
import moreMenu from '../../assets/icons/more-menu.svg'
import ActionMenu from '../ActionMenu'
import { useParams } from 'react-router-dom'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const TopicDetailBox = ({
  showMore,
  setPostModalVisible,
  navigate,
  selectedTopic = {},
  setClickedTopic,
  hideBack,
  deleteTopic,
  topicClickable = false,
}: any) => {
  const {
    title = '',
    description = 'Stay updated with the latest happenings in your community! Get real-time coverage of events, local stories, weather updates, and more! Connect with neighbors and be in the know with "Local News".',
    locationId = '',
  } = selectedTopic || {}
  const [isClick, setClick] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const params = useParams()
  const { postId, topicId, articleId } = params

  const handleContextClick = (menuItem: any) => {
    setShowMenu(false)
    switch (menuItem) {
      case 'delete':
        if (window.confirm('Are you sure you want to delete this topic?')) {
          navigate('/')
          deleteTopic(selectedTopic)
        }
        break
      default:
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // maxWidth:'470px',
        justifyContent: 'center',
        overflowX: 'hidden',
        marginRight: 'auto',
        marginLeft: 'auto',
        // width:'470px'
      }}
    >
      <div
        style={{
          flex: 1,
          background: '#D9D9D9',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 11 : 35,
          paddingBottom: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 0 : 10,
        }}
        className="mobile_view_padding_zero"
      >
        {hideBack ? (
          <Spacer width={15} />
        ) : (
          <div
            className={'back-button'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '10px',
            }}
            onClick={() => navigate(-1)}
          >
            <img style={{ height: 21, width: 21 }} src={back} alt="" />
          </div>
        )}

        {windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? null : (
          <Spacer width={20} />
        )}

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            textAlign: 'start',
            position: 'relative',
          }}
        >
          <div className="mobile_view_padding_right_zero desktop_view_padding_right_zero tab_view_padding_right_zero">
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <div style={{ flex: 90 }}>
                <text
                  className={topicClickable && 'post-button hover-change-color'}
                  style={{
                    fontSize:
                      windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                        ? '16px'
                        : '18px',
                    fontWeight: 'bold',
                    lineHeight: 0.9,
                  }}
                  onClick={() => {
                    topicClickable && navigate(-1)
                  }}
                >
                  {title}
                </text>
                <Spacer width={10} />
              </div>

              <div style={{ flex: 10, justifyContent: 'center' }}>
                <img style={{ height: 21, width: 21 }} src={heart} alt="" />
              </div>
            </div>

            {windowSize.innerWidth < 1300 &&
            topicId &&
            postId ? null : windowSize.innerWidth < 1300 &&
              topicId &&
              articleId ? null : (
              <>
                {windowSize.innerWidth < 1300 ? (
                  <Spacer height={19} />
                ) : (
                  <Spacer height={20} />
                )}
                <div
                  style={{ height: 2, width: 240, background: '#000000' }}
                ></div>
                {windowSize.innerWidth < 1300 ? (
                  <Spacer height={9} />
                ) : (
                  <Spacer height={20} />
                )}
              </>
            )}

            {/* <div className={"text_normal"}>
                            <text>Master topic:</text>
                            <text>{" "}</text>
                            <text style={{color: '#2443B2'}}>No master topic</text>
                        </div>
                        <div className={"text_normal"}>
                            <text>Parent topic:</text>
                            <text>{" "}</text>
                            <text style={{color: '#2443B2'}}>No parent topic</text>
                        </div> */}
            <div className={'text_normal'}>
              <text
                style={{
                  fontSize:
                    windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                      ? '15px'
                      : '16px',
                }}
              >
                Location:
              </text>
              <text> </text>
              <text style={{ color: '#2443B2' }}>{locationId}</text>
            </div>
            {windowSize.innerWidth < 1300 ? (
              <Spacer height={10} />
            ) : (
              <Spacer height={22} />
            )}

            {windowSize.innerWidth < 1300 &&
            topicId &&
            postId ? null : windowSize.innerWidth < 1300 &&
              topicId &&
              articleId ? null : (
              <>
                <div
                  style={{ height: 2, width: 240, background: '#000000' }}
                ></div>
                <Spacer height={20} />
                {windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? null : (
                  <Spacer height={20} />
                )}
                <div>
                  <text
                    className={'text_normal'}
                    style={{
                      width: '90%',
                      fontSize:
                        windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                          ? '14px'
                          : '16px',
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
                {windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? (
                  <Spacer height={15} />
                ) : (
                  <Spacer height={30} />
                )}
              </>
            )}
          </div>

          {showMore && (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ width: 30 }}>
                <ButtonComponent
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                  }}
                >
                  <div>
                    <img className={'action-more-img'} src={moreMenu} alt="" />
                  </div>
                </ButtonComponent>
              </div>
            </div>
          )}
          {showMenu && (
            <div
              style={{
                margin: 20,
                position: 'absolute',
                display: 'block',
                right: 9,
                bottom: -10,
                zIndex: 500,
                transform: 'translate(0,100%)',
              }}
            >
              <ActionMenu
                onClick={handleContextClick}
                menu={[{ id: 'delete', title: 'Delete' }]}
              />
            </div>
          )}
        </div>
      </div>
      {windowSize.innerWidth < 1300 &&
      topicId &&
      postId ? null : windowSize.innerWidth < 1300 &&
        topicId &&
        articleId ? null : (
        <div
          onClick={() => {
            setPostModalVisible(true)
            setClickedTopic(selectedTopic)
          }}
          className={'post-button'}
          style={{
            display: 'flex',
            border: '1px #D9D9D9 solid',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 5,
            paddingLeft:
              windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 14 : 58,
          }}
        >
          <text
            style={{
              fontStyle: 'italic',
              textAlign: 'start',
              color: '#D9D9D9',
              fontSize:
                windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? '14px' : '16px',
            }}
          >
            What do you want to share about this topic?
          </text>
        </div>
      )}
    </div>
  )
}

export default TopicDetailBox
