import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PostWithContrast from '../../../components-v2/Post/PostWithContrast'
import { getPostsOfATopic, getPostsOfALocalTopic } from '../../../services/post'
import Loading from '../../../components-v2/Loading'

type Props = {
  posts: any[]
  isLoading?: boolean
  cb: () => void
  onNoAuthCallback?: () => void
  analyticsPosts?: any
  addPostsToRedux?: () => void
}

export default function PostsTab({
  posts = [],
  cb,
  isLoading = false,
  onNoAuthCallback,
  analyticsPosts,
  addPostsToRedux,
}: Props) {
  const { topicId } = useParams<{ postId: string; topicId: string }>()
  // const [isLoading, setIsLoading] = useState(false)
  // const [posts, setPosts] = useState([])
  // console.log('🚀 ~ PostsTab ~ posts:', posts)

  // const pageLoad = useCallback(() => {
  //   if (topicId) {
  //     if (topicId === 'local') {
  //       //if the topic is local
  //       setIsLoading(true)
  //       getPostsOfALocalTopic()
  //         .then((data) => {
  //           setPosts(data)
  //         })
  //         .finally(() => {
  //           setIsLoading(false)
  //         })
  //     } else {
  //       //if it is not a local topic
  //       setIsLoading(true)
  //       getPostsOfATopic(topicId)
  //         .then((data) => {
  //           setPosts(data)
  //         })
  //         .finally(() => {
  //           setIsLoading(false)
  //         })
  //     }
  //   }
  // }, [topicId])

  // useEffect(() => {
  //   pageLoad()
  // }, [pageLoad])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'auto',
  //   })
  // }, [])

  return (
    <>
      {isLoading && (
        <div className="mt-5">
          <Loading />
        </div>
      )}

      <div className="flex w-full flex-col gap-6">
        {posts?.map((contrast: any, i: number) => {
          return (
            <PostWithContrast
              post={contrast}
              key={i}
              pageLoad={cb}
              onNoAuthCallback={onNoAuthCallback}
              analyticsPosts={analyticsPosts}
              addPostsToRedux={addPostsToRedux}
              isLocationModerator={contrast?.isLocationModerator}
            />
          )
        })}
      </div>
    </>
  )
}
