export const getAuth = () => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }
}
export const isAuthenticated = () => {
  const token = localStorage.getItem('token')
  return !!token
}
