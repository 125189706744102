import React from 'react'
import Button from '@mui/material/Button'
import { PuffLoader } from 'react-spinners'

import './index.css'

const OnBoardButton = ({
  buttonName,
  width,
  onClick,
  disabled = true,
  loading = false,
  borderRadius = '20px',
  backgroundColor = '#D9D9D9',
}: any) => {
  return (
    <div className={'flex-style-on-board'} style={{ width: width }}>
      <div>
        <Button
          style={{
            width: width,
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            color: '#000000',
            height: '29px',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '15px',
          }}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {buttonName}
        </Button>
      </div>
      <div
        style={{
          position: 'absolute',
          marginLeft: parseInt(width.replace('px', '')) - 28,
        }}
      >
        {loading && <PuffLoader color="black" size={20} />}
      </div>
    </div>
  )
}

export default OnBoardButton
