import axios from 'axios'
import { Dispatch } from 'react'

import {
  GET_ALL_POSTS,
  GET_POSTS_FOR_TOPICS,
  POSTS_PROGRESS_INDICATOR_OPEN,
  POSTS_PROGRESS_INDICATOR_CLOSE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  SET_SELECTED_POST,
  GET_USER_POST_REQUEST,
  GET_USER_POST_FAILURE,
  GET_USER_POST_SUCCESS,
  GET_ORG_POST_SUCCESS,
  GET_ORG_POST_REQUEST,
  GET_ORG_POST_FAILURE,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAILURE,
  DELETE_POST_DATA_CLEAR,
  SELECT_POST_DETAILS,
  //delet posts
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  POST_EDIT_RULES_REQUEST,
  POST_EDIT_RULES_SUCCESS,
  POST_EDIT_RULES_FAILURE,
} from './types'
import { getAuth } from '../../utils/auth'
import { getAllTopics } from '../topics/actions'
import { type } from 'os'
import { getTrustFeedChanges, trustFeedContrasts } from '../trustFeed/action'

const transport = axios.create({
  withCredentials: false,
})

export const getAllPostsSuccess = (posts: any) => {
  return {
    type: GET_ALL_POSTS,
    payload: posts,
  }
}

export const getAllPostsForTopicsSuccess = (posts: any) => {
  return {
    type: GET_POSTS_FOR_TOPICS,
    payload: posts,
  }
}

export const openPostsIndicator = () => {
  return {
    type: POSTS_PROGRESS_INDICATOR_OPEN,
    payload: true,
  }
}

export const closePostsIndicator = () => {
  return {
    type: POSTS_PROGRESS_INDICATOR_CLOSE,
    payload: false,
  }
}

export const getAllPosts = () => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/topic`, getAuth())
      .then((res) => {
        dispatch(getAllPostsSuccess(res.data)) //open the progress
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getAllPostsForTopics = (params: any) => {
  //get posts for a topic
  return (dispatch: Dispatch<any>) => {
    dispatch(openPostsIndicator()) //open the modal
    transport
      // .get(`${process.env.REACT_APP_BASE_URL}/post?topicId=` + params, getAuth())
      .get(
        `${process.env.REACT_APP_BASE_URL}/post?topicId=` + params,
        getAuth()
      )
      //http://localhost:9000/post?topicId=local
      .then((res) => {
        dispatch(getAllPostsForTopicsSuccess(res.data))
        dispatch(closePostsIndicator()) //close the progress
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const createPosts = (
  topicId: string,
  postTypeId: string,
  description: string,
  mediaId?: any,
  contrasts?: string,
  organizationId?: string,
  placeID?: any,
  locationName?: any,
  geoJson?: any,
  address?: any,
  addressType?: any,
  latitude?: any,
  longitude?: any,
  location?: any,
  cb?: () => void
) => {
  //get posts for a topic
  return (dispatch: Dispatch<any>) => {
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/v2/post/createPost`,
        {
          title: 'post sample 4',
          description: description,
          topicId: topicId,
          postTypeId: postTypeId,
          contrasts: contrasts,
          mediaId: mediaId,
          organizationId: organizationId,
          placeID: placeID,
          locationName: locationName,
          //geoJson: geoJson,
          address: address,
          addressType: addressType,
          coordinates: { latitude, longitude },
          location: location,
        },
        getAuth()
      )
      .then((res) => {
        cb && cb()
        //dispatch(getAllPostsForTopics(topicId))
        // dispatch(getAllTopics())
        if (contrasts) dispatch(getPost(contrasts))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const deletePostRequest = () => {
  return {
    type: DELETE_POST_REQUEST,
  }
}

export const deletePostSuccess = () => {
  return {
    type: DELETE_POST_SUCCESS,
  }
}

export const deletePostFailure = () => {
  return {
    type: DELETE_POST_FAILURE,
  }
}

export const deletePost = (post: any, isVisible: any, reason: any) => {
  //get posts for a topic
  return (dispatch: Dispatch<any>) => {
    dispatch(deletePostRequest())
    const { id, topicId, contrasts } = post
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/post/${id}`,
        { isVisible, reason },
        getAuth()
      )
      .then((res) => {
        //dispatch(getAllPostsForTopics(topicId))
        dispatch(deletePostSuccess())
        dispatch(getAllTopics())
        if (contrasts) dispatch(getPost(contrasts))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          dispatch(deletePostFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getPostRequest = () => {
  return {
    type: GET_POST_REQUEST,
  }
}

export const getPostSuccess = (post: any) => {
  return {
    type: GET_POST_SUCCESS,
    payload: post,
  }
}

export const getPostFailure = () => {
  return {
    type: GET_POST_FAILURE,
  }
}

export const getPost = (postId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getPostRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/post/${postId}`, getAuth())
      .then((res) => {
        dispatch(getPostSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getPostFailure())
          return
        }
      })
  }
}

export const setClickedPost = (post: any) => {
  return {
    type: SET_SELECTED_POST,
    payload: post,
  }
}

//get posts for based on user
export const getAllPostsForUser = (userId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getAllPostForUserRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/post/user/${userId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostForUserSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getAllPostForUserFailure())
          return
        }
      })
  }
}

export const getAllPostForUserSuccess = (postForUser: any) => {
  return {
    type: GET_USER_POST_SUCCESS,
    payload: postForUser,
  }
}

export const getAllPostForUserFailure = () => {
  return {
    type: GET_USER_POST_FAILURE,
  }
}

export const getAllPostForUserRequest = () => {
  return {
    type: GET_USER_POST_REQUEST,
  }
}

export const getAllPostsForOrg = (orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getAllPostForOrgRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/post/org/${orgId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostForOrgSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getAllPostForOrgFailure())
          return
        }
      })
  }
}

export const getAllPostForOrgSuccess = (postForOrg: any) => {
  return {
    type: GET_ORG_POST_SUCCESS,
    payload: postForOrg,
  }
}

export const getAllPostForOrgFailure = () => {
  return {
    type: GET_ORG_POST_FAILURE,
  }
}

export const getAllPostForOrgRequest = () => {
  return {
    type: GET_ORG_POST_REQUEST,
  }
}

export const voteToggleHomeNewsFeed = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/vote/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllTopics()) //homeNewsFeed type="homeNewsFeed"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const voteTogglePost = (postId: any, topicId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/vote/${postId}`, getAuth())
      .then((res) => {
        dispatch(getPost(postId)) //postScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const voteToggleTopic = (postId: any, topicId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/vote/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForTopics(topicId)) //TopicScreen type="topicPost"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const voteToggleUser = (postId: any, userId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/vote/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForUser(userId)) //TopicScreen type="topicPost"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const voteToggleOrg = (postId: any, orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getAllPostForOrgRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/vote/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForOrg(orgId)) //TopicScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleHomeNewsFeed = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllTopics()) //homeNewsFeed type="homeNewsFeed"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmTogglePost = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getPost(postId)) //postScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleTrustFeedChanges = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getTrustFeedChanges()) //postScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleTrustFeedContrasts = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(trustFeedContrasts()) //postScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleTopic = (postId: any, topicId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForTopics(topicId)) //TopicScreen type="topicPost"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleUser = (postId: any, userId: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForUser(userId)) //TopicScreen type="topicPost"
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

export const alarmToggleOrg = (postId: any, orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getAllPostForOrgRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/alarm/${postId}`, getAuth())
      .then((res) => {
        dispatch(getAllPostsForOrg(orgId)) //TopicScreen
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostForOrgFailure());
          return
        }
      })
  }
}

//update posts request
export const updatePost = (
  postTypeId: any,
  description: any,
  topicId: any,
  organizationId: any,
  mediaId: any,
  postId: any,
  location: any,
  reason: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updatePostRequest())
    transport
      .patch(
        `${process.env.REACT_APP_BASE_URL}/post/update/${postId}`,
        {
          postTypeId: postTypeId,
          description: description,
          topicId: topicId,
          organizationId: organizationId,
          mediaId: mediaId,
          location: location,
          reason: reason,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(getAllTopics())
        dispatch(updatePostSuccess())
      })
      .catch((error) => {
        if (error.response) {
          dispatch(updatePostFailure(error?.response?.data?.e))
          return
        }
      })
  }
}

export const updatePostDataClear = () => {
  return {
    type: DELETE_POST_DATA_CLEAR,
  }
}

export const updatePostRequest = () => {
  return {
    type: UPDATE_POST_REQUEST,
  }
}

export const updatePostSuccess = () => {
  return {
    type: UPDATE_POST_SUCCESS,
  }
}

export const updatePostFailure = (error: any) => {
  return {
    type: UPDATE_POST_FAILURE,
    payload: error,
  }
}

export const selectPostDetails = (post: any) => {
  return {
    type: SELECT_POST_DETAILS,
    payload: post,
  }
}

export const postEditRules = (postId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(postEditRulesRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/post/update/${postId}`, getAuth())
      .then((res) => {
        dispatch(postEditRulesSuccess(res.data)) //open the progress
      })
      .catch((error) => {
        if (error.response) {
          dispatch(postEditRulesFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const postEditRulesRequest = () => {
  return {
    type: POST_EDIT_RULES_REQUEST,
  }
}

export const postEditRulesSuccess = (rules: any) => {
  return {
    type: POST_EDIT_RULES_SUCCESS,
    payload: rules,
  }
}

export const postEditRulesFailure = () => {
  return {
    type: POST_EDIT_RULES_FAILURE,
  }
}
