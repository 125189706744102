import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components-v2/shadcn/dialog'
import { notEmpty } from '../../utils/formHelpers'
import { useState } from 'react'
import { Input } from '../../components-v2/shadcn/input'
import Button from '../../components-v2/Button'
import { useSelector } from 'react-redux'
import { changePassword } from '../../services/auth'
import { toast } from 'sonner'
import { cn } from '../../utils/cn'

type DialogProps = {
  cb?: () => void
  trigger?: React.ReactNode
  // open?: boolean
  // setOpen?: (isOpen: boolean) => void
  onSelectItem: (item: string) => void
  value?: string
}

const opts = [
  {
    name: 'Opinion maker (standard)',
    value: 'Opinion maker',
    description:
      'Start reading and sharing without identity verification and enjoy maximum fun and community feeling. Opinion makers can make posts of type Opinion, Rumor and Humor.',
  },
  {
    name: 'Citizen',
    value: 'Citizen',
    description:
      'Citizens can make posts of type Research, Opinion, Rumor and Humor. They can also contrast and counterbalance existing posts, which makes them a cornerstone of our community. We’d like to know who you are so there might be some follow-up questions. Politicians and policy makers are typically not approved in this category.',
  },
  {
    name: 'Professional',
    value: 'Professional',
    description:
      'Professionals can make all type of posts (including Facts). They can also contrast and counterbalance existing posts. Whether already working in the media industry or not, Professionals commit to journalism standards to ensure the quality of Facts, Research and other content they post. We’d like to know who you are so there might be some follow-up questions.',
  },
]

export default function SelectUserTypeDialog(props: DialogProps) {
  const { trigger } = props
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="max-h-screen max-w-[400px] overflow-y-auto px-5 py-5"
        // onInteractOutside={(e) => {
        //   e.preventDefault()
        // }}
      >
        <Content setOpen={setOpen} {...props} />
      </DialogContent>
    </Dialog>
  )
}

function Content({
  cb,
  setOpen,
  onSelectItem,
  value,
}: DialogProps & { setOpen: (b: boolean) => void }) {
  return (
    <div className="flex flex-col gap-5">
      {opts.map((x) => {
        return (
          <div
            key={x.value}
            className={cn(
              'cursor-pointer rounded-md border border-line p-5 transition-all hover:bg-gray-200',
              {
                'bg-gray-200': x.value === value,
              }
            )}
            onClick={() => {
              onSelectItem(x.value)
              setOpen && setOpen(false)
            }}
          >
            <h2 className="text-xl font-medium">{x.name}</h2>
            <p className="mt-1 text-sm">{x.description}</p>
          </div>
        )
      })}
    </div>
  )
}
