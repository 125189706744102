import React, { useState } from 'react'
import SidebarContent from './SidebarContent'
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/popover'
import { useLocation } from 'react-router-dom'
import { cn } from '../../../utils/cn'

type Props = {
  trigger: React.ReactNode
}

export default function SidebarPopover({ trigger }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  // console.log('🚀 ~ SidebarPopover ~ location:', location)
  const isHome = location.pathname === '/'

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={cn({
          'pointer-events-none': isHome,
          'mx-desktop:pointer-events-auto': isHome,
        })}
      >
        {trigger}
      </PopoverTrigger>
      <PopoverContent className="p-0 border-none mt-[8px] w-[310px]">
        <SidebarContent setIsOpen={setIsOpen} />
      </PopoverContent>
    </Popover>
  )
}
