import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_FAILURE,
  GET_ALL_USERS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  USER_TEMP_ID_SUCCESS,
  USER_TEMP_ID_REQUEST,
  USER_TEMP_ID_FAILUER,
  USER_LOCATION_STORE_REQUEST,
  USER_LOCATION_STORE_FAILURE,
  USER_LOCATION_STORE_SUCCESS,
  ADD_USER_CREDENTIALS,
  ADD_USER_DETAILS,

  //UPDATE USER DETAILS
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
} from './types'

const initialState = {
  user: null,
  userLoading: false,
  userError: false,
  userLoginError: '',
  users: null,

  userForProfile: null,
  userForProfileLoading: false,
  userForProfileError: false,

  userTempId: null,
  userTempIdLoading: false,
  userTempIdError: false,

  userLocationDetailsLoading: false,
  userLocationDetailsError: false,

  userDetails: {},

  //update user details
  updateUserDetailsLoading: false,
  updateUserDetailsError: false,
}

const loginReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, userLoading: true }

    case USER_LOGIN_SUCCESS:
      return { ...state, userLoading: false }

    case USER_LOGIN_FAILURE:
      return { ...state, userLoading: false, userLoginError: action.payload }

    case USER_LOGOUT_REQUEST:
      return { ...state, userLoading: true, userError: false }

    case USER_LOGOUT_SUCCESS:
      return { ...state, userLoading: false, userError: false, user: null }

    case USER_LOGOUT_FAILURE:
      return { ...state, userLoading: false, userError: true }

    case USER_GET_REQUEST:
      return { ...state, userLoading: true, userError: false }

    case USER_GET_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userError: false,
        user: action.payload,
      }
    case USER_GET_FAILURE:
      return {
        ...state,
        userLoading: false,
        userError: true,
        user: null,
      }
    case GET_ALL_USERS:
      return {
        ...state,
        userLoading: false,
        userError: false,
        users: action.payload,
      }
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        userForProfileError: false,
        userForProfileLoading: false,
        userForProfile: null,
      }
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        userForProfileError: false,
        userForProfileLoading: true,
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userForProfileError: false,
        userForProfileLoading: false,
        userForProfile: action.payload,
      }
    case USER_TEMP_ID_REQUEST:
      return {
        ...state,
        userTempId: null,
        userTempIdLoading: true,
        userTempIdError: false,
      }
    case USER_TEMP_ID_SUCCESS:
      return {
        ...state,
        userTempId: action.payload,
        userTempIdLoading: false,
        userTempIdError: false,
      }
    case USER_TEMP_ID_FAILUER:
      return {
        ...state,
        userTempId: null,
        userTempIdLoading: false,
        userTempIdError: true,
      }
    case USER_LOCATION_STORE_REQUEST:
      return {
        ...state,
        userLocationDetailsLoading: true,
        userLocationDetailsError: false,
      }
    case USER_LOCATION_STORE_SUCCESS:
      return {
        ...state,
        userLocationDetailsLoading: false,
        userLocationDetailsError: false,
      }
    case USER_LOCATION_STORE_FAILURE:
      return {
        ...state,
        userLocationDetailsLoading: false,
        userLocationDetailsError: true,
      }
    //user sign up
    case ADD_USER_CREDENTIALS:
      return {
        ...state,
        userDetails: {
          email: action.payload.email,
          password: action.payload.password,
        },
      }
    case ADD_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          location: action.payload.location,
          userType: action.payload.userType,

          description: action.payload.description,
          imageUrl: action.payload.imageUrl,
          userId: action.payload.userId,
          birthday: action.payload.birthday,
          phoneNumber: action.payload.phoneNumber,
          ...state.userDetails,
        },
      }
    case UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        updateUserDetailsLoading: true,
        updateUserDetailsError: false,
      }
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateUserDetailsLoading: false,
        updateUserDetailsError: false,
      }
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updateUserDetailsLoading: false,
        updateUserDetailsError: true,
      }
    default:
      return state
  }
}

export default loginReducer
