import { create } from 'zustand'

export const useUserStore = create((set) => ({
  posts: [],
  setPosts: (posts: any[]) => set({ posts: posts }),

  userDetails: null,
  setUserDetails: (userDetails: any) => set({ userDetails: userDetails }),

  articles: [],
  setArticles: (articles: any[]) => set({ articles: articles }),

  topics: [],
  setTopics: (topics: any[]) => set({ topics: topics }),
}))
