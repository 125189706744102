import { ADD_POST, IMPRESS_POST } from './types'

const initialState = {
  viewed_posts: [],
  impressed_posts: [],
}

const analyticsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ADD_POST:
      return { ...state, viewed_posts: [...state.viewed_posts, action.payload] }
    case IMPRESS_POST:
      return {
        ...state,
        impressed_posts: [...state.impressed_posts, action.payload],
      }
    default:
      return state
  }
}

export default analyticsReducer
