import bell from '../../assets/icons/bell.svg'
import greenBell from '../../assets/icons/green-bell.svg'
import contrastIco from '../../assets/icons/contrast.svg'
import comments from '../../assets/icons/comments.svg'
import textBlue from '../../assets/icons/text-blue.svg'
import moreMenu from '../../assets/icons/more-menu.svg'

import './index.css'
import arrowUp from '../../assets/icons/arrow-up.svg'
import ButtonComponent from '../ButtonComponent'
import Spacer from '../Spacer'
import Image from '../Image'
import { getRandomNumber } from '../../utils'
import React, { useState, useEffect, forwardRef } from 'react'
import ActionMenu from '../ActionMenu'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {
  MAX_PHONE_BREAK_POINT,
  MAX_TABLET_BREAK_POINT,
  MIN_DESKTOP_BREAK_POINT,
  MIN_TABLET_BREAK_POINT,
} from '../../utils/constants'
import PostEditDeleteReasonModal from '../PostEditDeleteReasonModal'
import { useInView } from 'react-intersection-observer'

const avatar =
  'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'

type Props = {
  children: React.ReactNode
}

const Post = forwardRef((props: any) => {
  const {
    contrast = null,
    user,
    onClick,
    post,
    deletePost,
    deletePostError,
    deletePostLoading,
    hideType,
    voteToggle,
    selectedTopic,
    isContrast = contrast,
    isPostHave = false,
    setEditPostModalVisible,
    editPostModalVisible,
    selectPostDetails,
    postEditRules,
    addPostsToRedux,
    alarmToggle,
    // ref,
    // inView
  } = props

  const {
    author,
    organization,
    description,
    postTypeId: type,
    mediaId,
    createdAt,
    topicId,
    articleId,
    con,
    isVoted = false,
    isAlarmed = false,
    vote,
    roles,
    id,
  } = post || {}

  const {
    name,
    imageUrl = avatar,
    public: authorPublic = false,
    verified,
    userTypeId = 'User',
  } = author || {}
  const {
    name: organizationName = '',
    imageUrl: organizationImageUrl = '',
    type: organizationType = '',
  } = organization || {}

  const [contrastNameBlur, setContrastNameBlur] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [postDeleteMessage, setPostDeleteMessage] = useState(null)
  const [postDeleteReasonModalVisible, setPostDeleteReasonModalVisible] =
    useState(false)

  const types = ['OPINION', 'RUMOR', 'HUMOR', 'FACT']

  const navigate = useNavigate()

  const getPrettyTime = () => {
    const duration = moment.duration(moment().diff(moment(createdAt)))

    const days = duration.days()
    const hours = duration.hours()
    const months = duration.months()

    if (months > 0) {
      return <span style={{ fontSize: '15px' }}>{months}m</span>
    }
    if (days > 0) {
      return <span style={{ fontSize: '15px' }}>{days}d</span>
    }
    if (hours > 0) {
      return <span style={{ fontSize: '15px' }}>{hours}h</span>
    }
  }

  const navigateToArticleDetail = () => {
    navigate(
      `/topic/${topicId ? topicId : selectedTopic?.id}/article/${articleId}`
    )
  }

  const [action, setAction] = useState('')
  // console.log('post::::::', post)
  const handleContextClick = (menuItem: any) => {
    setShowMenu(!showMenu)
    setAction(menuItem)
    switch (menuItem) {
      case 'delete':
        if (window.confirm('Are you sure you want to delete this post??')) {
          setPostDeleteReasonModalVisible((prevState) => !prevState)
        }
        break
      case 'visibleDelete':
        if (window.confirm('Are you sure you want to delete this post??')) {
          setPostDeleteReasonModalVisible((prevState) => !prevState)
        }
        break
      case 'invisibleDelete':
        if (window.confirm('Are you sure you want to delete this post??')) {
          setPostDeleteReasonModalVisible((prevState) => !prevState)
        }
        break
      case 'edit':
        selectPostDetails(post)
        setEditPostModalVisible(!editPostModalVisible)
        postEditRules(post)
        break
      default:
    }
  }

  const voteFunction = () => {
    voteToggle()
  }

  const alarmFunction = () => {
    alarmToggle()
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const widthForPost = () => {
    //width for posts and cotrasts
    if (windowSize.innerWidth > MIN_DESKTOP_BREAK_POINT) {
      //desktop width
      if (isContrast)
        //if it is contrasts
        return 370
      else return 385
    } else if (
      windowSize.innerWidth > MIN_TABLET_BREAK_POINT &&
      windowSize.innerWidth < MAX_TABLET_BREAK_POINT
    ) {
      //tablet width
      if (isContrast) {
        //if it is a contrasts
        return 370
      } // if it is a posts
      else return 400
    } else {
      if (isContrast) return windowSize.innerWidth * 0.77
      else return windowSize.innerWidth * 0.84
    }
  }

  const submitDeletePost = () => {
    deletePost(
      post,
      action === 'invisibleDelete' ? false : true,
      postDeleteMessage
    )
  }
  //
  const { ref, inView } = useInView({
    threshold: 2 / 3,
    triggerOnce: true,
    delay: 3000,
    trackVisibility: false,
  })

  const [onScroll, setOnScroll] = useState(false)
  useEffect(() => {
    const onScrolling = setTimeout(() => {
      if (inView) {
        // updateAnalytics([id])
        addPostsToRedux(id)
      }
    }, 2000)

    return () => clearTimeout(onScrolling)
  }, [
    onScroll,
    inView,
    //, inView, description, updateAnalytics, id, postIds
  ])

  return (
    <>
      {postDeleteReasonModalVisible && (
        <PostEditDeleteReasonModal
          setPostDeleteEditMessage={setPostDeleteMessage}
          setPostEditDeleteReasonModalVisible={setPostDeleteReasonModalVisible}
          postEditDeleteReasonModalVisible={postDeleteReasonModalVisible}
          loading={deletePostLoading}
          flag={'delete'}
          onSubmit={() => submitDeletePost()}
        />
      )}
      {/*<AnalyticsTrackerWrapper inView={inView}>*/}

      <div ref={ref} style={{ position: 'relative', alignItems: 'center' }}>
        <div
          className={'container'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            position: 'relative',
            marginTop: isContrast ? windowSize.innerWidth * 0.03 : 18,

            // marginRight: windowSize.innerWidth <  MAX_PHONE_BREAK_POINT ? isContrast ? 20 : 0 : 20,
            // marginLeft: windowSize.innerWidth <  MAX_PHONE_BREAK_POINT ? isContrast ? 20 : 0 : 20,
            marginBottom:
              windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 20 : 20,
          }}
          onClick={() => onClick()}
        >
          {!hideType && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                zIndex: 100,
                marginLeft: isContrast ? '-10px' : 20,
                marginBottom: '7px',
              }}
            >
              <text
                style={{
                  fontWeight: 600,
                  lineHeight: '14.52px',
                }}
              >
                {type ? type.toUpperCase() : types[getRandomNumber(-1, 4)]}
              </text>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              borderRadius: 8,
              border: 'solid 1px #9D9D9D',
              backgroundColor: 'white',
              paddingBottom: 10,
              paddingTop: 15,
              paddingRight: 15,
              paddingLeft: 15,
              marginTop: 0,
              marginLeft: isContrast ? -25 : 5,

              // width: windowSize.innerWidth <= MAX_PHONE_BREAK_POINT ? (isContrast ? windowSize.innerWidth*0.746 : windowSize.innerWidth*0.84) : 400,
              //width:isContrast ? windowSize.innerWidth*0.7 : windowSize.innerWidth*0.84,
              width: widthForPost(),
              position: 'relative',
            }}
          >
            <div
              style={{ position: 'absolute', right: -15, top: -35 }}
              onClick={(e) => {
                voteFunction()
                e.stopPropagation()
              }}
            >
              <text className={'up-text'}>{vote ? vote.length : 0}</text>
              <div
                className={'up-container'}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 8,
                  border: 'solid 1px #9D9D9D',
                  backgroundColor: isVoted ? '#D5E7C4' : '',
                }}
              >
                <img style={{ height: 30, width: 30 }} src={arrowUp} alt="" />
              </div>
            </div>
            {showMenu && (
              <div
                style={{
                  margin: 20,
                  position: 'absolute',
                  display: 'block',
                  right: 9,
                  bottom: -10,
                  zIndex: 10000,
                  transform: 'translate(0,100%)',
                }}
              >
                <ActionMenu
                  onClick={handleContextClick}
                  menu={
                    user?.admin
                      ? user?.id === post?.author?.id
                        ? [
                            // {id: "challenge", title: "Start challenge"},
                            // {id: "share", title: "Share"},
                            { id: 'edit', title: 'Edit' },
                            { id: 'delete', title: 'Delete' },
                          ]
                        : [
                            // {id: "challenge", title: "Start challenge"},
                            // {id: "share", title: "Share"},
                            { id: 'edit', title: 'Edit' },
                            { id: 'visibleDelete', title: 'Delete(Visible)' },
                            {
                              id: 'invisibleDelete',
                              title: 'Delete(Invisible)',
                            },
                          ]
                      : user?.id === post?.author?.id
                        ? [
                            // {id: "challenge", title: "Start challenge"},
                            // {id: "share", title: "Share"},
                            { id: 'delete', title: 'Delete' },
                            { id: 'edit', title: 'Edit' },
                          ]
                        : [
                            // {id: "challenge", title: "Start challenge"},
                            // {id: "share", title: "Share"}
                          ]
                  }
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                filter: `blur(${contrast && contrastNameBlur ? 4 : 0}px)`,
              }}
              onClick={(e: any) => {
                e.stopPropagation()
                setContrastNameBlur(false)
              }}
            >
              <div
                style={{
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '95%',
                  }}
                >
                  <text
                    onClick={() => {
                      navigate(`/user/${author?.id}`)
                    }}
                    style={{
                      fontWeight: 600,
                      fontSize:
                        windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 15 : 17,
                    }}
                  >
                    {name ? name : 'Steven Desmet'} ({getPrettyTime()})
                  </text>
                  <Spacer width={5} />
                  {authorPublic && (
                    <span style={{}}>
                      <div
                        style={{
                          width: 5,
                          height: 5,
                          borderRadius: 10,
                          background: '#000000',
                        }}
                      />
                      <Spacer width={10} />
                    </span>
                  )}
                  {verified && (
                    <span style={{ margin: '4px' }}>
                      <div
                        style={{
                          width: 5,
                          height: 5,
                          borderRadius: 10,
                          background: '#000000',
                        }}
                      />
                    </span>
                  )}
                </div>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px',
                  }}
                >
                  {organization && (
                    <text
                      style={{
                        fontSize:
                          windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                            ? 15
                            : 17,
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        navigate(`/organization/${organization.id}`)
                      }}
                    >
                      {organizationName}
                    </text>
                  )}
                  {organization && <text> </text>}

                  <text
                    className={'upper-case-post'}
                    style={{
                      marginTop: 2,
                      fontSize:
                        windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 15 : 17,
                    }}
                  >
                    {author?.roles?.name}
                  </text>
                </div>

                {organization && (
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      color: '#9D9D9D',
                    }}
                  >
                    <text
                      style={{
                        color: '#9D9D9D',
                        marginTop: 2,
                        fontSize:
                          windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                            ? 15
                            : 17,
                      }}
                    >
                      {organizationType}
                    </text>
                  </div>
                )}
              </div>
              <div style={{ flex: 1 }}></div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                {organization && (
                  <>
                    <Image
                      circle
                      width={40}
                      height={40}
                      src={organizationImageUrl}
                      onClick={() =>
                        navigate(`/organization/${organization.id}`)
                      }
                    />

                    <Spacer width={6} />
                  </>
                )}

                <div
                  style={{ height: 40, width: 40 }}
                  onClick={() => {
                    navigate(`/user/${author.id}`)
                  }}
                >
                  <Image
                    cropType={'cover'}
                    circle
                    width={40}
                    height={40}
                    src={imageUrl}
                  />
                </div>
              </div>

              <Spacer width={10} />
            </div>
            <Spacer height={14} />

            <text
              style={{
                textAlign: 'start',
                fontSize:
                  windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 14 : 16,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {mediaId && (
              <img
                src={mediaId}
                alt=""
                style={{
                  objectFit: 'cover',
                  marginTop: 22,
                  borderRadius: 10,
                  width: '100%',
                  // height: 300
                }}
              />
            )}
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'end',
                width: '100%',
              }}
            >
              <div>
                {articleId && (
                  <ButtonComponent
                    onClick={(e: any) => {
                      e.stopPropagation()
                      navigateToArticleDetail()
                    }}
                  >
                    <div className={'action-container_row'}>
                      <img
                        style={{ color: '#ff0000' }}
                        className={'action-img'}
                        src={textBlue}
                        alt=""
                      />
                      <Spacer width={5} />
                      <text className={'up-text_medium'}>{'Read Article'}</text>
                    </div>
                  </ButtonComponent>
                )}
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                  marginRight: -8,
                }}
              >
                <ButtonComponent
                  onClick={(e: any) => {
                    alarmFunction()
                    e.stopPropagation()
                  }}
                >
                  <div
                    style={{ marginBottom: 1 }}
                    className={'action-container'}
                  >
                    {isAlarmed ? (
                      <img className={'action-img'} src={greenBell} alt="" />
                    ) : (
                      <img className={'action-img'} src={bell} alt="" />
                    )}
                  </div>
                </ButtonComponent>

                <ButtonComponent>
                  <div className={'action-container'}>
                    <text className={'up-text'}>{con?.length || 0}</text>
                    <img className={'action-img'} src={contrastIco} alt="" />
                  </div>
                </ButtonComponent>

                <ButtonComponent>
                  <div className={'action-container'}>
                    <text className={'up-text'}>{getRandomNumber(0, 12)}</text>
                    <img className={'action-img'} src={comments} alt="" />
                  </div>
                </ButtonComponent>
                <div>
                  <ButtonComponent
                    onClick={(e: any) => {
                      e.stopPropagation()
                      setShowMenu(!showMenu)
                    }}
                  >
                    <div className={'action-container'}>
                      <img
                        className={'action-more-img'}
                        src={moreMenu}
                        alt=""
                      />
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            </div>

            <div></div>
            <image />
          </div>
          {/*{contrast && (*/}
          {/*    <text style={{fontWeight: 'bold', marginTop: 10, alignSelf: "end", color: '#2443B2'}}>More*/}
          {/*        Contrasts</text>*/}
          {/*)}*/}
        </div>
        {
          //location for post section
          // post?.postLocation?.city ?
          // <div style={{alignItems: 'right', display: 'flex', flexDirection: 'row',justifyContent:'space-between', marginRight:18,position:'relative',zIndex:-10}}
          //      className={"location-container"}>
          //     {
          //         isPostHave && <div style={{display:'flex',flexDirection:'column',marginLeft:'5%',marginTop:'0%',background:'red'}}>
          //             <div style={{height: '95px',borderLeft:'1px solid #9D9D9D',marginBottom:'10px',marginLeft:'10px'}}></div>
          //             <img style={{height: 22, width: 22}} src={checkGrey} alt=""/>
          //         </div>
          //     }
          //    {post?.postLocation?.city && <div style={{position:'absolute',top:0,right:'2px',display:'flex',flexDirection:'row'}}>
          //         <img className={"location-img"} src={locatioGray} alt=""/>
          //         <p style={{
          //                 color: '#9D9D9D',
          //                 fontSize: '12px',
          //                 fontWeight: 'bold'
          //         }}>{post?.postLocation?.city}</p>
          //     </div>}
          // </div>
          //  : null
        }
      </div>
      {/*</AnalyticsTrackerWrapper>*/}
    </>
  )
})

export default Post
