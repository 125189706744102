import './index.css'
import Spacer from '../Spacer'
import { useState, useEffect } from 'react'
import { getRandomNumber } from '../../utils'
import { useNavigate } from 'react-router-dom'
import ButtonComponent from '../ButtonComponent'
import moreMenu from '../../assets/icons/more-menu.svg'
import ActionMenu from '../ActionMenu'
import Image from '../Image'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const image = 'https://oceanservice.noaa.gov/news/feb17/containership.jpg'

const ArticlePost = ({
  setArticle,
  article,
  numberOfPosts = 0,
  deleteArticle,
  selectedTopic,
}: any) => {
  const navigate = useNavigate()

  const image = 'https://oceanservice.noaa.gov/news/feb17/containership.jpg'
  const image2 =
    'https://images.jacobinmag.com/wp-content/uploads/sites/4/2021/01/03105107/rt56.jpg'
  const image3 =
    'https://www.openaccessgovernment.org/wp-content/uploads/2019/07/dreamstime_m_35528530.jpg'
  const image4 =
    'https://www.pbctoday.co.uk/news/wp-content/uploads/2019/05/Most-expensive-cities-for-construction-in-2019-revealed-%C2%A9-Nahuel-Palet-.jpg'

  const images = [image, image2, image3, image4]

  const [showMenu, setShowMenu] = useState(false)

  const {
    id,
    title,
    imageUrl = image,
    posts = [],
    url,
    topicId,
    organization,
  } = article || {}
  const {
    name = '',
    imageUrl: organizationImageUrl = '',
    type = '',
  } = organization || {}

  const navigateToArticleDetail = () => {
    navigate(`/topic/${topicId ? topicId : selectedTopic?.id}/article/${id}`)
  }

  const handleContextClick = (menuItem: any) => {
    switch (menuItem) {
      case 'delete':
        if (window.confirm('Are you sure you want to delete this article??')) {
          deleteArticle()
        }
        break
      default:
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flex: 58,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            alignSelf: 'center',
            width:
              windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                ? windowSize.innerWidth
                : 480,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'start',
            marginTop: 5,
          }}
        >
          <img
            src={imageUrl}
            style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            alt={''}
          />
          <Spacer height={12} />
          <div style={{ marginRight: 15, marginLeft: 15 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '-3px',
              }}
            >
              <div style={{ display: 'flex', flex: '100%' }}>
                {/*<img src={organizationImageUrl}*/}
                {/*     className={"title-normal-pointer"}*/}
                {/*     style={{height: 50, width: 50, borderRadius: 50, border: '1px solid #000000'}}*/}
                {/*     alt={""}*/}
                {/*     onClick={() => navigate(`/organization/${article?.organization?.id}`)}*/}
                {/*/>*/}
                <Image
                  style={{ border: '1px solid #000000' }}
                  circle
                  width={
                    windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 30 : 50
                  }
                  height={
                    windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 30 : 50
                  }
                  src={organizationImageUrl}
                  onClick={() =>
                    navigate(`/organization/${article?.organization?.id}`)
                  }
                />
                <Spacer width={12} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop:
                      windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                        ? '0%'
                        : '1.5%',
                  }}
                >
                  <text
                    className={
                      'title-normal-pointer title-normal-pointer-mobile-view'
                    }
                    onClick={() =>
                      navigate(`/organization/${article?.organization?.id}`)
                    }
                  >
                    {name}
                  </text>
                  <text
                    className={'title-normal'}
                    style={{
                      fontSize:
                        windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                          ? '13px'
                          : '',
                    }}
                  >
                    {type}
                  </text>
                </div>
              </div>

              <div style={{ flex: '1%', marginRight: '-12px' }}>
                {showMenu && (
                  <div
                    style={{
                      position: 'absolute',
                      display: 'block',
                      zIndex: 500,
                      marginLeft: '-5.5%',
                      marginTop: '1%',
                    }}
                  >
                    <ActionMenu
                      onClick={handleContextClick}
                      menu={[{ id: 'delete', title: 'Delete' }]}
                    />
                  </div>
                )}
                <ButtonComponent
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                  }}
                >
                  <div className={'action-container'}>
                    <img className={'action-more-img'} src={moreMenu} alt="" />
                  </div>
                </ButtonComponent>
              </div>
            </div>
            <Spacer height={12} />
            <text
              onClick={() => {
                window.open(url, '_blank', 'noreferrer')
              }}
              style={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              {title}
            </text>
            <div
              style={{
                marginTop:
                  windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? '6px' : 0,
              }}
              onClick={() => navigateToArticleDetail()}
            >
              <text
                className={'title-normal'}
                style={{
                  fontSize:
                    windowSize.innerWidth < MAX_PHONE_BREAK_POINT
                      ? '14px'
                      : '16px',
                }}
              >{`${posts.length} posts`}</text>
              {/*<text className={"title-normal"}>{`${numberOfPosts} posts`}</text>*/}
            </div>

            <Spacer height={26} />
          </div>
        </div>
      </div>
      <div style={{ flex: 42 }}></div>
    </div>
  )
}

export default ArticlePost
