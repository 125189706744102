import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { notEmpty } from '../../utils/formHelpers'
import { Input } from '../../components-v2/shadcn/input'
import { Textarea } from '../../components-v2/shadcn/textarea'
import { Checkbox } from '../../components-v2/shadcn/checkbox'
import Button from '../../components-v2/Button'
import { toast } from 'sonner'
import { XCircle } from 'lucide-react'
import { cn } from '../../utils/cn'
import uploadImage, { useUpload } from '../../utils/uploadImage'
import LandscapeSVG from '../../assets/icons/landscape.svg'
import { createHouseguest } from '../../services/user'
import UploadProgress from '../../components-v2/UploadProgress'

type Props = {}

const schema = z.object({
  name: z.string().pipe(notEmpty),
  // lastName: z.string().pipe(notEmpty),
  description: z.string().optional(),
  public: z.boolean(),
  verified: z.boolean(),
  profileImageUrl: z.string().optional(),
  // password: z.string().pipe(notEmpty),
  // confirmPassword: z.string().pipe(notEmpty),
})
// .refine((data) => data.password === data.confirmPassword, {
//   path: ['confirmPassword'],
//   message: 'Passwords does not match',
// })

type SchemaType = z.infer<typeof schema>

export default function CreateHouseguest({}: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      public: false,
      verified: false,
    },
  })

  const {
    upload,
    progress,
    url: postImageUrl,
    setUrl: setPostImageUrl,
    totalBytes,
    transferedBytes,
  } = useUpload()

  function clearFields() {
    reset()
  }

  const profileImageUrl = watch('profileImageUrl')

  return (
    <div className="max-w-[364px] w-full mx-auto">
      {/* <h1 className="text-base font-semibold mt-5">Create a Houseguest</h1> */}
      <h1 className="text-2xl font-bold mt-5">Create a Houseguest</h1>
      <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
      <form className="flex flex-col gap-6 mt-5 pb-5">
        <div className="">
          <label className="text-xs font-bold">Name*</label>
          <Input
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Name"
            {...register('name')}
          />
          {errors?.name && (
            <p className="text-xs text-red-500 font-semibold">
              {errors?.name.message}
            </p>
          )}
        </div>
        {/* <div className="">
          <label className="text-xs font-bold">Last Name*</label>
          <Input
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Last Name"
            {...register('lastName')}
          />
          {errors?.lastName && (
            <p className="text-xs text-red-500 font-semibold">
              {errors?.lastName.message}
            </p>
          )}
        </div> */}

        <div className="">
          <Controller
            control={control}
            name="verified"
            render={({ field: { onChange, value } }) => (
              <label className="flex items-center gap-2 cursor-pointer w-fit">
                <Checkbox
                  checked={value}
                  onCheckedChange={(checked) => {
                    onChange(checked)
                  }}
                />
                <span className="text-xs font-bold">Verified</span>
              </label>
            )}
          />
        </div>

        <div className="">
          <Controller
            control={control}
            name="public"
            render={({ field: { onChange, value } }) => (
              <label className="flex items-center gap-2 cursor-pointer w-fit">
                <Checkbox
                  checked={value}
                  onCheckedChange={(checked) => {
                    onChange(checked)
                  }}
                />
                <span className="text-xs font-bold">Public</span>
              </label>
            )}
          />
        </div>

        <div className="">
          <label className="text-xs font-bold">Description</label>
          <Textarea
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Description"
            {...register('description')}
          />
          {errors?.description && (
            <p className="text-xs text-red-500 font-semibold">
              {errors?.description.message}
            </p>
          )}
        </div>

        {profileImageUrl && (
          <div className="flex flex-col gap-1 w-full">
            <XCircle
              className="text-border self-end cursor-pointer hover:text-red-500 transition-all"
              size={18}
              onClick={() => {
                setValue('profileImageUrl', '')
              }}
            />
            <img src={profileImageUrl} className="object-cover rounded-md " />
          </div>
        )}

        <div>
          {progress > 0 && (
            <UploadProgress
              progress={progress}
              transferedBytes={transferedBytes}
              totalBytes={totalBytes}
            />
          )}
          <label
            className={cn(
              'p-[3px] rounded-md w-fit transition-all cursor-pointer hover:bg-line'
            )}
          >
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(event) => {
                const image = event.target.files?.[0]
                if (image) {
                  upload(image, 'user-images', (url) => {
                    setValue('profileImageUrl', url)
                  })
                  // uploadImage(image, 'user-images').then((imageUrl) => {
                  //   setValue('profileImageUrl', imageUrl)
                  // })
                }
              }}
            />
            <img src={LandscapeSVG} className="w-[16px] h-[16px] " />
          </label>
        </div>

        {/* <div className="">
          <label className="text-xs font-bold">Password*</label>
          <Input
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Password"
            type="password"
            {...register('password')}
          />
          {errors?.password && (
            <p className="text-xs text-red-500 font-semibold">
              {errors?.password.message}
            </p>
          )}
        </div>
        <div className="">
          <label className="text-xs font-bold">Confirm Password*</label>
          <Input
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Confirm Password"
            type="password"
            {...register('confirmPassword')}
          />
          {errors?.confirmPassword && (
            <p className="text-xs text-red-500 font-semibold">
              {errors?.confirmPassword.message}
            </p>
          )}
        </div> */}

        <div className="flex items-center justify-end">
          <Button
            disabled={isSubmitting}
            onClick={handleSubmit(async (value) => {
              try {
                await createHouseguest(value)
                clearFields()
                toast.success(`Houseguest created ${value.name}`)
              } catch (error) {
                console.log(error)
                toast.error('Something went wrong')
              }
            })}
          >
            Create Houseguest
          </Button>
        </div>
      </form>
    </div>
  )
}
