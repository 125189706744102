import { create } from 'zustand'
import { fetchNotificationSummary } from '../../services/notifications'

export const useNotifications = create((set, get: any) => ({
  notifications: [],
  loadSummary: () => {
    fetchNotificationSummary().then((data) => {
      set({
        notifications: data,
      })
    })
  },
}))
