import React from 'react'
import './index.css'

const InputBoxTextArea = ({
  disable,
  defaultValue,
  label,
  setValue,
  required,
}: any) => {
  return (
    <div>
      <label className={'label-text-input-box-textarea'}>
        {label}
        {required && <span>*</span>}
      </label>
      <textarea
        disabled={disable}
        defaultValue={defaultValue}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        style={{
          width: '287px',
          backgroundColor: disable ? '#D9D9D9' : 'white', //change the color when disable
          borderRadius: '6px',
          paddingLeft: '10px',
          height: '73px',
          flexShrink: 0,
          fontStyle: 'normal',
          zIndex: 1000,
          borderColor: '#D9D9D9',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: 'black',
        }}
      ></textarea>
    </div>
  )
}

export default InputBoxTextArea
