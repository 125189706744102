import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBoUQcyMpkjpXEeLw0Wk33eMZEpfJtmijI',
  authDomain: 'check-a2e73.firebaseapp.com',
  projectId: 'check-a2e73',
  storageBucket: 'check-a2e73.appspot.com',
  messagingSenderId: '688229898255',
  appId: '1:688229898255:web:0d5a426b2b459d7755eb7a',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
