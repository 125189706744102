import checkGrey from '../../assets/icons/check-grey.svg'

import bell from '../../assets/icons/bell.svg'
import contrastIco from '../../assets/icons/contrast.svg'
import comments from '../../assets/icons/comments.svg'
import text from '../../assets/icons/text.svg'
import moreMenu from '../../assets/icons/more-menu.svg'
import { useState, useEffect } from 'react'

import './index.css'
import arrowUp from '../../assets/icons/arrow-up.svg'
import ButtonComponent from '../ButtonComponent'
import Spacer from '../Spacer'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const image = 'https://oceanservice.noaa.gov/news/feb17/containership.jpg'

const Post = ({ contrast, onClick }: any) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        width:
          windowSize.innerWidth < MAX_PHONE_BREAK_POINT
            ? windowSize.innerWidth
            : 490,
      }}
    >
      <div>
        <div
          onClick={onClick}
          className={'container'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              padding: 15,
              marginTop: 0,
              position: 'relative',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <text style={{ fontWeight: 'bold' }}>Steven Desmet (7h)</text>
                  <Spacer width={5} />
                  <div
                    style={{
                      width: 5,
                      height: 5,
                      borderRadius: 10,
                      background: '#000000',
                    }}
                  />
                  <Spacer width={5} />
                  <div
                    style={{
                      width: 5,
                      height: 5,
                      borderRadius: 10,
                      background: '#000000',
                    }}
                  />
                </div>

                <text style={{ color: '#9D9D9D', marginTop: 2 }}>
                  Professional
                </text>
              </div>
              <div style={{ flex: 1 }}></div>
              <img
                src={image}
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: 50,
                  border: '1px solid #000000',
                }}
                alt={''}
              />
              <Spacer width={10} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <text className={'up-text'}>0</text>
                <div
                  className={'up-container'}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 4,
                  }}
                >
                  <img style={{ height: 30, width: 30 }} src={arrowUp} alt="" />
                </div>
              </div>
            </div>
            <Spacer height={10} />

            <text style={{ textAlign: 'start' }}>
              Lorem ipsum dolor. Sed ut perspiciatis, unde omnis iste natus
              error sit voluptatem accusantium doloremque laudantium.
            </text>

            <div
              style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-evenly',
                width: '100%',
              }}
            >
              <div style={{ flex: 1 }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                }}
              >
                <ButtonComponent>
                  <div className={'action-container'}>
                    <text className={'up-text'}>10</text>
                    <img className={'action-img'} src={comments} alt="" />
                  </div>
                </ButtonComponent>
                <div>
                  <ButtonComponent>
                    <div className={'action-container'}>
                      <img
                        className={'action-more-img'}
                        src={moreMenu}
                        alt=""
                      />
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            </div>

            <div></div>
            <image />
          </div>
          {contrast && (
            <text
              style={{
                fontWeight: 'bold',
                marginTop: 10,
                alignSelf: 'end',
                color: '#2443B2',
              }}
            >
              More Contrasts
            </text>
          )}
        </div>
      </div>
    </div>
  )
}

export default Post
