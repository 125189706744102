export const termMd = `**TERMS OF SERVICE**

Effective September 25, 2024

**PLEASE READ THIS DOCUMENT CAREFULLY BEFORE USING OUR SERVICES. THESE**
**TERMS CONTAIN A WAIVER OF CLASS ACTION RIGHTS, EXPLICIT DISCLAIMERS OF**
**WARRANTIES, AND EXPLICIT LIMITATIONS OF LIABILITY. IF YOU DO NOT WISH TO**
**BE BOUND BY THESE TERMS, YOU ARE ADVISED NOT TO CONTINUE USING OUR**
**SERVICES.**

These Terms of Service (or "Terms") constitute an agreement between you
and NIOOZE, INC. (or "niooze", "we", "us" and "our"), a corporation duly
incorporated in and existing under the laws of the State of Delaware,
with registered agent Legalinc Corporate Services Inc.

It constitutes a legally binding contract that governs your access to
and use of any of our services, including but not limited to the niooze
platform, software, website, apps, and its Content (collectively
referred to as the "Services"), except where we expressly state that
separate terms (and not these) apply. By visiting or otherwise using our
Services in any manner, you agree to be bound by these Terms, or by the
then posted Terms, whichever was made available last.

Any content that appears, is uploaded, or is downloaded from or through
our Services, whether in the form of text, links, graphics, images,
photos, audios, videos, or any other type of information (collectively
referred to as "Content"), is also governed by these Terms.

If you accept these Terms and utilize the Services on behalf of a
corporation, organization, governmental body, or any other legal entity,
you affirm and warrant that you possess the necessary authorization to
act on its behalf and have the power to legally obligate said entity to
adhere to these Terms. Consequently, the terms \"you\" and \"your\"
within these Terms shall pertain to such entity.



**1. Who?**

You may use our Services only:

-   If you agree to enter into a binding agreement with us.

-   If you are at least 13 years old.

-   If you have your parents' permission when you're under the age of 18
    (which we will assume you have received).

-   If you are not prohibited from receiving any aspect of our Services
    under the laws of the applicable jurisdiction.

-   If you were not previously barred already by us from using our
    Services.

-   If you are not using any other person's account to access our
    Services.

-   If you are providing us with your correct contact information (first
    name, last name, email address, date of birth, telephone number) and
    if you keep these updated.



**2. Content permissions**

We do not claim ownership of any Content you add on or through our
Services. You retain your rights.

However, for any Content you add on or through our Services, you grant
us a worldwide, non-exclusive, royalty-free, transferable license (with
the right to sublicense) to use, host, copy, distribute, reproduce,
modify, process, publish, run, transmit, translate, curate, transform,
publicly perform, create derivative works of, and display your Content,
in any media or distribution methods presently known or that may be
developed in the future.

This license grants us the right to share your Content (even modified or
adapted) globally and permits others to do so as well. By accepting this
license, you acknowledge that we have the right to provide, promote, and
enhance the Services, as well as to distribute Content to other entities
for syndication, broadcast, distribution, reposting, promotion, or
publication on alternative media and services.

For clarity, under this license you also give us permission to host,
display, share, distribute and publish your username, profile picture
and other information about your profile, or any of your actions and
relationships on our Services.

You expressly agree that you want to use our Services and that the above
license is therefore provided without any compensation whatsoever, not
for the license, nor for the Content.



**3. Content warranties**

You are solely responsible for any Content you provide, including
compliance with applicable laws, rules, and regulations.

You represent and warrant that you own or have obtained all necessary
rights to the Content you add on or through our Services. You agree that
you cannot add Content that violates someone else's rights, including
but not limited to any applicable intellectual property rights, privacy
rights, publicity rights and contractual rights.

You are also responsible for safeguarding and maintaining the
confidentiality of your account login information, including using a
unique and strong password, to be used for our Services alone.

Any loss or damage of any kind arising from your failure to comply with
the above will be at your sole risk and liability.



**4. Content consumption and use of our Services**

You are responsible for your own use of our Services, and for the way
you consume its Content.

-   Even though we try to create an environment that conduces
    reliability, any use or reliance on any Content on our Services is
    solely and completely at your own risk and liability.

-   We do not endorse, support, recommend, verify, warrant, represent or
    guarantee the validity, completeness, truthfulness, accuracy,
    safety, legality, reliability, quality, appropriateness, decency,
    timeliness, nature, or any other aspect of any Content on our
    Services. We only provide a labeling service to our users, according
    to a community-guided and ever-evolving set of definitions and
    principles for each label, to be followed by every user on our
    Services. In case of inaccuracies and/or non-compliance, we provide
    a set of tools to mitigate its perverse effects, without claiming
    valid, complete, truthful, accurate, safe, legal, reliable,
    qualitative, appropriate, decent, or timely representation at any
    time.

-   You understand that by using our Services, you may be exposed to
    Content that might be offensive, insulting, harmful, inaccurate,
    mislabeled, deceptive, and/or inappropriate. All Content is the sole
    responsibility of the party who added such Content. We may not
    monitor or control the Content added through our Services and we
    cannot take responsibility for such Content.

-   In case you receive moderating or admin rights on our Services, you
    agree that this is a voluntary and unofficial engagement, and you
    agree that we are not responsible for any action taken by you as a
    moderator or admin. Having moderator or admin rights does not allow
    you to represent authority on behalf of niooze, or act on behalf of
    niooze. We reserve the right to remove or limit any moderator or
    admin rights, at any time and for any or no reason.



**5.  Rights we retain**

In practice we will be guided by several internal (ever-evolving)
policies, but any decisions we take remain at our sole discretion, to
the extent permitted by law.

-   We reserve the right to delete, modify, edit, or update any Content
    on or through our Services, whether they are deemed to be in
    violation of our Terms or policies, or if we deem them, at our sole
    discretion, unacceptable or not beneficial to our Services.

-   We reserve the right to delete, modify, edit, or update user
    profiles, including but not limited to changing its content, its
    visualization, and/or its reading, contributing, moderator, and/or
    admin rights.

-   We reserve the right to change our internal policies at any time.

-   We reserve the right to refuse access to our Services to any party.

-   We reserve the right to stop or suspend (permanently or temporarily)
    providing the Services or any features within the Services to you or
    to any set of users.

-   We reserve the right to suspend or terminate your account.

-   We reserve the right to create limits on use and storage for you or
    for any set of users.

-   We retain the right to limit distribution or visibility of any
    Content.

-   We reserve the right to take ad-hoc decisions in the benefit of
    niooze, even if these deem not to be aligned with our (ever-evolving
    and ever-lacking) policies.

-   We reserve the right to access, save, and disclose any information
    we reasonably believe is necessary to run our operations, including
    but not limited to:

    -   satisfying legal, regulatory or support requests, both from
        users and third parties such as governments.

    -   detecting, preventing, or addressing fraud, security, legal, or
        technical issues.

    -   enforcing these Terms.

All provisions of these Terms, which by nature should survive the
execution of the above rights, shall survive, including, without
limitation, intellectual property ownership and warranty disclaimers.

For each of the above rights, whether executed with or without notice,
there shall be no liability, responsibility, or compensation by us
towards you or any party, in any manner, whatsoever.



**6. How (not) to use our Services**

You agree not to misuse our Services, including but not limited to the
following ways:

-   Do not use or access, or attempt to use or access, our Services by
    any means other than through our interface and/or instructions we
    provide.

-   Do not breach or circumvent, or attempt to breach or circumvent, any
    security or authentication measures.

-   Do not use or access our Services in any way or take any action that
    may, intentionally or unintentionally, influence, undermine,
    disrupt, damage, interfere with, or manipulate the safety,
    functionality, performance, availability, or accessibility of our
    Services, including but not limited to virus sending, spamming,
    mail-bombing, overloading, flooding, or scripting anything that
    might create an undue burden on our Services.

-   Do not reverse engineer, disassemble, copy, decompile, modify,
    create derivative works of, attempt to derive the source code of, or
    work around any technical limitations of our Services, platform,
    software, and/or any part thereof.

-   Do not use or access our Services in any manner, for any purpose, or
    for any activity that might be illegal, fraudulent, unlawful, or
    harmful.

-   Do not plagiarize or violate the rights of third parties, including
    but not limited to copyright, trademark, trade secret, privacy,
    publicity, or other proprietary rights.

-   Do not conceal your identity, impersonate any person or
    organization, use false IP addresses or headers, or misrepresent any
    affiliations you may have with other persons or organizations.

-   Do not share or publish any confidential or personal information
    belonging to any person or organization without obtaining prior
    consent.

-   Do not use, or access, or tamper with, non-public areas of our
    Services.

-   Do not test, probe, or scan the vulnerability of our Services.

-   Do not scrape or crawl our Services in any form and for any purpose,
    without our prior written consent. Training AI models based on our
    Services, or by using our data or Content, is expressly prohibited
    without our prior written consent.

-   Do not use any data collected from our Services for any direct
    marketing activities,

-   Do not assist, facilitate, or encourage others in misusing our
    Services, either by personal actions, nor by developing or
    distributing such products or services.

Please review our internal, ever-evolving, and probably ever-lacking
internal policies for additional guidance on how to use and not use our
Services. These policies can normally be accessed through our Services.
Typically, our actions will be guided by our Terms and by our internal
policies, but we reserve the right for discretionary ad-hoc decisions to
the benefit of niooze.



**7.  Our license to you, including its limitations**

To use and access our Services, we provide you with a personal,
royalty-free, non-exclusive, and worldwide license, which is
non-assignable and non-transferable. The sole purpose of this license is
to allow and enable you to use our Services according to these Terms,
and we reserve the right to retract this license at any time at our
discretion.

Our Services and its Content are protected by intellectual property
rights, including but not limited to copyright, trademark, and patent
rights, as well as other laws in the United States and other countries.
We do not give you the right to use any of our brand names, trademarks,
logos, domain names, designs, look & feels, distinctive features,
distinctive processes & protocols, code, architecture, or other elements
that can be categorized as Intellectual Property, whether registered or
unregistered.

All right, title, and interest in and to our Services are and will
remain our and our licensors\' exclusive property. We disclaim all
rights and claims to the intellectual property rights over Content
provided by third parties.

We operate the Services from the USA and make no representation that the
Services are appropriate or available for use beyond the USA. If you use
our Services from other locations, you are doing so on your own
initiative, and at your own risk and liability. You are solely
responsible for compliance with applicable local laws and regulations.



**8. Advertisement, third-party providers, and partners**

You agree that we, and third-party providers or partners, may provide
advertisements, external links, affiliated Content, affiliated services,
or affiliated products (collectively referred to as "Advertising") on
and through our Services.

We are not associated with such Advertising, unless expressly stated
otherwise, and we do not endorse, support, recommend, verify, warrant,
represent or guarantee the validity, completeness, truthfulness,
accuracy, safety, legality, reliability, quality, appropriateness,
decency, timeliness, nature, or any other aspect of any such third-party
Advertising in any way.

We accept no responsibility for third-party Advertising on or through
our Services, and consuming, clicking through, using, or purchasing any
(elements of) such Advertising in any way, is solely at your own risk
and responsibility. If you agree to consume, clickthrough, use or
purchase goods and/or services from any third-party, you do so at your
own risk and these Terms do not apply anymore for such third-party goods
and/or services.

You expressly agree that you want to use our Services and that the above
Advertising is provided without any compensation to you whatsoever, even
if the above Advertising is consumed by you in any way.



**9. Additional services**

We may offer specific functionalities, features, products, Content,
processes, or services (collectively referred to as "Additional
Services") to specific sets of users that may or may not have to pay for
those Additional Services. These Terms remain applicable, but you agree
that there may be additional terms & conditions for such Additional
Services, and you agree that those additional terms & conditions become
part of our agreement with you.

In case of conflicts, the additional terms & conditions will prevail,
but only to the Additional Services to which they apply.



**10. Communications and feedback**

You agree that any communication between you and niooze uses electronic
means, and that all such electronic communications satisfy any and all
legal requirements as if it were to be in hardcopy writing, or with your
written signature, including communications for contractual purposes
such as these Terms or any other agreement, notice, or disclosure.

You agree that you shall not challenge the validity, enforceability, or
admissibility of these Terms, or any other electronic communication,
based on the grounds that it was electronically transmitted or
authorized.

You agree that any ideas, suggestions, or other feedback you provide
about niooze or our Services, whether solicited or unsolicited, is not
confidential and may be used by us without restriction and without
compensation to you. We do not waive any rights to use similar or
related ideas previously known to us, developed by our employees, or
obtained from other sources.



**11. Disclaimer of warranties**

**To the maximum extent permitted by applicable law, you understand and**
**agree that the Services and Content are provided to you on an "AS IS",**
**"AS AVAILABLE" and "WITH ALL FAULTS" basis. You expressly agree that you**
**use and access our Services at your own risk and liability.**

**To the maximum extent permitted by applicable law, niooze and its**
**direct and indirect parents, subsidiaries, affiliates, and each of their**
**respective employees, directors, members, managers, shareholders,**
**agents, vendors, licensors, licensees, contractors, customers,**
**successors, and assigns (collectively referred to as "Collective**
**Parties"), make no warranty or representation, in any way, and we**
**disclaim all warranty, responsibility, endorsement, promise, or**
**liability, whether express or implied, for all warranties related to,**
**but not limited to:**

-   **Merchantability.**

-   **Fitness for a particular purpose.**

-   **Satisfactory quality.**

-   **Title.**

-   **Non-infringement.**

-   **The validity, completeness, truthfulness, accuracy, safety,**
    **reliability, quality, appropriateness, decency, availability,**
    **timeliness, security, or nature of our Services and/or Content.**

-   **Whether our Services and/or Content will meet your requirements.**

-   **Whether our Services and/or Content will be uninterrupted, secure,**
    **error-free, harmful-free, or defect-free.**

-   **Whether any issues with our Services and/or Content will be**
    **immediately corrected.**

-   **Any harm to your computer system, loss of data, or other harm that**
    **results from your use or access of our Services and/or Content.**

-   **The failure to store, the failure to transmit, or the deletion of**
    **any Content or data maintained by our Services.**

**No oral or written communication obtained from us or on or through our**
**Services and/or Content, will create any warranty or representation not**
**expressly made herein.**



**12. Limitation of liability**

**To the maximum extent permitted by applicable law, under no**
**circumstances any of the Collective Parties will be liable for any**
**indirect, incidental, consequential, special, exemplary, or punitive**
**damages, including but not limited to any loss of profits, savings, or**
**revenues (whether incurred directly or indirectly), or any loss of**
**content, data, use, goodwill, or other intangible losses, arising**
**from:**

-   **your use or access of our Services and/or Content.**

-   **your inability to use or access our Services and/or Content.**

-   **any delay in the capacity to use or access our Services and/or**
    **Content.**

-   **any failure, delay, or reduction in the performance of our**
    **Services and/or Content.**

-   **any events, actions, or failure of actions, caused by events**
    **outside our reasonable control (force majeure events).**

-   **any unauthorized access, use or modification of your Content.**

-   **any conduct or Content of any user or third party on our**
    **Services.**

**Under no circumstances shall the aggregate liability of niooze exceed**
**the greater of one hundred US dollars (\$ 100 USD) or the amount you**
**paid us, if any, in the past six months for the services giving rise to**
**the claim.**

**The above limitations of liability shall apply to any theory of**
**liability, including but not limited to:**

-   **warranty.**

-   **contract.**

-   **statute.**

-   **tort.**

-   **negligence.**

-   **strict liability.**

**These limitations of liability shall apply even if we may have been**
**advised of the possibility of any such liability or damage, and even if**
**any remedy set forth in these Terms is found to have failed its**
**essential purpose.**



**13. Indemnification**

You agree to indemnify, defend, and hold harmless the Collective Parties
from and against all claims, damages, suits, losses, costs, judgments,
investigations, liabilities, fines, penalties, settlements, interest,
and expenses (including litigation costs and reasonable attorney's fees)
that directly or indirectly arise from, or in connection with:

-   your use or access of our Services and/or Content.

-   your inability to use or access our Services and/or Content.

-   your violation or alleged violation of any provision of these Terms.

-   your violation or alleged violation of any rights of a third party.

-   your violation or alleged violation of any applicable laws, rules,
    regulations, codes, statutes, ordinances, or orders.

-   content transmitted through your device, even if not submitted by
    you.

-   any misrepresentation made by you.

-   our use of the information that you submitted to us.

You agree to fully cooperate as required by the Collective Parties in
the defense of any of the above claims and losses. The Collective
Parties reserve the exclusive right to assume defense and control of any
matter otherwise subject to indemnification by you, including but not
limited to settling, compromising, and paying any and all claims and
losses.



**14. Privacy**

Our Privacy Policy, available on our website at <www.niooze.com/privacy-policy>, 
outlines how we manage the personal information you provide when using our 
Services. By using our Services, you acknowledge and agree to the collection 
and use of your information as described in the Privacy Policy. As such,
**the Privacy Policy is an integral part of these Terms.**



**15. General**

These Terms shall be governed by and construed in accordance with the
laws of the State of Delaware without regard to conflict of law
provisions, and all disputes related to these Terms, or the Services,
will be brought solely to the exclusive jurisdiction of the competent
courts thereof.

To the maximum extent permitted by applicable law, you waive the right
to participate as a plaintiff or class member in any purported class
action, collective action, or representative action proceeding.

Niooze reserves the right, at its sole discretion, to update, revise,
supplement and to otherwise modify these Terms at any time, and such
modifications shall be effective immediately upon availability on or
through our Services. You agree to review the Terms periodically,
ideally upon each visit, to be aware of such changes. We will try -- at
our sole discretion -- to notify you of material revisions, without this
being a requirement for your acceptance of the changes. Your continued
use or access of the Services shall be deemed as a conclusive acceptance
of any modified Terms. In case we provide these Terms in multiple
languages, the English language version of the Terms shall take
precedence in case of discrepancies or inconsistencies.

If any provision of the Terms is found by a court of competent
jurisdiction to be invalid or unenforceable, then that provision will be
limited or eliminated to the minimum extent necessary, and this will not
affect the enforceability of any other part or provision of these Terms.



**16. Contact information**

If you have questions about these Terms, our Services, or its Content,
please contact us by email at <legal@niooze.com> for legal questions, 
at <hello@niooze.com> for general questions, or write to us at 936 SW 1^st^
Ave, Unit 279, Miami, 33130, Florida, USA.
`
