import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

//redux
import {
  createPosts,
  updatePost,
  updatePostDataClear,
} from '../../redux/posts/actions'
import { getAllOrganizations } from '../../redux/organizations/actions'
import './index.css'

//components
import PostCreateContent from '../../components/PostCreateContent'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { searchTopics } from '../../redux/topics/actions'

const PostCreateModal = (prop: any) => {
  const {
    user,
    setPostModalVisible,
    editPostModalVisible,
    createPosts,
    selectedTopics,
    clickedPost,
    postLoadIndicator,
    organizations,
    getAllOrganizations,
    editPost = false,
    updatePost,
    selectedPost,
    topics,
    updatePostLoading,
    setErrorMessageForModal,
    updatePostFailureMessage,
    updatePostError,
    updatePostDataClear,
    editPostRules,
  } = prop

  const [postTypeId, setpostTypeid] = useState('') //post type id
  const [description, setDescription] = useState() //description
  const [org, setOrg] = useState() //organization
  const [imageUrl, setImageUrl] = useState('') //image url
  const [locationsData, setLocationsData] = useState('') //location data from API
  const [locations, setLocations] = useState('')
  const [selectlocation, setSelectLocation] = useState<any>()

  const [updatePostTypeId, setUpdatePostTypeId] = useState(
    selectedPost?.postTypeId === 'Fact'
      ? 0
      : selectedPost?.postTypeId === 'Research'
        ? 1
        : selectedPost?.postTypeId === 'Opinion'
          ? 2
          : selectedPost?.postTypeId === 'Rumor'
            ? 3
            : 4
  )
  const [updateDescription, setUpdateDescription] = useState(
    selectedPost?.description
  )
  const [updateTopicId, setUpdateTopicId] = useState(selectedPost?.topicId)
  const [updateOrganizationId, setUpdateOrganizationId] = useState(
    selectedPost?.organisationId
  )
  const [updateMediaId, setUpdateMediaId] = useState(selectedPost?.mediaId)
  const [selectUpdatelocation, setUpdateSelectLocation] = useState<any>()
  const [locationsUpdateData, setLocationsUpdateData] = useState('') //location data from API
  const [topic, setTopic] = useState<any>()
  const [selectedLocationFrmAutoComp, setSelectedLocationFrmAutoComp] =
    useState<any>()
  const [countryToDisplay, setCountryToDisplay] = useState<any>()
  const [selectedTopicFrmAutoComp, setSelectedTopicFrmAutoComp] =
    useState<any>()
  const [selectTopicFromModal, setSelectTopicFromModal] = useState()
  const [selectedItemToDisplay, setSelectedItemToDisplay] = useState<any>(
    selectedPost?.topicId
      ? topics
          .filter((topic: any) => {
            return topic?.id === selectedPost?.topicId //extract the topic
          })
          .map((filterTopic: any) => filterTopic?.title)
      : //extract the location of the post
        selectedPost?.postLocation?.country +
          ',' +
          selectedPost?.postLocation?.state +
          ',' +
          selectedPost?.postLocation?.county +
          ',' +
          selectedPost?.postLocation?.state
  )

  const [locationLoading, setLocationLoading] = useState(false)
  const [topicLoading, setTopicLoading] = useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData(locations)
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [locations])

  const fetchData = async (location: any) => {
    try {
      const response = await axios.get(
        'https://nominatim.openstreetmap.org/search?q=' +
          location +
          '&format=json&addressdetails=1&countrycodes=us&accept-language=us&polygon_geojson=1'
      ) // Replace with your API endpoint
      const data = response.data // Extract the data from the response
      const newLocationData = data.map((location: any) => {
        return {
          id: location.place_id,
          name: location.display_name,
          ...location,
        }
      })
      setLocationsData(newLocationData)
    } catch (error) {
      console.error(error)
    }
  }

  const [image, setImgae] = useState(null) //image

  const handleTextWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setDescription(textWithLineBreaks)
  }

  const handleTextUpdateWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setUpdateDescription(textWithLineBreaks)
  }

  const clickSubmit = () => {
    if (image == null) {
      createPosts(
        selectedTopics.id,
        postTypeId,
        description,
        null,
        clickedPost ? clickedPost.id : null,
        org ? org : null,
        selectlocation?.id,
        selectlocation?.name,
        selectlocation?.geojson,
        selectlocation?.address,
        selectlocation?.addresstype
      )
      setPostModalVisible(false)
    } else {
      const imageRef = ref(storage, `post-images/${image['name'] + uuidv4()}`)
      uploadBytes(imageRef, image).then((e) => {
        getDownloadURL(e.ref).then((url) => {
          setImageUrl(url)
          createPosts(
            selectedTopics.id,
            postTypeId,
            description,
            url,
            clickedPost ? clickedPost.id : null,
            org ? org : null,
            selectlocation?.id,
            selectlocation?.name,
            selectlocation?.geojson,
            selectlocation?.address,
            selectlocation?.addresstype
          )
          setPostModalVisible(false)
        })
      })
    }
  }

  useEffect(() => {
    if (image == null) return
  }, [image])

  useEffect(() => {
    getAllOrganizations()
    listAll(ref(storage, 'post-images/')).then((e) => {})
  }, [])

  useEffect(() => {
    setTopicLoading(true)
    const delayDebounceFn = setTimeout(() => {
      fetchData(locations)
      searchTopics(locations != '' ? locations : '`')
        .then((data) => {
          // Handle the data
          setTopic(data)
          setTopicLoading(false)
        })
        .catch((error) => {
          // Handle the error
        })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [locations])

  const [isSubmitEditPost, setIsSubmitEditPost] = useState(false)

  const updatePostSubmit = (reason: any) => {
    setIsSubmitEditPost(true)
    if (typeof updateMediaId === 'object' && updateMediaId) {
      const imageRef = ref(
        storage,
        `post-images/${updateMediaId['name'] + uuidv4()}`
      )
      uploadBytes(imageRef, updateMediaId).then((e) => {
        getDownloadURL(e.ref).then((url) => {
          updatePost(
            updatePostTypeId === 0
              ? 'Fact'
              : updatePostTypeId === 1
                ? 'Research'
                : updatePostTypeId === 2
                  ? 'Opinion'
                  : updatePostTypeId === 3
                    ? 'Rumor'
                    : 'Humor',
            updateDescription,
            updateTopicId,
            updateOrganizationId,
            url,
            selectedPost?.id,
            selectUpdatelocation,
            reason
          )
        })
      })
    } else {
      updatePost(
        updatePostTypeId === 0
          ? 'Fact'
          : updatePostTypeId === 1
            ? 'Research'
            : updatePostTypeId === 2
              ? 'Opinion'
              : updatePostTypeId === 3
                ? 'Rumor'
                : 'Humor',
        updateDescription,
        updateTopicId,
        updateOrganizationId,
        updateMediaId === undefined ? null : updateMediaId,
        selectedPost?.id,
        selectUpdatelocation,
        reason
      )
    }
  }

  const [errorMessageFlag, setErrorMessageFlag] = useState(updatePostError)

  useEffect(() => {
    setErrorMessageFlag(updatePostError)
  }, [updatePostError])

  useEffect(() => {
    if (isSubmitEditPost) {
      if (
        !updatePostLoading &&
        isSubmitEditPost &&
        updatePostFailureMessage === null &&
        !updatePostError
      ) {
        //if there is no problem
        setPostModalVisible(false)
      }
    }
  }, [
    updatePostLoading,
    isSubmitEditPost,
    updatePostError,
    updatePostFailureMessage,
    setPostModalVisible,
  ])

  const closModal = () => {
    setPostModalVisible(false)
    updatePostDataClear()
  }

  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 50,
      }}
      onClick={() => {
        closModal()
      }}
    >
      <PostCreateContent
        onCancel={() => setPostModalVisible(false)}
        user={user}
        setPostModalVisible={setPostModalVisible}
        setpostTypeid={setpostTypeid}
        description={description}
        handleTextWithLineBreaks={handleTextWithLineBreaks}
        clickSubmit={clickSubmit}
        selectedTopic={selectedTopics}
        clickedPost={clickedPost}
        organizationsData={organizations}
        setOrg={setOrg}
        org={org}
        setImgae={setImgae}
        image={image}
        showOrganizationSelection
        setLocations={setLocations}
        setSelectLocation={setUpdateSelectLocation}
        locationsData={locationsData}
        editPost={editPost}
        selectedItemToDisplay={selectedItemToDisplay}
        updatePostLoading={updatePostLoading}
        countryToDisplay={countryToDisplay}
        postDetailsTobeEdited={selectedPost}
        setUpdatePostTypeId={setUpdatePostTypeId}
        updatePostTypeId={updatePostTypeId}
        handleTextUpdateWithLineBreaks={handleTextUpdateWithLineBreaks}
        setUpdateTopicId={setUpdateTopicId}
        setUpdateOrganizationId={setUpdateOrganizationId}
        setUpdateMediaId={setUpdateMediaId}
        updateMediaId={updateMediaId}
        updateDescription={updateDescription}
        updatePost={updatePostSubmit}
        locationsUpdateData={locationsUpdateData}
        setLocationsUpdateData={setLocationsUpdateData}
        setSelectedLocationFrmAutoComp={setSelectedLocationFrmAutoComp}
        selectedLocationFrmAutoComp={selectedLocationFrmAutoComp}
        setCountryToDisplay={setCountryToDisplay}
        setSelectedTopicFrmAutoComp={setSelectedTopicFrmAutoComp}
        selectedTopicFrmAutoComp={selectedTopicFrmAutoComp}
        setSelectTopicFromModal={setSelectTopicFromModal}
        setSelectedItemToDisplay={setSelectedItemToDisplay}
        setTopic={setTopic}
        locationLoading={locationLoading}
        topicLoading={topicLoading}
        topic={topic}
        updatePostFailureMessage={updatePostFailureMessage}
        errorMessageFlag={errorMessageFlag}
        setErrorMessageFlag={setErrorMessageFlag}
        updatePostDataClear={updatePostDataClear}
        editPostRules={editPostRules}
      />
      {/* {postLoadIndicator && <Progress/>} */}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userLoginError: state.login.userLoginError,
    // topicsID:state.topics.topicsID,
    postLoadIndicator: state.posts.postLoadIndicator,

    selectedTopics: state.topics.topicsID,
    clickedPost: state.posts.clickedPost,
    organizations: state.organization.organizations,

    //update posts
    updatePostLoading: state.posts.updatePostLoading,
    updatePostError: state.posts.updatePostError,
    updatePostFailureMessage: state.posts.updatePostFailureMessage,

    //selected post
    selectedPost: state.posts.selectedPost,
    topics: state.topics.topics,

    //post edit rules
    editPostRules: state.posts.editPostRules,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createPosts: (
      topicId: string,
      postTypeId: string,
      description: string,
      mediaId?: any,
      contrasts?: string,
      organizationId?: string,
      placeID?: any,
      locationName?: any,
      geoJson?: any,
      address?: any,
      addressType?: any
    ) => {
      dispatch(
        createPosts(
          topicId,
          postTypeId,
          description,
          mediaId,
          contrasts,
          organizationId,
          placeID,
          locationName,
          geoJson,
          address,
          addressType
        )
      )
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
    updatePost: (
      postTypeId: any,
      description: any,
      topicId: any,
      organizationId: any,
      mediaId: any,
      postId: any,
      location: any,
      reason: any
    ) => {
      dispatch(
        updatePost(
          postTypeId,
          description,
          topicId,
          organizationId,
          mediaId,
          postId,
          location,
          reason
        )
      )
    },
    updatePostDataClear: () => {
      dispatch(updatePostDataClear())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostCreateModal)
