import { ADD_ARTICLE } from './types'

const initialState = {
  article: null,
}

const articleReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    // case ADD_ARTICLE:
    //     return {...state, article:action.payload};
    default:
      return state
  }
}

export default articleReducer
