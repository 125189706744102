import { QueryFunctionContext } from '@tanstack/react-query'
import authAxios from '../../utils/authAxios'

export async function getNiooze({ postType, org, page }: any) {
  console.log('Fetching', { postType, org, page })

  const res = await authAxios.post(`v2/post/getNiooze`, {
    // users: 'all',
    // discovery: 'all',
    // period: '24h',
    // impressions: 'all',
    // sorting: 'all',
    pagination: {
      page: page,
      limit: 4,
    },
    organisationType: org,
    postType: postType,
  })

  // console.log('Response', res.data)

  return res.data
}

export const getNextPageParam = (pageResponse: any) => {
  // const currentPage = pageResponse.page
  // const totalHits = pageResponse.found
  // const perPage = 4

  // const totalPages = Math.ceil(totalHits / perPage)

  // console.log({ pageResponse })

  const { currentPage, totalPages } = pageResponse?.pagination
  if (currentPage < totalPages) {
    return currentPage + 1 // Return the next page number
  } else {
    return null // No more pages
  }

  // return pageResponse?.next?.page ? pageResponse?.next?.page : undefined
}

export async function getNioozeWrapper({ queryKey, pageParam }: any) {
  // const [key, filterPostType, filterOrganization] = queryKey

  const res = await authAxios.post('/v2/topic/getNioozeTopics', {
    pagination: {
      currentPage: pageParam,
      itemsPerPage: 4,
    },
  })
  // console.log('🚀 ~ getNioozeWrapper ~ res:', res)

  // const res = await getNiooze({
  //   postType: filterPostType,
  //   org: filterOrganization,
  //   page: pageParam,
  // })

  return res?.data
}
export async function getPostsOfATopic({
  queryKey,
  pageParam,
}: QueryFunctionContext) {
  const [key, topicId, dimensions, org, postType] = queryKey

  const res = await authAxios.post(`v2/topic/getTopicPostsForNioozeTopics `, {
    id: topicId,
    dimensions: dimensions,
    organisationType: org,
    postType: postType,
  })

  // return []
  return res.data
}
