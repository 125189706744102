import React from 'react'
import { cn } from '../../utils/cn'

type Props = {
  className?: string
  items: any[]
  onClickItem?: (item: string) => void
  currentDomain: string
}

export default function DomainScroll({
  className,
  items,
  currentDomain,
  onClickItem,
}: Props) {
  const filterItems = [
    {
      domainName: '',
      customName: 'Show all',
    },
    ...items,
  ]

  return (
    <div
      className={cn('flex gap-[7px] overflow-x-auto max-w-[390px]', className)}
    >
      {filterItems.map((x, i) => {
        return (
          <div
            key={i}
            className="mb-2 shrink-0 cursor-pointer"
            onClick={() => {
              onClickItem && onClickItem(x.domainName)
            }}
          >
            <div
              className={cn(
                'w-[65px] h-[65px] rounded-full border-[2px] p-[1px]',
                {
                  'border-primary': currentDomain === x.domainName,
                }
              )}
            >
              {x.favicon ? (
                <img
                  src={x.favicon}
                  alt={x.domainName}
                  className="h-full w-full rounded-full"
                />
              ) : (
                <div className="bg-primary text-[9px] text-line h-full w-full rounded-full flex items-center justify-center">
                  {x.customName ? x.customName : 'No Image'}
                </div>
              )}
            </div>
            <p className="w-[65px] overflow-hidden overflow-ellipsis text-[8px] text-center mt-1">
              {x.domainName}
            </p>
          </div>
        )
      })}
    </div>
  )
}
