import PostCard from '../Post/PostCard'
import { parsePostObjToProps } from '../Post/helpers'
import { alarmToggle, upvoteToggle } from '../Post/services'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'

const TrustFeedContrasts = ({ trustFeedContrasts, addPostsToRedux }: any) => {
  return (
    <>
      {trustFeedContrasts?.map((trustFeedContrast: any) => {
        return (
          <div className={'flex justify-center mb-5'} style={{ width: '100%' }}>
            <div className={'flex-row pl-5 pr-5'} style={{ width: '400px' }}>
              <PostCard
                postTypeForAnalytics="trust contrast"
                {...parsePostObjToProps(trustFeedContrast)}
                onClickVote={() => upvoteToggle(trustFeedContrast?.id)}
                onClickAlarm={() => alarmToggle(trustFeedContrast?.id)}
                addPostsToRedux={addPostsToRedux}
              />

              <div className="flex mt-2">
                <div className="flex flex-col items-center pr-[10px]">
                  <div className="w-[1px] bg-line h-[70px]" />

                  <img src={ContrastSVG} className="w-4 h-4" />
                </div>
                <div className="w-full max-w-[338px]">
                  {trustFeedContrast?.con?.map((contrast: any) => {
                    return (
                      <div className="mb-2">
                        <PostCard
                          postTypeForAnalytics="trust contrast"
                          {...parsePostObjToProps(contrast)}
                          onClickVote={() => upvoteToggle(contrast?.id)}
                          // onClickVote={onClickVoteContrast}
                          onClickAlarm={() => alarmToggle(contrast?.id)}
                          // cb={pageLoad}
                          addPostsToRedux={addPostsToRedux}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TrustFeedContrasts
