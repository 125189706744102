import React from 'react'
import { Progress } from './shadcn/progress'
import { formatBytes } from '../utils/uploadImage'
import { cn } from '../utils/cn'

type Props = {
  progress: number
  transferedBytes?: number | null
  totalBytes?: number | null
  className?: string
}

export default function UploadProgress({
  progress,
  totalBytes,
  transferedBytes,
  className,
}: Props) {
  return (
    <div>
      <Progress value={progress} className={cn('mt-1 h-2', className)} />
      <div className="mt-[2px] text-sm">
        {formatBytes(transferedBytes || 0)}/{formatBytes(totalBytes || 0)}
      </div>
    </div>
  )
}
