import './index.css'
import OnBoardButton from '../OnBoardButton'
import Spacer from '../Spacer'

const DeleteConfirmModal = ({ deleteEvent, cancelEvent }: any) => {
  return (
    <div className="absolute z-[9000] mt-[50px] h-[380px] w-[390px] rounded-[6px] bg-white">
      <div className={'mt-[100px] text-center'}>
        <h3>Are you sure?You want to</h3>
      </div>
      <div className={'button-section-delete-confirm'}>
        <OnBoardButton
          width={'30px'}
          onClick={() => deleteEvent()}
          buttonName={'Delete'}
          disabled={false}
        />
        <Spacer width={50} />
        <OnBoardButton
          width={'30px'}
          onClick={() => cancelEvent()}
          buttonName={'Cancel'}
          disabled={false}
        />
      </div>
    </div>
  )
}

export default DeleteConfirmModal
