import './index.css'
import { useCallback, useState } from 'react'

import dropDownArrow from '../../assets/icons/drop-down-arrow.svg'
import { allowOnlyOneLevelBelowCity } from '../../utils/osmHelpers'
import debounce from '../../utils/debounce'
import { fetchLocationsAndTopics } from '../../components-v2/CreateContrastDialog/helper'

const InputText = ({
  name,
  mandatory,
  placeHolder,
  inputType,
  setValue,
  setLocationId,
  errorMessage = 'sdsd',
  type,
  value,
  onFocus,
  setCountry,
  setState,
  setCounty,
  setCity,
  setGeoJson,
  onfocus,
  textColor,
  inputColor,
  valueColor,
  setLocation,
}: any) => {
  const [locations, setLocations] = useState<any>()

  const setLocationAndLocationId = (location: any) => {
    setLocation(location)
    setValue(location?.display_name)
    setLocationId(location?.place_id)
    setCountry(location?.address?.country)
    setState(location?.address?.state)
    setCounty(location?.address?.county)
    setCity(location?.address?.city)

    setLocations('')
  }

  const setValueAndClear = (e: any) => {
    setValue(e)
    fetch(
      'https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&accept-language=us&addressdetails=1&q=' +
        value,
      {
        method: 'GET',
        redirect: 'follow',
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLocations(allowOnlyOneLevelBelowCity(result))
      })
      .catch((error) => console.log('error', error))
    setLocations('')
  }

  // const debouncedOnChange = useCallback(
  //   debounce((value) => {
  //     // console.log('Input value:', value)
  //     if (value) {
  //       setLoading(true)
  //       fetchLocationsAndTopics(value)
  //         .then((data) => {
  //           setData(data)
  //         })
  //         .finally(() => {
  //           setLoading(false)
  //         })
  //     }
  //   }, 300),
  //   []
  // )

  return (
    <div>
      <div style={{ marginBottom: '6px' }}>
        <text
          style={{
            fontWeight: 'bold',
            fontSize: '12px',
            color: textColor ? textColor : '#FBFBFD',
          }}
        >
          {name}
          {mandatory && <span style={{ fontSize: '13px' }}>*</span>}
        </text>
      </div>
      {inputType === 'text' ? (
        <input
          className={'inputCss'}
          value={value}
          onFocus={(e) => {
            onfocus && onfocus(e.target.value)
          }}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          type={type}
          placeholder={placeHolder}
          style={{
            width: '100%',
            maxWidth: '310px',
            backgroundColor: inputColor ? inputColor : '#303655',
            borderRadius: '6px',
            height: '29px',
            flexShrink: 0,
            fontStyle: 'italic',
            paddingLeft: '12px',
            zIndex: 1000,
            borderColor: '#D9D9D9',
            borderWidth: '2px',
            borderStyle: 'solid',
            color: valueColor ? valueColor : 'white',
            lineHeight: '29px', // Center vertically
            alignItems: 'center', // Center text horizontally
          }}
        />
      ) : inputType === 'autoComplete' ? (
        <div style={{ width: '310px' }}>
          <input
            className={'inputList'}
            value={value}
            onChange={(e) => {
              setValueAndClear(e.target.value)
            }}
            type={type}
            placeholder={placeHolder}
            style={{
              width: '100%',
              maxWidth: '310px',
              backgroundColor: inputColor ? inputColor : '#303655',
              borderRadius: '6px',
              height: '29px',
              flexShrink: 0,
              //paddingLeft:'10px',
              fontStyle: 'italic',
              //paddingLeft:'12px',
              letterSpacing: '0.00001em',
              zIndex: 1000,
              borderColor: '#D9D9D9',
              borderWidth: '2px',
              borderStyle: 'solid',
              color: valueColor ? valueColor : 'white',
            }}
          />
          <div
            style={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: '#D9D9D9',
              marginTop: '-2px',
              width: '304px',
              marginLeft: '3px',
            }}
          >
            {locations
              ? locations?.map((location: any) => (
                  <p
                    style={{ color: '#D9D9D9', fontSize: '13px' }}
                    onClick={() => {
                      setLocationAndLocationId(location)
                    }}
                  >
                    {location?.display_name}
                  </p>
                ))
              : null}
          </div>
        </div>
      ) : inputType === 'focusuText' ? (
        <div style={{ position: 'relative' }}>
          <img
            style={{
              marginLeft: '277px',
              marginTop: '6px',
              position: 'absolute',
            }}
            src={dropDownArrow}
          />
          <input
            className={'inputCss'}
            onFocus={() => onFocus(true)}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            type={type}
            placeholder={placeHolder}
            style={{
              width: '100%',
              maxWidth: '310px',
              backgroundColor: inputColor ? inputColor : '#303655',
              borderRadius: '6px',
              height: '29px',
              flexShrink: 0,
              fontStyle: 'italic',
              paddingLeft: '12px',
              zIndex: 1000,
              borderColor: '#D9D9D9',
              borderWidth: '2px',
              borderStyle: 'solid',
              color: valueColor ? valueColor : 'white',
            }}
          />
        </div>
      ) : (
        <textarea
          onChange={(e: any) => {
            setValue(e?.target?.value)
          }}
          //value={value}
          placeholder={placeHolder}
          className={'text-area-placeholder'}
          rows={4}
          style={{
            width: '300px',
            backgroundColor: inputColor ? inputColor : '#303655',
            borderRadius: '5px',
            //color:'white',
            zIndex: 1000,
            borderColor: '#D9D9D9',
            borderWidth: '2px',
            borderStyle: 'solid',
            color: valueColor ? valueColor : 'white',
            paddingTop: '5px',
            paddingLeft: '9px',
            fontStyle: 'italic',
          }}
        />
      )}
    </div>
  )
}

export default InputText
