import {
  GET_ALL_POSTS,
  GET_POSTS_FOR_TOPICS,
  POSTS_PROGRESS_INDICATOR_OPEN,
  POSTS_PROGRESS_INDICATOR_CLOSE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  SET_SELECTED_POST,
  GET_USER_POST_REQUEST,
  GET_USER_POST_FAILURE,
  GET_USER_POST_SUCCESS,
  GET_ORG_POST_SUCCESS,
  GET_ORG_POST_REQUEST,
  GET_ORG_POST_FAILURE,

  //UPDATE POSTS
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAILURE,
  DELETE_POST_DATA_CLEAR,
  //select post
  SELECT_POST_DETAILS,

  //delete post
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,

  //editing rules for post
  POST_EDIT_RULES_REQUEST,
  POST_EDIT_RULES_SUCCESS,
  POST_EDIT_RULES_FAILURE,
} from './types'
import {
  GET_TOPIC_FAILURE,
  GET_TOPIC_REQUEST,
  GET_TOPIC_SUCCESS,
} from '../topics/types'

const initialState = {
  posts: null,
  postsFailed: false,
  topicPosts: null,
  postLoadIndicator: false,

  post: null,
  postLoading: false,
  postError: false,

  deletePostLoading: false,
  deletePostError: false,

  clickedPost: null,

  userPosts: null,
  usetPostsLoading: false,
  userPostsError: false,

  orgPosts: null,
  orgPostsLoading: false,
  orgPostsError: false,

  //UPDATE POSTS
  updatePostLoading: false,
  updatePostError: false,
  updatePostFailureMessage: null,

  //SELECTED POST
  selectedPost: null,

  //POST EDIT RULES
  editPostRulesLoading: false,
  editPostRulesError: false,
  editPostRules: null,
}

const postsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_POST_REQUEST:
      return { ...state, post: null, postLoading: true, postError: false }
    case GET_POST_SUCCESS:
      return {
        ...state,
        post: action.payload,
        postLoading: true,
        postError: false,
      }
    case GET_POST_FAILURE:
      return { ...state, post: null, postLoading: false, postError: true }

    case GET_ALL_POSTS:
      return { ...state, posts: action.payload, postsFailed: true }
    case GET_POSTS_FOR_TOPICS:
      return { ...state, topicPosts: action.payload }
    case POSTS_PROGRESS_INDICATOR_OPEN:
      return { ...state, postLoadIndicator: action.payload }
    case POSTS_PROGRESS_INDICATOR_CLOSE:
      return { ...state, postLoadIndicator: action.payload }

    case SET_SELECTED_POST:
      return { ...state, clickedPost: action.payload }

    //get posts based on user
    case GET_USER_POST_REQUEST:
      return {
        ...state,
        userPosts: null,
        usetPostsLoading: true,
        userPostsError: false,
      }
    case GET_USER_POST_FAILURE:
      return {
        ...state,
        userPosts: null,
        usetPostsLoading: false,
        userPostsError: true,
      }
    case GET_USER_POST_SUCCESS:
      return {
        ...state,
        userPosts: action.payload,
        usetPostsLoading: true,
        userPostsError: false,
      }
    case GET_ORG_POST_SUCCESS:
      return {
        ...state,
        orgPosts: action.payload,
        orgPostsLoading: false,
        orgPostsError: false,
      }
    case GET_ORG_POST_REQUEST:
      return {
        ...state,
        orgPosts: null,
        orgPostsLoading: true,
        orgPostsError: false,
      }
    case GET_ORG_POST_FAILURE:
      return {
        ...state,
        orgPosts: null,
        orgPostsLoading: false,
        orgPostsError: true,
      }
    //update post
    case UPDATE_POST_REQUEST:
      return { ...state, updatePostLoading: true, updatePostError: false }
    case UPDATE_POST_SUCCESS:
      return { ...state, updatePostLoading: false, updatePostError: false }
    case UPDATE_POST_FAILURE:
      return {
        ...state,
        updatePostLoading: false,
        updatePostError: true,
        updatePostFailureMessage: action.payload,
      }
    case DELETE_POST_DATA_CLEAR:
      return {
        ...state,
        updatePostLoading: false,
        updatePostError: false,
        updatePostFailureMessage: null,
      }
    case SELECT_POST_DETAILS:
      return { ...state, selectedPost: action.payload }
    //post delete
    case DELETE_POST_REQUEST:
      return { ...state, deletePostLoading: true, deletePostError: false }
    case DELETE_POST_SUCCESS:
      return { ...state, deletePostLoading: false, deletePostError: false }
    case DELETE_POST_FAILURE:
      return { ...state, deletePostLoading: false, deletePostError: true }
    //POST EDIT RULES
    case POST_EDIT_RULES_REQUEST:
      return { ...state, editPostRulesLoading: true, editPostRulesError: false }
    case POST_EDIT_RULES_SUCCESS:
      return {
        ...state,
        editPostRulesLoading: false,
        editPostRulesError: false,
        editPostRules: action.payload,
      }
    case POST_EDIT_RULES_FAILURE:
      return { ...state, editPostRulesLoading: false, editPostRulesError: true }
    default:
      return state
  }
}

export default postsReducer
