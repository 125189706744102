import axios from 'axios'
import authAxios from '../../utils/authAxios'

export async function getTokenDetails(token: string) {
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v2/auth/getTokenDetails`,
    {
      token,
    }
  )

  return res.data
}

export async function setNewPassword(values: any) {
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v2/auth/setNewPassword`,
    values
  )

  return res.data
}
