import { create } from 'zustand'
import {
  impressionsDimensionOptions,
  periodDimensionOptions,
  sortingDimensionOptions,
  userDimensionOptions,
} from './TopicFilterwheel/helpers'

export const useTopicStore = create((set, get: any) => ({
  posts: [],
  setPosts: (posts: any[]) => set({ posts: posts }),

  topic: null,
  setTopic: (topic: any) => set({ topic: topic }),

  articles: [],
  setArticles: (articles: any[]) => set({ articles: articles }),

  filters: {},
  setFilter: (id: string, key: string, value: string) => {
    set((state: any) => {
      return {
        filters: {
          ...state.filters,
          [id]: {
            ...state.filters?.[id],
            [key]: value,
          },
        },
      }
    })
  },
  getFilter: (id: string, key: string) => {
    return get().filters?.[id]?.[key]
  },

  // impressionsDimension:
  //   impressionsDimensionOptions.find((x) => x.default)?.value || 'all',
  // setImpressionsDimension: (filter: string) =>
  //   set({ impressionsDimension: filter }),

  // periodDimension:
  //   periodDimensionOptions.find((x) => x.default)?.value || 'all',
  // setPeriodDimension: (filter: string) => set({ periodDimension: filter }),

  // sortingDimension:
  //   sortingDimensionOptions.find((x) => x.default)?.value || 'all',
  // setSortingDimension: (filter: string) => set({ sortingDimension: filter }),

  // userDimension: userDimensionOptions.find((x) => x.default)?.value || 'all',
  // setUserDimension: (filter: string) => set({ userDimension: filter }),

  // filterPostType: 'all',
  // setFilterPostType: (filter: string) => set({ filterPostType: filter }),

  // filterOrganization: 'all',
  // setFilterOrganization: (filter: string) =>
  //   set({ filterOrganization: filter }),
}))
