import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import PublicHeader from '../../components-v2/Header/PublicHeader'
import { validateEmailConfirmationToken } from './helpers'
import { CheckCircle, CheckCircle2 } from 'lucide-react'
import { isAuthenticated } from '../../utils/auth'
import { toast } from 'sonner'

type Props = {}

export default function ConfirmEmailPage({}: Props) {
  const [searchParams] = useSearchParams()
  const [tokenValid, setIsTokenValid] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const currentUrl = `${location.pathname}${location.search}`

  const [count, setCount] = useState(0)
  console.log('🚀 ~ Header ~ count:', count)

  // console.log('🚀 ~ ConfirmEmailPage ~ location:', currentUrl)

  const token = searchParams.get('token')
  // console.log('🚀 ~ ConfirmEmailPage ~ token:', token)

  useEffect(() => {
    if (!isAuthenticated() && currentUrl) {
      setTimeout(() => {
        toast.warning('Please login to activate your email')
        navigate(`/login?redirect=${currentUrl}`)
      }, 300)
    }
  }, [isAuthenticated, currentUrl])
  // console.log('🚀 ~ ConfirmEmailPage ~ toast:', window.location.pathname)

  useEffect(() => {
    if (token && isAuthenticated()) {
      validateEmailConfirmationToken(token)
        .then((data) => {
          // console.log('🚀 ~ .then ~ data:', data)
          setIsTokenValid(data.isValid)
          setCount(5)

          setTimeout(() => {
            navigate('/')
          }, 5000)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err?.response?.data?.message || 'Something went wrong')
          navigate('/')
        })
    }
  }, [token, isAuthenticated])

  useEffect(() => {
    // Exit early if count is already 0
    if (count <= 0) return

    // Set up the interval
    const timer = setTimeout(() => {
      setCount((prevCount) => prevCount - 1)
    }, 1000)

    // Clean up the interval on component unmount or if count changes
    return () => clearInterval(timer)
  }, [count])

  return (
    <div>
      <PublicHeader />

      {tokenValid && (
        <div className="mx-auto w-fit flex flex-col items-center mt-10">
          <p className="font-semibold">Your account email is verified</p>
          <p className="text-xs">
            You will be redirected to home in {count} seconds
          </p>
          <CheckCircle2 color="green" size={30} />
        </div>
      )}
    </div>
  )
}
