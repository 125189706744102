import {
  CLASS_FOR_LOCATION,
  LOCATION_ADDRESS_TYPE_TO_AVOID,
  TYPE_FOR_LOCATION,
} from './constants'

const clearAddressKeys = (address: any) => {
  // List of keys to remove
  if (!address || typeof address !== 'object') {
    return address
  }

  // List of keys to remove
  const keysToRemove = [
    'country_code',
    'postcode',
    'ISO3166-2-lvl4',
    'house_number',
  ]

  // Iterate through the keys to remove and delete them from the address object
  keysToRemove.forEach((key) => delete address[key])

  return address
}

export const allowOnlyOneLevelBelowCity = (locationArr: any) => {
  const locations = locationArr?.filter(
    (locationObj: any) =>
      CLASS_FOR_LOCATION.includes(locationObj?.class) &&
      TYPE_FOR_LOCATION.includes(locationObj?.type) &&
      !LOCATION_ADDRESS_TYPE_TO_AVOID.includes(locationObj?.addresstype)
  )
  sanitizeLocation(locations)
  return locations
  // const objectOfSanitizedLocation = clearAddressKeys(locationObj?.address)
  // let countTheLevel = 0
  // let countyExist = false
  // for (const [key, value] of Object.entries(objectOfSanitizedLocation)) {
  //   // if (key === 'city') {
  //   //   countyExist = true
  //   //   break
  //   // }
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   console.log('key::::', value)
  //   countTheLevel++
  // }
  // if (countTheLevel === 4 || countTheLevel === 5) {
  //   return locationObj
  // } else {
  //   return null
  // }

  // return countTheLevel <= 1 ? locationObj : null
}

export const capitalizeFirstLetter = (str: any) => {
  return str?.replace(/\b\w/g, function (char: any) {
    return char.toUpperCase()
  })
}

export const sanitizeLocation = (locationsArr: any) => {
  locationsArr?.map((location: any) => {
    location.address.county = capitalizeFirstLetter(
      location?.address?.county?.replace(/ County/g, '').replace(/-/g, ' ')
    )
  })
  return locationsArr
}
