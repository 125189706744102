import './index.css'
const HeadingAndValue = ({ heading, value }: any) => {
  return (
    <table style={{ width: '350px', marginBottom: '13px' }}>
      <tr>
        <td className={'table-data table-header heading-text'}>{heading}</td>
        <td className={'table-data value-text'} style={{ textAlign: 'start' }}>
          {value}
        </td>
      </tr>
    </table>
  )
}

export default HeadingAndValue
