import React, { useEffect } from 'react'
import {
  getNotifications,
  readFromSection,
} from '../../../services/notifications'
import { useQuery } from '@tanstack/react-query'
import Loading from '../../../components-v2/Loading'
import PostCard from '../../../components-v2/Post/PostCard'
import { useNotifications } from '../notifications.store'
import {
  getHowLongAgo,
  parsePostObjToProps,
} from '../../../components-v2/Post/helpers'

type Props = {}

export default function ContrastsTab({}: Props) {
  // useEffect(() => {
  //   readFromSection('contrasts')
  // }, [])
  const loadSummary = useNotifications((state: any) => state.loadSummary)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      readFromSection('contrasts').then(() => {
        loadSummary()
      })
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [])

  const { isLoading, error, data } = useQuery({
    queryKey: ['getNotifications', 'contrasts'],
    queryFn: getNotifications,
    initialData: [],
  })

  return (
    <div className=" flex flex-col items-center">
      {isLoading && <Loading />}

      {data.length < 1 && <p className="text-xs text-border">All read.</p>}

      {data.map((x: any) => {
        const time = getHowLongAgo(x?.createdAt)
        return (
          <div className=" w-full flex flex-col items-center pt-2 pb-4">
            <div className="max-w-[362px] w-full mb-2 text-xs">
              <p>
                <span className="font-bold">{x?.actingUser?.name}</span>{' '}
                contrasted on your post{' '}
                {time === 'Just now' ? time : `${time} ago`}.
              </p>
            </div>
            <PostCard {...parsePostObjToProps(x.relatedPost)} />
          </div>
        )
      })}
    </div>
  )
}
