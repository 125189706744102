import React from 'react'
import Loading from './Loading'

type Props = {}

export default function LoadingOverlay({}: Props) {
  return (
    <div className="absolute bg-white bg-opacity-50 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex justify-center items-center">
      <Loading />
    </div>
  )
}
