type Func<T extends any[], R> = (...args: T) => R

export default function debounce<T extends any[], R>(
  func: Func<T, R>,
  delay: number
): Func<T, void> {
  let timerId: NodeJS.Timeout

  return function (this: any, ...args: T): void {
    const context = this

    clearTimeout(timerId)

    timerId = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}
