import HomeMenu from '../../components/HomeMenu'
import HomeNewsFeedDesktop from '../../containers/HomeNewsFeedDesktop'
import HomeNewsFeedMobileAndTab from '../../containers/HomeNewsFeedMobileAndTab'
import { useEffect, useState } from 'react'
import { getAllTopics } from '../../redux/topics/actions'
import { selectPostDetails, postEditRules } from '../../redux/posts/actions'
import { connect } from 'react-redux'
import { getMe, login } from '../../redux/login/actions'
import { useNavigate } from 'react-router-dom'
import ContrastsUnderlay from '../ContrastsUnderlay'
import {
  MAX_PHONE_BREAK_POINT,
  MAX_TABLET_BREAK_POINT,
  MIN_TABLET_BREAK_POINT,
  MIN_DESKTOP_BREAK_POINT,
} from '../../utils/constants'
import { addPostsToRedux } from '../../redux/analytics/actions'
import { useInView } from 'react-intersection-observer'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'

const HomeContainer = ({
  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  postEditRules,
  addPostsToRedux,
}: any) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const postEditRulesFunction = (post: any) => {
    postEditRules(post?.id)
  }

  return (
    <AnalyticsTrackerWrapper>
      <div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
        {windowSize.innerWidth > 1500 && <ContrastsUnderlay marginTop={50} />}
        <div
          style={{
            display: 'flex',
            flex: 1,
            overflowY: 'hidden',
            background: 'transparent',
            zIndex: 100,
          }}
        >
          {windowSize.innerWidth < 1570 ? (
            <>
              {/* mobile and tab*/}
              <HomeNewsFeedMobileAndTab
                selectPostDetails={selectPostDetails}
                editPostModalVisible={editPostModalVisible}
                setEditPostModalVisible={setEditPostModalVisible}
                style={{}}
                setPostModalVisible={setPostModalVisible}
                setSelectTopicPostCreateModalVisible={
                  setSelectTopicPostCreateModalVisible
                }
                postEditRules={postEditRulesFunction}
                addPostsToRedux={addPostsToRedux}
              />
            </>
          ) : (
            <>
              {/* desktop view */}
              <div style={{ flex: 3 }}></div>
              <div style={{ flex: 7 }}>
                <HomeNewsFeedDesktop
                  selectPostDetails={selectPostDetails}
                  editPostModalVisible={editPostModalVisible}
                  setEditPostModalVisible={setEditPostModalVisible}
                  style={{}}
                  setPostModalVisible={setPostModalVisible}
                  setSelectTopicPostCreateModalVisible={
                    setSelectTopicPostCreateModalVisible
                  }
                  postEditRules={postEditRulesFunction}
                  addPostsToRedux={addPostsToRedux}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </AnalyticsTrackerWrapper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
    selectPostDetails: (post: any) => {
      dispatch(selectPostDetails(post))
    },
    postEditRules: (postId: any) => {
      dispatch(postEditRules(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
