import { create } from 'zustand'

export const useIdsStore = create((set) => ({
  userFollowIds: [],
  setUserFollowIds: (userFollowIds: string[]) => {
    set({ userFollowIds: userFollowIds })
  },

  userVoteIds: [],
  setUserVoteIds: (userVoteIds: any[]) => {
    set({ userVoteIds: userVoteIds })
  },

  userModeratorsStatus: [],
  setUserModeratorStatus: (userModeratorsStatus: any) => {
    set({ userModeratorsStatus: userModeratorsStatus })
  },

  userAlarmIds: [],
  setAlarmIds: (userAlarmIds: any) => {
    set({ userAlarmIds: userAlarmIds })
  },
}))
