import axios from 'axios'
import { Dispatch } from 'react'
import {
  TRUST_FEED_CHANGES_LOADING,
  TRUST_FEED_CHANGES_SUCCESS,
  TRUST_FEED_CHANGES_ERROR,

  //trust feed contrasts
  TRUST_FEED_CONTRASTS_LOADING,
  TRUST_FEED_CONTRASTS_SUCCESS,
  TRUST_FEED_CONTRASTS_ERROR,

  //is trust feed seen?
  TRUST_FEED_SEEN,
  TRUST_FEED_AUTO_LOAD,
} from './types'

import { getAuth } from '../../utils/auth'

const transport = axios.create({
  withCredentials: false,
})

export const trustFeedContrastsLoading = () => {
  return {
    type: TRUST_FEED_CONTRASTS_LOADING,
  }
}

export const trustFeedContrastsSuccess = (trustFeedContrast: any) => {
  return {
    type: TRUST_FEED_CONTRASTS_SUCCESS,
    payload: trustFeedContrast,
  }
}

export const trustFeedContrastsFailure = () => {
  return {
    type: TRUST_FEED_CONTRASTS_ERROR,
  }
}

export const trustFeedContrasts = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(trustFeedContrastsLoading())
    transport
      .get(
        `${process.env.REACT_APP_BASE_URL}/v2/trustfeed/contrasts`,
        getAuth()
      )
      .then((res) => {
        dispatch(trustFeedContrastsSuccess(res.data)) //open the progress
      })
      .catch((error) => {
        if (error.response) {
          dispatch(trustFeedContrastsFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getTrustFeedChanges = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getTrustFeedChangesLoading())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/v2/trustfeed/changes`, getAuth())
      .then((res) => {
        dispatch(getTrustFeedChangesSuccess(res.data)) //open the progress
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getTrustFeedChangesError())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getTrustFeedChangesLoading = () => {
  return {
    type: TRUST_FEED_CHANGES_LOADING,
  }
}

export const getTrustFeedChangesSuccess = (trustFeed: any) => {
  return {
    type: TRUST_FEED_CHANGES_SUCCESS,
    payload: trustFeed,
  }
}

export const getTrustFeedChangesError = () => {
  return {
    type: TRUST_FEED_CHANGES_ERROR,
  }
}

export const trustFeedSeen = () => {
  return {
    type: TRUST_FEED_SEEN,
  }
}

export const trustFeedAutoLoad = () => {
  return {
    type: TRUST_FEED_AUTO_LOAD,
  }
}
