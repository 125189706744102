import './index.css'
import Spacer from '../Spacer'
import Image from '../Image'

import { useEffect, useState } from 'react'
import {
  MAX_PHONE_BREAK_POINT,
  MAX_TABLET_BREAK_POINT,
} from '../../utils/constants'

const StoryItem = ({
  title,
  imageUrl,
  onClick,
  filterSelected = false,
}: any) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const widthAndheight = windowSize.innerWidth > MAX_PHONE_BREAK_POINT ? 70 : 65
  return (
    <div
      className={
        'container-story story-item-style-des story-item-style-mob story-item-style-tab'
      }
      onClick={onClick}
    >
      {imageUrl ? (
        <Image
          style={{ border: '1px #000000 solid' }}
          circle
          width={widthAndheight}
          height={widthAndheight}
          src={imageUrl}
        />
      ) : (
        <div
          style={{
            background: '#303655',
            height: widthAndheight,
            width: widthAndheight,
            borderRadius: 60,
            border: filterSelected ? '4px solid #303655' : '2px solid #303655',
          }}
        />
      )}

      <Spacer height={6} />
      <text
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'clip',
          overflow: 'hidden',
          overflowWrap: 'break-word',
          maxLines: 1,
        }}
        className={'text'}
      >
        {title}
      </text>
    </div>
  )
}

export default StoryItem
