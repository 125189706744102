import { useCallback, useEffect, useState } from 'react'
import { Input } from '../shadcn/input'
import debounce from '../../utils/debounce'
// import { fetchLocationsAndTopics } from './helper'
import Loading from '../Loading'
import { allowOnlyOneLevelBelowCity } from '../../utils/osmHelpers'
import { publicAxios } from '../../utils/authAxios'

type Props = {
  // onSelectLocation: (v: any) => void
  // setLocationObj: any
  setLocation: (obj: any) => any
  locationFilterNeeded?: boolean
  // anyLevelOfLocation?: any
}

export default function LocationSelect({
  // onSelectLocation,
  // setLocationObj,
  setLocation,
  // anyLevelOfLocation = false,
  locationFilterNeeded,
}: Props) {
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] =
    useState<{ id: string; label: string; type: string }[]>()
  // console.log('🚀 ~ TopicSelectDialogContent ~ data:', data)

  const filterByFeatureType = (
    data: any,
    allowedTypes = ['place', 'locality', 'neighborhood']
  ) => {
    if (!Array.isArray(data)) {
      return []
    }

    return data.filter((feature: any) => {
      return (
        feature.properties &&
        allowedTypes.includes(feature.properties.feature_type)
      )
    })
  }

  const debouncedOnChange = useCallback(
    debounce((value) => {
      setLoading(true)
      if (value) {
        publicAxios
          .post('/v2/public/location/search', {
            q: value,
            feature_type: 'place,locality,neighborhood',
          })
          .then((res) => {
            setLoading(false)

            const filteredLocations = locationFilterNeeded
              ? filterByFeatureType(res?.data)
              : res?.data
            setData(
              filteredLocations.map((x: any) => ({
                id: x.id,
                label: x.properties.full_address,
                type: x.properties?.feature_type,
              }))
            )
            // console.log({ res })
          })
          .finally(() => {
            setLoading(false)
          })
        // fetch(
        //   'https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&addressdetails=1&accept-language=us&q=' +
        //     value,
        //   {
        //     method: 'GET',
        //     redirect: 'follow',
        //   }
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     setData(
        //       !anyLevelOfLocation ? allowOnlyOneLevelBelowCity(result) : result
        //     )
        //     setLoading(false)
        //   })
        //   .finally(() => {
        //     setLoading(false)
        //   })
      }
    }, 1000),
    []
  )

  return (
    <div className="p-5">
      <Input
        className="placeholder:text-[11px] placeholder:italic placeholder:text-line"
        placeholder="Search on US locations only"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          debouncedOnChange(e.target.value)
        }}
      />
      <div className="flex gap-1">
        <div className="mt-1 flex-1 text-xs">
          <h2 className="ml-1 text-border">Locations</h2>
          <ul className="mt-2 flex h-[300px] flex-col gap-1 overflow-auto font-bold">
            {loading && (
              <div className="mt-5 flex justify-center">
                <Loading />
              </div>
            )}
            {data &&
              data?.map((x) => {
                return (
                  <li
                    key={x.id}
                    className="cursor-pointer rounded-sm p-1 transition-all hover:bg-gray-100"
                    onClick={() => {
                      // console.log({ x })

                      // onSelectLocation({
                      //   ...x?.address,
                      //   display_name: x?.display_name,
                      // })
                      setLocation(x)
                      // setLocationObj(x?.display_name)
                    }}
                  >
                    {x?.label}
                    <div key={x?.id} className={'mt-0.5'}>
                      <span
                        className={
                          'rounded bg-gray-200 p-0.5 font-normal text-gray-500'
                        }
                      >
                        {x?.type}
                      </span>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}
