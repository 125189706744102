import React from 'react'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import Markdown from 'react-markdown'
import { termMd } from '../SignupPage/tabs/TermTab/content'
import { privacyMd } from './privacy'

type Props = {}

export default function PrivacyPolicy({}: Props) {
  return (
    <div>
      <PublicHeader />
      {/* <div className="max-w-[800px] mx-auto py-5 px-5">
        <div dangerouslySetInnerHTML={{ __html: termsOfServiceHTML }}></div>
      </div> */}

      <Markdown
        className={
          'prose [&_a]:text-blue-500 [&_strong]:text-black max-w-[800px] mx-auto py-5 px-5'
        }
      >
        {privacyMd}
      </Markdown>
    </div>
  )
}
