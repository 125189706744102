import './index.css'

import { useEffect, useState } from 'react'
import Spacer from '../../components/Spacer'
import { MenuItem, Select } from '@mui/material'
import text from '../../assets/icons/text.svg'

import landscape from '../../assets/icons/landscape.svg'
import contrastDark from '../../assets/icons/contrast-dark.svg'
import clip from '../../assets/icons/clip.svg'
import gifImg from '../../assets/icons/gifimg.svg'
import face from '../../assets/icons/face.svg'
import Button from '../../components/Button'
import InputToggle from '../../components/InputToggle'

const ContrastsCreateModal = ({ setContrastsModalVisible }: any) => {
  const types = [
    { id: 0, name: 'Opinion' },
    { id: 1, name: 'Rumor' },
    { id: 2, name: 'Humor' },
  ]

  const [type, setType] = useState(-1)
  const [desc, setDesc] = useState('Tell the world')

  const closeModal = () => {
    setContrastsModalVisible(false)
  }

  useEffect(() => {
    switch (type) {
      case 0:
        setDesc('Tell the world what you believe in')
        break
      case 1:
        setDesc('Tell the world what you heard')
        break
      default:
        setDesc('Hahahahaha')
    }
  }, [type])

  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => {
        setContrastsModalVisible(false)
      }}
    >
      <div
        style={{
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 500,
          height: 550,
          padding: 30,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div
          style={{
            textAlign: 'start',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div style={{ display: 'flex' }}>
            <text>Create new</text>
            <Spacer width={5} />
            <Select
              size={'small'}
              style={{ fontWeight: 'bold', fontSize: 16, marginTop: -2 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="City"
              variant="standard"
              onChange={(event) => {
                setType(Number(event.target.value))
              }}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Spacer height={20} />

          <div
            style={{
              background: '#D9D9D9',
              display: 'flex',
              flexDirection: 'column',
              padding: 10,
              borderRadius: 4,
            }}
          ></div>
          <Spacer height={10} />
          <div
            style={{
              background: '#D9D9D9',
              display: 'flex',
              flexDirection: 'column',
              padding: 10,
              borderRadius: 4,
              height: '10%',
            }}
          >
            <text style={{ textAlign: 'start', fontSize: '12px' }}>
              <span style={{ fontWeight: 'bold' }}>Contrasts </span>to another
              humor post
            </text>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Spacer width={10} />

              <Spacer width={4} />
            </div>
          </div>

          <Spacer height={20} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <text style={{ textAlign: 'start', fontWeight: 'bold' }}>
              Jonathan Ampe
            </text>
            <text style={{ textAlign: 'start', fontWeight: 'bold' }}>
              Personal
            </text>

            <Spacer height={20} />
            <text style={{ fontStyle: 'italic', color: '#9D9D9D' }}>
              {desc}
            </text>

            <Spacer height={90} />
            <div
              style={{ height: 1, width: '100%', background: '#000000' }}
            ></div>
            <Spacer height={3} />
            <div style={{ display: 'flex' }}>
              <Spacer width={4} />
              <img
                className={'img-button'}
                style={{ height: 18, width: 18 }}
                src={landscape}
                alt=""
              />
              <Spacer width={4} />
              <img
                className={'img-button'}
                style={{ height: 18, width: 18 }}
                src={contrastDark}
                alt=""
              />
              <Spacer width={4} />
              <img
                className={'img-button'}
                style={{ height: 18, width: 18 }}
                src={clip}
                alt=""
              />
              <Spacer width={4} />
              <img
                className={'img-button'}
                style={{ height: 18, width: 18 }}
                src={gifImg}
                alt=""
              />
              <Spacer width={4} />
              <img
                className={'img-button'}
                style={{ height: 18, width: 18 }}
                src={face}
                alt=""
              />
            </div>
            <Spacer height={4} />
            <div
              style={{ height: 1, width: '100%', background: '#000000' }}
            ></div>
            <div style={{ flex: 1 }}></div>
          </div>

          <Spacer height={30} />
          <div style={{ flex: 1 }}>
            <InputToggle title={'Add reasoning  '} />
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button title={'Cancel'} onClick={() => closeModal()} />
              <Button title={'Post'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContrastsCreateModal
