export const GET_ALL_POSTS = 'GET_ALL_POSTS'
export const CREATE_POSTS = 'CREATE_POSTS'

export const GET_POSTS_FOR_TOPICS = 'GET_POSTS_FOR_TOPICS'

export const POSTS_PROGRESS_INDICATOR_OPEN = 'POSTS_PROGRESS_INDICATOR_OPEN'
export const POSTS_PROGRESS_INDICATOR_CLOSE = 'POSTS_PROGRESS_INDICATOR_CLOSE'

export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAILURE = 'GET_POST_FAILURE'

//delete post
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'
export const DELETE_POST_DATA_CLEAR = 'DELETE_POST_DATA_CLEAR'

export const SET_SELECTED_POST = 'SET_SELECTED_POST'

//get posts based on user
export const GET_USER_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_USER_POST_SUCCESS = 'GET_USER_POST_SUCCESS'
export const GET_USER_POST_FAILURE = 'GET_USER_POST_FAILURE'

export const GET_ORG_POST_REQUEST = 'GET_ORG_POST_REQUEST'
export const GET_ORG_POST_SUCCESS = 'GET_ORG_POST_SUCCESS'
export const GET_ORG_POST_FAILURE = 'GET_ORG_POST_FAILURE'

export const VOTE_POST = 'VOTE_POST'

export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE'

export const SELECT_POST_DETAILS = 'SELECT_POST_DETAILS'

//rules for edit posts
export const POST_EDIT_RULES_REQUEST = 'POST_EDIT_RULES_REQUEST'
export const POST_EDIT_RULES_SUCCESS = 'POST_EDIT_RULES_SUCCESS'
export const POST_EDIT_RULES_FAILURE = 'POST_EDIT_RULES_FAILURE'
