export default function scrollToDiv(sectionId: string, options: any) {
  // console.log('in scrollToDiv')

  const { smooth } = options
  var section = document.getElementById(sectionId)
  if (section) {
    if (smooth) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }

    section.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' })

    // section.scrollIntoView({ behavior: 'smooth' })
  }
}
