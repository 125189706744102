import React, { useCallback, useEffect, useState } from 'react'
import PostWithContrast from '../../../components-v2/Post/PostWithContrast'
import AddSVG from '../../../assets/icons/add.svg'
import { useParams } from 'react-router-dom'

import CreateContrastDialog from '../../../components-v2/CreateContrastDialog/CreateContrastDialog'
import EntryDialog from '../EntryDialog'
import { getAllowedPostTypes } from '../../../utils/permissions'
import { useSelector } from 'react-redux'
import { getContrastsOfAPost } from '../../../services/public'
import { createAContrast } from '../services'

type Props = {
  post: any
  // setCurrentTab: (tab: string) => void
  contrastsAllow?: boolean
  onNoAuthDialogOpen: () => void
}

export default function ContrastsTab({
  post,
  contrastsAllow,
  onNoAuthDialogOpen,
}: Props) {
  const { postId, topicId } = useParams<{ postId: string; topicId: string }>()
  const [contrasts, setContrasts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  // const [entryOpen, setEntryOpen] = useState(false)
  // const [postOpen, setPostOpen] = useState(false)
  // const [defaultType, setDefaultType] = useState<string>()

  const currentUser = useSelector((state: any) => state.login.user)

  const pageLoad = useCallback(() => {
    if (postId) {
      setIsLoading(true)
      getContrastsOfAPost(postId)
        .then((data) => {
          const arr = data?.con || []
          // if (arr.length < 1) {
          //   setCurrentTab('comments')
          // }
          setContrasts(data?.con || [])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [postId])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  // const allowedPostTypes = getAllowedPostTypes(
  //   currentUser?.roles?.permissions?.postCreation,
  //   ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']
  // )

  return (
    <>
      <div
        className="flex h-[25px] w-[364px] cursor-pointer items-center gap-[6px] rounded-full bg-[#F5F5F5] p-[6px] mx-mobile:w-full"
        onClick={onNoAuthDialogOpen}
      >
        <img src={AddSVG} className="h-4 w-4" />
        <p className="text-[11px] text-border">Post a contrast</p>
      </div>

      {/* <CreateContrastDialog
        parentPostType={post?.postTypeId}
        parentPostId={post?.id}
        parentTopicId={topicId || ''}
        postTypes={allowedPostTypes}
        dialogType="contrast"
        defaultType={defaultType}
        onClickPost={(values) => {
          return createAContrast({
            description: values.description,
            postTypeId: values.postType,
            mediaId: values.postImageUrl,
            organizationId: values.organizationId,
            parentPostId: values.parentPostId,
            topicId: values.parentTopicId,
          }).then(() => {
            pageLoad()
          })
        }}
        isOpen={postOpen}
        setIsOpen={setPostOpen}
      /> */}

      {isLoading && (
        <p className="mt-6 text-center text-xs text-border">Loading</p>
      )}

      {!isLoading && contrasts.length < 1 && (
        <p className="mt-6 text-center text-xs text-border">No Contrasts</p>
      )}

      <div className="mb-16 mt-6 flex w-full flex-col gap-6">
        {contrasts.map((contrast: any, i: number) => {
          return (
            <PostWithContrast
              post={contrast}
              key={i}
              pageLoad={pageLoad}
              blurMainFeedPosts
              onNoAuthCallback={onNoAuthDialogOpen}
            />
          )
        })}
      </div>

      {/* <EntryDialog
        postTypes={allowedPostTypes}
        setOpen={setEntryOpen}
        open={entryOpen}
        onTypeSelect={(type) => {
          setDefaultType(type)
          setEntryOpen(false)
          setPostOpen(true)
        }}
      /> */}
    </>
  )
}
