import authAxios from '../../utils/authAxios'

export async function getEmailSettingsByToken({ queryKey }: any) {
  const [key, token] = queryKey

  const res = await authAxios.post(`/v2/public/getEmailSettingsByToken`, {
    token,
  })
  return res.data
}

export async function saveEmailSettings(token: string, data: any) {
  const res = await authAxios.post(`/v2/public/saveEmailSettings`, {
    token,
    data,
  })

  return res.data
}

export async function getWLEmailSettingsByToken({ queryKey }: any) {
  const [key, token] = queryKey

  const res = await authAxios.post(`/v2/public/getWLEmailSettingsByToken`, {
    token,
  })
  return res.data
}

export async function saveWLEmailSettings(token: string, data: any) {
  const res = await authAxios.post(`/v2/public/saveWLEmailSettings`, {
    token,
    data,
  })

  return res.data
}
