export function getDomainFromUrl(url?: string) {
  try {
    const parsedUrl = new URL(url || '')
    return parsedUrl.hostname
  } catch (error) {
    return ''
  }
}

export function removeQueryParamsFromSrc(src: string) {
  try {
    const url = new URL(src)
    url.search = '' // Clear the query string
    return url.toString()
  } catch (error) {
    console.error('Invalid image src URL:', error)
    return src // Return the original src if invalid
  }
}
