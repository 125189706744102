import React from 'react'
import LogoSVG from '../../assets/icons/check-logo.svg'
import PlusSVG from '../../assets/icons/plus.svg'
import MenuSVG from '../../assets/icons/fi-rr-menu-burger.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EntryDialog from '../EntryDialog/EntryDialog'
import DropdownNav from './DropdownNav'
import { cn } from '../../utils/cn'
import { getAllTopics } from '../../redux/topics/actions'
import { useDispatch } from 'react-redux'
import FilterDialog from '../FilterWheel/postType/PostTypeWheelDialog'

type Props = {}

export default function PublicHeader({}: Props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className="h-[43px]">
      <header className="flex items-center justify-between bg-primary h-[43px] px-[10px] fixed w-full z-50">
        {/* Logo */}
        <Link to={'/'}>
          <img src={LogoSVG} className="w-6 h-6" />
        </Link>
      </header>
    </div>
  )
}
