import authAxios from '../utils/authAxios'

export async function getAllPostsForOrg(orgId: string | undefined) {
  const res = await authAxios.get(`post/org/${orgId}`)

  return res.data
}

export async function getOrganization(orgId: string | undefined) {
  const res = await authAxios.get(`/organization/${orgId}`)

  return res.data
}
