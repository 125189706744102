import authAxios from '../utils/authAxios'
import { getMe } from '../redux/login/actions'

export async function searchUsers({
  text,
  onlyVerified,
}: {
  text: string
  onlyVerified?: boolean
}) {
  const res = await authAxios.post(`/v2/user/search`, {
    searchText: text,
    ...(onlyVerified && { onlyVerified: true }),
  })

  return res.data
}

export async function getOne(userId: string) {
  const res = await authAxios.post(`/v2/user/getOne`, {
    userId: userId,
  })

  return res.data
}

export async function createHouseguest(data: any) {
  const res = await authAxios.post(`/v2/user/create-houseguest`, data)

  return res.data
}

export async function getUserDetails(userId: string | undefined) {
  const res = await authAxios.get(`/user/getuserdetails/${userId}`)

  return res.data
}

export async function updateEmailPreference(
  peopleResponse: any,
  trustFeed: any,
  topPosts: any
) {
  const res = await authAxios.post(`/v2/user/updateEmailPreference`, {
    peopleResponse,
    trustFeed,
    topPosts,
  })
  return res.data
}
