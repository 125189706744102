import './index.css'

import React from 'react'
const SocialLoginButton = ({ icon, text, onClick }: any) => {
  return (
    <div
      className={'flex-style'}
      onClick={() => {
        onClick()
      }}
    >
      <div style={{ flex: 10, alignItems: 'center' }}>
        <img
          style={{
            height: 14,
            width: 14,
            marginLeft: '14px',
            marginTop: '7px',
            marginBottom: '8px',
            flexShrink: 0,
          }}
          src={icon}
          alt=""
        />
      </div>
      <div
        style={{ flex: 80, marginBottom: 'auto', marginTop: 'auto' }}
        className={'button-text'}
      >
        {text}
      </div>
      <div style={{ flex: 10 }}></div>
    </div>
  )
}

export default SocialLoginButton
