import { XIcon } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import Tabs from '../../components-v2/Tabs'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  deleteArticlesForTopic,
  getTopicArticles,
} from '../../redux/article/actions'
import {
  alarmToggleTopic,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  voteToggleTopic,
} from '../../redux/posts/actions'
import {
  deleteTopic,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import { getAllArticlesOfATopic } from '../../services/article'
import { getPostsOfALocalTopic, getPostsOfATopic } from '../../services/post'
import DescriptionCard from '../ArticleScreen/DescriptionCard'
import {
  filterPosts,
  getDisabledStates,
  getInvolvedDisableState,
  getNextPageParam,
  getOrderFilteredPosts,
  getTimeFilteredPosts,
  getTopicPosts,
} from './helpers'
import './index.css'
import ArticlesTab from './tabs/ArticlesTab'
import PostsTab from './tabs/PostsTab'
import { isAuthenticated } from '../../utils/auth'
import Header from '../../components-v2/Header/Header'
import { getMe } from '../../redux/login/actions'
import { useTopicStore } from './topic.store'
import FilterWheelEntry from './TopicFilterwheel/FilterWheelEntry'
import {
  impressionsDimensionOptions,
  periodDimensionOptions,
  sortingDimensionOptions,
  userDimensionOptions,
} from './TopicFilterwheel/helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import Loading from '../../components-v2/Loading'
// import { useUserStore } from '../UserScreen-v2/user.store'
import { getUserFollowerIds } from '../../services/helpersApis'
import { useIdsStore } from '../../components-v2/Post/post.store'

const TopicScreen = ({
  getTopic,
  topic,
  setClickedTopic,

  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setContrastsModalVisible,
  topicPosts,
  getAllPostsForTopics,
  getTopicArticles,
  deletePost,
  deleteTopic,

  topicArticles = [],
  voteToggleTopic,
  deleteArticlesForTopic,
  setParamsForArticleModal,
  pageArticle,
  setPageArticle,
  addPostsToRedux,
  alarmToggleTopic,
  permissions,
  getMe,
  analyticsPosts,
  locationDetails,
  isAdmin,
  details,
}: any) => {
  // console.log('🚀 ~ topic:', topic)
  const [currentTab, setCurrentTab] = useState('posts')
  const params = useParams()
  // const { topicId } = params

  let topicId = ''
  if (params?.topicId) {
    topicId = params?.topicId ?? ''
  } else {
    topicId = params?.localId ?? ''
  }

  const [loadingPosts, setLoadingPosts] = useState(false)
  // const [posts, setPosts] = useState<any[]>([])
  // const posts = useTopicStore((state: any) => state.posts)
  const setPosts = useTopicStore((state: any) => state.setPosts)

  const [loadingArticles, setLoadingArticles] = useState(false)
  const articles = useTopicStore((state: any) => state.articles)
  const setArticles = useTopicStore((state: any) => state.setArticles)

  // const [filterPostType, setFilterPostType] = useState('all')
  // const [filterOrganization, setFilterOrganization] = useState('all')

  const defaultImpressions =
    impressionsDimensionOptions.find((x) => x.default)?.value || 'all'
  const defaultPeriod =
    periodDimensionOptions.find((x) => x.default)?.value || 'all'
  const defaultSorting =
    sortingDimensionOptions.find((x) => x.default)?.value || 'all'
  const defaultUser =
    userDimensionOptions.find((x) => x.default)?.value || 'all'

  // const [impressionsDimension, setImpressionsDimension] =
  //   useState(defaultImpressions)
  // const [periodDimension, setPeriodDimension] = useState(defaultPeriod)
  // const [sortingDimension, setSortingDimension] = useState(defaultSorting)
  // const [userDimension, setUserDimension] = useState(defaultUser)

  // const impressionsDimension = useTopicStore(
  //   (state: any) => state.impressionsDimension
  // )
  // const setImpressionsDimension = useTopicStore(
  //   (state: any) => state.setImpressionsDimension
  // )
  // const periodDimension = useTopicStore((state: any) => state.periodDimension)
  // const setPeriodDimension = useTopicStore(
  //   (state: any) => state.setPeriodDimension
  // )
  // const sortingDimension = useTopicStore((state: any) => state.sortingDimension)
  // const setSortingDimension = useTopicStore(
  //   (state: any) => state.setSortingDimension
  // )
  // const userDimension = useTopicStore((state: any) => state.userDimension)
  // const setUserDimension = useTopicStore((state: any) => state.setUserDimension)

  // const filterPostType = useTopicStore((state: any) => state.filterPostType)
  // const setFilterPostType = useTopicStore(
  //   (state: any) => state.setFilterPostType
  // )

  // const filterOrganization = useTopicStore(
  //   (state: any) => state.filterOrganization
  // )
  // const setFilterOrganization = useTopicStore(
  //   (state: any) => state.setFilterOrganization
  // )

  const filters = useTopicStore((state: any) => state.filters)
  const setFilter = useTopicStore((state: any) => state.setFilter)

  const filterPostType = topicId
    ? filters?.[topicId]?.filterPostType || 'all'
    : 'all'
  const setFilterPostType = (value: string) => {
    setFilter(topicId, 'filterPostType', value)
  }

  const filterOrganization = topicId
    ? filters?.[topicId]?.filterOrganization || 'all'
    : 'all'
  const setFilterOrganization = (value: string) => {
    setFilter(topicId, 'filterOrganization', value)
  }

  const impressionsDimension = topicId
    ? filters?.[topicId]?.impressionsDimension ||
      impressionsDimensionOptions.find((x) => x.default)?.value ||
      'all'
    : 'all'

  const setImpressionsDimension = (value: string) => {
    setFilter(topicId, 'impressionsDimension', value)
  }

  const periodDimension = topicId
    ? filters?.[topicId]?.periodDimension ||
      periodDimensionOptions.find((x) => x.default)?.value ||
      'all'
    : 'all'
  const setPeriodDimension = (value: string) => {
    setFilter(topicId, 'periodDimension', value)
  }

  const sortingDimension = topicId
    ? filters?.[topicId]?.sortingDimension ||
      sortingDimensionOptions.find((x) => x.default)?.value ||
      'all'
    : 'all'
  const setSortingDimension = (value: string) => {
    setFilter(topicId, 'sortingDimension', value)
  }

  const userDimension = topicId
    ? filters?.[topicId]?.userDimension ||
      userDimensionOptions.find((x) => x.default)?.value ||
      'all'
    : 'all'
  const setUserDimension = (value: string) => {
    setFilter(topicId, 'userDimension', value)
  }

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)

  const isAuthed = isAuthenticated()

  const loadArticles = useCallback(() => {
    if (topicId) {
      setLoadingArticles(true)
      getAllArticlesOfATopic(topicId)
        .then((data) => {
          setArticles(data)
        })
        .finally(() => {
          setLoadingArticles(false)
        })
    }
  }, [topicId])

  useEffect(() => {
    getTopic(topicId)
  }, [topicId])

  const {
    data: postsData,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: [
      'posts',
      topicId,
      impressionsDimension,
      periodDimension,
      sortingDimension,
      userDimension,
      filterPostType,
      filterOrganization,
    ],
    queryFn: getTopicPosts,
    initialPageParam: 1,
    getNextPageParam: getNextPageParam,
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [topicId])

  useEffect(() => {
    if (currentTab === 'posts') {
      refetch()
    }

    if (currentTab === 'articles') {
      loadArticles()
    }
  }, [currentTab, refetch, loadArticles])

  const {
    ref: loadMoreRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      if (inView) {
        fetchNextPage()
      }
    },
  })

  // console.log(postsData)

  let posts = []

  for (const page of postsData?.pages || []) {
    for (const item of page?.homeFeed || []) {
      posts.push(item)
    }
  }

  // for (const page of postsData?.pages || []) {
  //   for (const item of page?.homeFeed || []) {
  //     topicsWithPosts.push(item)
  //   }
  // }

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'auto',
  //   })
  // }, [posts])

  // TODO: memoize this for performance

  // const isAllowed = getInvolvedDisableState(posts, topic?.associatedUsers)
  // console.log('🚀 ~ isAllowed:', isAllowed)
  // console.log('🚀 ~ disabledItems:', disabledItems)

  // console.log({ topic })

  // const disabledItems = getDisabledStates(posts, topic?.associatedUsers)

  let tab = null
  const isFilterSelected =
    impressionsDimension != 'all' ||
    periodDimension != 'all' ||
    sortingDimension != 'newtoold' ||
    userDimension != 'niooze' ||
    filterPostType != 'all' ||
    filterOrganization != 'all'
  switch (currentTab) {
    case 'posts':
      // const filteredLayer1 = filterPosts(
      //   posts,
      //   filterPostType,
      //   filterOrganization,
      //   topic?.associatedUsers
      // )
      // const filteredLayer2 = getTimeFilteredPosts(filteredLayer1, filterTime)
      // const filteredLayer3 = getOrderFilteredPosts(filteredLayer2, filterOrder)

      tab = (
        <PostsTab
          posts={posts}
          cb={refetch}
          isLoading={isLoading}
          analyticsPosts={analyticsPosts}
          addPostsToRedux={addPostsToRedux}
          isFilterSelected={isFilterSelected}
        />
      )
      break

    case 'articles':
      tab = (
        <ArticlesTab
          articles={articles}
          cb={loadArticles}
          isLoading={loadingArticles && articles.length <= 0}
        />
      )
      break
  }
  // const setUserFollowIds = useUserStore((state: any) => state.setUserFollowIds)
  useEffect(() => {
    // getUserFollowerIds().then((userIds: any) => {
    //   setUserFollowIds(userIds)
    // })
    getMe()
  }, [getMe])

  const navigate = useNavigate()

  const userModeratorStatus = useIdsStore(
    (state: any) => state.userModeratorsStatus
  )
  return (
    <>
      <Header cb={refetch} />
      <div className={'mb-8'}>
        <div className="h-[38px]">
          <div className="fixed z-30 h-[38px] w-full justify-between border-b bg-white">
            <div className="mx-auto flex items-center h-full max-w-[406px] relative">
              <img
                src={ArrowLeftSVG}
                className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-line absolute left-5"
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Tabs
                className="items-center w-full py-2"
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'posts',
                    label: 'Posts',
                  },
                  {
                    value: 'articles',
                    label: 'Articles',
                  },
                ]}
              />

              {/* <div className="h-6 w-6" /> */}
            </div>
          </div>
        </div>

        {topic && (
          <div className="mx-auto hidden max-w-[390px] mx-desktop:block">
            <DescriptionCard
              permissions={permissions}
              title={topic.title}
              description={topic?.description}
              location={topic?.locationId}
              topicId={
                topic.id === 'local' ? 'local/' + topic?.location : topic?.id
              }
              locationDetails={locationDetails}
              cb={() => {
                refetch()
                getTopic(topicId)
                // loadArticles()
              }}
              isTopicModerator={
                userModeratorStatus?.[0]?.topicModerator?.includes(topicId) ||
                isAdmin
              }
              isLocationModerator={
                userModeratorStatus?.[1]?.locationModerator.includes(
                  topic?.location
                ) || isAdmin
              }
            />
          </div>
        )}

        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            {topic && (
              <div className="fixed w-[30%] max-w-[600px]">
                <DescriptionCard
                  permissions={permissions}
                  title={topic.title}
                  description={topic?.description}
                  location={topic?.locationId}
                  locationDetails={locationDetails}
                  topicId={
                    topic.id === 'local'
                      ? 'local/' + topic?.location
                      : topic?.id
                  }
                  cb={() => {
                    refetch()
                    getTopic(topicId)
                    // loadArticles()
                  }}
                  isTopicModerator={
                    userModeratorStatus?.[0]?.topicModerator?.includes(
                      topicId
                    ) || isAdmin
                  }
                  isLocationModerator={
                    userModeratorStatus?.[1]?.locationModerator.includes(
                      topic?.location
                    ) || isAdmin
                  }
                  // isLocationModerator={topic?.locationModerator}
                />
                {/* <FilterEntry
                currentPostType={filterPostType}
                setPostType={setFilterPostType}
              /> */}

                {/* <PostTypeWheel /> */}
                {currentTab === 'posts' && (
                  <FilterWheelEntry
                    className="mt-10"
                    currentPostType={filterPostType}
                    setPostType={(type: string) => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                      setFilterOrganization('all')
                      setFilterPostType(type)
                    }}
                    currentOrgType={filterOrganization}
                    setOrgType={(org: string) => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                      setFilterPostType('all')
                      setFilterOrganization(org)
                    }}
                    // setFilterOrder={setFilterOrder}
                    // filterOrder={filterOrder}
                    // setFilterTime={setFilterTime}
                    // filterTime={filterTime}
                    impressionsDimension={impressionsDimension}
                    setImpressionsDimension={setImpressionsDimension}
                    periodDimension={periodDimension}
                    setPeriodDimension={setPeriodDimension}
                    sortingDimension={sortingDimension}
                    setSortingDimension={setSortingDimension}
                    userDimension={userDimension}
                    setUserDimension={setUserDimension}
                    postTypeWheelOpen={postTypeWheelOpen}
                    setPostTypeWheelOpen={setPostTypeWheelOpen}
                    orgWheelOpen={orgWheelOpen}
                    setOrgWheelOpen={setOrgWheelOpen}
                    // disabledPostTypes={disabledItems.postTypes}
                    // disabledOrgTypes={disabledItems.orgTypes}
                  />
                )}
              </div>
            )}
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 mx-mobile:px-5"
            style={{
              flex: '0 0 40%',
            }}
          >
            {/* Filters */}
            {currentTab === 'posts' && (
              <div className="mb-4 mt-5 flex flex-wrap items-center gap-1 mobile:px-5">
                {filterPostType !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    Filtering {filterPostType}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterPostType('all')
                      }}
                    />
                  </div>
                )}

                {filterOrganization !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setOrgWheelOpen(true)
                    }}
                  >
                    Filtering {filterOrganization}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrganization('all')
                      }}
                    />
                  </div>
                )}

                {userDimension && userDimension !== defaultUser && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      userDimensionOptions.find(
                        (x) => x.value === userDimension
                      )?.label
                    }

                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setUserDimension(defaultUser)
                      }}
                    />
                  </div>
                )}

                {periodDimension && periodDimension !== defaultPeriod && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      periodDimensionOptions.find(
                        (x) => x.value === periodDimension
                      )?.label
                    }
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setPeriodDimension(defaultPeriod)
                      }}
                    />
                  </div>
                )}

                {impressionsDimension &&
                  impressionsDimension !== defaultImpressions && (
                    <div
                      className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                      onClick={() => {
                        setPostTypeWheelOpen(true)
                      }}
                    >
                      {
                        impressionsDimensionOptions.find(
                          (x) => x.value === impressionsDimension
                        )?.label
                      }
                      <XIcon
                        className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                        size={18}
                        onClick={(e) => {
                          e.stopPropagation()
                          setImpressionsDimension(defaultImpressions)
                        }}
                      />
                    </div>
                  )}

                {sortingDimension && sortingDimension !== defaultSorting && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      sortingDimensionOptions.find(
                        (x) => x.value === sortingDimension
                      )?.label
                    }
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setSortingDimension(defaultSorting)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tab}

            {/* Loading */}
            <div className="mt-5">{isFetching && <Loading />}</div>

            {/* Intersection Observer for load more */}
            <div ref={loadMoreRef} className="h-5 w-full"></div>
          </div>
          {/* Right div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>

        {currentTab === 'posts' && (
          <div
            className="h- fixed bottom-3 right-3 z-50 rounded-full bg-white desktop:hidden"
            style={{
              // '-webkit-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              // '-moz-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              boxShadow: 'rgb(255, 255, 255) 0px 0px 15px 8px',
            }}
          >
            <FilterWheelEntry
              currentPostType={filterPostType}
              setPostType={(type: string) => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
                setFilterOrganization('all')
                setFilterPostType(type)
              }}
              currentOrgType={filterOrganization}
              setOrgType={(org: string) => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
                setFilterPostType('all')
                setFilterOrganization(org)
              }}
              // setFilterOrder={setFilterOrder}
              // filterOrder={filterOrder}
              // setFilterTime={setFilterTime}
              // filterTime={filterTime}

              impressionsDimension={impressionsDimension}
              setImpressionsDimension={setImpressionsDimension}
              periodDimension={periodDimension}
              setPeriodDimension={setPeriodDimension}
              sortingDimension={sortingDimension}
              setSortingDimension={setSortingDimension}
              userDimension={userDimension}
              setUserDimension={setUserDimension}
              postTypeWheelOpen={postTypeWheelOpen}
              setPostTypeWheelOpen={setPostTypeWheelOpen}
              orgWheelOpen={orgWheelOpen}
              setOrgWheelOpen={setOrgWheelOpen}
              // disabledPostTypes={disabledItems.postTypes}
              // disabledOrgTypes={disabledItems.orgTypes}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles,

    topic: state.topics.topic,

    topicArticles: state.articles.topicArticles,
    topicsID: state.topics.topicsID,
    analyticsPosts: state.analytics.viewed_posts,
    locationDetails: state.login.user?.userLocations,
    isAdmin: state?.login?.user?.admin,
    details: state.topics,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    getMe: () => {
      dispatch(getMe())
    },
    getTopicArticles: (topicId: string) => {
      dispatch(getTopicArticles(topicId))
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    deleteTopic: (topic: any) => {
      dispatch(deleteTopic(topic))
    },
    voteToggleTopic: (postId: any, topicId: any) => {
      dispatch(voteToggleTopic(postId, topicId))
    },
    alarmToggleTopic: (postId: any, topicId: any) => {
      dispatch(alarmToggleTopic(postId, topicId))
    },
    deleteArticlesForTopic: (artcileId: any, topicId: any) => {
      dispatch(deleteArticlesForTopic(artcileId, topicId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicScreen)
