import Post from '../Post'
import checkGrey from '../../assets/icons/check-grey.svg'
import React, { useEffect, useRef, useState } from 'react'
import { alarmToggleOrg } from '../../redux/posts/actions'

const HomePostMoibleAndTab = ({
  onClick,
  post = {},
  setContrastsModalVisible,
  navigateToPostDetailWithPost,
  deletePost,
  userId,
  voteToggle,
  selectedTopic,
  deletePostError,
  deletePostLoading,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  user,
  postEditRules,
  ref,
  inView,
  addPostsToRedux,
  alarmToggle,
}: any) => {
  const { con = [] } = post || {}

  const [parentHeight, setParentHeight] = useState(0)
  const [contrastHeight, setContrastHeight] = useState(0)
  const [contrastHGapWidth, setContrastHGapWidth] = useState(0)
  const [contrastLineGap, setContrastLineGap] = useState(0)

  const parentPostRef = useRef<HTMLDivElement>(null)
  const contrastPostRef = useRef<HTMLDivElement>(null)
  const contrastHGap = useRef<HTMLDivElement>(null)
  const [lineGap, setLineGap] = useState(0)

  useEffect(() => {
    if (
      parentPostRef.current &&
      contrastPostRef.current &&
      contrastHGap.current
    ) {
      setParentHeight(parentPostRef.current.clientHeight)
      setContrastHeight(contrastPostRef.current.clientHeight)
      setContrastHGapWidth(contrastHGap.current.clientWidth)
    }
  })

  useEffect(() => {
    if (contrastHeight < parentHeight) {
      setLineGap(contrastHeight)
    } else {
      setLineGap(parentHeight)
    }
  }, [parentHeight, contrastHeight, contrastHGapWidth])

  useEffect(() => {
    if (post && post.organization) {
      setContrastLineGap(128)
    } else {
      setContrastLineGap(110)
    }
  }, [post])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const showContrast = () => {
    const windowSizeAllows = windowSize.innerWidth > 1500

    const hasContrasts = con && con.length > 0
    return hasContrasts && windowSizeAllows
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        marginRight: '2%',
      }}
    >
      <div>
        <Post
          selectPostDetails={selectPostDetails}
          user={user}
          selectedTopic={selectedTopic}
          deletePost={deletePost}
          post={post}
          onClick={onClick}
          deletePostError={deletePostError}
          deletePostLoading={deletePostLoading}
          setContrastsModalVisible={setContrastsModalVisible}
          // userId={userId}
          voteToggle={voteToggle}
          alarmToggle={alarmToggle}
          setEditPostModalVisible={setEditPostModalVisible}
          editPostModalVisible={editPostModalVisible}
          isPostHave={con.length > 0 && windowSize.innerWidth < 1500}
          postEditRules={postEditRules}
          ref={ref}
          inView={inView}
          addPostsToRedux={addPostsToRedux}
        />

        {con && con.length > 0 && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <div>
              <div
                style={{
                  height: '95px',
                  borderLeft: '1px solid #9D9D9D',
                  marginBottom: '10px',
                  marginLeft: '10px',
                }}
              ></div>
              <img style={{ height: 22, width: 22 }} src={checkGrey} alt="" />
            </div>
            <div style={{ marginLeft: '8%' }}>
              <Post
                addPostsToRedux={addPostsToRedux}
                voteToggle={voteToggle}
                deletePost={deletePost}
                alarmToggle={alarmToggle}
                contrast
                post={con[0]}
                onClick={() => navigateToPostDetailWithPost(con[0])}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomePostMoibleAndTab
