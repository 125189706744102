import moment from 'moment'
import { Post } from '../../types'

export function getUniqueDomains(articles: any[]) {
  let uniqueArr: { domainName: string; favicon?: string }[] = []

  for (const article of articles) {
    if (!uniqueArr.find((x) => x.domainName === article.domainName)) {
      uniqueArr.push({
        domainName: article.domainName,
        favicon: article.favicon,
      })
    }
  }

  return uniqueArr
}

export function getFilteredArticles(articles: any[], domainNames: string) {
  if (domainNames === '') {
    return articles
  }
  return articles.filter((x) => x.domainName === domainNames)
}

export function getInvolvedPosts(posts: Post[], users: any[]) {
  // console.log({ posts, users })

  const arr = []
  for (const post of posts) {
    const authorId = post?.author?.id
    const quoteUserId = post.quoteData?.userId
    // const postType = post.type

    for (const auser of users) {
      if (auser.id === authorId || auser.id === quoteUserId) {
        arr.push(post)
        continue
      }
    }
  }

  return arr
}

export function filterPosts(
  posts: Post[],
  postType: string,
  org: string,
  assoUsers: any[]
) {
  let typeFilteredPosts = []
  if (postType === 'all') {
    typeFilteredPosts = [...posts]
  } else {
    typeFilteredPosts = [
      ...posts.filter(
        (x) => x.postTypeId.toLocaleLowerCase() === postType.toLocaleLowerCase()
      ),
    ]
  }

  // console.log({ org })

  let orgFilteredPosts: Post[] = []
  if (org === 'all') {
    orgFilteredPosts = [...typeFilteredPosts]
  } else {
    if (org.toLowerCase() === 'Involved Parties'.toLowerCase()) {
      return getInvolvedPosts([...typeFilteredPosts], assoUsers)
    }

    orgFilteredPosts = [
      ...typeFilteredPosts.filter(
        (x) =>
          x.organization?.type.toLocaleLowerCase() === org.toLocaleLowerCase()
      ),
    ]
  }
  return orgFilteredPosts
}

export function getTimeFilteredPosts(posts: Post[], filterTime: string) {
  switch (filterTime) {
    case 'all':
      return posts

    case 'last-24h':
      return posts.filter((x) => {
        return moment(x.createdAt).isAfter(moment().subtract(1, 'day'))
      })

    case 'last-2-days':
      return posts.filter((x) => {
        return moment(x.createdAt).isAfter(moment().subtract(2, 'day'))
      })

    case 'last-7-days':
      return posts.filter((x) => {
        return moment(x.createdAt).isAfter(moment().subtract(7, 'day'))
      })

    default:
      break
  }

  return posts
}

export function getOrderFilteredPosts(posts: Post[], filterOrder: string) {
  switch (filterOrder) {
    case 'old-to-new':
      return posts.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })

    case 'new-to-old':
      return posts.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })

    case 'most-u-to-least-u':
      return posts.sort((a, b) => b.vote.length - a.vote.length)

    default:
      break
  }

  return posts
}

export function getInvolvedDisableState(posts: Post[], assoUsers: any[] = []) {
  // console.log('🚀 ~ getInvolvedDisableState ~ posts:', posts, assoUsers)
  let isAllowed = false
  for (const post of posts) {
    const authorId = post?.author?.id
    const quoteUserId = post.quoteData?.userId
    // const postType = post.type

    for (const auser of assoUsers) {
      if (auser.id === authorId || auser.id === quoteUserId) {
        isAllowed = true
        break
      }
    }
  }

  return isAllowed
}

export function getDisabledStates(posts: Post[], assoUsers: any[]) {
  let postTypes = ['research', 'rumor', 'fact', 'opinion', 'humor']
  let orgTypes = [
    'Private company/org'.toLocaleLowerCase(),
    'Government linked media'.toLocaleLowerCase(),
    'Non-governmental organization'.toLocaleLowerCase(),
    'International Organization'.toLocaleLowerCase(),
    'Educational institution'.toLocaleLowerCase(),
    'Non-profit media'.toLocaleLowerCase(),
    'For-profit media'.toLocaleLowerCase(),
    'Involved Parties'.toLocaleLowerCase(),
    'Government'.toLocaleLowerCase(),
    'Political Organization'.toLocaleLowerCase(),
  ]

  for (const post of posts) {
    for (const postType of postTypes) {
      if (postType === post?.postTypeId?.toLocaleLowerCase()) {
        postTypes = postTypes.filter((x) => x !== postType.toLocaleLowerCase())
      }
    }

    for (const orgType of orgTypes) {
      if (orgType === post?.organization?.type?.toLocaleLowerCase()) {
        orgTypes = orgTypes.filter((x) => x !== orgType.toLocaleLowerCase())
      }
    }
  }

  if (getInvolvedDisableState(posts, assoUsers)) {
    orgTypes = orgTypes.filter(
      (x) => x !== 'Involved Parties'.toLocaleLowerCase()
    )
  }

  return { postTypes, orgTypes }
}
