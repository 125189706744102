import './index.css'
import { useState, useEffect } from 'react'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const ToggleButton = ({
  title,
  offBackground = '#00000000',
  onBackground = '#D9D9D9',
  onClick,
  width,
  toggle,
}: any) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      className={'t-button flex items-center py-[3px]'}
      style={{
        paddingRight: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 10 : 30,
        paddingLeft: windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? 10 : 30,
        // paddingTop:'auto',
        // paddingBottom:'auto',
        // paddingBottom: '3px',
        // width,
        background: toggle ? onBackground : offBackground,
      }}
      onClick={onClick}
    >
      <text
        style={{
          fontSize:
            windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? '11px' : '14px',
        }}
      >
        {title}
      </text>
    </div>
  )
}

export default ToggleButton
