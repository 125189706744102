import React from 'react'

import './index.css'

const InputBox = ({
  disable,
  defaultValue,
  label,
  required,
  setValue,
}: any) => {
  return (
    <div>
      <label className={'label-text-input-box'}>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="text"
        defaultValue={defaultValue}
        onChange={(e) => setValue(e?.target?.value)}
        disabled={disable}
        style={{
          width: '287px',
          backgroundColor: disable ? '#D9D9D9' : 'white', //change the color when disable
          borderRadius: '6px',
          paddingLeft: '10px',
          height: '29px',
          flexShrink: 0,
          fontStyle: 'normal',
          zIndex: 1000,
          borderColor: '#D9D9D9',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: disable ? '#9D9D9D' : '#000',
        }}
      />
    </div>
  )
}

export default InputBox
