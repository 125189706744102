import authAxios from '../utils/authAxios'
import { URLMetadata } from './article.types'

export async function getUrlMetadata(url: string) {
  const res = await authAxios.post(`/v2/article/get-url-metadata`, {
    url,
  })

  return res.data as URLMetadata
}

export async function createAnArticle(data: any) {
  const res = await authAxios.post(`/v2/article/createAnArticle`, data)

  return res.data
}

export async function getOneArticle(articleId: string) {
  const res = await authAxios.post(`/v2/article/getOneArticle`, {
    id: articleId,
  })

  return res.data
}

export async function getAllArticlesOfATopic(topicId: any) {
  const res = await authAxios.post(`/v2/article/getAllArticlesOfATopic`, {
    topicId,
  })

  return res.data
}

export async function getArticleForUser(userId: string | undefined) {
  const res = await authAxios.post(`/v2/article/getArticleForUser`, {
    userId,
  })

  return res.data
}
export async function getArticleForOrganization(orgId: string | undefined) {
  const res = await authAxios.post(`/v2/article/getArticleForOrganization`, {
    orgId,
  })

  return res.data
}
