import React from 'react'
import { useParams } from 'react-router-dom'
import EmailTab from './tabs/EmailTab'
import InitialTab from './tabs/InitialTab'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSignupFormData } from './signupFormData.store'
import ProfileTab from './tabs/ProfileTab'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import TermTab from './tabs/TermTab/TermTab'

type Props = {}

export default function SignupPage({}: Props) {
  const { tab } = useParams<{ tab: string }>()

  const data = useSignupFormData((state: any) => state.data)
  // console.log('🚀 ~ SignupPage ~ data:', data)

  let currentTab = null

  switch (tab) {
    case 'email':
      currentTab = <EmailTab />
      break

    case 'profile':
      currentTab = <ProfileTab />
      break

    case 'term':
      currentTab = <TermTab />
      break

    default:
      currentTab = <InitialTab />
      break
  }

  // return (
  //   <FormProvider {...form}>
  //     <form>{currentTab}</form>
  //   </FormProvider>
  // )

  return (
    <>
      {/* <PublicHeader /> */}
      {currentTab}
    </>
  )
}
