import {
  TRUST_FEED_CHANGES_LOADING,
  TRUST_FEED_CHANGES_SUCCESS,
  TRUST_FEED_CHANGES_ERROR,

  //trust feed contrasts
  TRUST_FEED_CONTRASTS_LOADING,
  TRUST_FEED_CONTRASTS_SUCCESS,
  TRUST_FEED_CONTRASTS_ERROR,
  TRUST_FEED_SEEN,
  TRUST_FEED_AUTO_LOAD,
} from './types'

const initialState = {
  trustFeedChanges: null,
  trustFeedChangesLoading: false,
  trustFeedChangesSuccess: false,

  trustFeedContrasts: null,
  trustFeedContrastsLoading: false,
  trustFeedContrastsSuccess: false,

  isTrustFeedSeen: false,
  isTrustFeedAutoLoad: false,
}

const trustFeedReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TRUST_FEED_CHANGES_LOADING:
      return {
        ...state,
        trustFeedChanges: null,
        trustFeedChangesLoading: true,
        trustFeedChangesSuccess: false,
      }
    case TRUST_FEED_CHANGES_SUCCESS:
      return {
        ...state,
        trustFeedChanges: action.payload,
        trustFeedChangesLoading: false,
        trustFeedChangesSuccess: false,
      }
    case TRUST_FEED_CHANGES_ERROR:
      return {
        ...state,
        trustFeedChanges: null,
        trustFeedChangesLoading: false,
        trustFeedChangesSuccess: true,
      }
    case TRUST_FEED_CONTRASTS_LOADING:
      return {
        ...state,
        trustFeedContrasts: null,
        trustFeedContrastsLoading: true,
        trustFeedContrastsSuccess: false,
      }
    case TRUST_FEED_CONTRASTS_SUCCESS:
      return {
        ...state,
        trustFeedContrasts: action.payload,
        trustFeedContrastsLoading: false,
        trustFeedContrastsSuccess: true,
      }
    case TRUST_FEED_CONTRASTS_ERROR:
      return {
        ...state,
        trustFeedContrasts: null,
        trustFeedContrastsLoading: false,
        trustFeedContrastsSuccess: false,
      }
    case TRUST_FEED_SEEN:
      return {
        ...state,
        isTrustFeedSeen: true,
      }
    case TRUST_FEED_AUTO_LOAD:
      return {
        ...state,
        isTrustFeedAutoLoad: true,
      }
    default:
      return state
  }
}

export default trustFeedReducer
