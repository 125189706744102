import { create } from 'zustand'

export const useOrganizationsStore = create((set) => ({
  posts: [],
  setPosts: (posts: any[]) => set({ posts: posts }),

  organizationDetails: null,
  setOrganizationDetails: (organizationDetails: any) =>
    set({ organizationDetails: organizationDetails }),

  articles: [],
  setArticles: (articles: any[]) => set({ articles: articles }),

  topics: [],
  setTopics: (topics: any[]) => set({ topics: topics }),
}))
