import './index.css'
import Spacer from '../Spacer'
import { useState, useEffect } from 'react'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'

const PostEmpty = () => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 58 }}>
        <div className={'text-container'}>
          <Spacer height={40} />
          <text className={'no-post-text'}>No Posts</text>
          <Spacer height={40} />
        </div>
      </div>
      {windowSize.innerWidth > MAX_PHONE_BREAK_POINT && (
        <div style={{ flex: 42 }}></div>
      )}
    </div>
  )
}
export default PostEmpty
