import './index.css'
import editWhite from '../../assets/icons/editWhite.svg'
import { Avatar, AvatarFallback } from '../../components-v2/shadcn/avatar'
import { getInitials } from '../../components-v2/Post/helpers'
import React from 'react'

const MembersProfile = ({
  user = {},
  selectUserToDeleteFromOrgRedux,
  isAdmin,
}: any) => {
  const { name = '', roles, imageUrl, userOrganization } = user
  return (
    <div className="flex justify-between mt-5 mr-6 ml-6">
      <div style={{ display: 'flex' }}>
        {imageUrl ? (
          <img
            src={imageUrl}
            className={'w-[40px] h-[40px] object-cover rounded-full'}
            alt={''}
          />
        ) : (
          <Avatar className="w-[40px] h-[40px] object-cover rounded-full">
            <AvatarFallback className={'text-1xl text-gray-600 '}>
              {getInitials(name)}
            </AvatarFallback>
          </Avatar>
        )}
        <div className={'flex flex-col text-left ml-5 justify-center'}>
          <text className={'text-name-member first-letter'}>{name}</text>
          <text className={'text-email'}>{roles?.name}</text>
        </div>
      </div>

      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <text className={'text-role-members-profile'}>
          {userOrganization?.role === 1 ? 'Admin' : 'Member'}
        </text>
        {isAdmin && (
          <img
            src={editWhite}
            style={{ height: 14, width: 14 }}
            alt={''}
            onClick={() =>
              selectUserToDeleteFromOrgRedux({
                imageUrl: imageUrl,
                userTypeId: roles?.name,
                name: name,
                id: user?.id,
              })
            }
          />
        )}
      </div>
    </div>
  )
}

export default MembersProfile
