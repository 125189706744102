import {
  GET_ALL_ORGANIZATIONS,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_FAILUER,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,

  //add users for org
  ADD_USER_FOR_ORGANIZATION_FAILURE,
  ADD_USER_FOR_ORGANIZATION_REQUEST,
  ADD_USER_FOR_ORGANIZATION_SUCCESS,

  //delete user from org
  DELETE_USER_FOR_ORGANIZATION_REQUEST,
  DELETE_USER_FOR_ORGANIZATION_SUCCESS,
  DELETE_USER_FOR_ORGANIZATION_FAILURE,

  //SELECT USER TO DELETE FROM ORGANIZATION
  SELECT_USER_TO_DELETE_FROM_ORGANIZATION,

  //UPDATE USER TYPE FOR ORGANIZATION
  UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST,
  UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS,
  UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE,

  //update organization details,
  UPDATE_ORGANIZATION_DETAILS_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  UPDATE_ORGANIZATION_DETAILS_FAILURE,
} from './types'

const initialState = {
  organizations: null,
  organizationsLoading: false,
  organizationsError: false,

  organization: null,
  organizationLoading: false,
  organizationError: false,

  articlesForOrg: null,
  articlesForLoading: false,
  articlesForOrgError: false,

  //create organization
  createOrganizationLoading: false,
  createOrganizationError: false,

  //add users for organization
  addUserForOrganizationLoading: false,
  addUserForOrganizationError: false,

  //delete users from organization
  deleteUserFromOrganizationLoading: false,
  deleteUserFromOrganizationError: false,
  deleteUserFromOrganizationErrorMessage: null,

  selectedUserDeleteFromOrganization: null,

  //update user's type of organization
  updateUserForOrganizationLoading: false,
  updateUserForOrganizationError: false,

  //update organization details
  updateOrganizationDetailsLoading: false,
  updateOrganizationDetailsError: false,
}

const organizationsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        organizationsLoading: false,
        organizationsError: false,
      }
    case GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        organization: null,
        organizationLoading: true,
        organizationError: false,
      }
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        organizationLoading: false,
        organizationError: false,
      }
    case GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        organization: null,
        organizationLoading: false,
        organizationError: true,
      }
    case GET_ARTICLES_REQUEST:
      return {
        ...state,
        articlesForOrg: null,
        articlesForLoading: true,
        articlesForOrgError: false,
      }
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articlesForOrg: action.payload,
        articlesForLoading: false,
        articlesForOrgError: false,
      }
    case GET_ARTICLES_FAILUER:
      return {
        ...state,
        articlesForOrg: null,
        articlesForLoading: false,
        articlesForOrgError: true,
      }
    case CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        createOrganizationLoading: true,
        createOrganizationError: false,
      }
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        createOrganizationLoading: false,
        createOrganizationError: false,
      }
    case CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        createOrganizationLoading: false,
        createOrganizationError: true,
      }
    case ADD_USER_FOR_ORGANIZATION_REQUEST:
      return {
        ...state,
        addUserForOrganizationLoading: true,
        addUserForOrganizationError: false,
      }
    case ADD_USER_FOR_ORGANIZATION_SUCCESS:
      return {
        ...state,
        addUserForOrganizationLoading: false,
        addUserForOrganizationError: false,
      }
    case ADD_USER_FOR_ORGANIZATION_FAILURE:
      return {
        ...state,
        addUserForOrganizationLoading: false,
        addUserForOrganizationError: true,
      }
    case DELETE_USER_FOR_ORGANIZATION_REQUEST:
      return {
        ...state,
        deleteUserFromOrganizationLoading: true,
        deleteUserFromOrganizationError: false,
      }
    case DELETE_USER_FOR_ORGANIZATION_SUCCESS:
      return {
        ...state,
        deleteUserFromOrganizationLoading: false,
        deleteUserFromOrganizationError: false,
      }
    case DELETE_USER_FOR_ORGANIZATION_FAILURE:
      return {
        ...state,
        deleteUserFromOrganizationError: true,
        deleteUserFromOrganizationLoading: false,
        deleteUserFromOrganizationErrorMessage: action.payload,
      }
    case SELECT_USER_TO_DELETE_FROM_ORGANIZATION:
      return { ...state, selectedUserDeleteFromOrganization: action.payload }
    case UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST:
      return {
        ...state,
        updateUserForOrganizationError: false,
        updateUserForOrganizationLoading: true,
      }
    case UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS:
      return {
        ...state,
        updateUserForOrganizationLoading: false,
        updateUserForOrganizationError: false,
      }
    case UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE:
      return {
        ...state,
        updateUserForOrganizationError: true,
        updateUserForOrganizationLoading: false,
      }
    case UPDATE_ORGANIZATION_DETAILS_REQUEST:
      return {
        ...state,
        updateOrganizationDetailsLoading: true,
        updateOrganizationDetailsError: false,
      }
    case UPDATE_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        updateOrganizationDetailsLoading: false,
        updateOrganizationDetailsError: false,
      }
    case UPDATE_ORGANIZATION_DETAILS_FAILURE:
      return {
        ...state,
        updateOrganizationDetailsLoading: false,
        updateOrganizationDetailsError: true,
      }
    default:
      return state
  }
}

export default organizationsReducer
