import React from 'react'
import { cn } from '../utils/cn'

type Props = {
  tabs: {
    value: string
    label: string | React.ReactNode
  }[]

  currentTab: string

  setCurrentTab: (ct: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export default function Tabs({
  tabs,
  currentTab,
  setCurrentTab,
  className,
}: Props) {
  return (
    <div
      className={cn(
        'flex w-full mx-mobile:px-4 justify-center text-[11px] font-semibold gap-[15px]',
        className
      )}
    >
      {tabs.map((x) => {
        return (
          <div
            key={x.value}
            className={cn(
              'h-[21px] flex justify-center items-center min-w-[100px] border-[2px] border-black rounded-full cursor-pointer hover:bg-line transition-all leading-[10px]',
              {
                'bg-line': currentTab === x.value,
              }
            )}
            onClick={() => {
              setCurrentTab(x.value)
            }}
          >
            {x.label}
          </div>
        )
      })}
    </div>
  )
}
