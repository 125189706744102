import Spacer from '../Spacer'
import './index.css'
import { getRandomNumber } from '../../utils'
import React, { useEffect, useRef, useState } from 'react'
import ButtonComponent from '../ButtonComponent'

import prev from '../../assets/icons/prev-black.svg'
import next from '../../assets/icons/next-black.svg'
import landscape from '../../assets/icons/landscape.svg'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase'
import { v4 as uuidv4 } from 'uuid'

const ArticlePost = ({
  setArticle,
  content,
  setScrapImgIndex,
  setImgUrl,
}: any) => {
  const { imageUrls, titles } = content

  const [imageIndex, setImageIndex] = useState(0)
  const [titleIndex, setTitleIndex] = useState(0)

  const incrementImage = () => {
    if (imageIndex + 1 <= imageUrls.length - 1) {
      setImageIndex(imageIndex + 1)
      setScrapImgIndex(imageIndex + 1)
    }
  }

  const decrementImage = () => {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1)
      setScrapImgIndex(imageIndex - 1)
    }
  }

  const incrementTitle = () => {
    if (titleIndex + 1 <= titles.length - 1) {
      setTitleIndex(titleIndex + 1)
    }
  }

  const decrementTitle = () => {
    if (titleIndex > 0) {
      setTitleIndex(titleIndex - 1)
    }
  }

  const inputImage = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    if (inputImage.current) {
      inputImage.current.click()
    }
  }

  const [articleImage, setArticleImage] = useState(null)
  useEffect(() => {
    if (articleImage != null) {
      const imageRef = ref(
        storage,
        `article-images/${articleImage['name'] + uuidv4()}`
      )

      uploadBytes(imageRef, articleImage).then((e) => {
        getDownloadURL(e.ref).then((url) => {
          setImgUrl(url)
        })
      })
    }
  }, [articleImage])

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'start',
            marginTop: 5,
          }}
        >
          {imageUrls.length > 0 && !articleImage ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ButtonComponent
                disabled={imageIndex < 1}
                onClick={decrementImage}
              >
                <img className={'nav-button'} src={prev} alt="" />
              </ButtonComponent>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  maxHeight: 500,
                }}
              >
                <img
                  src={imageUrls[imageIndex]}
                  style={{
                    width: '100%',
                  }}
                  alt={''}
                />
              </div>
              <ButtonComponent
                disabled={imageIndex >= imageUrls.length - 1}
                onClick={incrementImage}
              >
                <img className={'nav-button'} src={next} alt="" />
              </ButtonComponent>
            </div>
          ) : (
            articleImage && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <img
                  src={URL.createObjectURL(articleImage)}
                  style={{ width: '100%' }}
                />
              </div>
            )
          )}

          <Spacer height={20} />
          <div style={{ width: 170 }}>
            <input
              type="file"
              hidden
              ref={inputImage}
              onChange={(event: any) => {
                setArticleImage(event.target.files[0])
              }}
            />

            <ButtonComponent>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  padding: 5,
                  alignItems: 'center',
                }}
                onClick={handleClick}
              >
                <img
                  style={{ height: 18, width: 18, marginRight: 10 }}
                  src={landscape}
                  alt="ksdsd"
                />
                <text
                  style={{ fontSize: '14px', fontWeight: 600, maxLines: 1 }}
                >{`Upload an image instead`}</text>
              </div>
            </ButtonComponent>
          </div>

          <Spacer height={20} />
          {titles.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ButtonComponent
                disabled={titleIndex < 1}
                onClick={decrementTitle}
              >
                <img className={'nav-button'} src={prev} alt="" />
              </ButtonComponent>
              <text className={'title-header'}>{titles[titleIndex]}</text>
              <ButtonComponent
                disabled={titleIndex >= titles.length - 1}
                onClick={incrementTitle}
              >
                <img className={'nav-button'} src={next} alt="" />
              </ButtonComponent>
            </div>
          )}
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  )
}

export default ArticlePost
