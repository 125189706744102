import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getArticle } from '../../redux/article/actions'
import { getTopic } from '../../redux/topics/actions'

import { ChevronLeft } from 'lucide-react'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import ArticleCard from './ArticleCard'
import DescriptionCard from './DescriptionCard'
// import './index.css'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'

const ArticleScreen = ({
  getArticle,
  getTopic,
  article,
  topic,
  // setPostModalVisible,
  // setClickedTopic,
  // addPostsToRedux,
}: any) => {
  // console.log('🚀 ~ topic:', topic)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
  }, [])

  const navigate = useNavigate()

  const { articleId, topicId } = useParams()

  useEffect(() => {
    getArticle(articleId)
  }, [articleId])

  useEffect(() => {
    getTopic(topicId)
  }, [topicId])

  return (
    <div className="w-full pb-20">
      <div className="bg-line fixed h-11 w-full text-sm font-semibold flex justify-center items-center px-5 z-50">
        <div className="w-[390px] flex justify-between items-center">
          <img
            src={ArrowLeftSVG}
            className="w-6 h-6 cursor-pointer hover:bg-border transition-all rounded-lg"
            onClick={() => {
              navigate(-1)
            }}
          />
          <h1
            className="cursor-pointer mx-mobile:text-xs"
            onClick={() => {
              navigate(`/topic/${topic?.id}`)
            }}
          >
            {topic?.title}
          </h1>
          <div className="w-6" />
        </div>
      </div>

      <div className="flex mx-desktop:flex-col max-w-[2000px] mx-auto mt-10">
        {/* Left div */}
        <div
          className="min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        >
          {/* {topic && (
            <div className="">
              <DescriptionCard
                title={topic.title}
                description={topic?.description}
                location={topic?.locationId}
                topicId={topic.id}
              />
            </div>
          )} */}
        </div>

        {/* Middle div */}
        <div
          className=" flex flex-col items-center border-r mx-desktop:border-r-0 border-line mx-mobile:px-5 min-h-screen"
          style={{
            flex: '0 0 40%',
          }}
        >
          {/* {topic && (
            <div className="hidden w-full max-w-[390px] mx-desktop:block">
              <DescriptionCard
                title={topic.title}
                description={topic?.description}
                location={topic?.locationId}
                topicId={topic.id}
              />
            </div>
          )} */}

          {/* <div className="flex justify-between items-center w-full max-w-[390px] mt-7">
            <div
              className="w-6 flex items-center justify-center hover:bg-line transition-all rounded-md cursor-pointer -ml-1"
              onClick={() => {
                navigate(-1)
              }}
            >
              <ChevronLeft className="" size={24} />
            </div>
            <p className="text-xl font-semibold shrink-0">Article</p>
            <div className="w-6" />
          </div> */}

          {article && (
            <ArticleCard
              className="mt-3"
              title={article.title}
              description={article?.description}
              iconSRC={article.favicon}
              imageSRC={article.imageUrl}
              name={article.domainName}
              postCount={article?.posts?.length}
              linkUrl={article.url}
            />
          )}

          <div className="w-full max-w-[390px] h-[1px] bg-line mt-2" />

          <div className="w-full mt-7 flex flex-col gap-6">
            {article &&
              article.posts.map((post: any, i: number) => {
                return (
                  <PostWithContrast
                    post={post}
                    key={i}
                    pageLoad={() => {}}
                    disableArticleButton
                  />
                )
              })}
          </div>
        </div>
        {/* Right div */}
        <div
          className="min-h-44 relative mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        >
          {/* asdsad */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    topic: state.topics.topic,

    article: state.articles.article,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getArticle: (articleId: string) => {
      dispatch(getArticle(articleId))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    // setClickedTopic: (id: any) => {
    //   dispatch(setClickedTopic(id))
    // },
    // addPostsToRedux: (postId: any) => {
    //   dispatch(addPostsToRedux(postId))
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleScreen)
