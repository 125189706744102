import './index.css'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import { useEffect, useState } from 'react'
import InputToggle from '../../components/InputToggle'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { createTopics } from '../../redux/topics/actions'
import Progress from '../../components/Progress'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import { getAllTopics } from '../../redux/topics/actions'
import { getAllOrganizations } from '../../redux/organizations/actions'

import AutoCompleteInput from '../../components/AutoCompleteInput'
import { connect } from 'react-redux'
import { stat } from 'fs'
import { getAllUsers } from '../../redux/login/actions'

const TopicCreateModal = ({
  setTopicModalVisible,
  createTopics,
  getAllOrganizations,
  organizations,
  getAllUsers,
  users,
}: any) => {
  const [city, setCity] = useState(10)
  const [topic, setTopic] = useState('')
  const [pTopic, setPTopic] = useState(10)
  const [endDate, setEndDate] = useState('no')

  const [date, setDate] = useState(new Date())
  const [description, setDescription] = useState()
  const [eDate, setEDate] = useState(new Date())

  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const [progressOpen, setProgressOpen] = useState(false)

  const [organization, setOrganization] = useState(false)
  const [user, setUser] = useState(false)

  useEffect(() => {
    getAllOrganizations()
    getAllUsers()
  }, [])

  const closeModal = () => {
    setTopicModalVisible(false)
  }

  const onPressButton = () => {
    createTopics(
      city,
      topic,
      pTopic,
      date,
      description,
      eDate,
      selectedOrganizations,
      selectedUsers,
      setProgressOpen,
      setTopicModalVisible
    )
  }

  const handleTextWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setDescription(textWithLineBreaks)
  }

  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000B2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
      }}
      onClick={() => {
        setTopicModalVisible(false)
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 1000,
          height: 750,
          padding: 50,
          borderRadius: 6,
          marginTop: 60,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'start',
              textAlign: 'start',
            }}
          >
            <div className={'text_header'}>
              <text>Create new</text>
              <text style={{ fontWeight: 'bold' }}> TOPIC</text>
            </div>
            <TextField
              style={{
                marginTop: 50,
                width: 200,
                fontSize: 30,
                color: '#000000',
              }}
              size={'small'}
              label="Topic Title"
              variant="standard"
              className={'TextField'}
              sx={{
                width: 300,
                color: 'success.main',
              }}
              onChange={(e: any) => {
                setTopic(e.target.value)
              }}
            />
            <Spacer height={10} />
            <div></div>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <text className={'text_small'}>Parent topic:</text>
              <Spacer width={10} />
              <Select
                disableUnderline
                size={'small'}
                style={{ fontSize: 14, marginTop: -2 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pTopic}
                label="City"
                variant="standard"
                onChange={(event) => setPTopic(Number(event.target.value))}
              >
                <MenuItem value={10}>No Parent</MenuItem>
              </Select>
            </div>

            {
              <Backdrop
                style={{ color: '#fff', zIndex: 4 }}
                open={progressOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            }

            <Spacer height={40} />
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <TextField
                style={{
                  width: 200,
                  fontSize: 14,
                  color: '#000000',
                }}
                size={'small'}
                label="Location"
                variant="standard"
                className={'TextField'}
                sx={{
                  width: 300,
                  color: 'success.main',
                }}
                onChange={(e: any) => {
                  setCity(e.target.value)
                }}
              />
            </div>
            <Spacer height={5} />
            <InputToggle title={'Add City'} />
            <Spacer height={40} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <text className={'text_small'}>Date:</text>
              <Spacer width={8} />
              <DatePicker
                className={'react-datepicker-wrapper'}
                selected={date}
                onChange={(date: any) => setDate(date)}
              />
            </div>

            <Spacer height={5} />
            <div style={{ display: 'flex' }}>
              <text className={'text_small'}>
                Is this something with an end date?
              </text>
              <Spacer width={5} />
              <Select
                disableUnderline
                size={'small'}
                style={{ fontSize: 14, marginTop: -2 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={endDate}
                defaultValue={'no'}
                label="End Date"
                variant="standard"
                onChange={(event) => {
                  setEndDate(event.target.value)
                }}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
              </Select>
            </div>

            {endDate === 'yes' && (
              <div style={{ display: 'flex' }}>
                <text className={'text_small'}>End date</text>
                <Spacer width={8} />
                <div style={{ width: 50 }}>
                  <DatePicker
                    selected={eDate}
                    onChange={(date: any) => setEDate(date)}
                  />
                </div>
              </div>
            )}

            <Spacer height={90} />
            <div style={{ height: 1, width: 120, background: '#000000' }}></div>
            <Spacer height={20} />
            <text
              className={'text_small'}
              style={{
                width: 200,
                color: '#9D9D9D',
              }}
            >
              Short summary of the topic. Can also be used to provide additional
              information that may provide more context
            </text>

            <Spacer height={30} />
            <InputToggle title={'Add long description'}>
              <TextField
                style={{
                  width: 250,
                  fontSize: 20,
                  background: '#ffffff',
                }}
                size={'small'}
                label="Long Description"
                rows={4}
                variant={'filled'}
                onChange={handleTextWithLineBreaks}
                InputProps={{ disableUnderline: true }}
                multiline
              />
            </InputToggle>
          </div>
          <div style={{ marginTop: 85, width: 1, background: '#000000' }}></div>
          <div style={{ flex: 1.5, marginLeft: 50, textAlign: 'start' }}>
            <Spacer height={87} />
            <text className={'text_header_bold'}>Involved parties</text>
            <Spacer height={6} />
            <div style={{ height: 1, width: 130, background: '#000000' }}></div>
            <Spacer height={20} />
            <InputToggle
              title={'Add Organization'}
              onClick={() => {
                setOrganization(true)
              }}
            />
            {organization && (
              <>
                <Spacer height={20} />
                <AutoCompleteInput
                  setSelectedItems={setSelectedOrganizations}
                  placeholder={'Organizations'}
                  data={organizations}
                />
              </>
            )}
            <Spacer height={10} />
            <InputToggle
              title={'Add User'}
              onClick={() => {
                setUser(true)
              }}
            />
            {user && (
              <>
                <Spacer height={20} />
                <AutoCompleteInput
                  setSelectedItems={setSelectedUsers}
                  placeholder={'Users'}
                  data={users}
                />
              </>
            )}
            <Spacer height={60} />
            <text className={'text_header_bold'}>Search words</text>
            <Spacer height={6} />
            <div style={{ height: 1, width: 130, background: '#000000' }}></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button title={'Cancel'} onClick={() => closeModal()} />
          <div style={{ display: 'flex' }}>
            <Button title={'Save for later'} />
            <Spacer width={20} />
            <Button
              onClick={() => {
                onPressButton()
              }}
              title={'Create'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createTopics: (
      city: any,
      topic: any,
      parentTopic: any,
      startDate: any,
      description: any,
      endDate: any,
      selectedOrganizations: any,
      selectedUsers: any,
      setProgressOpen: any,
      setTopicModalVisible: any
    ): any => {
      dispatch(
        createTopics(
          city,
          topic,
          parentTopic,
          startDate,
          description,
          endDate,
          selectedOrganizations,
          selectedUsers,
          setProgressOpen,
          setTopicModalVisible
        )
      )
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
    getAllUsers: () => {
      dispatch(getAllUsers())
    },
  }
}

const mapStateToProps = (state: any) => {
  return {
    organizations: state.organization.organizations,
    users: state.login.users,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicCreateModal)
