import './index.css'

const ActionMenu = ({ header, menu = [], onClick }: any) => {
  return menu?.length > 0 ? (
    <div className={'menu-container'}>
      {header}
      {header && (
        <div
          style={{
            height: 1,
            background: '#9D9D9D',
            width: '99%',
            marginBottom: 10,
            marginTop: 5,
          }}
        />
      )}
      {menu
        ? menu.map((menuItem: any) => {
            return (
              <div
                className={'menu-item-container'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  onClick(menuItem.id)
                }}
              >
                <text>{menuItem.title}</text>
              </div>
            )
          })
        : null}
    </div>
  ) : null
}

export default ActionMenu
