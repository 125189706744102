import React, { useEffect } from 'react'
// import './index.css'

import checkLandingSticker from '../../assets/icons/check-landing-page.svg'
import Spacer from '../Spacer'
import OnBoardButton from '../OnBoardButton'
import { Link, useNavigate } from 'react-router-dom'
import { Input } from '../../components-v2/shadcn/input'
import { cn } from '../../utils/cn'
import Button from '../../components-v2/Button'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { saveWaitlistEmail } from './helper'
import { toast } from 'sonner'
import { isAuthenticated } from '../../utils/auth'

const schema = z.object({
  email: z.string().email(),
})

type SchemaType = z.infer<typeof schema>

const LandingPage = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })
  // console.log('🚀 ~ LandingPage ~ errors:', errors)

  return (
    <div className="min-h-screen bg-primary flex flex-col justify-between text-white">
      <div className="p-5 flex justify-end">
        {!isAuthenticated() ? (
          <Link to="/login">
            <Button>Login</Button>
          </Link>
        ) : (
          <Link to="/">
            <Button>Home</Button>
          </Link>
        )}
      </div>
      <div className="flex flex-col w-fit items-center mx-auto mt-20 px-5">
        <img
          src={checkLandingSticker}
          className="w-[250px] h-[250px] mx-sm:w-[200px] mx-sm:h-[200px]"
        />
        <h1 className=" font-bold text-[40px] mx-sm:text-[40px]">niooze</h1>

        <h2 className="mt-1 text-[30px] font-arapey mx-sm:text-sm text-center text-sm">
          social media with the reliability of journalism
        </h2>

        <div className="w-[300px] mx-sm:w-full mt-14">
          <p className="w-full text-center pl-3_ text-md font-semibold text-gray-400">
            Join Waitlist
          </p>

          <form
            className={cn(
              'flex items-center gap-1 mt-1 border-2 border-line rounded-3xl overflow-hidden',
              {
                'border-red-500': !!errors.email,
              }
            )}
            onSubmit={handleSubmit(async (values) => {
              // console.log({ values })
              await saveWaitlistEmail(values.email)
                .then((res) => {
                  reset()
                  toast.success(`Successfully Waitlisted.`)
                })
                .catch((err) => {
                  console.log('🚀 ~ onSubmit={handleSubmit ~ err:', err)
                  toast.error(err.response?.data || 'Something went wrong')
                })
            })}
          >
            <Input
              {...register('email')}
              // value={field.value}
              className={cn(
                'h-[29px] bg-transparent placeholder:text-[11px] placeholder:italic text-xs border-none'
              )}
              style={{
                borderRadius: '500px 0px 0px 500px',
              }}
              placeholder="Enter your email"
            />

            <Button
              type="submit"
              className="rounded-none"
              disabled={!!errors.email}
            >
              Submit
            </Button>
          </form>

          {errors.email && (
            <p className="text-red-500 text-xs mt-1 text-center">
              {errors.email?.message}
            </p>
          )}
        </div>
      </div>

      <div className="w-full flex justify-between max-w-[2000px] mx-auto px-10 mx-sm:px-5 pb-10 font-arapey text-border text-[13px] mx-sm:text-xs mt-5 mx-sm:flex-col">
        <p>Copyright © 2023 niooze. All rights reserved.</p>
        <p>Patent pending.</p>
      </div>
    </div>
  )
}

export default LandingPage
