import './index.css'
import { PuffLoader } from 'react-spinners'

import React from 'react'

const Button = ({
  title,
  background = '#D9D9D9',
  onClick,
  disable,
  loading = false,
}: any) => {
  return (
    <div
      className={'c-button button-style-des button-style-mobile'}
      style={{
        opacity: disable ? 0.5 : 1,
        display: 'flex',
      }}
      onClick={disable ? null : onClick}
    >
      <div>
        <text>{title}</text>
      </div>
      <div style={{ marginBottom: '5px' }}>
        {loading && <PuffLoader color="black" size={15} />}
      </div>
    </div>
  )
}

export default Button
