import axios from 'axios'
import authAxios from '../../utils/authAxios'

export async function validateEmailConfirmationToken(token: string) {
  const res = await authAxios.post(
    `${process.env.REACT_APP_BASE_URL}/v2/auth/validateEmailConfirmationToken`,
    {
      token,
    }
  )

  return res.data
}
