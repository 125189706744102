import {
  StorageError,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { storage } from '../firebase/firebase'
import { useState } from 'react'
import Compressor from 'compressorjs'

export default async function uploadImage(image: File, folder: string) {
  return new Promise<string>((resolve) => {
    const path = `${folder}/${image['name'] + uuidv4()}`
    const imageRef = ref(storage, path)

    uploadBytes(imageRef, image).then((e) => {
      getDownloadURL(e.ref).then((url) => {
        resolve(url)
      })
    })
  })
}

export function useUpload() {
  const [totalBytes, setTotalBytes] = useState<null | number>()
  const [transferedBytes, setTransferedBytes] = useState<null | number>()
  const [progress, setProgress] = useState<number>(0)
  const [error, setError] = useState<StorageError | null>(null)
  const [url, setUrl] = useState<null | string>(null)

  function upload(
    file: File,
    folder: string,
    onSetUrl?: (url: string) => void
  ) {
    // TODO: dont compress if its a gif
    setProgress(1)
    new Compressor(file, {
      quality: 0.8,

      success(result) {
        const path = `${folder}/${file['name'] + uuidv4()}`

        const imageRef = ref(storage, path)

        const uploadTask = uploadBytesResumable(imageRef, result)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')

            setTransferedBytes(snapshot.bytesTransferred)
            setTotalBytes(snapshot.totalBytes)
            setProgress(progress < 1 ? 1 : progress)
          },
          (error) => {
            setError(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUrl(downloadURL)
              onSetUrl && onSetUrl(downloadURL)
            })
          }
        )
      },
      error(err) {
        console.log(err.message)
      },
      height: 700,
    })
  }

  return {
    upload,
    url,
    totalBytes,
    transferedBytes,
    progress,
    error,
    setUrl,
  }
}

export function formatBytes(bytes: number): string {
  if (bytes < 1024) {
    return bytes + ' bytes'
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB'
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
  }
}
