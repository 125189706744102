export function filterConsecutiveCons(posts: any[]) {
  // Initialize an empty array to store the filtered posts
  const filteredPosts = []

  // Iterate through each post
  for (let i = 0; i < posts.length; i++) {
    // Get the current post
    const currentPost = posts[i]

    // Check if the previous post has a "con"
    const previousHasCon =
      i > 0 && filteredPosts[i - 1].con && filteredPosts[i - 1].con.length > 0

    // If the previous post has a "con", remove the "con" from the current post
    if (previousHasCon) {
      currentPost.con = []
    }

    // Add the current post to the filteredPosts array
    filteredPosts.push(currentPost)
  }

  return filteredPosts
}
