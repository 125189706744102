import HomeTopicDesktop from '../../components/HomeTopicDesktop'
import TopicCreateModal from '../TopicCreateModal'
import { useNavigate } from 'react-router-dom'

import './index.css'
import HomeStories from '../HomeStories'
import {
  getAllTopics,
  addTopicID,
  getSelectedTopics,
  setClickedTopic,
} from '../../redux/topics/actions'
import { connect } from 'react-redux'

import { useEffect, useState } from 'react'
import {
  deletePost,
  voteToggleHomeNewsFeed,
  alarmToggleHomeNewsFeed,
} from '../../redux/posts/actions'
import Spacer from '../../components/Spacer'

const HomeNewsFeedDesktop = ({
  getAllTopics,
  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setClickedTopic,
  topics,
  topicsFailed,
  getSelectedTopics,
  user,
  deletePost,
  deletePostLoading,
  deletePostError,
  voteToggleHomeNewsFeed,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  postEditRules,
  updateAnalytics,
  addPostsToRedux,
  alarmToggleHomeNewsFeed,
}: any) => {
  const navigate = useNavigate()

  const [contrastTitleShown, setContrastTitleShown] = useState(false)

  useEffect(() => {
    getAllTopics()
  }, [])

  const navigateToDetail = (selectedTopic: any) => {
    navigate(`/topic/${selectedTopic.id}`)
    addTopicID(selectedTopic.id)
    getSelectedTopics(selectedTopic) //store the selected topic to redux
  }

  const navigateToPostDetail = (selectedTopic: any, selectedPost: any) => {
    navigate(`/topic/${selectedTopic.id}/post/${selectedPost.id}`)
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      style={{
        flex: 1,
        height: '100%',
        alignItems: 'center',
        overflow: 'scroll',
        zIndex: 999,
        maxWidth: '100%',
        overflowX: 'hidden',
        marginTop: 50,
      }}
    >
      {/* <Spacer height={2} /> */}
      {/* {user && (
        <HomeStories
          user={user}
          setSelectTopicPostCreateModalVisible={
            setSelectTopicPostCreateModalVisible
          }
        />
      )} */}
      {/* <Spacer height={2} /> */}
      {topics?.map((topic: any) => (
        <HomeTopicDesktop
          user={user}
          selectPostDetails={selectPostDetails}
          setEditPostModalVisible={setEditPostModalVisible}
          editPostModalVisible={editPostModalVisible}
          setSelectTopicPostCreateModalVisible={
            setSelectTopicPostCreateModalVisible
          }
          setContrastTitleShown={setContrastTitleShown}
          contrastTitleShown={contrastTitleShown}
          deletePost={deletePost}
          deletePostLoading={deletePostLoading}
          deletePostError={deletePostError}
          key={topic}
          setPostModalVisible={setPostModalVisible}
          topic={topic}
          navigateToPostDetail={navigateToPostDetail}
          setClickedTopic={setClickedTopic}
          posts={topic?.posts || []}
          image="https://i.ibb.co/XsVQ8Tw/images.jpg"
          onClick={() => navigateToDetail(topic)}
          voteToggle={voteToggleHomeNewsFeed}
          alarmToggle={alarmToggleHomeNewsFeed}
          postEditRules={postEditRules}
          updateAnalytics={updateAnalytics}
          addPostsToRedux={addPostsToRedux}
        />
      ))}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,

    topics: state.topics.topics,
    topicsFailed: state.topics.topicsFailed,

    deletePostLoading: state.posts.deletePostLoading,
    deletePostError: state.posts.deletePostError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllTopics: () => {
      dispatch(getAllTopics())
    },
    setClickedTopic: (id: any) => {
      dispatch(setClickedTopic(id))
    },
    getSelectedTopics: (selectedTopic: any) => {
      dispatch(getSelectedTopics(selectedTopic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    voteToggleHomeNewsFeed: (postId: any) => {
      dispatch(voteToggleHomeNewsFeed(postId))
    },
    alarmToggleHomeNewsFeed: (postId: any) => {
      dispatch(alarmToggleHomeNewsFeed(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeNewsFeedDesktop)
