import './index.css'
import Spacer from '../Spacer'
import { useEffect, useState } from 'react'

const UserScreenTopics = ({
  onClick,
  topicName,
  place,
  selected,
  topicKey,
}: any) => {
  const handleKey = (index: any) => {
    onClick(index)
  }

  return (
    <li key={topicKey} className={'list-none'}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => {
          handleKey(topicKey)
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 300 }}>
              <div
                style={{
                  flex: 1,
                  // background: '#D9D9D9',
                  background: selected ? '#959190' : '#D9D9D9',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 15,
                  paddingRight: 40,
                  borderRadius: '10px',
                  cursor: 'pointer',
                  borderStyle: 'solid',
                  borderColor: selected ? 'black' : '#D9D9D9',
                  justifyContent: 'center',
                }}
                // onClick={onClick}
                className={'topic-container click-button'}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <text className="text-[14px] font-semibold">{topicName}</text>
                  <Spacer width={10} />
                </div>

                <Spacer height={3} />
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <text style={{ fontSize: 17, textAlign: 'start' }}>
                    {place}
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Spacer height={10} />
      </div>
    </li>
  )
}

export default UserScreenTopics
