import Spacer from '../Spacer'
import text from '../../assets/icons/text.svg'

const image = 'https://oceanservice.noaa.gov/news/feb17/containership.jpg'

const OrganisationSelector = (organization: any) => {
  const {
    name = '',
    type = '',
    imageUrl = '',
  } = organization?.organization || {}
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <text className={'title-normal_dark'}>{name}</text>
          <text className={'title-normal'}>{type}</text>
        </div>
        <img
          src={imageUrl}
          style={{
            height: 50,
            width: 50,
            borderRadius: 50,
            border: '1px solid #000000',
          }}
          alt={''}
        />
      </div>
    </div>
  )
}

export default OrganisationSelector
