import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import TextSVG from '../../assets/icons/text-blue.svg'
import BellSVG from '../../assets/icons/bell.svg'
import GreenBellSVG from '../../assets/icons/green-bell.svg'
import ContrastSVG from '../../assets/icons/contrast.svg'
import CommentsSVG from '../../assets/icons/comments.svg'
import ArrowUpSVG from '../../assets/icons/arrow-up.svg'
import { cn } from '../../utils/cn'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../shadcn/dropdown-menu'
import { Menu, MoreVertical } from 'lucide-react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CreateContrastDialog from '../CreateContrastDialog/CreateContrastDialog'
import ReasonDialog from './ReasonDialog'
import { deletePost, updatePost, upvoteToggle, alarmToggle } from './services'
import { toast } from 'sonner'
import { contentToJSX, getLocationToDisplay } from './helpers'
import ProfileComponent from './ProfileComponent'
import QuoteProfileComponent from './QuoteProfileComponent'
import QuoteContent from './QuoteContent'
import AddQuote from '../EntryDialog/dialogs/AddQuote/AddQuote'
import { getAllowedPostTypes } from '../../utils/permissions'
import { PROPERTIES_TO_DELETE } from '../../utils/constants'
import locationGrey from '../../assets/icons/location-gray.svg'
import { useInView } from 'react-intersection-observer'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import {
  addDailyPost,
  addLocalPicks,
  addSchoolPicks,
} from '../../services/emailDigest'
import { useIdsStore } from './post.store'
import { getUserAlarmIds, getUserVoteIds } from '../../services/helpersApis'

type Props = {
  postId: string
  postType?: string
  postTypeLineThrough?: boolean
  disableActions?: boolean
  type?: string
  author?: string
  authorId?: string
  // voteCount?: number
  // isVoted?: boolean
  upvoteCount?: any
  alarms?: any[]
  moderators?: any[]
  content?: string
  contentJSX?: ReactNode
  authorProfileUrl?: string
  authorRole?: string
  organizationProfileUrl?: string
  organizationName?: string
  organizationType?: string
  organizationId?: string
  mediaUrl?: string
  articleId?: string
  contrastCount?: number
  commentCount?: number
  createdAt?: string
  urlsCollapsed?: boolean
  blurProfile?: boolean
  quoteData?: any
  topicId?: string
  onClickVote?: () => any
  onClickAlarm?: () => any
  location?: any
  userLocation?: any
  authorIsVerified?: boolean
  authorIsPublic?: boolean
  cb?: () => any
  addPostsToRedux?: (postId: { trustFeed: string; postId: string }) => any
  postTypeForAnalytics?: any
  disableArticleButton?: boolean
  onNoAuthCallback?: () => void
  address?: any
  analyticsPosts?: any
  onClickFollowUser?: (userId: any) => void
  isFollowed?: boolean
  isLocationModerator?: boolean
  isClickedVote?: boolean
  oldPostType?: any
  oldPostTypeLineThrough?: boolean
  postContentLineThrough?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export default function PostCard({
  className,
  postId,
  postType = 'Fact',
  postTypeLineThrough,
  type,
  author = 'John Doe',
  // isVoted = false,
  // voteCount,
  content,
  contentJSX,
  upvoteCount = 0,
  alarms,
  authorProfileUrl,
  authorRole,
  organizationProfileUrl,
  organizationName,
  organizationType,
  organizationId,
  mediaUrl,
  articleId,
  contrastCount = 0,
  commentCount = 0,
  createdAt,
  authorId,
  urlsCollapsed,
  blurProfile = false,
  quoteData,
  topicId,
  disableActions,
  onClickVote,
  onClickAlarm,
  cb,
  location: postLocation,
  userLocation,
  addPostsToRedux,
  postTypeForAnalytics = 'home',
  disableArticleButton,
  onNoAuthCallback,
  authorIsVerified,
  authorIsPublic,
  address,
  analyticsPosts,
  onClickFollowUser,
  isFollowed,
  moderators,
  isLocationModerator = false,
  //  isClickedVote,
  oldPostType = null,
  oldPostTypeLineThrough,
  postContentLineThrough,
  ...rest
}: Props) {
  const user = useSelector((state: any) => state.login.user)

  const [locationToDisplayUnderThePost, setLocationToDisplayUnderThePost] =
    useState('')

  useEffect(() => {
    //location logic to display post locations
    getLocationToDisplay(
      address,
      PROPERTIES_TO_DELETE,
      setLocationToDisplayUnderThePost
    )
  }, [address])

  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteReasonOpen, setIsDeleteReasonOpen] = useState(false)
  const [isVisibleDelete, setIsVisibleDelete] = useState(true)

  const [topicModelOpen, isTopicModelOpen] = useState(false)
  // console.log('🚀 ~ isEditOpen:', isEditOpen)

  const currentUser = useSelector((state: any) => state.login.user)

  // const { topicId } = useParams<{ topicId: string }>()
  const href = `/topic/${topicId === null ? 'local' : topicId}/post/${postId}`

  // console.log('🚀 ~ href:', href)
  const navigate = useNavigate()

  // let isVoted = false
  //
  // if (user && voteCount && Array.isArray(votes)) {
  //   isVoted = !!votes?.find((v: any) => v?.userId === user?.id)
  // }

  // let isAlarmed = false
  //
  // if (user && alarms && Array.isArray(alarms)) {
  //   isAlarmed = !!alarms?.find((a: any) => a?.userId === user?.id)
  // }

  // console.log('🚀 ~ isAlarmed:', isAlarmed)
  const setUserVoteIds = useIdsStore((state: any) => state.setUserVoteIds)

  const userVoteIds = useIdsStore((state: any) => state.userVoteIds)

  const setAlarmIds = useIdsStore((state: any) => state.setAlarmIds)

  const userAlarmIds = useIdsStore((state: any) => state.userAlarmIds)
  // let voteCount = votes?.length ?? 0
  const [voteCountDisplay, setVoteCountDisplay] = useState(0)

  useEffect(() => {
    setVoteCountDisplay(upvoteCount)
  }, [upvoteCount])

  const clickVote = (postId: any) => {
    onClickVotePost(postId)
  }

  function onClickVotePost(postId: any) {
    if (onNoAuthCallback) {
      onNoAuthCallback()
      return
    }
    upvoteToggle(postId).then((voteCount: any) => {
      setVoteCountDisplay(voteCount?.upvote_count)
      getUserVoteIds()
        .then((postIds: any) => {
          setUserVoteIds(postIds)
        })
        .catch((err) => {
          console.log('Err::::::', err)
        })
    })
  }

  const clickAlarm = (postId: any) => {
    onClickAlarmPost(postId)
  }
  function onClickAlarmPost(postId: any) {
    if (onNoAuthCallback) {
      onNoAuthCallback()
      return
    }
    alarmToggle(postId)
      .then(() => {
        getUserAlarmIds()
          .then((alarmIds) => {
            setAlarmIds(alarmIds)
          })
          .catch((e: any) => {
            toast.error('Something went wrong,try again')
          })
      })
      .catch((err) => {
        toast.error('Something went wrong,try again')
      })
  }

  const adminOrSelf = authorId === currentUser?.id || currentUser?.admin

  const onDropDownItemClick = useCallback((actionType: string) => {
    switch (actionType) {
      case 'edit':
        if (isQuote) {
          if (adminOrSelf) {
            isTopicModelOpen(true)
          } else {
            toast.warning('You are not allowed to edit this Quote.')
          }
          break
        }
        setIsEditOpen(true)
        break
      case 'delete':
        setIsDeleteReasonOpen(true)
        break
      case 'visibleDelete':
        setIsDeleteReasonOpen(true)
        setIsVisibleDelete(true)
        break
      case 'invisibleDelete':
        setIsDeleteReasonOpen(true)
        setIsVisibleDelete(false)
        break
    }
  }, [])

  let dropDownItems: any[] = []

  const userModeratorStatus = useIdsStore(
    (state: any) => state.userModeratorsStatus
  )

  let isSchoolModerator = user?.admin
  if (userModeratorStatus[2]?.schoolTopicModerator.includes(topicId)) {
    isSchoolModerator = true
  }

  let isLocationModeratorRights = false
  if (postLocation) {
    isLocationModeratorRights = user?.admin
    if (userModeratorStatus?.[1]?.locationModerator.includes(postLocation)) {
      isLocationModeratorRights = true
    }
  }

  const isUserAMod =
    userModeratorStatus?.[0]?.topicModerator?.includes(topicId) ||
    userModeratorStatus?.[1]?.locationModerator.includes(postLocation)
  if (user?.admin || isUserAMod || isLocationModerator) {
    if (user?.id === authorId) {
      dropDownItems = [
        { id: 'edit', title: 'Edit' },
        { id: 'delete', title: 'Delete' },
      ]
    } else {
      dropDownItems = [
        { id: 'edit', title: 'Edit' },
        { id: 'visibleDelete', title: 'Delete(Visible)' },
        {
          id: 'invisibleDelete',
          title: 'Delete(Invisible)',
        },
      ]
    }
  } else {
    if (user?.id === authorId) {
      dropDownItems = [
        { id: 'delete', title: 'Delete' },
        { id: 'edit', title: 'Edit' },
      ]
    }
  }

  const isQuote = type?.toLowerCase() === 'quote'

  let allowedPostTypes
  if (isUserAMod) {
    allowedPostTypes = ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']
  } else {
    allowedPostTypes = getAllowedPostTypes(
      currentUser?.roles?.permissions?.postCreation,
      ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']
    )
  }

  const { ref, inView } = useInView({
    threshold: 2 / 3,
    triggerOnce: true,
    delay: 3000,
    trackVisibility: false,
  })

  const [onScroll, setOnScroll] = useState(false)

  const checkPresence = (array: any, itemToCheck: any) => {
    return array?.some(
      (item: any) =>
        item.postId === itemToCheck.postId &&
        item.trustFeed === itemToCheck.trustFeed
    )
  }

  useEffect(() => {
    const onScrolling = setTimeout(() => {
      if (inView) {
        // updateAnalytics([id])
        if (
          addPostsToRedux &&
          postId &&
          !checkPresence(analyticsPosts, {
            postId: postId,
            trustFeed: postTypeForAnalytics,
          })
        ) {
          postId &&
            addPostsToRedux({
              postId: postId,
              trustFeed: postTypeForAnalytics,
            })
        }
      }
    }, 2000)

    return () => clearTimeout(onScrolling)
  }, [
    onScroll,
    inView,
    //, inView, description, updateAnalytics, id, postIds
  ])

  const articleUrl = `/topic/${
    topicId === null ? 'local' : topicId
  }/article/${articleId}`

  const ArticleButtonComp = onNoAuthCallback ? 'div' : (Link as any)

  const isVoted = userVoteIds.includes(postId)
  const isAlarmed = userAlarmIds.includes(postId)

  const isCurrentPost = window.location.pathname === href

  // const userFollowsIds = useIdsStore((state: any) => state.userFollowIds)
  return (
    <AnalyticsTrackerWrapper>
      <div
        className={cn('flex w-full max-w-[364px] flex-col', className)}
        {...rest}
        ref={ref}
      >
        {isQuote && (
          <p className="ml-[12px] pb-[1px] text-start text-[10px] font-normal text-border">
            <span className="text-xs font-semibold uppercase">QUOTE </span>{' '}
            posted by{' '}
            <span
              className="cursor-pointer"
              onClick={() => {
                if (onNoAuthCallback) {
                  onNoAuthCallback()
                } else {
                  navigate(`/user/${authorId}`)
                }
              }}
            >
              {author}
            </span>{' '}
            | {authorRole}
          </p>
        )}
        <p
          className={cn(
            'ml-[12px] pb-[2px] text-start text-xs font-semibold uppercase text-text'
            // {
            //   'line-through': postTypeLineThrough,
            // }
          )}
        >
          {oldPostType && (
            <span
              className={cn('opacity-15', {
                'line-through': oldPostTypeLineThrough,
              })}
            >
              {oldPostType}
            </span>
          )}{' '}
          <span className={cn({ 'line-through': postTypeLineThrough })}>
            {postType}
          </span>
        </p>
        <div
          className="relative flex w-full flex-col items-start rounded-lg border border-border pt-[10px]"
          style={
            {
              // border: '1px solid #9D9D9D',
            }
          }
        >
          <div
            // to={href}
            className={cn('flex w-full flex-col items-start px-[12px]', {
              'cursor-pointer': !isCurrentPost,
            })}
            onClick={() => {
              // console.log('navigation')

              if (!isCurrentPost) {
                navigate(href)
              }
            }}
          >
            {!isQuote && (
              <ProfileComponent
                author={author}
                authorProfileUrl={authorProfileUrl}
                authorRole={authorRole}
                organizationName={organizationName}
                organizationProfileUrl={organizationProfileUrl}
                organizationType={organizationType}
                createdAt={createdAt}
                blurProfile={blurProfile}
                isVerified={authorIsVerified}
                isPublic={authorIsPublic}
                onNoAuthCallback={onNoAuthCallback}
                navigateToProfile={() => {
                  if (onNoAuthCallback) {
                    onNoAuthCallback()
                  } else {
                    navigate('/user/' + authorId)
                  }
                }}
                navigateToOrganisation={() =>
                  navigate('/organization/' + organizationId)
                }
                onClickFollowUser={onClickFollowUser}
                followed={isFollowed}
                authorId={authorId}
                isUsersPost={authorId === user?.id}
                // userFollowsIds={userFollowsIds}
              />
            )}

            {isQuote && (
              <QuoteProfileComponent
                customUserName={quoteData?.customUserName}
                userName={quoteData?.user?.name || 'John'}
                userProfileImg={quoteData?.user?.imageUrl}
                // If the role is not there it means it's a custom user (It's also Houseguest)
                userRole={quoteData?.user?.roles?.name || 'Houseguest'}
                createdAt={createdAt}
                isVerified={quoteData?.user?.verified}
                isPublic={quoteData?.user?.public}
                isFollowed={isFollowed}
                isUsersQuotes={quoteData?.user?.id === user?.id}
                onClickFollowUser={onClickFollowUser}
                quotedUserId={quoteData?.user?.id}
                onNoAuthCallback={onNoAuthCallback}
                // userFollowsIds={userFollowsIds}
                // userProfileImg=''
              />
            )}

            {/*<span className={'text-xs text-subtext'}>{organizationType}</span>*/}

            {contentJSX}

            {/* Content */}
            {!contentJSX && content && (
              <div
                className={cn('mt-2 w-full text-left text-[12px]', {
                  'line-through': postContentLineThrough,
                })}
              >
                {contentToJSX({
                  content: content,
                  collapsedURL: urlsCollapsed,
                })}
              </div>
            )}

            {isQuote && (
              <QuoteContent
                content={quoteData?.text}
                date={new Date(quoteData?.date)}
                role={quoteData?.userRole}
                source={quoteData?.source}
              />
            )}

            {/* <div className="bg-red-400 w-full h-[1px]" /> */}

            {/* <p className="text-left text-[11px] mt-2 bg-red-200 w-full break-words">
            {content}
          </p> */}

            {mediaUrl && (
              <img src={mediaUrl} className="mt-3 w-full rounded-lg" />
            )}
          </div>

          {/* Actions*/}
          <div
            className={cn(
              'mt-2 flex w-full items-end justify-between px-[12px] pb-[10px]',
              {
                'mt-0': isQuote,
              }
            )}
          >
            {/* Read Article */}
            {!disableActions && !disableArticleButton && articleId ? (
              <ArticleButtonComp
                to={onNoAuthCallback ? undefined : articleUrl}
                onClick={onNoAuthCallback ? onNoAuthCallback : undefined}
                className="-mb-1 -ml-1 flex cursor-pointer gap-[6px] rounded-md p-1 transition-all hover:bg-line"
              >
                <img src={TextSVG} className="h-4 w-4" />
                <span className="text-xs font-bold text-[#2443B2]">
                  Read article
                </span>
              </ArticleButtonComp>
            ) : (
              <div></div>
            )}
            {/* Right side actions */}
            {!disableActions && (
              <div className="-mb-[2px] flex">
                <div
                  className="flex cursor-pointer flex-col justify-end"
                  onClick={() => {
                    if (onClickAlarm) {
                      if (onNoAuthCallback) {
                        onNoAuthCallback()
                      } else {
                        clickAlarm(postId)
                      }
                    }
                  }}
                >
                  <div
                    className={cn(
                      'rounded-md p-[2px] transition-all hover:bg-line'
                    )}
                  >
                    <img
                      src={
                        userAlarmIds.includes(postId) ? GreenBellSVG : BellSVG
                      }
                      className="h-4 w-4"
                    />
                  </div>
                </div>
                <div className="flex cursor-pointer flex-col justify-end">
                  <span className="-mb-[2px] text-center text-[8px] text-border">
                    {contrastCount}
                  </span>
                  <div
                    className="rounded-md p-[2px] transition-all hover:bg-line"
                    onClick={() => {
                      if (onNoAuthCallback) {
                        onNoAuthCallback()
                      } else {
                        if (!isCurrentPost) {
                          navigate(`${href}?tab=contrasts`)
                        }
                      }
                    }}
                  >
                    <img
                      src={ContrastSVG}
                      className="h-4 w-4 rounded-lg hover:bg-line"
                    />
                  </div>
                </div>
                <div className="flex cursor-pointer flex-col justify-end">
                  <span className="-mb-[2px] text-center text-[8px] text-border">
                    {commentCount}
                  </span>
                  <div
                    className="rounded-md p-[2px] transition-all hover:bg-line"
                    onClick={() => {
                      if (onNoAuthCallback) {
                        onNoAuthCallback()
                      } else {
                        if (!isCurrentPost) {
                          navigate(`${href}?tab=comments`)
                        }
                      }
                    }}
                  >
                    <img src={CommentsSVG} className="h-4 w-4" />
                  </div>
                </div>

                <DropdownMenu>
                  <DropdownMenuTrigger className="-mr-2 flex cursor-pointer flex-col justify-end focus-visible:outline-0">
                    <div className="rounded-md p-[2px] text-border transition-all hover:bg-line">
                      <MoreVertical size={16} />
                    </div>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent
                    className="rounded-lg border border-border text-xs shadow-xl"
                    align="end"
                  >
                    {dropDownItems?.length > 0 &&
                      dropDownItems.map((x) => {
                        return (
                          <DropdownMenuItem
                            key={x.id}
                            className="cursor-pointer rounded-md text-xs"
                            onClick={() => {
                              onDropDownItemClick(x.id)
                            }}
                          >
                            {x.title}
                          </DropdownMenuItem>
                        )
                      })}

                    {dropDownItems?.length > 0 && user?.admin && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() => {
                          addDailyPost(postId)
                            .then(() => {
                              toast.success("Post added to today's list")
                            })
                            .catch((err) => {
                              console.log('🚀 ~ err:', err)
                              toast.error(err?.response?.data, {
                                description:
                                  "Post is already added to today's list, You can add the same post some other day.",
                              })
                            })
                        }}
                      >
                        Add to digest post list
                      </DropdownMenuItem>
                    )}

                    {isSchoolModerator &&
                      !postLocation &&
                      userModeratorStatus[2]?.schoolTopicIds.includes(
                        topicId
                      ) && (
                        <DropdownMenuItem
                          className="text-xs"
                          onClick={() => {
                            addSchoolPicks(postId, topicId)
                              .then((e) => {
                                toast.success(e)
                              })
                              .catch((err) => {
                                console.log('🚀 ~ err:', err)
                                toast.error(err?.response?.data, {
                                  description:
                                    "Post is already added to today's list, You can add the same post some other day.",
                                })
                              })
                          }}
                        >
                          Add to today’s School Newsletter
                        </DropdownMenuItem>
                      )}

                    {isLocationModeratorRights && (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() => {
                          addLocalPicks(postId, postLocation)
                            .then((e) => {
                              toast.success(e)
                            })
                            .catch((err) => {
                              console.log('🚀 ~ err:', err)
                              toast.error(err?.response?.data, {
                                description:
                                  "Post is already added to today's list, You can add the same post some other day.",
                              })
                            })
                        }}
                      >
                        Add to today’s Local Newsletter
                      </DropdownMenuItem>
                    )}

                    <DropdownMenuItem
                      className="text-xs"
                      onClick={() => {
                        const url = `${window.location.origin}/topic/${topicId}/post/${postId}`
                        navigator.clipboard.writeText(url).then(() => {
                          toast.success(`Post url copied to your clipboard`, {
                            description: url,
                          })
                        })
                      }}
                    >
                      Share: Copy link to clipboard
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </div>

          {isQuote && (
            <div className="w-full border-t border-border px-[12px] py-[5px] text-[8px] text-border">
              This is a Quote. The quoted person did not post this content, and
              might not even be on this platform. The quote and source have been
              added for journalistic reasons. In case the quote is not exact,
              please report.
            </div>
          )}

          {/* Upvote button */}
          {!disableActions && (
            <div
              className="absolute -right-[10px] -top-[23px] flex cursor-pointer flex-col rounded-lg bg-background"
              onClick={() => {
                // if (onClickVote) {
                //   if (onNoAuthCallback) {
                //     onNoAuthCallback()
                //   } else {
                clickVote(postId)
                //  }
                // }
              }}
            >
              <span className="h-3 text-center text-[8px]">
                {voteCountDisplay}
              </span>
              <div
                className={cn(
                  'flex h-[25px] w-[25px] flex-col items-center justify-center rounded-lg border border-border transition-all ',
                  {
                    'bg-[#d3e6c6] sm:hover:bg-[#c2e4ab]': isVoted,
                    'sm:hover:bg-[#d3e6c6a5]': !isVoted,
                  }
                )}
              >
                <img src={ArrowUpSVG} className="h-4 w-4" />
              </div>
            </div>
          )}
        </div>

        <ReasonDialog
          isOpen={isDeleteReasonOpen}
          setIsOpen={setIsDeleteReasonOpen}
          description="You just deleted a post. If you want, you can explain here why you did this."
          onClickFinish={(reason) => {
            // console.log({ reason })

            deletePost(postId, isVisibleDelete, reason)
              .then((res) => {
                setIsDeleteReasonOpen(false)
              })
              .catch((err) => {
                toast(err?.response?.data || err?.message)
              })
              .finally(() => {
                cb && cb()
              })
          }}
        />
        {locationToDisplayUnderThePost !== '' && (
          <div className={'mr-4 mt-0.5 flex justify-end'}>
            <img src={locationGrey} alt="something" className={'mr-1'} />
            <text
              className={'text-xs font-normal lowercase leading-3'}
              style={{ color: '#9D9D9D' }}
            >
              {locationToDisplayUnderThePost}
            </text>
          </div>
        )}

        <AddQuote
          open={topicModelOpen}
          setOpen={isTopicModelOpen}
          postId={postId}
          cb={() => {
            cb && cb()
          }}
        />

        <CreateContrastDialog
          isOpen={isEditOpen}
          // defaultView="create"
          editPostId={postId}
          setIsOpen={setIsEditOpen}
          parentPostType={postType}
          parentPostId={postId || ''}
          parentTopicId={topicId}
          postTypes={allowedPostTypes}
          dialogType={type || 'post'}
          onClickPost={async (values) => {
            updatePost({
              postTypeId: values.postType,
              description: values.description,
              topicId: values.topicId,
              organizationId: values.organizationId,
              mediaId: values.postImageUrl,
              postId: postId,
              // location: {
              //   address: values?.locationObj?.address,
              //   addressType: values?.locationObj?.addresstype,
              // },
              location: values.location,
              reason: values.reason,
            })
              .catch((err) => {
                toast(err?.response?.data?.e || err?.message)
              })
              .finally(() => {
                cb && cb()
              })
          }}
          // trigger={<p className="text-[11px] text-border">Post a contrast</p>}
        />
      </div>
    </AnalyticsTrackerWrapper>
  )
}
