import React, { useEffect, useState, useRef } from 'react'
import Spacer from '../Spacer'
import { MenuItem, Select, TextField } from '@mui/material'
import landscape from '../../assets/icons/landscape.svg'
import contrastDark from '../../assets/icons/contrast-dark.svg'
import clip from '../../assets/icons/clip.svg'
import gifImg from '../../assets/icons/gifimg.svg'
import face from '../../assets/icons/face.svg'
import InputToggle from '../InputToggle'
import Button from '../Button'

import { SimpleDropdown } from '../SimpleDropDown'
import Autocomplete from '@mui/material/Autocomplete'
import { storage } from '../../firebase/firebase'
import {
  MAX_PHONE_BREAK_POINT,
  MIN_PHONE_BREAK_POINT,
} from '../../utils/constants'
import ChannelPicker from '../../containers/ChannelPicker'
import AutoCompleteTwoText from '../AutoCompleteTwoText'
import axios from 'axios'
import wrongBoldBlack from '../../assets/icons/wrongBoldBlack.svg'
import astrickMarkRed from '../../assets/icons/astrick-mark-red.svg'

import './index.css'
import PostEditDeleteReasonModal from '../PostEditDeleteReasonModal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import icon from '../../assets/icons/icon.svg'
import Popover from 'react-bootstrap/Popover'
import OverlayError from '../OverlayError'

const PostCreateContent = (props: any) => {
  const {
    user,
    onCancel,
    clickSubmit,
    setpostTypeid,
    handleTextWithLineBreaks,
    countryToDisplay,
    description = '',
    selectedTopic,
    clickedPost,
    organizationsData,
    locationsData, //locations from the api
    setLocations, //grab the location from the api
    setSelectLocation,
    setOrg,
    setImgae,
    image,
    org,

    //edit and update post
    editPost,
    postDetailsTobeEdited,
    setUpdatePostTypeId,
    updatePostTypeId,
    selectedItemToDisplay,
    handleTextUpdateWithLineBreaks,
    setUpdateTopicId,
    setUpdateOrganizationId,
    setUpdateMediaId,
    updateMediaId,
    updateDescription,
    updatePost,
    locationsUpdateData,
    setLocationsUpdateData,
    setTopic,
    topic,
    setSelectedLocationFrmAutoComp,
    selectedLocationFrmAutoComp,
    showOrganizationSelection,
    setCountryToDisplay,
    setSelectedTopicFrmAutoComp,
    selectedTopicFrmAutoComp,
    setSelectTopicFromModal,
    setSelectedItemToDisplay,
    locationLoading,
    topicLoading,
    showMediaUpload,
    updatePostLoading,
    updatePostFailureMessage,
    errorMessageFlag,
    setErrorMessageFlag,
    updatePostDataClear,
    editPostRules,
  }: any = props
  const { name } = user

  const [type, setType] = useState(editPost ? 0 : -1) //posts type
  const [desc, setDesc] = useState('Tell the world')
  const { title: topicTitle, locationId } = selectedTopic || {}
  const { postTypeId: parentPostTypeId } = clickedPost || {}

  const [conditionalUserTypes, setConditionalUserTypes] = useState<any>()
  // (user?.roles?.permissions?.postCreation?.map(
  //     (name: any, id: any) => ({
  //     id,
  //     name
  // })))

  const arrayDestructuring = () => {
    const array = []
    for (let i = 0; i < user?.roles?.permissions?.postCreation?.length; i++) {
      if (
        user?.roles?.permissions?.postCreation[i]?.toUpperCase() ===
        'Fact'.toUpperCase()
      ) {
        array.push({ id: 0, name: user?.roles?.permissions?.postCreation[i] })
      } else if (
        user?.roles?.permissions?.postCreation[i]?.toUpperCase() ===
        'research'.toUpperCase()
      ) {
        array.push({ id: 1, name: user?.roles?.permissions?.postCreation[i] })
      } else if (
        user?.roles?.permissions?.postCreation[i]?.toUpperCase() ===
        'opinion'.toUpperCase()
      ) {
        array.push({ id: 2, name: user?.roles?.permissions?.postCreation[i] })
      } else if (
        user?.roles?.permissions?.postCreation[i]?.toUpperCase() ===
        'rumor'.toUpperCase()
      ) {
        array.push({ id: 3, name: user?.roles?.permissions?.postCreation[i] })
      } else {
        array.push({ id: 4, name: user?.roles?.permissions?.postCreation[i] })
      }
    }

    setConditionalUserTypes(array)
  }

  useEffect(() => {
    arrayDestructuring()
  }, [])

  const [editTypeDisable, setEditTypeDisable] = useState(false) //edit type of post
  const [editTopicDisable, setEditTopicDisable] = useState(false) //edit topic of post
  const [editChannelDisable, setEditChannelDisable] = useState(false)
  const [editTextDisable, setEditTextDisable] = useState(false)
  const [editMediaDisable, setEditMediaDisable] = useState(false)
  const defineUserTypes = () => {
    //function for define user rights for users
    // const resultArray = user?.roles?.permissions?.postCreation.map((name:any, id:any) => ({ id, name }));
    // setConditionalUserTypes(resultArray)

    if (editPost) {
      //if it is an edit post
      if (user?.admin) {
        //if the user is an admin
        setEditTypeDisable(false)
        setEditTopicDisable(false)
        setEditChannelDisable(false)
        setEditTextDisable(false)
        setEditMediaDisable(false)
      } else {
        if (
          user?.roles?.name?.toUpperCase() === 'opinion maker'.toUpperCase()
        ) {
          setEditTypeDisable(false)
          setEditTopicDisable(false)
          setEditChannelDisable(false)
          setEditTextDisable(true)
          setEditMediaDisable(true)
        } else if (
          user?.roles?.name?.toUpperCase() === 'citizen'.toUpperCase()
        ) {
          setEditTypeDisable(false)
          setEditTopicDisable(false)
          setEditChannelDisable(false)
          setEditTextDisable(false)
          setEditMediaDisable(false)
        } else if (
          user?.roles?.name?.toUpperCase() === 'professional'.toUpperCase()
        ) {
          setEditTypeDisable(false)
          setEditTopicDisable(false)
          setEditChannelDisable(false)
          setEditTextDisable(false)
          setEditMediaDisable(false)
        }
      }
    }
  }

  useEffect(() => {
    defineUserTypes()
  }, [])

  const savePost = () => {
    clickSubmit()
  }

  const closeModal = () => {
    onCancel()
    updatePostDataClear()
  }

  const fileInputRef = useRef<HTMLInputElement>(null)
  const fileInputUpdateRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleClickUpdate = () => {
    if (fileInputUpdateRef.current) {
      fileInputUpdateRef.current.click()
    }
  }

  useEffect(() => {
    switch (type) {
      case 0:
        setpostTypeid('Fact') //set the type
        setDesc('Share factual elements with the world')
        break
      case 1:
        setpostTypeid('Research')
        setDesc('Tell the world what info and conclusions you have gathered')
        break
      case 2:
        setpostTypeid('Opinion')
        setDesc('Tell the world what you believe in')
        break
      case 3:
        setpostTypeid('Rumor')
        setDesc('Tell the world what you heard')
        break
      case 4:
        setpostTypeid('Humor')
        setDesc('Hahahahaha')
        break
      default:
        setpostTypeid('-')
        setDesc('-')
    }
  }, [type, updatePostTypeId])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const makeNull = () => {
    setSelectedItemToDisplay(null)
    setCountryToDisplay(null)
  }

  const [postEditMessage, setPostEditMessage] = useState(null)
  const [postEditReasonModalVisible, setPostEditReasonModalVisible] =
    useState(false)

  const submitEditPost = () => {
    updatePost(postEditMessage)
  }

  const [updateTypeModalFlag, setUpdateTypeModalFlag] = useState(false)
  const [updateTopicModalFlag, setUpdateTopicModalFlag] = useState(false)
  const [updateChannelModalFlag, setUpdateChannelModalFlag] = useState(false)
  const [updateTextModalFlag, setUpdateTextModalFlag] = useState(false)
  const [updateImageModalFlag, setUpdateImageModalFlag] = useState(false)

  const typeClicked = () => {
    setUpdateTypeModalFlag(true)
    setUpdateTopicModalFlag(false)
    setUpdateChannelModalFlag(false)
    setUpdateTextModalFlag(false)
    setUpdateImageModalFlag(false)
  }

  const topicClicked = () => {
    setUpdateTypeModalFlag(false)
    setUpdateTopicModalFlag(true)
    setUpdateChannelModalFlag(false)
    setUpdateTextModalFlag(false)
    setUpdateImageModalFlag(false)
  }

  const channelClicked = () => {
    setUpdateTypeModalFlag(false)
    setUpdateTopicModalFlag(false)
    setUpdateChannelModalFlag(true)
    setUpdateTextModalFlag(false)
    setUpdateImageModalFlag(false)
  }

  const textClicked = () => {
    setUpdateTypeModalFlag(false)
    setUpdateTopicModalFlag(false)
    setUpdateChannelModalFlag(false)
    setUpdateTextModalFlag(true)
    setUpdateImageModalFlag(false)
  }

  const imageClicked = () => {
    setUpdateTypeModalFlag(false)
    setUpdateTopicModalFlag(false)
    setUpdateChannelModalFlag(false)
    setUpdateTextModalFlag(false)
    setUpdateImageModalFlag(true)
  }

  return (
    <>
      {postEditReasonModalVisible && (
        <PostEditDeleteReasonModal
          setPostDeleteEditMessage={setPostEditMessage}
          setPostEditDeleteReasonModalVisible={setPostEditReasonModalVisible}
          postEditDeleteReasonModalVisible={postEditReasonModalVisible}
          loading={updatePostLoading}
          flag={'edit'}
          onSubmit={() => submitEditPost()}
        />
      )}
      <div
        style={{
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 800,
          position: 'relative',
          overflow: 'hidden',
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {type === -1 && (
          <div
            style={{
              textAlign: 'start',
              paddingRight: 80,
              paddingLeft: 80,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <text style={{ fontSize: 24, fontWeight: 'bold' }}>
              Choose Type
            </text>
            <Spacer height={10} />
            <div style={{ height: 1, width: 130, background: '#000000' }}></div>
            <Spacer height={20} />
            <div style={{ flexDirection: 'column' }}>
              {conditionalUserTypes?.map((type: any) => (
                <div
                  key={type?.key}
                  className={'option'}
                  onClick={() => {
                    setType(type?.id)
                  }}
                >
                  <text>{type?.name}</text>
                  <Spacer height={20} />
                </div>
              ))}
            </div>
          </div>
        )}

        {type !== -1 && (
          <div
            style={{
              textAlign: 'start',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 30,
              paddingBottom: 30,
              overflow: 'hidden',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  flex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {editPost && !editPostRules?.type?.allowed && (
                  <OverlayError
                    modalClose={typeClicked}
                    toggleFlag={updateTypeModalFlag}
                    errorMessage={editPostRules?.type?.reason}
                  />
                )}
              </div>
              <div
                style={{
                  flex: 80,
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: '5px',
                }}
              >
                <text style={{ fontSize: 16, paddingTop: '2px' }}>
                  {editPost ? 'Edit' : 'Create news'}
                </text>
                <Spacer width={10} />
                {editPost ? (
                  <Select
                    size={'small'}
                    disabled={editTypeDisable}
                    style={{
                      fontSize: '16px',
                      color: '#000',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                      pointerEvents: !editPostRules?.type?.allowed
                        ? 'none'
                        : 'auto',
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={updatePostTypeId}
                    label="City"
                    variant="standard"
                    onChange={(event) => {
                      setUpdatePostTypeId(Number(event?.target?.value))
                    }}
                  >
                    {conditionalUserTypes?.map((type: any) => (
                      <MenuItem key={type?.id} value={type?.id}>
                        {type?.name?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    size={'small'}
                    style={{ fontWeight: 'bold', fontSize: 24, marginTop: -5 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="City"
                    variant="standard"
                    onChange={(event) => {
                      setType(Number(event.target.value))
                    }}
                  >
                    {conditionalUserTypes?.map((type: any) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              <div style={{ flex: 10 }}></div>
            </div>
            <Spacer height={20} />
            {editPost ? (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    flex: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!editPostRules?.topic?.allowed && (
                    <OverlayError
                      modalClose={topicClicked}
                      toggleFlag={updateTopicModalFlag}
                      errorMessage={editPostRules?.topic?.reason}
                    />
                  )}
                </div>
                {selectedItemToDisplay ? (
                  <div
                    style={{
                      background: '#D9D9D9',
                      display: 'flex',
                      borderRadius: 6,
                      flex: 80,
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ padding: 12 }}>
                      <text
                        style={{ textAlign: 'start' }}
                        className={'topic-location-text-pcc'}
                      >
                        {selectedItemToDisplay}
                      </text>
                      {/*<p style={{textAlign: 'start'}}>{countryToDisplay ? countryToDisplay : null}</p>*/}
                    </div>
                    <div>
                      {!editTopicDisable && editPostRules?.topic?.allowed && (
                        <img
                          style={{
                            height: 16,
                            width: 16,
                            marginRight: 5,
                            marginTop: 5,
                          }}
                          src={wrongBoldBlack}
                          onClick={() => {
                            makeNull()
                          }}
                          alt="ksdsd"
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ flex: 80 }}>
                    <AutoCompleteTwoText
                      firstDataListTopic="Locations..." //topic for first data list
                      firstDataList={locationsData} //data for the first data list
                      secondDataListTopic="Topics..." //topic for second data list
                      secondDataList={topic} //data for the second data list
                      InputPlaceHolder="Select location or topic..."
                      setFirstDataItem={setSelectedLocationFrmAutoComp}
                      setSelectLocation={setSelectLocation}
                      setCountryToDisplay={setCountryToDisplay}
                      setSecondDataItem={setSelectedTopicFrmAutoComp} //setUpdateTopicId
                      searchText={setLocations}
                      clearTheSelections={
                        selectedTopicFrmAutoComp && selectedLocationFrmAutoComp
                          ? true
                          : false
                      }
                      setSelectTopicFromModal={setUpdateTopicId}
                      setSelectedItemToDisplay={setSelectedItemToDisplay}
                      place="article"
                      firstDataListloading={locationLoading}
                      secondDataListloading={topicLoading}
                    />
                  </div>
                )}
                <div style={{ flex: 10 }}></div>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 10 }}></div>

                <div
                  style={{
                    background: '#D9D9D9',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 20,
                    flex: 80,
                    borderRadius: 4,
                  }}
                >
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <text style={{ textAlign: 'start' }}>{locationId}</text>
                  </div>
                </div>
                <div style={{ flex: 10 }}></div>
              </div>
            )}

            {clickedPost && (
              <>
                <Spacer height={20} />
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 11 }}></div>
                  <div
                    style={{
                      background: '#D9D9D9',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 20,
                      borderRadius: 4,
                      flex: 82,
                    }}
                  >
                    <text
                      style={{
                        textAlign: 'start',
                        fontWeight: 'bold',
                      }}
                    >{`Contrast to another ${parentPostTypeId} post`}</text>
                  </div>
                  <div style={{ flex: 11 }}></div>
                </div>
              </>
            )}
            <Spacer height={15} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/*<text style={{textAlign: 'start', fontWeight: 'bold'}}>{name}</text>*/}
              {/*{!org && (<text style={{textAlign: 'start', fontWeight: 'bold'}}>Personal</text>)}*/}

              {editPost ? (
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      flex: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {!editPostRules?.channel?.allowed && (
                      <OverlayError
                        modalClose={channelClicked}
                        toggleFlag={updateChannelModalFlag}
                        errorMessage={editPostRules?.channel?.reason}
                      />
                    )}
                  </div>
                  <div style={{ flex: 80 }}>
                    <div
                      className={'channel-picker-wrapper-pc'}
                      style={{
                        pointerEvents:
                          editChannelDisable || !editPostRules?.channel?.allowed
                            ? 'none'
                            : 'auto',
                      }}
                    >
                      <ChannelPicker
                        editPost={true} //edit channel picker
                        orgName={postDetailsTobeEdited?.organization?.name}
                        orgType={postDetailsTobeEdited?.organization?.type}
                        orgImage={postDetailsTobeEdited?.organization?.imageUrl}
                        orgId={postDetailsTobeEdited?.organization?.id}
                        setOrg={setUpdateOrganizationId}
                        articlePicker={false}
                        width={'100%'}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 10 }}></div>
                </div>
              ) : (
                <div className={'flex-class-post-create-content'}>
                  <div className={'flex-left-twenty'}></div>
                  <div className={'flex-eighteen'}>
                    <div className={'channel-picker-wrapper-pc'}>
                      {showOrganizationSelection && (
                        <ChannelPicker
                          articlePicker={false}
                          setOrg={setOrg}
                          width={'100%'}
                        />
                      )}
                    </div>
                  </div>
                  <div className={'flex-right-twenty'}></div>
                </div>
              )}

              <Spacer height={15} />
            </div>

            <div style={{ flex: 1, overflow: 'scroll', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {editPost ? (
                  <div className={'flex-class-post-create-content'}>
                    <div className={'flex-left-twenty justify-align-center'}>
                      {!editPostRules?.text?.allowed && (
                        <OverlayError
                          modalClose={textClicked}
                          toggleFlag={updateTextModalFlag}
                          errorMessage={editPostRules?.text?.reason}
                        />
                      )}
                    </div>
                    <div
                      className={'flex-eighteen'}
                      style={{
                        pointerEvents: !editPostRules?.text?.allowed
                          ? 'none'
                          : 'auto',
                      }}
                    >
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        style={{
                          fontSize: 20,
                          background: '#D9D9D9',
                          width: '100%',
                          borderRadius: '6px',
                        }}
                        size={'small'}
                        defaultValue={postDetailsTobeEdited?.description}
                        rows={4}
                        disabled={editTextDisable}
                        variant={'filled'}
                        onChange={handleTextUpdateWithLineBreaks}
                        multiline
                      />
                    </div>
                    <div className={'flex-right-twenty'}></div>
                  </div>
                ) : (
                  <div className={'flex-class-post-create-content'}>
                    <div className={'flex-left-twenty'}></div>
                    <div className={'flex-eighteen'}>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        style={{
                          fontSize: 20,
                          background: '#D9D9D9',
                          width: '100%',
                          borderRadius: '6px',
                        }}
                        size={'small'}
                        label={desc}
                        rows={4}
                        variant={'filled'}
                        onChange={handleTextWithLineBreaks}
                        multiline
                      />
                    </div>
                    <div className={'flex-right-twenty'}></div>
                  </div>
                )}

                <Spacer height={30} />
                <div>
                  {editPost ? (
                    <div
                      style={{
                        pointerEvents: editMediaDisable ? 'none' : 'auto',
                        height: '200px',
                      }}
                      className={'flex-class-post-create-content'}
                    >
                      {/*edited post photo*/}
                      {updateMediaId && (
                        <>
                          <div
                            className={'flex-left-twenty justify-align-center'}
                          >
                            {!editPostRules?.media?.allowed && (
                              <OverlayError
                                modalClose={imageClicked}
                                toggleFlag={updateImageModalFlag}
                                errorMessage={editPostRules?.media?.reason}
                              />
                            )}
                          </div>
                          <div className={'flex-eighteen'}>
                            <div
                              className={
                                'flex-class-post-create-content flex-column-post-create-content'
                              }
                            >
                              <div style={{ alignItems: 'flex-end' }}>
                                {editPostRules?.media?.allowed && (
                                  <img
                                    style={{ height: 16, width: 16 }}
                                    src={wrongBoldBlack}
                                    onClick={() => {
                                      setUpdateMediaId()
                                      //console.log("jjjbjjb:::::::::::")
                                    }}
                                    alt="ksdsd"
                                  />
                                )}
                              </div>

                              <div>
                                <img //check image is there
                                  src={
                                    typeof updateMediaId === 'string'
                                      ? updateMediaId
                                      : URL.createObjectURL(updateMediaId)
                                  }
                                  style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    objectFit: 'fill',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={'flex-right-twenty'}></div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {image ? (
                        <div className={'flex-class-post-create-content'}>
                          <div className={'flex-left-twenty'}></div>
                          <div className={'flex-eighteen'}>
                            <img
                              src={URL.createObjectURL(image)}
                              style={{ width: '100%', borderRadius: '8px' }}
                            />
                          </div>
                          <div className={'flex-right-twenty'}></div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
            <Spacer height={10} />
            <Spacer height={3} />
            <div style={{ display: 'flex' }}>
              <div className={'flex-left-twenty'}></div>
              <div
                className={'flex-eighteen'}
                style={{ flexDirection: 'row', display: 'flex' }}
              >
                <div>
                  <label htmlFor="upload-button">
                    <>
                      {editPost ? (
                        <>
                          {!editMediaDisable &&
                            editPostRules?.media?.allowed && (
                              <input
                                type="file"
                                onChange={(event: any) => {
                                  setUpdateMediaId(event.target.files[0])
                                }}
                                id="imgs"
                                ref={fileInputUpdateRef}
                                style={{ display: 'none' }}
                              />
                            )}
                          <img
                            className={'img-button'}
                            style={{
                              height: 16,
                              width: 16,
                              background: editMediaDisable
                                ? '#D9D9D9'
                                : 'white',
                            }}
                            src={landscape}
                            alt="k"
                            onClick={handleClickUpdate}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            onChange={(event: any) => {
                              setImgae(event.target.files[0])
                            }}
                            id="imgs"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          />
                          <img
                            className={'img-button'}
                            style={{ height: 16, width: 16 }}
                            src={landscape}
                            alt="k"
                            onClick={handleClick}
                          />
                        </>
                      )}
                    </>
                  </label>
                  <br />
                </div>

                <div className={'flex-class-post-create-content'}>
                  <div>
                    <img
                      className={'img-button'}
                      style={{ height: 16, width: 16 }}
                      src={face}
                      alt=""
                    />
                    <img
                      className={'img-button'}
                      style={{ height: 16, width: 16 }}
                      src={gifImg}
                      alt=""
                    />
                  </div>

                  <div>
                    {updatePost ? (
                      <text
                        style={{
                          color:
                            updateDescription?.length > 299 ? 'red' : '#9D9D9D',
                        }}
                        className={'text-length-pc'}
                      >{`${updateDescription?.length}/300`}</text>
                    ) : (
                      <text
                        style={{
                          color: description?.length > 299 ? 'red' : '#9D9D9D',
                        }}
                        className={'text-length-pc'}
                      >{`${description?.length}/300`}</text>
                    )}
                  </div>
                </div>
              </div>
              <div className={'flex-right-twenty'}></div>
            </div>

            <Spacer height={15} />
            {errorMessageFlag ? (
              <div
                style={{
                  background: '#E5F6FD',
                  display: 'flex',
                  borderRadius: 6,
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ padding: 12 }}>
                  <text
                    style={{ textAlign: 'start', color: '#67CBF7' }}
                    className={'topic-location-text-pcc'}
                  >
                    {updatePostFailureMessage}
                  </text>
                </div>
                <div>
                  <img
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: 5,
                      marginTop: 5,
                    }}
                    src={wrongBoldBlack}
                    onClick={() => {
                      setErrorMessageFlag(false)
                    }}
                    alt="ksdsd"
                  />
                </div>
              </div>
            ) : null}
            <Spacer height={15} />
            <div className={'flex-class-post-create-content'}>
              <div className={'flex-left-twenty'}></div>
              <div
                style={{
                  flex: 80,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button title={'Cancel'} onClick={() => closeModal()} />
                {editPost ? (
                  <Button
                    loading={updatePostLoading}
                    disable={updateDescription?.length > 299}
                    title={'Confirm'}
                    onClick={() =>
                      setPostEditReasonModalVisible(!postEditReasonModalVisible)
                    }
                  />
                ) : (
                  <Button
                    disable={description?.length > 299}
                    title={'Post'}
                    onClick={() => savePost()}
                  />
                )}
              </div>
              <div className={'flex-right-twenty'}></div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default PostCreateContent
