import { create } from 'zustand'

export const useSignupFormData = create((set, get: any) => ({
  data: {},
  setValue: (key: string, value: any) => {
    const data = get().data
    set({
      data: {
        ...data,
        [key]: value,
      },
    })
  },
  setMultipleValues: (obj: any) => {
    const data = get().data
    set({
      data: {
        ...data,
        ...obj,
      },
    })
  },
}))
