import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export default function AutoCompleteInput({
  data = [],
  placeholder = '',
  selectedItems = [],
  setSelectedItems,
}: any) {
  // const [organizations, setOrganizations] = useState(data)
  const onChange = (event: any, values: any) => {
    setSelectedItems(values.map((val: any) => val.id))
  }

  return (
    <Autocomplete
      multiple
      limitTags={2}
      id="multiple-limit-tags"
      options={data}
      onChange={onChange}
      getOptionLabel={(option: any) => option.name}
      renderInput={(params) => (
        <TextField {...params} name={placeholder} placeholder={placeholder} />
      )}
      sx={{ width: '500px' }}
    />
  )
}
