import axios from 'axios'
import { Dispatch } from 'react'

import {
  GET_ALL_ORGANIZATIONS,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_FAILUER,
  //create organization
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  //add users for organization
  ADD_USER_FOR_ORGANIZATION_REQUEST,
  ADD_USER_FOR_ORGANIZATION_SUCCESS,
  ADD_USER_FOR_ORGANIZATION_FAILURE,
  DELETE_USER_FOR_ORGANIZATION_SUCCESS,
  DELETE_USER_FOR_ORGANIZATION_FAILURE,
  DELETE_USER_FOR_ORGANIZATION_REQUEST,

  //select user to delete from organization
  SELECT_USER_TO_DELETE_FROM_ORGANIZATION,
  UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST,
  UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS,
  UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE,

  //update organization details,
  UPDATE_ORGANIZATION_DETAILS_REQUEST,
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  UPDATE_ORGANIZATION_DETAILS_FAILURE,
} from './types'
import { getAuth } from '../../utils/auth'
import { getMe } from '../login/actions'
import {
  GET_ORG_POST_FAILURE,
  GET_ORG_POST_REQUEST,
  GET_ORG_POST_SUCCESS,
} from '../posts/types'
import { rehydrateTestStyles } from 'styled-components/native/dist/test/utils'
import { type } from 'os'

const transport = axios.create({
  withCredentials: false,
})

export const getAllOrganizationsSuccess = (organizations: any) => {
  return {
    type: GET_ALL_ORGANIZATIONS,
    payload: organizations,
  }
}

export const getAllOrganizations = () => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/organization`, getAuth())
      .then((res) => {
        dispatch(getAllOrganizationsSuccess(res.data)) //open the progress
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const deleteOrganization = (id: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .delete(`${process.env.REACT_APP_BASE_URL}/organization/${id}`, getAuth())
      .then((res) => {
        // dispatch(getAllPostsForTopics(topicId))
        dispatch(getAllOrganizations())
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

//create organization actions start
export const createOrganizationRequest = () => {
  //create organization request
  return {
    type: CREATE_ORGANIZATION_REQUEST,
  }
}

export const createOrganizationSuccess = () => {
  //create organization success
  return {
    type: CREATE_ORGANIZATION_SUCCESS,
  }
}

export const createOrganizationFailure = () => {
  //create organization failure
  return {
    type: CREATE_ORGANIZATION_FAILURE,
  }
}
export const createOrganizations = (
  name: string,
  imageUrl: string,
  type: string,
  description: string,
  orgLink: string,
  userId: string,
  email: string,
  phone: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createOrganizationRequest())
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/organization`,
        {
          name: name,
          imageUrl: imageUrl,
          type: type,
          description: description,
          orgLink: orgLink,
          userId: userId,
          email: email,
          phone: phone,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(createOrganizationSuccess())
        dispatch(getAllOrganizations()) //open the progress
        dispatch(getMe())
      })
      .catch((error) => {
        if (error.response) {
          dispatch(createOrganizationFailure())
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getOrganizationSuccess = (org: any) => {
  return {
    type: GET_ORGANIZATION_SUCCESS,
    payload: org,
  }
}

export const getOrganizationFailure = () => {
  return {
    type: GET_ORGANIZATION_FAILURE,
  }
}

export const getOrganizationRequest = () => {
  return {
    type: GET_ORGANIZATION_REQUEST,
  }
}

export const getOrganization = (orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getOrganizationRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/organization/${orgId}`, getAuth())
      .then((res) => {
        dispatch(getOrganizationSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getOrganizationFailure())
          return
        }
      })
  }
}

export const getOrganizationArticleSuccess = (articles: any) => {
  return {
    type: GET_ARTICLES_SUCCESS,
    payload: articles,
  }
}

export const getOrganizationArticleFailure = () => {
  return {
    type: GET_ARTICLES_FAILUER,
  }
}

export const getOrganizationArticleRequest = () => {
  return {
    type: GET_ARTICLES_REQUEST,
  }
}

export const getOrganizationArticles = (orgId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getOrganizationArticleRequest())
    transport
      .get(
        `${process.env.REACT_APP_BASE_URL}/article/organization/${orgId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(getOrganizationArticleSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getOrganizationArticleFailure())
          return
        }
      })
  }
}

export const addUserForOrganizationSuccess = () => {
  return {
    type: ADD_USER_FOR_ORGANIZATION_SUCCESS,
  }
}

export const addUserForOrganizationFailure = () => {
  return {
    type: ADD_USER_FOR_ORGANIZATION_FAILURE,
  }
}

export const addUsersForOrganizationRequest = () => {
  return {
    type: ADD_USER_FOR_ORGANIZATION_REQUEST,
  }
}

export const addUsersForOrganization = (
  organizationMemberId: string,
  role: string,
  organizationId: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addUsersForOrganizationRequest())
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/organization/adduser`,
        {
          organizationMemberId: organizationMemberId,
          role: role,
          organizationId: organizationId,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(addUserForOrganizationSuccess())
        dispatch(getOrganization(organizationId))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(addUserForOrganizationFailure())
          return
        }
      })
  }
}

//delete user from organization

export const deleteUserFromOrganizationSuccess = () => {
  return {
    type: DELETE_USER_FOR_ORGANIZATION_SUCCESS,
  }
}

export const deleteUserFromOrganizationFailure = (errorMessage: any) => {
  return {
    type: DELETE_USER_FOR_ORGANIZATION_FAILURE,
    payload: errorMessage,
  }
}

export const deleteUserFromOrganizationRequest = () => {
  return {
    type: DELETE_USER_FOR_ORGANIZATION_REQUEST,
  }
}

export const deleteUserFromOrganization = (
  memberId: any,
  organizationId: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(deleteUserFromOrganizationRequest())
    transport
      .delete(
        `${process.env.REACT_APP_BASE_URL}/organization/deleteuser/${memberId}/${organizationId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(deleteUserFromOrganizationSuccess())
        dispatch(getOrganization(organizationId))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(deleteUserFromOrganizationFailure(error.response.data))
          return
        }
      })
  }
}

export const selectUserToDeleteFromOrganization = (user: any) => {
  return {
    type: SELECT_USER_TO_DELETE_FROM_ORGANIZATION,
    payload: user,
  }
}

export const updateUserTypeForOrganizationSuccess = () => {
  return {
    type: UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS,
  }
}

export const updateUserTypeForOrganizationRequest = () => {
  return {
    type: UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST,
  }
}

export const updateUserTypeForOrganizationFailure = () => {
  return {
    type: UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE,
  }
}

export const updateUserTypeForOrganization = (
  userIdToBeUpdate: any,
  roleToBeChanged: any,
  organizationId: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateUserTypeForOrganizationRequest())
    transport
      .put(
        `${process.env.REACT_APP_BASE_URL}/organization/edituser`,
        {
          organizationUserId: userIdToBeUpdate,
          roleToBeChanged: roleToBeChanged,
          organizationId: organizationId,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(updateUserTypeForOrganizationSuccess())
        dispatch(getOrganization(organizationId))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(updateUserTypeForOrganizationFailure())
          return
        }
      })
  }
}

//update organization details request
export const updateOrganizationDetailsRequest = () => {
  return {
    type: UPDATE_ORGANIZATION_DETAILS_REQUEST,
  }
}

export const updateOrganizationDetailsSuccess = () => {
  return {
    type: UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  }
}

export const updateOrganizationDetailsFailure = () => {
  return {
    type: UPDATE_ORGANIZATION_DETAILS_FAILURE,
  }
}

export const updateOrganizationDetails = (
  name: any,
  description: any,
  imageUrl: any,
  type: any,
  orgLink: any,
  email: any,
  organizationId: any,
  phone: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateOrganizationDetailsRequest())
    transport
      .put(
        `${process.env.REACT_APP_BASE_URL}/organization/updateorgdetails/${organizationId}`,
        {
          name,
          description,
          imageUrl,
          type,
          orgLink,
          email,
          phone,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(updateOrganizationDetailsSuccess())
        dispatch(getMe())
      })
      .catch((error) => {
        if (error.response) {
          dispatch(updateOrganizationDetailsFailure())
          return
        }
      })
  }
}
