import React, { useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import { Calendar } from '../../../shadcn/calendar'
import Button from '../../../Button'
import moment from 'moment'
import { DateCalendar, StaticDatePicker } from '@mui/x-date-pickers'

type Props = {
  trigger: React.ReactNode
  date?: Date
  setDate: (date: Date) => void
}

export default function DateField({ trigger, date, setDate }: Props) {
  const [open, setOpen] = useState(false)

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="h-fit w-fit p-0">
        {/* <Calendar
          mode="single"
          selected={date}
          onSelect={(date) => {
            setDate(date || new Date())
            setOpen(false)
          }}
          disabled={(date) => {
            return moment(date).isAfter(moment())
          }}
          initialFocus
        /> */}

        <DateCalendar
          // shouldDisableYear={(date) => {
          //   return
          // }}
          defaultValue={moment(date)}
          onChange={(value, state, view) => {
            // console.log({ state })
            setDate(value.toDate())

            if (state === 'finish') {
              setOpen(false)
            }
            // setOpen(false)
          }}
          disableFuture
        />
      </PopoverContent>
    </Popover>
  )
}
