function isToday(isoTimestamp: any) {
  const timestampDate = new Date(isoTimestamp)

  const now = new Date()

  // Get the start of the day (midnight) in local time
  const startOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  ).getTime()

  // Get the end of the day (11:59:59 PM) in local time
  const endOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    -1
  ).getTime()

  // Check if the parsed timestamp is within today's local time range
  return (
    timestampDate.getTime() >= startOfDay && timestampDate.getTime() <= endOfDay
  )
}

export default isToday
