import React from 'react'

type Props = {
  currentPostType?: string
  onClick: () => void
}

export default function PostTypeFilterWheelButton({
  currentPostType,
  onClick,
}: Props) {
  return (
    <button
      className="h-[104px] mx-mobile:w-[80px] w-[104px] mx-mobile:h-[80px] cursor-pointer"
      onClick={onClick}
    >
      <svg
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="72"
        fill="none"
        viewBox="0 0 72 72"
      >
        <g id="Check Navigator">
          <path
            id="Slice 1"
            fill={currentPostType === 'rumor' ? '#d5e7c4' : '#303655'}
            stroke="#FBFBFD"
            d="M7.595 55.794l.295.406.405-.297 28-20.5 1.234-.903H1v.5c0 7.49 2.185 14.723 6.595 20.794z"
          ></path>
          <path
            id="Slice 2"
            fill={currentPostType === 'research' ? '#d5e7c4' : '#303655'}
            stroke="#FBFBFD"
            d="M25.018 68.759l.476.154.154-.475 10.827-33.283.48-1.474-1.25.916-28 20.5-.402.294.292.403c4.398 6.057 10.283 10.642 17.423 12.965z"
          ></path>
          <path
            id="Slice 3"
            fill={currentPostType === 'fact' ? '#d5e7c4' : '#303655'}
            stroke="#FBFBFD"
            d="M47.155 68.975l.477-.154-.157-.477-11-33.5L36 33.397l-.475 1.447-11 33.5-.156.477.477.154c7.13 2.316 15.179 2.318 22.309 0z"
          ></path>
          <path
            id="Slice 4"
            fill={currentPostType === 'opinion' ? '#d5e7c4' : '#303655'}
            stroke="#FBFBFD"
            d="M64.904 55.794l.296-.406-.408-.294-28.5-20.5-1.246-.896.479 1.458 11 33.5.156.474.474-.154c7.14-2.32 13.35-7.126 17.75-13.182z"
          ></path>
          <path
            id="Slice 5"
            fill={currentPostType === 'humor' ? '#d5e7c4' : '#303655'}
            stroke="#FBFBFD"
            d="M71.5 35v-.5H34.449l1.259.906 28.5 20.5.404.29.293-.402C69.314 49.723 71.5 42.489 71.5 35z"
          ></path>
          <g id="Upper Half Circle">
            <mask id="path-7-inside-1_9125_10732" fill="#fff">
              <path d="M71 35.5a35 35 0 10-70 0h70z"></path>
            </mask>
            <path
              fill="#FBFBFD"
              stroke="#303655"
              strokeWidth="0"
              d="M71 35.5a35 35 0 10-70 0h70z"
              mask="url(#path-7-inside-1_9125_10732)"
            ></path>
          </g>
          <circle
            id="Center"
            cx="35.767"
            cy="35.001"
            r="13.333"
            fill="#FBFBFD"
            stroke="#303655"
          ></circle>
          <circle
            id="Outer"
            cx="36.25"
            cy="35.367"
            r="34.75"
            stroke="#303655"
          ></circle>
        </g>
      </svg>
    </button>
  )
}
