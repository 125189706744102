export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE'

export const USER_GET_REQUEST = 'USER_GET_REQUEST'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAILURE = 'USER_GET_FAILURE'

export const ACCOUNT_VERIFY_REQUEST = 'ACCOUNT_VERIFY_REQUEST'
export const ACCOUNT_VERIFY_SUCCESS = 'ACCOUNT_VERIFY_SUCCESS'
export const ACCOUNT_VERIFY_FAILURE = 'ACCOUNT_VERIFY_FAILURE'

export const GET_ALL_USERS = 'GET ALL USERS'

//get user details
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE'

//store user's temp details
export const USER_TEMP_ID_REQUEST = 'USER_TEMP_ID_REQUEST'
export const USER_TEMP_ID_SUCCESS = 'USER_TEMP_ID_SUCCESS'
export const USER_TEMP_ID_FAILUER = 'USER_TEMP_ID_FAILUER'

//store user's location
export const USER_LOCATION_STORE_REQUEST = 'USER_LOCATION_STORE_REQUEST'
export const USER_LOCATION_STORE_SUCCESS = 'USER_LOCATION_STORE_SUCCESS'
export const USER_LOCATION_STORE_FAILURE = 'USER_LOCATION_STORE_FAILURE'

//sign up

export const ADD_USER_CREDENTIALS = 'ADD_USER_CREDENTIALS'
export const ADD_USER_DETAILS = 'ADD_USER_DETAILS'

//UPDATE USER DETAILS
export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS'
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS'
export const UPDATE_USER_DETAILS_FAILURE = 'UPDATE_USER_DETAILS_FAILURE'
