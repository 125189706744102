// import Post from "../Post";
// import checkGrey from '../../assets/icons/check-grey.svg'
// import React, {useEffect, useRef, useState} from "react";
// import text from "../../assets/icons/text.svg";
// import {getRandomNumber} from "../../utils";
// import arrowUp from "../../assets/icons/arrow-up.svg";
// import {MAX_PHONE_BREAK_POINT,MIN_PHONE_BREAK_POINT,MAX_TABLET_BREAK_POINT} from "../../utils/constants"
//
// const HomePostDesktop = ({
//                       onClick, post = {}, setContrastsModalVisible,
//                       navigateToPostDetailWithPost,
//                       deletePost, userId,
//                       voteToggle,
//                       selectedTopic
//                   }: any) => {
//     const {con = []} = post || {}
//
//     const [parentHeight, setParentHeight] = useState(0)
//     const [contrastHeight, setContrastHeight] = useState(0)
//     const [contrastHGapWidth, setContrastHGapWidth] = useState(0)
//     const [contrastLineGap, setContrastLineGap] = useState(0)
//
//     const parentPostRef = useRef<HTMLDivElement>(null)
//     const contrastPostRef = useRef<HTMLDivElement>(null)
//     const contrastHGap = useRef<HTMLDivElement>(null)
//     const [lineGap, setLineGap] = useState(0)
//
//
//     useEffect(() => {
//         if (parentPostRef.current && contrastPostRef.current && contrastHGap.current) {
//             setParentHeight(parentPostRef.current.clientHeight)
//             setContrastHeight(contrastPostRef.current.clientHeight)
//             setContrastHGapWidth(contrastHGap.current.clientWidth)
//         }
//     })
//
//     useEffect(() => {
//         if (contrastHeight < parentHeight) {
//             setLineGap(contrastHeight)
//         } else {
//             setLineGap(parentHeight)
//         }
//     }, [parentHeight, contrastHeight, contrastHGapWidth])
//
//     useEffect(() => {
//         if (post && post.organization) {
//             setContrastLineGap(128)
//         } else {
//             setContrastLineGap(110)
//         }
//     }, [post])
//
//     function getWindowSize() {
//         const {innerWidth, innerHeight} = window;
//         return {innerWidth, innerHeight};
//     }
//
//     const [windowSize, setWindowSize] = useState(getWindowSize());
//
//     useEffect(() => {
//         function handleWindowResize() {
//             setWindowSize(getWindowSize());
//         }
//
//         window.addEventListener('resize', handleWindowResize);
//
//         return () => {
//             window.removeEventListener('resize', handleWindowResize);
//         };
//     }, []);
//
//     const showContrast = () => {
//
//         const windowSizeAllows = windowSize.innerWidth > 1570
//
//         const hasContrasts = con && con.length > 0
//         return (hasContrasts && windowSizeAllows)
//     }
//
//
//     return (
//         <div style={{
//             position: 'relative',
//             display: 'flex',
//             flexDirection: 'row',
//             flex: 1,
//             overflowX:"hidden",
//            // overflowY:"hidden"
//         }}>
//             <div style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 flex: 58,
//                 justifyContent: 'center',
//                 alignItems:'flex-start'
//             }}>
//                 <div style={{flex: 1}}></div>
//                 <div ref={parentPostRef}
//                      style={{display: 'flex', alignItems: 'center', justifyContent: 'center',zIndex:2000}}>
//                     <Post
//                         selectedTopic={selectedTopic}
//                         deletePost={deletePost}
//                         post={post}
//                         onClick={onClick}
//                         setContrastsModalVisible={setContrastsModalVisible}
//                         // userId={userId}
//                         voteToggle={voteToggle}
//                         isPostHave={con.length > 0 && windowSize.innerWidth < 1500}
//
//                     />
//
//                 </div>
//                 <div style={{
//                     flex: 1,
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     display: 'flex',
//                 }}>
//                     {showContrast() &&
//                       //  (   (windowSize.innerWidth > MAX_TABLET_BREAK_POINT) ?
//                             <div ref={contrastHGap} style={{
//                                 zIndex: 20,
//                                 flex: 1, height: '100%',
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 position: 'relative'
//                             }}>
//
//                                 <div style={{height: contrastLineGap}}></div>
//                                 <div style={{
//                                     flexDirection: 'row',
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     zIndex: 100,
//                                     marginLeft:'20px',
//                                 }}>
//                                           <div style={{flex: 1, background: '#D9D9D9', height: 1, width: '100%'}}></div>
//                                     <img style={{height: 22, width: 22,}} src={checkGrey} alt=""/>
//                                 </div>
//                                 <div style={{flex: 1}}></div>
//                                 <div style={{position: 'absolute', display: 'block',}}>
//                                     <div
//                                         style={{
//                                             background: 'white',
//                                             display: 'flex', flex: 1,
//                                             height: lineGap, width: contrastHGapWidth,
//                                         }}>
//                                     </div>
//                                 </div>
//                             </div>
//                         //: <></>
//                     //    ) : null
//                     }
//                 </div>
//
//             </div>
//             <div style={{flex: 42, display: 'flex', alignItems: 'start'}}>
//                     {(showContrast()) && (
//                         <div ref={contrastPostRef} style={{
//                             position: 'static',
//                             display: 'block',
//                             flexDirection: 'column',
//                             alignItems: 'start',
//                             marginLeft:'8%'
//                         }}>
//                             <Post voteToggle={voteToggle} deletePost={deletePost} contrast post={con[0]}
//                                   onClick={() => navigateToPostDetailWithPost(con[0])}/>
//
//                         </div>
//                     )}
//             </div>
//             {/*{con && con.length > 0 && (windowSize.innerWidth <= 1570) ? (*/}
//             {/*            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>*/}
//             {/*                <div style={{display:'flex',flexDirection:'column'}}>*/}
//             {/*                 <div style={{height: '95px',borderLeft:'1px solid #9D9D9D',marginBottom:'10px',marginLeft:'10px'}}></div>*/}
//             {/*                    <img style={{height: 22, width: 22,}} src={checkGrey} alt=""/>*/}
//
//             {/*                </div>*/}
//             {/*                <div style={{marginLeft:windowSize.innerWidth < MAX_PHONE_BREAK_POINT ? '30px' : '30px'}}>*/}
//             {/*                    <Post voteToggle={voteToggle} deletePost={deletePost} contrast post={con[0]}*/}
//             {/*                        onClick={() => navigateToPostDetailWithPost(con[0])}/>*/}
//             {/*                </div>*/}
//             {/*            </div>*/}
//             {/*    ) : null}*/}
//
//         </div>)
// }
//
// export default HomePostDesktop

import Post from '../Post'
import checkGrey from '../../assets/icons/check-grey.svg'
import React, { useEffect, useRef, useState } from 'react'
import text from '../../assets/icons/text.svg'
import { getRandomNumber } from '../../utils'
import arrowUp from '../../assets/icons/arrow-up.svg'

const HomePostDesktop = ({
  onClick,
  post = {},
  setContrastsModalVisible,
  navigateToPostDetailWithPost,
  deletePost,
  userId,
  voteToggle,
  selectedTopic,
  addPostsToRedux,
  user,
  alarmToggle,
  selectPostDetails,
  setEditPostModalVisible,
  postEditRules,
}: any) => {
  const { con = [] } = post || {}

  const [parentHeight, setParentHeight] = useState(0)
  const [contrastHeight, setContrastHeight] = useState(0)
  const [contrastHGapWidth, setContrastHGapWidth] = useState(0)
  const [contrastLineGap, setContrastLineGap] = useState(0)

  const parentPostRef = useRef<HTMLDivElement>(null)
  const contrastPostRef = useRef<HTMLDivElement>(null)
  const contrastHGap = useRef<HTMLDivElement>(null)
  const [lineGap, setLineGap] = useState(0)

  useEffect(() => {
    if (
      parentPostRef.current &&
      contrastPostRef.current &&
      contrastHGap.current
    ) {
      setParentHeight(parentPostRef.current.clientHeight)
      setContrastHeight(contrastPostRef.current.clientHeight)
      setContrastHGapWidth(contrastHGap.current.clientWidth)
    }
  })

  useEffect(() => {
    if (contrastHeight < parentHeight) {
      setLineGap(contrastHeight)
    } else {
      setLineGap(parentHeight)
    }
  }, [parentHeight, contrastHeight, contrastHGapWidth])

  useEffect(() => {
    if (post && post.organization) {
      setContrastLineGap(128)
    } else {
      setContrastLineGap(110)
    }
  }, [post])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const showContrast = () => {
    const windowSizeAllows = windowSize.innerWidth > 1500

    const hasContrasts = con && con.length > 0
    return hasContrasts && windowSizeAllows
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 58,
          justifyContent: 'center',
        }}
      >
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '9px',
            justifyContent: 'center',
            // zIndex: 200
          }}
        >
          <Post
            selectedTopic={selectedTopic}
            deletePost={deletePost}
            post={post}
            onClick={onClick}
            setContrastsModalVisible={setContrastsModalVisible}
            user={user}
            voteToggle={voteToggle}
            addPostsToRedux={addPostsToRedux}
            alarmToggle={alarmToggle}
            selectPostDetails={selectPostDetails}
            setEditPostModalVisible={setEditPostModalVisible}
            postEditRules={postEditRules}
          />
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {showContrast() && (
            <div
              ref={contrastHGap}
              style={{
                zIndex: 20,
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                // marginTop: -100,
                marginLeft: 10,
                background: 'white',
              }}
            >
              <div style={{ height: contrastLineGap }}></div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  zIndex: 100,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    background: '#D9D9D9',
                    height: 1,
                    width: '100%',
                  }}
                ></div>
                <img style={{ height: 22, width: 22 }} src={checkGrey} alt="" />
              </div>
              <div style={{ flex: 1 }}></div>
              <div
                style={{
                  position: 'absolute',
                  display: 'block',
                  marginTop: '35px',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    display: 'flex',
                    flex: 1,
                    height: lineGap,
                    width: contrastHGapWidth,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ flex: 42, display: 'flex', alignItems: 'start' }}>
        {showContrast() && (
          <div
            ref={contrastPostRef}
            style={{
              position: 'absolute',
              display: 'block',
              flexDirection: 'column',
              alignItems: 'start',
              marginLeft: 50,
              marginTop: -(windowSize.innerWidth * 0.022), // reason - in order to responsive for larger desktop(make it zoom out and zoom in)
            }}
          >
            <Post
              alarmToggle={alarmToggle}
              addPostsToRedux={addPostsToRedux}
              voteToggle={voteToggle}
              deletePost={deletePost}
              selectPostDetails={selectPostDetails}
              contrast
              post={con[0]}
              user={user}
              onClick={() => navigateToPostDetailWithPost(con[0])}
              setEditPostModalVisible={setEditPostModalVisible}
              postEditRules={postEditRules}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default HomePostDesktop
