import axios from 'axios'
import { ADD_ARTICLE } from './types'
import { Dispatch } from 'react'
import { getAuth } from '../../utils/auth'
import { getAllTopics } from '../topics/actions'
import { getAllPostsForTopics } from '../posts/actions'
import { getTopicArticles } from '../article/actions'

const transport = axios.create({
  withCredentials: false,
})

export const addArticles = (artcileObj: any) => {
  const { topicId } = artcileObj

  if (topicId === 'local') {
    delete artcileObj.topicId
  }

  return (dispatch: Dispatch<any>) => {
    transport
      .post(`${process.env.REACT_APP_BASE_URL}/article`, artcileObj, getAuth())
      .then((res) => {
        dispatch(getAllPostsForTopics(topicId))
        dispatch(getTopicArticles(topicId))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}
