import React, { useCallback, useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import { Calendar } from '../../../shadcn/calendar'
import { Input } from '../../../shadcn/input'
import Button from '../../../Button'
import debounce from '../../../../utils/debounce'
import { searchUsers } from '../../../../services/user'
import PartyCard from '../AddTopic/PartyCard'
import { User } from '../../../../types/user'
import Loading from '../../../Loading'

type Props = {
  trigger: React.ReactNode
  onSelectUser: (userId: string) => void
  onSetCustomUser: (user: string) => void
}

export default function SearchUserField({
  trigger,
  onSetCustomUser,
  onSelectUser,
}: Props) {
  const [fieldValue, setFieldValue] = useState('')
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  // const [showCustomCreate, setShowCustomCreate] = useState(false)
  // console.log('🚀 ~ SearchUserField ~ users:', users)

  const debouncedOnChange = useCallback(
    debounce((value) => {
      if (value) {
        setLoading(true)
        searchUsers({
          text: value,
        })
          .then((data) => {
            setUsers(data)
            // if (data.length <= 0) {
            //   setShowCustomCreate(true)
            // }
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setUsers([])
      }
    }, 300),
    []
  )

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div className="w-[518px] px-5 py-5">
          <Input
            value={fieldValue}
            className="h-[29px]"
            placeholder="Search User"
            onChange={(event) => {
              setFieldValue(event.target.value)
              debouncedOnChange(event.target.value)
            }}
          />

          {loading && (
            <div className="mt-5 flex justify-center">
              <Loading />
            </div>
          )}

          {/* User list */}
          <div className="mt-5">
            {users.map((x) => {
              return (
                <PartyCard
                  name={x.name}
                  profileImageUrl={x.imageUrl}
                  roleName={x.roles?.name}
                  location={x.userLocation?.city}
                  organization={x.organizationUsers?.[0]?.name}
                  onClickCard={() => {
                    onSelectUser(x.id)
                    setOpen(false)
                  }}
                  isVerified={x?.verified}
                  isPublic={x?.public}
                />
              )
            })}
          </div>

          {fieldValue.length > 0 && (
            <Button
              className="mt-5"
              onClick={() => {
                onSetCustomUser(fieldValue)
                setOpen(false)
              }}
            >
              Set Custom User: {fieldValue}
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}
