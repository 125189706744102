import axios from 'axios'
import { publicAxios } from '../utils/authAxios'

export async function getPostsOfATopicPublic(topicId: string) {
  const res = await publicAxios.post(`/v2/public/getPostsOfATopic`, {
    topicId: topicId,
  })

  return res.data as any
}

export async function getATopic(topicId: string) {
  const res = await publicAxios.post(`/v2/public/getATopic`, {
    topicId: topicId,
  })

  return res.data as any
}

export async function getArticlesOfATopic(topicId: string) {
  const res = await publicAxios.post(`/v2/public/getArticlesOfATopic`, {
    topicId: topicId,
  })

  return res.data as any
}

export async function getPostWithTrails(postId: string) {
  const res = await publicAxios.post(`/v2/public/getPostWithTrail`, {
    postId: postId,
  })

  return res.data as any
}

export async function getContrastsOfAPost(postId: string) {
  const res = await publicAxios.post(`/v2/public/getContrasts`, {
    postId: postId,
  })

  return res.data as any
}

export async function getCommentsOfAPost(postId: string) {
  const res = await publicAxios.post(`/v2/public/getComments`, {
    postId: postId,
  })

  return res.data as any
}

export async function getOneArticle(id: string) {
  const res = await publicAxios.post(`/v2/public/getOneArticle`, {
    id,
  })

  return res.data as any
}
