import React from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './shadcn/tooltip'

type Props = {
  trigger: React.ReactNode
  tooltip: string | React.ReactNode
  contentClassName?: string
}

export default function TooltipComp({
  trigger,
  tooltip,
  contentClassName,
}: Props) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={50} defaultOpen={false}>
        <TooltipTrigger className="h-fit" asChild>
          {trigger}
        </TooltipTrigger>
        <TooltipContent className={contentClassName} align="start">
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
