import React from 'react'
import './index.css'

import Spacer from '../Spacer'
import OnBoardButton from '../OnBoardButton'

const UserTypeNoticeModal = ({ submitNotOnOpinionMaker }: any) => {
  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 292,
          height: 255,
          padding: 26,
          marginBottom: '50px',
        }}
      >
        <div>
          <div style={{ background: '#FBFBFD' }}>
            <p className={'body-text-style'}>
              Your request to become a “Citizen” or “Professional” has been
              noted and will be processed. You might receive some questions
              through email going forward.
            </p>

            <p className={'body-text-style'} style={{ marginTop: '20px' }}>
              Please note that your user type will be “Opinion maker” in the
              meantime.
            </p>

            <Spacer height={25} />

            <OnBoardButton
              width={'248px'}
              buttonName={'Continue'}
              disabled={false}
              onClick={() => {
                submitNotOnOpinionMaker()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserTypeNoticeModal
