import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components-v2/shadcn/dialog'

type Props = {
  trigger?: React.ReactNode
  cb?: () => void
  open: boolean
  setOpen: (open: boolean) => void
  onTypeSelect?: (type: string) => void
  postTypes: string[]
}

// const postTypes = ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']

export default function EntryDialog({
  trigger,
  cb,
  open,
  setOpen,
  onTypeSelect,
  postTypes,
}: Props) {
  // const [openPost, setOpenPost] = useState(false)

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent className=" max-w-[400px] px-14 py-14 max-h-screen overflow-y-auto overflow-x-visible mx-mobile:h-[100dvh]">
          <div>
            <h1 className="text-2xl font-bold">Choose Type</h1>
            <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
            <div className="flex flex-col gap-2 mt-4">
              {postTypes.map((x) => {
                return (
                  <div
                    key={x}
                    className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                    onClick={() => {
                      onTypeSelect && onTypeSelect(x)
                      // setPostDialogOpen(true)
                      // setType(x)
                      // setOpenPost(true)
                      // setOpenEntry(false)
                    }}
                  >
                    {x}
                  </div>
                )
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* <AddQuote cb={cb} setOpen={setOpenQuote} open={openQuote} />
      <AddTopic cb={cb} setOpen={setOpenTopic} open={openTopic} />
      <AddArticle setOpen={setOpenArticle} open={openArticle} /> */}
    </div>
  )
}
