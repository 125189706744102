import axios from 'axios'
import {
  USER_GET_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  GET_ALL_USERS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  USER_TEMP_ID_SUCCESS,
  USER_TEMP_ID_REQUEST,
  USER_TEMP_ID_FAILUER,
  USER_LOCATION_STORE_REQUEST,
  USER_LOCATION_STORE_FAILURE,
  USER_LOCATION_STORE_SUCCESS,
  ADD_USER_CREDENTIALS,
  ADD_USER_DETAILS,

  //update user details
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
} from './types'
import { Dispatch } from 'react'
import { getAuth } from '../../utils/auth'

// axios.defaults.withCredentials = true;

const transport = axios.create({
  withCredentials: false,
})

export const getMeRequest = () => {
  return {
    type: USER_GET_REQUEST,
  }
}

export const getMeSuccess = (user: any) => {
  return {
    type: USER_GET_SUCCESS,
    payload: user,
  }
}

export const getMeFailure = (error: any) => {
  return {
    type: USER_GET_FAILURE,
    payload: error,
  }
}

export const getMe = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getMeRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/user/me`, getAuth())
      .then((res) => {
        dispatch(getMeSuccess(res.data))
      })
      .catch((error) => {
        // localStorage.setItem('token', '')
        if (error.response) {
          dispatch(getMeFailure(error.response.data))
          return
        }
        dispatch(getMeFailure('something went wrongsds'))
      })
  }
}

export const loginRequest = () => {
  return {
    type: USER_LOGIN_REQUEST,
  }
}

export const loginSuccess = () => {
  return {
    type: USER_LOGIN_SUCCESS,
  }
}

export const loginFailure = (error: any) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error,
  }
}

export const login = (name: string, password: string) => {
  return (dispatch: any) => {
    dispatch(loginRequest())
    transport
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        email: name,
        password: password,
      })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token)
          dispatch(loginSuccess())
          dispatch(getMe())
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch(loginFailure(error.response.data))
          return error
        }

        dispatch(loginFailure(error.response.data))
      })
  }
}

export const userCreate = (
  name: any,
  password: any,
  email: any,
  verified: boolean,
  publicchecked: boolean,
  imageUrl: any,
  type: any,
  location: any,
  description: any,
  placeID: any,
  locationName: any,
  geoJson: any,
  address: any,
  phoneNumber: any,
  birthday: any
) => {
  return (dispatch: any) => {
    dispatch(loginRequest())
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/create`,
        {
          name: name,
          password: password,
          email: email,
          verified: verified,
          public: publicchecked,
          imageUrl: imageUrl,
          type: type,
          location: location,
          description: description,
          placeId: placeID,
          locationName: locationName,
          geoJson: geoJson,
          address: address,
          phoneNumber: phoneNumber,
          birthday: birthday,
        },
        getAuth()
      )
      .then((res) => {
        //localStorage.setItem('token', res.data.token)
        dispatch(loginSuccess())
        //    dispatch(getMe());
      })
      .catch((error) => {
        if (error.response) {
          dispatch(loginFailure(error.response.data))
          return
        }

        dispatch(loginFailure('something went wrong'))
      })
  }
}

export const userCreateCredentials = (password: any, email: any) => {
  return (dispatch: any) => {
    dispatch(userCreateCredentialsRequest())
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/creatuser/usercredetials`,
        {
          password: password,
          email: email,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(userCreateCredentialsSuccess(res.data.id))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(userCreateCredentialsFailure())
          return
        }
        dispatch(loginFailure('something went wrong'))
      })
  }
}

export const userCreateCredentialsRequest = () => {
  return {
    type: USER_TEMP_ID_REQUEST,
  }
}

export const userCreateCredentialsSuccess = (data: any) => {
  return {
    type: USER_TEMP_ID_SUCCESS,
    payload: data,
  }
}

export const userCreateCredentialsFailure = () => {
  return {
    type: USER_TEMP_ID_FAILUER,
  }
}

export const userCreateOnAgreement = (
  email: any,
  password: any,
  firstName: any,
  lastName: any,
  location: any,
  userType: any,
  aboutYourSelf: any,
  imageUrl: any,
  userId: any,
  userTerms: any,
  phoneNumber: any,
  birthday: any,
  toast: any,
  terms: any
) => {
  return (dispatch: any) => {
    dispatch(userCreatLocationRequest())
    return transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/v2/onboarding/usercreate`,
        {
          firstName: firstName,
          lastName: lastName,
          userTypeId: userType,
          description: aboutYourSelf,
          verified: false,
          public: false,
          imageUrl: imageUrl,
          // locationName: location?.displayName,
          location: location,

          email: email,
          password: password,
          userId: userId,
          userTerms: userTerms,
          phoneNumber: phoneNumber,
          birthday: birthday,
          terms: terms,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(userCreatLocationSuccess())
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.message)
        if (error.response) {
          dispatch(userCreatLocationFailure())
          return
        }
      })
  }
}

export const userCreatLocationRequest = () => {
  return {
    type: USER_LOCATION_STORE_REQUEST,
  }
}

export const userCreatLocationSuccess = () => {
  return {
    type: USER_LOCATION_STORE_SUCCESS,
  }
}

export const userCreatLocationFailure = () => {
  return {
    type: USER_LOCATION_STORE_FAILURE,
  }
}

export const logoutRequest = () => {
  return {
    type: USER_LOGOUT_REQUEST,
  }
}

export const logoutSuccess = () => {
  return {
    type: USER_LOGOUT_SUCCESS,
  }
}

export const logoutFailure = (error: any) => {
  return {
    type: USER_LOGOUT_FAILURE,
    error: error,
  }
}

export const logout = () => {
  return (dispatch: any) => {
    dispatch(logoutRequest())
    // transport
    //     .get(`${baseUrl}/auth/logout`)
    //     .then((res) => {
    //         dispatch(logoutSuccess());
    //         localStorage.setItem('token', '')

    //         dispatch(getMe());
    //     })
    //     .catch((error) => {
    //         dispatch(loginFailure("something went wrong"));
    //     });
  }
}

export const addUsers = (data: any) => {
  return {
    type: GET_ALL_USERS,
    payload: data,
  }
}

export const getAllUsers = () => {
  return (dispatch: any) => {
    // dispatch(loginRequest());
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/user/getallusers`, getAuth())
      .then((res) => {
        //localStorage.setItem('token', res.data.token)
        //dispatch(loginSuccess());
        //    dispatch(getMe());
        dispatch(addUsers(res.data))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(loginFailure(error.response.data));
          return
        }

        // dispatch(loginFailure("something went wrong"));
      })
  }
}

export const deleteUser = (id: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .delete(`${process.env.REACT_APP_BASE_URL}/user/${id}`, getAuth())
      .then((res) => {
        dispatch(getAllUsers())
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const getUserDetails = (userId: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getUserDetailsRequest())
    transport
      .get(
        `${process.env.REACT_APP_BASE_URL}/user/getuserdetails/${userId}`,
        getAuth()
      )
      .then((res) => {
        dispatch(getUserDetailsSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getUserDetailsFailure())
          return
        }
      })
  }
}

export const getUserDetailsRequest = () => {
  return {
    type: GET_USER_DETAILS_REQUEST,
  }
}

export const getUserDetailsSuccess = (details: any) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: details,
  }
}

export const getUserDetailsFailure = () => {
  return {
    type: GET_USER_DETAILS_FAILURE,
  }
}

export const addUserCredentials = (email: any, password: any) => {
  return {
    type: ADD_USER_CREDENTIALS,
    payload: { email, password },
  }
}

export const addUserDetails = (
  firstName: any,
  lastName: any,
  location: any,
  userType: any,
  description: any,
  imageUrl: any,
  userId: any,
  birthday: any,
  phoneNumber: any
) => {
  return {
    type: ADD_USER_DETAILS,
    payload: {
      firstName,
      lastName,
      location,
      userType,
      description,
      imageUrl,
      userId,
      birthday,
      phoneNumber,
    },
  }
}

//update user details API
export const updateUserDetailsRequest = () => {
  return {
    type: UPDATE_USER_DETAILS_REQUEST,
  }
}

export const updateUserDetailsSuccess = () => {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
  }
}

export const updateUserDetailsFailure = () => {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
  }
}

export const updateUserDetails = (
  homeTown: any,
  userType: any,
  aboutYourSelf: any,
  imageURL: any,
  phoneNumber: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateUserDetailsRequest())
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/v2/user/updateprofile`,
        {
          homeTown: homeTown,
          userType: userType,
          aboutYourSelf: aboutYourSelf,
          imageURL: imageURL,
          phoneNumber: phoneNumber,
        },
        getAuth()
      )
      .then((res) => {
        dispatch(getMe())
        dispatch(updateUserDetailsSuccess())
      })
      .catch((error) => {
        if (error.response) {
          dispatch(updateUserDetailsFailure())
          return
        }
      })
  }
}
