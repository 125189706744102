import './index.css'
//components
import SocialLoginButton from '../SocialLoginButton'
import { useNavigate } from 'react-router-dom'
//icons

import checkLogo from '../../assets/icons/check-logo.svg'
import mailbox from '../../assets/icons/mailbox.svg'

const OnBoarding = () => {
  const navigate = useNavigate()

  function goToSignUpEmail(event: any) {
    navigate('/signupemail')
  }
  return (
    <div className="main-div h-screen">
      {/*main div*/}
      <div className={'flex justify-center'}>
        {/*check logo div*/}
        <img className={'image-size'} src={checkLogo} alt="" />
        {/*check logo*/}
      </div>

      <div className={'text-center mt-8'}>
        <text className={'check-text'}>niooze</text>
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '94px' }}
      >
        <div
          className={'middle-flex-mobile middle-flex-desktop middle-flex-tab'}
        >
          <div className={'text-left'}>
            <text className={'text-onboard'}>
              Create an account to <br />
              unlock the best of Niooze.
            </text>
          </div>

          <div className="cursor-pointer mt-10">
            <SocialLoginButton
              icon={mailbox}
              text={'Continue with email'}
              onClick={goToSignUpEmail}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnBoarding
