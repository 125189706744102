import axios from 'axios'
import { Dispatch } from 'react'

import {
  GET_ALL_TOPICS,
  CREATE_TOPICS,
  ADD_TOPIC_ID,
  SHOW_CREATE_TOPIC_MODAL,
  CLOSE_CREATE_TOPIC_MODAL,
  GET_SELECTED_TOPICS,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_REQUEST,
  GET_TOPIC_FAILURE,
} from './types'
import { getAuth } from '../../utils/auth'
import { getAllPostsForTopics, getPost, setClickedPost } from '../posts/actions'

const transport = axios.create({
  withCredentials: false,
})

export const getAllTopicsSuccess = (posts: any) => {
  return {
    type: GET_ALL_TOPICS,
    payload: posts,
  }
}

export const addTopicID = (id: any) => {
  return {
    type: ADD_TOPIC_ID,
    payload: id,
  }
}

export const showCreateTopicModal = (value: boolean) => {
  return {
    type: SHOW_CREATE_TOPIC_MODAL,
    payload: value,
  }
}

export const closeCreateTopicModal = (value: boolean) => {
  return {
    type: CLOSE_CREATE_TOPIC_MODAL,
    payload: value,
  }
}

export const getSelectedTopics = (topics: any) => {
  return {
    type: GET_SELECTED_TOPICS,
    payload: topics,
  }
}

export const getAllTopics = () => {
  return (dispatch: Dispatch<any>) => {
    transport
      .get(
        `${process.env.REACT_APP_BASE_URL}/v2/post/newsfeed/local`,
        getAuth()
      )
      .then((res) => {
        const updatedData = res.data.map((topic: any) => {
          let isPreviousPostHaveCon = false

          const updatedPosts = topic.posts.map((post: any, index: number) => {
            if (index === 0) {
              if (post?.con.length > 0) {
                isPreviousPostHaveCon = true
                return { ...post, showCon: true }
              } else {
                isPreviousPostHaveCon = false
                return { ...post, showCon: true }
              }
            } else {
              if (post?.con.length > 0) {
                if (isPreviousPostHaveCon) {
                  isPreviousPostHaveCon = false
                  return { ...post, showCon: false }
                } else {
                  isPreviousPostHaveCon = true
                  return { ...post, showCon: true }
                }
              } else {
                isPreviousPostHaveCon = false
                return { ...post, showCon: true }
              }
            }
          })

          return { ...topic, posts: updatedPosts }
        })

        dispatch(getAllTopicsSuccess(updatedData))
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const createTopics = (
  city: any,
  topic: any,
  parentTopic: any,
  startDate: any,
  description: any,
  endDate: any,
  selectedOrganizations: any,
  selectedUsers: any,
  setProgressOpen: any,
  setTopicModalVisible: any
): any => {
  setProgressOpen(true)
  return (dispatch: Dispatch<any>) => {
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/topic`,
        {
          title: topic,
          description: description,
          locationId: city,
          selectedOrganizations: selectedOrganizations,
          selectedUsers: selectedUsers,
        },
        getAuth()
      )
      .then((res) => {
        setProgressOpen(false)
        setTopicModalVisible(false)
        dispatch(getAllTopics())
      })
      .catch((error) => {
        if (error.response) {
          //return error.response
        }
      })
    // };
  }
}

export const getTopicRequest = () => {
  return {
    type: GET_TOPIC_REQUEST,
  }
}

export const getTopicSuccess = (topic: any) => {
  return {
    type: GET_TOPIC_SUCCESS,
    payload: topic,
  }
}

export const getTopicFailure = () => {
  return {
    type: GET_TOPIC_FAILURE,
  }
}

export const getTopic = (topicId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getTopicRequest())
    transport
      .get(`${process.env.REACT_APP_BASE_URL}/topic/${topicId}`, getAuth())
      .then((res) => {
        dispatch(getTopicSuccess(res.data))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getTopicFailure())
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const deleteTopic = (topic: any) => {
  //get posts for a topic
  return (dispatch: Dispatch<any>) => {
    const { id } = topic
    transport
      .delete(`${process.env.REACT_APP_BASE_URL}/topic/${id}`, getAuth())
      .then((res) => {
        dispatch(getAllTopics())
      })
      .catch((error) => {
        if (error.response) {
          // dispatch(getAllPostsSuccess(error.response.data));
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const searchTopics = (topic: any) => {
  //get posts for a topic
  return transport
    .get(`${process.env.REACT_APP_BASE_URL}/topic/search/${topic}`, getAuth())
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      if (error.response) {
        // dispatch(getAllPostsSuccess(error.response.data));
        return error
      }
      // dispatch(getMeFailure("something went wrongsds"));
    })
}

export const setClickedTopic = (topic: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addTopicID(topic))
    dispatch(setClickedPost(null))
  }
}
