import { Outlet, useNavigate } from 'react-router-dom'

import HomeHeader from '../../components/HomeHeader'
import FooterMenu from '../../components/FooterMenu'
import { getMe } from '../../redux/login/actions'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { MAX_PHONE_BREAK_POINT } from '../../utils/constants'
import Header from '../../components-v2/Header/Header'
import { useUserStore } from '../UserScreen-v2/user.store'
import { getUserFollowerIds } from '../../services/helpersApis'

const HomeScreen = ({
  setArticleModalVisible,
  setTopicModalVisible,
  user,
  userLoading,
  userError,
  getMe,
  setParamsForArticleModal,
  setTopicArticleModalVisible,
  setEditPostModalVisible,
}: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    setParamsForArticleModal({ topicId: 'home' })
  }, [])

  const logout = () => {
    window.confirm('Are you sure you want to logout?')
    localStorage.setItem('token', '')
    getMe()
  }
  //const setUserFollowIds = useUserStore((state: any) => state.setUserFollowIds)

  useEffect(() => {
    // getUserFollowerIds().then((userIds: any) => {
    //   setUserFollowIds(userIds)
    // })
    getMe()
  }, [getMe])

  useEffect(() => {
    if (!userLoading && userError && !user) {
      navigate('/landing')
    }
  }, [navigate, user, userError, userLoading])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <>
      {/* <HomeHeader
        logout={logout}
        user={user}
        setTopicModalVisible={setTopicModalVisible}
        setArticleModalVisible={setArticleModalVisible}
        setTopicArticleModalVisible={setTopicArticleModalVisible}
      /> */}

      <Header />

      <Outlet />
      {
        // MAX_PHONE_BREAK_POINT > windowSize.innerWidth && <FooterMenu/>
      }
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
