export function getHoverFillProps(hoverFill?: string, normalFill?: string) {
  return {
    onMouseEnter: (e: any) => {
      e.target.setAttribute('fill', hoverFill || '#d5e7c4')
    },
    onMouseLeave: (e: any) => {
      e.target.setAttribute('fill', normalFill || '#303655')
    },
  }
}

export const timeFilterOptions = [
  {
    label: 'All posts',
    value: 'all',
  },
  {
    label: 'Last 24h',
    value: 'last-24h',
  },
  {
    label: 'Last 2 days',
    value: 'last-2-days',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
]

export const orderFilterOptions = [
  {
    label: 'New to Old',
    value: 'new-to-old',
  },
  {
    label: 'Old to New',
    value: 'old-to-new',
  },
  {
    label: 'Most Upvotes',
    value: 'most-u-to-least-u',
  },
]
