import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export function SimpleDropdown({ organizationsData, setOrg, type }: any) {
  const [organizations, setOrganizations] = useState(organizationsData)

  const onSelect = (e: any) => {
    if (e) {
      setOrg(e.id)
    } else {
      setOrg(null)
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={organizations}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      defaultValue={[organizations[0]?.name] || []}
      sx={{ width: 300 }}
      onChange={(event, value) => onSelect(value)}
      renderInput={(params) => <TextField {...params} label="Organisation" />}
    />
  )
}
