import './index.css'

import { TextField } from '@mui/material'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import PostCreateContent from '../../components/PostCreateContent'
import { connect } from 'react-redux'
import Post from '../../components/Post'
import text from '../../assets/icons/text.svg'
import ArticlePostPreview from '../../components/ArticlePostPreview'
import OrganisationSelector from '../../components/OrganisationSelector'
import { BeatLoader } from 'react-spinners'
import { addArticles } from '../../redux/articles/action'
import { SimpleDropdown } from '../../components/SimpleDropDown'
import { getAllOrganizations } from '../../redux/organizations/actions'
import plus from '../../assets/icons/plus-black.svg'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase'
import { v4 as uuidv4 } from 'uuid'

const TopicArticleCreateModal = ({
  setPostModalVisible,
  setArticleModalVisible,
  user,
  article,
  addArticles,
  topic: selectedTopic,
  getAllOrganizations,
  organizations,
}: any) => {
  const [createPostModal, setCreatePostModal] = useState(false)
  const [posts, setPosts] = useState<any[]>([])

  const [articleUrl, setArticleUrl] = useState('') //article link

  const [articleContent, setArticleContent] = useState(null)
  const [articleContentLoading, setArticleContentLoading] = useState(false)
  const [scrapImgIndex, setScrapImgIndex] = useState(0)

  const [postTypeId, setpostTypeid] = useState('') //post type id
  const [description, setDescription] = useState() //description
  const [title, setTitle] = useState() //title
  const [imgUrl, setImgUrl] = useState() //image url
  const [articleObj, setArticleObj] = useState({}) //article obj

  const [org, setOrg] = useState(null)
  const [image, setImgae] = useState(null) //image
  // const [postOrg, setPostOrg] = useState(null);

  const {
    title: topicTitle = '',
    description: topicDescription = '',
    locationId = '',
  } = selectedTopic || {}

  useEffect(() => {
    getAllOrganizations()
  }, [])

  useEffect(() => {
    if (articleUrl === '') {
      setArticleContent(null)
    }
  }, [articleUrl])

  useEffect(() => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      setImgUrl(imageUrls[scrapImgIndex])
    }
  }, [scrapImgIndex])

  useEffect(() => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      setTitle(titles[0])
    }
  }, [articleContent])

  const saveArticle = () => {
    if (articleContent) {
      const { imageUrls, titles } = articleContent
      const obj = {
        // "topicId":selectedTopics.id,
        url: articleUrl,
        title: title,
        topicId: selectedTopic?.id,
        imageUrl: imageUrls[scrapImgIndex],
        posts: posts,
        organizationId: org !== 0 ? org : null,
      }
      setArticleObj(obj)
      addArticles(obj)
    }
    closeModal()
  }

  const closeModal = () => {
    setArticleModalVisible(false)
  }

  const addPost = (post: any) => {
    const newPosts = [...posts]

    if (org) {
      post.organizationId = org
    }
    newPosts.push(post)
    setPosts(newPosts)
  }

  const crawlArticleUrl = async () => {
    setArticleContentLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/common/scrape?url=${articleUrl}`
      )
      const content = await response.json()
      setArticleContent(content)
      setArticleContentLoading(false)
    } catch (error) {
      setArticleContent(null)
      setArticleContentLoading(false)
    }
  }

  const clickSubmit = async () => {
    const postObject: any = {
      title: title,
      description: description,
      topicId: selectedTopic?.id,
      postTypeId: postTypeId,
    }

    if (image) {
      const imageRef = ref(storage, `post-images/${image['name'] + uuidv4()}`)
      await uploadBytes(imageRef, image).then((e) => {
        getDownloadURL(e.ref)
          .then((url) => {
            postObject.mediaId = url
            addPost(postObject)
            setCreatePostModal(false)
          })
          .catch((e) => {
            return
          })
      })
    } else {
      addPost(postObject)
      setCreatePostModal(false)
    }
  }

  const handleTextWithLineBreaks = (event: any) => {
    const inputValue = event.target.value
    const textWithLineBreaks = inputValue.replace(/\n/g, '<br>')
    setDescription(textWithLineBreaks)
  }

  return (
    <div
      style={{
        zIndex: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000B2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
      onClick={() => {
        setArticleModalVisible(false)
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: '100%',
          maxWidth: 1150,
          padding: 50,
          borderRadius: 6,
          margin: 60,
          textAlign: 'start',
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={'text_header'}>
          <text>Create newsdsd</text>
          <text style={{ fontWeight: 'bold' }}> ARTICLE</text>
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              marginRight: 50,
            }}
          >
            <div
              style={{
                background: '#D9D9D9',
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                borderRadius: 4,
              }}
            >
              <text style={{ textAlign: 'start', fontWeight: 'bold' }}>
                {topicTitle}
              </text>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <text style={{ textAlign: 'start' }}>{locationId}</text>
              </div>
            </div>
            <div style={{ overflow: 'scroll' }}>
              {!articleContent && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 50,
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      style={{
                        width: '100%',
                        fontSize: 20,
                        color: '#000000',
                      }}
                      size={'small'}
                      label="Article link"
                      variant="standard"
                      className={'TextField'}
                      sx={{
                        width: 300,
                        color: 'success.main',
                      }}
                      onChange={(e: any) => {
                        setArticleUrl(e.target.value)
                      }}
                    />

                    {articleContentLoading && (
                      <BeatLoader color="#303655" size={10} />
                    )}
                  </div>
                  <Spacer height={10} />
                  <div style={{ width: 170 }}>
                    <Button
                      title={'Load RSS Data'}
                      onClick={() => crawlArticleUrl()}
                    />
                  </div>
                </>
              )}

              {articleContent && (
                <div>
                  <Spacer height={20} />
                  {
                    <SimpleDropdown
                      organizationsData={organizations}
                      setOrg={setOrg}
                    />
                  }
                  <Spacer height={20} />
                  {org && (
                    <OrganisationSelector
                      organization={
                        organizations.filter((o: any) => o.id === org)[0]
                      }
                    />
                  )}
                  <Spacer height={40} />
                  <ArticlePostPreview
                    content={articleContent}
                    setScrapImgIndex={setScrapImgIndex}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: 1, background: '#000000' }}></div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 50,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <text className={'text_header_bold'}>Posts</text>
              {posts.length > 0 && (
                <text className={'text_header_bold'}>({posts.length})</text>
              )}
              <Spacer width={10} />
              <div
                onClick={() => {
                  // setPostOrg(null)
                  setImgae(null)
                  setCreatePostModal(true)
                }}
              >
                <img
                  className={'contrast-button'}
                  style={{ height: 27, width: 27, marginBottom: -3 }}
                  src={plus}
                  alt=""
                />
              </div>
            </div>

            <div style={{ overflow: 'scroll' }}>
              {posts.map((post: any) => {
                const { organizationId } = post
                const organizationObject = organizations.filter(
                  (o: any) => o.id === organizationId
                )
                post.organization =
                  organizationObject && organizationObject.length > 0
                    ? organizationObject[0]
                    : null
                post.author = user

                return (
                  <div>
                    <Post deletePost={() => {}} post={post} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button title={'Cancel'} onClick={() => closeModal()} />
          <div style={{ display: 'flex' }}>
            <Button title={'Save for later'} />
            <Spacer width={20} />
            <Button
              title={'Create'}
              onClick={() => {
                saveArticle()
              }}
            />
          </div>
        </div>
        {createPostModal && (
          <div
            style={{
              zIndex: 300,
              position: 'absolute',
              background: '#000000bb',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => {
              setCreatePostModal(false)
            }}
          >
            <PostCreateContent
              selectedTopic={selectedTopic}
              onCancel={() => {
                setCreatePostModal(false)
              }}
              onPost={(post: any) => {
                setCreatePostModal(false)
                addPost(post)
              }}
              user={user}
              setpostTypeid={setpostTypeid}
              setDescription={setDescription}
              description={description}
              clickSubmit={clickSubmit}
              organizationsData={organizations}
              setOrg={setOrg}
              org={org}
              setImgae={setImgae}
              image={image}
              handleTextWithLineBreaks={handleTextWithLineBreaks}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userLoginError: state.login.userLoginError,
    article: state.article.article,
    selectedTopics: state.topics.selectedTopics,
    topic: state.topics.topic,
    organizations: state.organization.organizations,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addArticles: (obj: any) => {
      dispatch(addArticles(obj))
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicArticleCreateModal)
