import authAxios from '../utils/authAxios'

export async function fetchNotificationSummary() {
  const res = await authAxios.post(`/v2/notifications/fetchNotificationSummary`)

  return res.data
}

export async function readFromSection(section: string) {
  const res = await authAxios.post(`/v2/notifications/readFromSection`, {
    section,
  })

  return res.data
}

export async function getNotifications({ queryKey }: any) {
  const [key, section] = queryKey

  const res = await authAxios.post(`/v2/notifications/getNotifications`, {
    section,
  })

  return res.data
}
