import React, { useState } from 'react'
import { cn } from '../../utils/cn'
import PostTypeWheelDialog from './PostTypeWheel'
import OrganizationsWheelDialog from './OrganizationsWheel'
import PostTypeFilterWheelButton from './PostTypeFilterWheelButton'
import OrganizationFilterWheelButton from './OrganizationFilterWheelButton'
import TimeFilters from './TimeFilters/TimeFilters'

type Props = {
  className?: string
  currentPostType: string
  setPostType: (type: string) => void
  currentOrgType: string
  setOrgType: (type: string) => void

  setFilterOrder: (type: string) => void
  filterOrder: string

  setFilterTime: (type: string) => void
  filterTime: string

  setPostTypeWheelOpen: (isOpen: boolean) => void
  postTypeWheelOpen: boolean

  setOrgWheelOpen: (isOpen: boolean) => void
  orgWheelOpen: boolean

  disabledPostTypes: string[]
  disabledOrgTypes: string[]
}

export default function FilterWheelEntry({
  className,
  currentPostType,
  currentOrgType,
  setPostType,
  setOrgType,

  filterOrder,
  filterTime,
  setFilterOrder,
  setFilterTime,

  postTypeWheelOpen,
  setPostTypeWheelOpen,

  orgWheelOpen,
  setOrgWheelOpen,

  disabledPostTypes,
  disabledOrgTypes,
}: Props) {
  function getFilterWheelButton() {
    if (currentPostType !== 'all') {
      return (
        <PostTypeFilterWheelButton
          onClick={() => {
            setPostTypeWheelOpen(true)
          }}
          currentPostType={currentPostType}
        />
      )
    }
    if (currentOrgType !== 'all') {
      return (
        <OrganizationFilterWheelButton
          onClick={() => {
            setPostTypeWheelOpen(true)
          }}
          currentOrgType={currentOrgType}
        />
      )
    }

    if (currentOrgType == 'all' && currentPostType == 'all') {
      return (
        <PostTypeFilterWheelButton
          onClick={() => {
            setPostTypeWheelOpen(true)
          }}
          currentPostType={currentPostType}
        />
      )
    }
  }

  const timeFilter = (
    <TimeFilters
      setFilterOrder={setFilterOrder}
      filterOrder={filterOrder}
      setFilterTime={setFilterTime}
      filterTime={filterTime}
    />
  )

  return (
    <div className={cn('flex flex-col items-end rounded-full', className)}>
      {/* <div
        onClick={() => {
          setPostTypeWheelOpen(true)
        }}
      > */}
      {/* {currentPostType !== 'all' && (
          <PostTypeFilterWheelButton currentPostType={currentPostType} />
        )}

        {currentOrgType !== 'all' && (
          <OrganizationFilterWheelButton currentOrgType={currentOrgType} />
        )} */}

      {getFilterWheelButton()}
      {/* </div> */}

      <PostTypeWheelDialog
        timeFilterComponent={timeFilter}
        // setPostType={setPostType}
        currentPostType={currentPostType}
        open={postTypeWheelOpen}
        setOpen={setPostTypeWheelOpen}
        onClickPostType={(type) => {
          setPostType(type)
          setPostTypeWheelOpen(false)
        }}
        onClickOrganization={() => {
          setOrgWheelOpen(true)
          setPostTypeWheelOpen(false)
        }}
        onClickShowAll={() => {
          setPostTypeWheelOpen(false)
          setPostType('all')
          // setFilterOrder('reverse-chronological')
          // setFilterTime('all')
        }}
        disabledPostTypes={disabledPostTypes}
      />

      <OrganizationsWheelDialog
        timeFilterComponent={timeFilter}
        open={orgWheelOpen}
        setOpen={setOrgWheelOpen}
        onClickOrganization={(org) => {
          setOrgType(org)
          setOrgWheelOpen(false)
        }}
        onClickBack={() => {
          setOrgWheelOpen(false)
          setPostTypeWheelOpen(true)
        }}
        disabledOrgTypes={disabledOrgTypes}
      />
    </div>
  )
}
