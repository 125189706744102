import axios from 'axios'

const authAxios = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_URL,
})

authAxios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export default authAxios
