import { DELETE_POST_FAILURE, DELETE_POST_REQUEST } from '../posts/types'

export const GET_ALL_ORGANIZATIONS = 'GET_ALL_ORGANIZATIONS'

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST'
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE'

export const GET_ARTICLES_REQUEST = 'GET_ARTICLES_REQUEST'
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS'
export const GET_ARTICLES_FAILUER = 'GET_ARTICLES_FAILUER'

//create organization
export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE'

//add users for organization
export const ADD_USER_FOR_ORGANIZATION_REQUEST = 'ADD_USER_FOR_ORGANIZATION'
export const ADD_USER_FOR_ORGANIZATION_SUCCESS =
  'ADD_USER_FOR_ORGANIZATION_SUCCESS'
export const ADD_USER_FOR_ORGANIZATION_FAILURE =
  'ADD_USER_FOR_ORGANIZATION_FAILURE'

//delete user for organization
export const DELETE_USER_FOR_ORGANIZATION_REQUEST =
  'DELETE_USER_FOR_ORGANIZATION_REQUEST'
export const DELETE_USER_FOR_ORGANIZATION_SUCCESS =
  'DELETE_USER_FOR_ORGANIZATION_SUCCESS'
export const DELETE_USER_FOR_ORGANIZATION_FAILURE =
  'DELETE_USER_FOR_ORGANIZATION_FAILURE'

//select user for delete for organization
export const SELECT_USER_TO_DELETE_FROM_ORGANIZATION =
  'SELECT_USER_TO_DELETE_FROM_ORGANIZATION'

//update user type for org
export const UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST =
  'UPDATE_USER_TYPE_FOR_ORGANIZATION_REQUEST'
export const UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS =
  'UPDATE_USER_TYPE_FOR_ORGANIZATION_SUCCESS'
export const UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE =
  'UPDATE_USER_TYPE_FOR_ORGANIZATION_FAILURE'

//update organization details
export const UPDATE_ORGANIZATION_DETAILS_REQUEST =
  'UPDATE_ORGANIZATION_DETAILS_REQUEST'
export const UPDATE_ORGANIZATION_DETAILS_SUCCESS =
  'UPDATE_ORGANIZATION_DETAILS_SUCCESS'

export const UPDATE_ORGANIZATION_DETAILS_FAILURE =
  'UPDATE_ORGANIZATION_DETAILS_FAILURE'
