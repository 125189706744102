import { connect } from 'react-redux'
import plusBlack from '../../assets/icons/plus-black.svg'
import HeadingAndValue from '../../components/HeadingAndValue'
import {
  getOrganization,
  selectUserToDeleteFromOrganization,
} from '../../redux/organizations/actions'

import './index.css'
import MembersProfile from '../../components/MembersProfile'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import edit from '../../assets/icons/edit.svg'
import Spacer from '../../components/Spacer'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components-v2/shadcn/avatar'
import { getInitials } from '../../components-v2/Post/helpers'

const OrganizationDetails = ({
  getOrganization,
  organization,
  setAddMemberToOrgModalVisible,
  setDeleteMemberToOrgModalVisible,
  selectUserToDeleteFromOrganization,
  userId,
}: any) => {
  const params = useParams()
  const { orgId } = params
  const navigate = useNavigate()

  const [isAdmin, setIsAdmin] = useState(false)
  const selectUserToDeleteFromOrgRedux = (e: any) => {
    selectUserToDeleteFromOrganization(e)
    setDeleteMemberToOrgModalVisible(true)
  }

  useEffect(() => {
    getOrganization(orgId)
  }, [orgId, getOrganization])

  useEffect(() => {
    const isAdmin = organization?.organizationUsers.some(
      (org: any) => userId === org?.id && org?.userOrganization?.role === 1
    )
    setIsAdmin(isAdmin)
  }, [organization?.organizationUsers, userId])

  return (
    <div className="main-div-org">
      {/*main div*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '20px', paddingLeft: '34px' }}></div>
        <div>
          <text className={'check-text-org'}>{organization?.name}</text>
        </div>
        <div
          style={{
            width: '20px',
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '3px',
            marginRight: '8px',
          }}
        >
          {isAdmin ? (
            <img
              className={'edit-pencil-org-details'}
              src={edit}
              onClick={() => {
                navigate(`/editorganizationdetails/${orgId}`)
              }}
              alt=""
            />
          ) : null}
        </div>
      </div>

      <div className={'org-logo  flex justify-center'}>
        {/*check logo div*/}
        {/*<img className={'image-size-org'} src={organization?.imageUrl} alt="" />*/}

        <Avatar className="rounded-full border border-border w-[200px] h-[200px] object-cover">
          <AvatarImage src={organization?.imageUrl} className="" />
          {/* TODO: Render 2 letters of the name */}
          <AvatarFallback className={'text-6xl text-gray-600'}>
            {getInitials(organization?.name)}
          </AvatarFallback>
        </Avatar>
        {/*check logo*/}
      </div>

      <Spacer height={30} />

      <div
        style={{ marginTop: '10px', width: '350px' }}
        className={
          'details-margin-desktop details-margin-tab details-margin-mobile'
        }
      >
        <HeadingAndValue heading={'Name'} value={organization?.name} />
        <HeadingAndValue heading={'Type'} value={organization?.type} />
        <HeadingAndValue heading={'Website'} value={organization?.orgLink} />
        <HeadingAndValue heading={'Email'} value={organization?.email} />
        <HeadingAndValue
          heading={'Phone number'}
          value={organization?.phoneNumber}
        />
        <HeadingAndValue
          heading={'About yourself'}
          value={organization?.description}
        />
      </div>

      <div
        style={{
          width: '390px',
          background: '#D9D9D9',
          height: '52px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '25px' }}>
          <text className={'check-text-org'}>Members</text>
        </div>
        <div style={{ marginRight: '23px' }}>
          {isAdmin ? (
            <img
              className={'image-size-plus'}
              src={plusBlack}
              alt=""
              onClick={() => {
                setAddMemberToOrgModalVisible(true)
              }}
            />
          ) : null}
        </div>
      </div>

      <div style={{ width: '100%', marginRight: 'auto', marginLeft: 'auto' }}>
        {organization?.organizationUsers.map((orgMember: any) => {
          return (
            <MembersProfile
              user={orgMember}
              key={orgMember?.id}
              isAdmin={isAdmin}
              selectUserToDeleteFromOrgRedux={selectUserToDeleteFromOrgRedux}
            />
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    organization: state.organization.organization,
    userId: state?.login?.user?.id,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOrganization: (orgId: string) => {
      dispatch(getOrganization(orgId))
    },
    selectUserToDeleteFromOrganization: (user: string) => {
      dispatch(selectUserToDeleteFromOrganization(user))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails)
