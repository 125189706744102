import React from 'react'
import './index.css'

import Spacer from '../Spacer'
import OnBoardButton from '../OnBoardButton'

const CreateOrganizationNoticeModal = ({ submitCreateOrg, loading }: any) => {
  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className={'overflow-hidden'}
        style={{
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 335,
          height: 280,
          padding: 26,
          marginBottom: '50px',
        }}
      >
        <div>
          <Spacer height={20} />

          <div style={{ background: '#FBFBFD' }}>
            <p className={'body-text-style'}>
              Thank you for creating a new organization.
            </p>

            <p className={'body-text-style'} style={{ marginTop: '20px' }}>
              Please note that your “type” of organization is under review. We
              might change the type or send you some questions through email
              going forward.
            </p>

            <div>
              <Spacer height={20} />
              <OnBoardButton
                loading={loading}
                width={'285px'}
                buttonName={'Continue'}
                disabled={false}
                onClick={() => {
                  submitCreateOrg()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOrganizationNoticeModal
