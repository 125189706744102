import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '../../../shadcn/dialog'
import { Input } from '../../../shadcn/input'
import { PlusCircle, X, XCircle } from 'lucide-react'
import Button from '../../../Button'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { cn } from '../../../../utils/cn'
import { notEmpty } from '../../../../utils/formHelpers'
import { toast } from 'sonner'
import { Textarea } from '../../../shadcn/textarea'
import DateField from './DateField'
import SearchUserField from './SearchUserField'
import PartyCard from '../AddTopic/PartyCard'
import moment from 'moment'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import TopicSelectDialogContent from '../../../CreateContrastDialog/TopicSelectDialogContent'
import uploadImage from '../../../../utils/uploadImage'
import LandscapeSVG from '../../../../assets/icons/landscape.svg'
import {
  createAQuote,
  getOnePost,
  updateAQuote,
} from '../../../../services/post'
import { getMyLocation, getOneTopic } from '../../../../services/topic'
import { DatePicker } from '@mui/x-date-pickers'
import LoadingOverlay from '../../../LoadingOverlay'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  cb?: () => void
  postId?: string
  topicId?: string
}

export default function AddQuote(props: DialogProps) {
  const { open, setOpen } = props
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="overflow-y-auto max-w-[600px] px-10 py-10 max-h-screen"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

const schema = z
  .object({
    topicId: z.string().optional(),
    userId: z.string().optional(),
    // imageUrl: z.string().optional(),
    customUserName: z.string().optional(),
    text: z.string().max(300).pipe(notEmpty),
    role: z.string(),
    source: z.string().pipe(notEmpty),
    date: z.date(),
  })
  .refine((data) => data.userId || data.customUserName, {
    message: 'Either a user or a custom user must be provided.',
    path: ['userId'],
  })

type SchemaType = z.infer<typeof schema>

function Content({ setOpen, cb, postId, topicId }: DialogProps) {
  const [topicOpen, setTopicOpen] = useState<boolean>(false)

  const [topic, setTopic] = useState<any | null>(null)

  const [locationObj, setLocationObj] = useState<any | null>(null)

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  const [isLoading, setIsLoading] = useState(false)

  const uuidRegexLocal =
    /local\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/
  useEffect(() => {
    if (topicId) {
      setIsLoading(true)
      if (!topicId.match(uuidRegexLocal)) {
        getOneTopic(topicId)
          .then((data) => {
            setIsLoading(false)
            setValue('topicId', data.id)
            setTopic({
              label: data.title,
              value: data.id,
            })
          })
          .catch(() => {
            setIsLoading(false)
          })
      } else {
        getMyLocation()
          .then((data) => {
            setIsLoading(false)
            setLocationObj({
              display_name: data.address,
              locationId: data.id,
            })
          })
          .catch(() => {
            setIsLoading(false)
          })
      }
    }
  }, [topicId])

  //Load edit data
  useEffect(() => {
    if (!!postId) {
      setIsLoading(true)
      getOnePost(postId)
        .then((data) => {
          setIsLoading(false)
          if (data?.quoteData?.date) {
            setValue('date', new Date(data?.quoteData?.date))
          }

          if (data?.topicId) {
            setValue('topicId', data?.topicId)
            setTopic({
              label: data?.topic?.title,
              value: data?.topic?.id,
            })
          }

          if (data?.quoteData?.userId) {
            setValue('userId', data?.quoteData?.userId)
          }

          if (data?.quoteData?.customUserName) {
            setValue('customUserName', data?.quoteData?.customUserName)
          }

          if (data?.quoteData?.text) {
            setValue('text', data?.quoteData?.text)
          }

          if (data?.quoteData?.userRole) {
            setValue('role', data?.quoteData?.userRole)
          }

          if (data?.quoteData?.source) {
            setValue('source', data?.quoteData?.source)
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [])

  const customUserName = watch('customUserName')
  const userId = watch('userId')
  // const imageUrl = watch('imageUrl')
  const text = watch('text')

  // console.log(errors)

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {/* <LoadingOverlay /> */}
      <form className="">
        <p>
          Create{' '}
          <span className="font-bold underline underline-offset-4">QUOTE</span>
        </p>

        <div className="flex flex-col gap-[18px] mt-5">
          <div>
            <Dialog open={topicOpen} onOpenChange={setTopicOpen}>
              <DialogTrigger asChild>
                <button
                  className={cn('cursor-pointer w-full text-left text-[11px]')}
                >
                  {!topic && !locationObj && (
                    <div className="bg-line p-3 rounded-md text-sm h-[64px]">
                      Select location or topic...
                    </div>
                  )}

                  {topic && (
                    <div className="bg-line p-3 rounded-md text-sm flex justify-between items-center">
                      <p className="flex flex-col">
                        <span className="">Topic</span>
                        <span className="font-semibold">{topic.label}</span>
                      </p>

                      <XCircle
                        size={16}
                        className="hover:text-red-500 transition-all cursor-pointer w-4 flex-shrink-0"
                        onClick={() => {
                          setTopic(null)
                        }}
                      />
                    </div>
                  )}

                  {locationObj && (
                    <div className="bg-line p-3 py-4 rounded-md text-sm flex justify-between items-center">
                      {/* <p className="">
                        <span className="text-border ">Location:</span>{' '}
                        <span>{locationObj.display_name}</span>
                      </p> */}

                      <p className="flex flex-col">
                        <span className="">Location</span>
                        <span className="font-semibold">
                          {locationObj.label
                            ? locationObj.label
                            : locationObj?.display_name}
                        </span>
                      </p>

                      <XCircle
                        size={16}
                        className="hover:text-red-500 transition-all cursor-pointer w-4 flex-shrink-0"
                        onClick={() => {
                          setLocationObj(null)
                        }}
                      />
                    </div>
                  )}
                </button>
              </DialogTrigger>
              <DialogContent className="p-0 w-[518px] mx-sm:w-screen">
                <TopicSelectDialogContent
                  onSelectLocation={(value) => {
                    setLocationObj(value)
                    setTopic(null)
                    setTopicOpen(false)
                  }}
                  onSelectTopic={(value) => {
                    setTopic(value)
                    setLocationObj(null)
                    setTopicOpen(false)
                  }}
                />
              </DialogContent>
            </Dialog>
          </div>
          <div>
            <SearchUserField
              trigger={
                <div>
                  {customUserName && (
                    <div className="border border-line rounded-lg w-full h-10 text-[11px] flex items-center pl-3 cursor-pointer space-x-1">
                      <span>Custom User: </span>
                      <span className="font-bold">{customUserName}</span>
                    </div>
                  )}

                  {userId && (
                    <div className="border border-line rounded-lg w-full text-[11px] flex items-center cursor-pointer p-2">
                      <PartyCard userId={userId} />
                    </div>
                  )}

                  {!(customUserName || userId) && (
                    <div className="border border-line rounded-lg w-full h-10 text-[11px] text-line italic flex items-center pl-3 cursor-pointer">
                      Search User*
                    </div>
                  )}
                </div>
              }
              onSelectUser={(userId) => {
                setValue('userId', userId)
                setValue('customUserName', '')
              }}
              onSetCustomUser={(name) => {
                setValue('customUserName', name)
                setValue('userId', '')
              }}
            />

            {errors?.userId && (
              <p className="text-xs text-red-500 font-semibold">
                {errors?.userId.message}
              </p>
            )}
          </div>

          <div className="">
            {/* <label className="text-xs font-bold">Role*</label> */}
            <Textarea
              className="h-[29px] bg-line border-line placeholder:text-border placeholder:text-[11px] placeholder:italic mt-1 text-xs"
              placeholder="Quote"
              {...register('text')}
            />
            {errors?.text && (
              <p className="text-xs text-red-500 font-semibold">
                {errors?.text.message}
              </p>
            )}
          </div>

          <div className="space-y-1">
            <div className="">
              {/* <label className="text-xs font-bold">Role*</label> */}
              <Input
                className="h-[29px] border-line placeholder:text-line placeholder:text-[11px] placeholder:italic text-xs"
                placeholder="Role of quoted person (not compulsory)"
                {...register('role')}
              />
              {errors?.role && (
                <p className="text-xs text-red-500 font-semibold">
                  {errors?.role.message}
                </p>
              )}
            </div>

            <div className="">
              {/* <label className="text-xs font-bold">Source*</label> */}
              <Input
                className="h-[29px] border-line placeholder:text-line placeholder:text-[11px] placeholder:italic text-xs"
                placeholder="Source*"
                {...register('source')}
              />
              {errors?.source && (
                <p className="text-xs text-red-500 font-semibold">
                  {errors?.source.message}
                </p>
              )}
            </div>

            <div>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value } }) => {
                  return (
                    <DateField
                      trigger={
                        <div>
                          {value ? (
                            <div className="border border-line rounded-lg w-full h-[29px] text-[11px]  flex items-center pl-3 cursor-pointer">
                              {moment(value).format('YYYY-MM-DD')}
                            </div>
                          ) : (
                            <div className="border border-line rounded-lg w-full h-[29px] text-[11px] text-line italic flex items-center pl-3 cursor-pointer">
                              Date*
                            </div>
                          )}
                        </div>
                      }
                      date={value}
                      setDate={onChange}
                    />
                  )
                }}
              />
              {errors?.date && (
                <p className="text-xs text-red-500 font-semibold">
                  {errors?.date.message}
                </p>
              )}
            </div>
          </div>

          {/* <DatePicker
            slots={{
           
            }}
          /> */}

          {/* {imageUrl && (
            <div className="flex flex-col gap-1 w-full">
              <XCircle
                className="text-border self-end cursor-pointer hover:text-red-500 transition-all"
                size={18}
                onClick={() => {
                  setValue('imageUrl', '')
                }}
              />
              <img src={imageUrl} className="object-cover rounded-md " />
            </div>
          )} */}

          <div className="flex justify-end items-center">
            {/* <label
              className={cn(
                'p-[3px] rounded-md w-fit transition-all cursor-pointer hover:bg-line'
              )}
            >
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(event) => {
                  const image = event.target.files?.[0]
                  if (image) {
                    uploadImage(image, 'user-images').then((imageUrl) => {
                      setValue('imageUrl', imageUrl)
                    })
                  }
                }}
              />
              <img src={LandscapeSVG} className="w-[16px] h-[16px] " />
            </label> */}

            <p className="text-[11px] text-border">{text?.length || 0}/300</p>
          </div>
        </div>
      </form>

      {/* Actions */}
      <div className="flex items-center justify-between mt-5">
        <Button
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isSubmitting}
          type="submit"
          onClick={handleSubmit(async (value) => {
            try {
              if (postId) {
                await updateAQuote({
                  ...value,
                  topicId: topic?.value,

                  postId: postId,
                })

                toast.success('Quote Successfully Updated')
              } else {
                await createAQuote({
                  location: locationObj?.id
                    ? locationObj?.id
                    : locationObj?.locationId,
                  ...value,
                  topicId: topic?.value,
                })

                toast.success('Quote Successfully Created')
              }

              setOpen(false)
              // toast.success(`Topic: ${value.name} Created`)
              cb && cb()
            } catch (error) {
              console.log(error)
              toast.error('Something went wrong')
            }
          })}
        >
          {!!postId ? 'Update' : 'Create'}
        </Button>
      </div>
    </>
  )
}
