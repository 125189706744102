export const privacyMd = `**Privacy Policy**

Effective September 25, 2024

**PLEASE READ THIS DOCUMENT CAREFULLY BEFORE USING OUR SERVICES. IF YOU
DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, YOU ARE ADVISED NOT
TO CONTINUE USING OUR SERVICES.**

This is the Privacy Policy (or “Policy”) of NIOOZE, INC. (or “niooze”,
“we”, “us” and “our”), a corporation duly incorporated in and existing
under the laws of the State of Delaware, with registered agent Legalinc
Corporate Services Inc. This Privacy Policy applies to all users of any
of our services, including but not limited to the niooze platform,
software, website, apps, and its Content (collectively referred to as
the “Services”).

Posts, articles, topics, comments, contrasts, text, images, photos,
graphics, videos, livestreams, links, audio, GIFs, people, users,
organizations, messages, advertisements, newsletters, communications, or
any other type of information, are all considered “Content”.

If you utilize the Services on behalf of a corporation, organization,
governmental body, or any other legal entity, you affirm and warrant
that you possess the necessary authorization to act on its behalf.
Consequently, the terms "you" and "your" within this Policy shall
pertain to such entity.

This Policy explains how we collect, use, share, disclose and safeguard
information, including Personal Information, and how to exercise any
rights you may have with respect to that information.

This Privacy Policy is part of and subject to our Terms of Service (http://www.niooze.com/terms-of-service).



**1. What we collect**

**1.1 From you**

While it is possible to use some of our Services without an account,
most Services require an account for which you will need to provide
information. The required information can be different between users, as
users will make choices in terms of user rights, preferences, and which
Services or functionalities they subscribe to.

- **Personal information**: such as your email address, full name,
  geographical location, date of birth, phone number, picture, etc.

- **Organizational information**: such as the company’s name,
  professional category, website, phone number, email address, physical
  address, logo, etc.

- **Settings**: information about your preferences in terms of
  notifications, email frequency, visualizations, security, access,
  privacy, etc.

- **Payment information**: such as card names, credit or debit card
  numbers, expiration dates, CVV codes and addresses, to be provided
  when you would like to purchase certain Services.

In case of verification of your profile, for example to become part of
our Trusted Community, we will require additional information from you,
such as driver’s licenses, ID cards, social media profiles, professional
backgrounds, etc. You have the right to decline providing this
information; however, if we feel we are unable to sufficiently verify
your identity, we reserve the right to limit your user rights or access
to our services at our sole discretion.

The scope of information you (need to) provide us will be subject to
change as we roll out functionalities and extend our Services.

**1.2 Through our Services**

Through your use of our Services, we might also collect other
information about you, whether you have an account or not.

- **Activity**: most of your usage of our Services, and activity on our
  platform is automatically logged into our databases.

  - Login and usage data

  - Pages or Content you’ve visited

  - Content you’ve posted or shared

  - Content you’ve watched

  - Content you’ve upvoted

  - Content you’ve edited

  - Content you’ve subscribed to

  - People you follow or who follow you

  - Topics you follow

  - Links you’ve followed

  - Alarms or bookmarks on Content

  - Organizations you’ve created or you are part of

  - Interactions with Content, with other users, or with us

  - Payments or money transaction information

  - Etc.

- **Devices, software and location**: through the devices and software
  you use to access our Services, we can also receive certain
  information.

  - Regarding the device or the software itself: device type, operating
    system, browser or app type, IDs, carriers, languages, locale,
    location, apps, settings, etc.

  - Regarding the connection: IP addresses, IDs, locations, Wi-Fi or
    carrier connections, speeds, etc.

- **Transactions**: in case of purchases or money transactions through
  our Services, or through Third-Party Services, we may log its metadata
  such as dates, amounts, counterparties, etc.

- **Cookies**: we may use cookies and/or similar technologies such as
  beacons, pixels, IDs, Google Analytics or others, to collect data that
  helps both in customizing our Services and improving your user
  experience. The idea behind these technologies is that they link a
  device or software with a user profile or with certain settings. As a
  result, users:

  - can remain logged in and keep their preferences

  - can receive personalized or more relevant Content

  - share how they interact with our Services

  - etc.

These technologies are typically governed by the browser or software
through which you access our Services, and normally you can reject or
reduce your exposure to them, even though this might affect the
availability and functionality of our Services.

**1.3 From third parties**

We may receive or collect information from third parties we collaborate
with.

- **Websites and software that integrate our Content or Services**: when
  viewing our Content on, or when using our Services through,
  third-party websites, apps, or software, we may receive information
  regarding your interaction with our Content and/or Services.

- **Third parties we partner with**: we may collaborate with
  advertisement partners, business partners, developers, publishers,
  affiliate partners, etc. on or off our platform. They might share
  information with us regarding actions you’ve taken, content you’ve
  viewed, behavior you’ve shared, etc.

The kind of information shared with us is similar to the information we
collect in Chapter 1.2 of this Policy. However, the information external
third parties share with us is subject to their Privacy Policy, not
ours. Our Privacy Policy is not applicable to third-party software and
their associated cookies policy. We bear no responsibility or liability
for the content and activities of these third-party links or websites.



**2. How we use information**

In general, we use your information to 1) operate our Services, 2)
ensure reliability of Content, 3) improve our Services, and 4) tailor
your user experience. The below list gives a non-exhaustive overview of
key purposes:

- To facilitate the creation of an account.

- To allow the authentication and verification of users, if required
  across services and devices.

- To build a trusted environment, with maximum reliability of Content.

- To safeguard and secure information and Content, and defend against
  fraud, unauthorized use, and illegal activity.

- To detect unusual behavior or Content on our platform.

- To facilitate the moderation of Content.

- To notify you about changes, updates, or important information
  regarding Content and/or our Services.

- To send you emails, newsletters, text messages, or other types of
  communications, according to your preferences.

- To operate the Services and provide a customer-friendly user
  experience.

- To detect potential improvements to the Services, and then improve our
  functionalities or develop new Services.

- To measure the effectiveness of our Services and Content, including
  ads.

- To conduct research, surveys, product testing and troubleshooting.

- To comply with any legal requirements.

- To help you discover Content, users, topics, ads, promotions,
  partners, (third party) apps, services, etc.

- To customize and personalize the Services and Content to your
  preferences and/or behavior.

- To advertise and market to you.

- To allow business partners to offer you certain products, services,
  events or promotions.

- To provide, customize and personalize any advertising or promotions,
  offered by us or by our business partners.

- To enable third parties to carry out operations on our behalf.

- To train machine learning or artificial intelligence models.

- To help others find your account.



**3. How we share information**

We may share information we have collected with different parties and
for different reasons.

- **Other users on our platform**: we may show your Content, or your
  interactions with Content, to users you may or may not know on our
  platform.

- **General public**: The general public can view content on our
  services without needing an account or login. They can also share that
  content through various external channels. Consequently, your content,
  along with your user profile (name, profile picture, user type,
  organization, organization type, description, number of followers,
  etc.), may be visible and shared by the public. Additionally, your
  content and user profile could appear on other websites, software, or
  apps, if they link to our Content.

- **Third parties**

  - <u>Service providers</u>: we may share information with third party
    service providers that perform services for us or on our behalf.
    Examples include hosting services, payment services, analytics
    tools, tracking systems, security and fraud services, etc.

  - <u>Partners</u>: we may share information with business partners,
    publishers, content providers, third-party integrations,
    subsidiaries, etc. to help us offer or operate Content and Services.

  - <u>Advertisers</u>: we may share information with third party
    advertisers and/or advertising companies in the framework of your
    interaction, engagement and interest in ads and promotions.

  - <u>APIs</u>: we may share information with external parties through
    our API, which allows direct access to our Content, as well as
    analysis of large data quantities.

- **Regulatory instances**: we may share information with external
  parties in case special situations require us to do so.

  - To detect and address fraud, security, payment, or technical issues.

  - To protect the rights and property of others or us.

  - To protect the safety of others or us.

  - To protect against spam or malicious conduct.

  - To explain and remedy violations of our policies and procedures.

  - To respond to a legal process, an investigation, a regulator, a
    government request, law enforcement, subpoenas, etc.

  - In the event of a merger, divestiture, restructuring,
    reorganization, dissolution, bankruptcy, liquidation, capital
    transaction, or any other similar proceeding or sale or transfer of
    assets.

As a general principle, we strive to minimize the amount of personal
information we need to share about you. We want to be seen as a trusted
partner, not only in terms of Content, but also in terms of privacy. We
are a follower incumbent, however, not a leader, in a highly competitive
social media, news, and advertising environment. As such, we will
unavoidably have to follow industry practices and accept a certain
degree of commercialization.



**4. Storage**

We keep your information on secured servers, and we implemented
reasonable, industry-wide, and generally accepted standards and
technology to safeguard your information from loss, misuse, unauthorized
access, disclosure, or destruction.

Unfortunately, no security measures are perfect or impenetrable. Nor is
any method of transmission over the internet. We strive to protect your
information, but **we cannot ensure, warrant or guarantee the security
of any information you transmit to us. You do so at your own risk.**



**5. Retention**

We retain your personal information for as long as we deem it necessary,
or as required or permitted by law. When deactivating your account, we
typically keep your profile information and Content in our databases,
even though it’s not publicly available anymore to users or visitors of
our Services.

We may fully delete your account, personal information and Content, at
our sole discretion, or upon your explicit request. If your account has
ever violated our policies, however, we may indefinitely keep the
contested Content, and some personal information (such as name, email
address and phone number), to detect when policy offenders create new
accounts in the future.

We may also keep personal information longer or shorter than specified,
as a measure to comply with legal, safety, payment, or security
requirements.

Please note that public content may still exist after we remove it from
our Services. Certain third-party providers and search engines may keep
copies based on their own privacy policies.



**6. Rights**

Applicable data protection rights grant you several rights regarding
your personal information. Some of these rights include:

- **The right to access, correct or modify your personal information**:
  you can do so by going to your user profile and editing the applicable
  fields. Some fields may not be editable, in which case you can send an
  email with the correct information to <users@niooze.com>.

- **The right to delete your information**: you can send an email to
  <users@niooze.com>, after which your account will be deactivated and
  will not be visible anymore for users or visitors. Typically (but not
  always), we keep your profile information and Content in our
  databases, which allows you to recover your account. If you want a
  full delete of all your personal information, please specify this
  explicitly in your mail.

- **The right to object, restrict or withdraw your consent**: please
  send an email to <users@niooze.com> until we’ve been able to set up a
  dedicated privacy section in your settings screen on the platform.
  Note that we may, at our full and sole discretion, reduce your user
  rights, user experience, or access to the platform in case we cannot
  fulfill your request without impacting our operations. We intend,
  however, to always respect your consent choices.

When submitting any of the above (or similar) requests, please ensure
the following:

- Your request is clear and detailed enough for us to fully understand
  your requirements.

- You provide sufficient information to verify your identity, as we may
  reject your request without proper verification.



**7. Data locations**

We may use data centers, cloud providers and hosting services located in
different locations and countries, even though we try to keep the data
of our users as close as possible to their geographic location.



**8. General**

Niooze reserves the right, at its sole discretion, to update, revise,
supplement and to otherwise modify this Policy at any time, and such
modifications shall be effective immediately upon availability on or
through our Services. You agree to review the Policy periodically,
ideally upon each visit, to be aware of such changes. We will try – at
our sole discretion – to notify you of material revisions, without this
being a requirement for your acceptance of the changes. Your continued
use or access of the Services shall be deemed as a conclusive acceptance
of any modifications. In case we provide this Policy in multiple
languages, the English language version of the Policy shall take
precedence in case of discrepancies or inconsistencies.



**9. Contact information**

If you have questions about this Policy or your Personal Information,
please contact our Data Protection Officer by email at
<legal@niooze.com>, or write to us at 936 SW 1<sup>st</sup> Ave, Unit
279, Miami, 33130, Florida, USA.
`
