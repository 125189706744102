import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import Spacer from '../../components/Spacer'
import back from '../../assets/icons/back.svg'
import { useNavigate } from 'react-router-dom'

import {
  createOrganizations,
  getAllOrganizations,
  deleteOrganization,
} from '../../redux/organizations/actions'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'

const OrganizationCreate = ({
  createOrganizations,
  getAllOrganizations,
  organizations,
  deleteOrganization,
}: any) => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [imageUrl, setImageUrl] = useState('') //image url
  const [type, setType] = useState('') //type

  const [orgIdDelete, setOrgIdDelete] = useState()
  const [orgNameDelete, setOrgNameDelete] = useState()
  const [orgLink, setOrgLink] = useState('')
  const [description, setDescription] = useState()

  const [image, setImage] = useState(null)

  useEffect(() => {
    getAllOrganizations()
  }, [])

  const signUp = () => {
    if (image == null) return
    const imageRef = ref(storage, `user-images/${image['name'] + uuidv4()}`)
    uploadBytes(imageRef, image).then((e) => {
      getDownloadURL(e.ref).then((url) => {
        setImageUrl(url)
        createOrganizations(name, url, type, description, orgLink)

        setName('')
        setImage(null)
        setType('')
        setOrgLink('')
      })
    })
  }

  const deleteOrg = () => {
    deleteOrganization(orgNameDelete)
  }

  return (
    <>
      <div
        className={'back-button'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
          marginLeft: 10,
        }}
        onClick={() => navigate(-1)}
      >
        <img style={{ height: 21, width: 21 }} src={back} alt="" />
      </div>
      <div style={{ width: '100%', overflow: 'hidden', marginTop: '3%' }}>
        <div style={{ width: '50%', float: 'left' }}>
          <div>
            <text style={{ fontWeight: 'bold', fontSize: '25px' }}>
              CREATE ORGANIZATIONS
            </text>
            <div style={{ marginTop: '5%', marginBottom: '3%' }}>
              <TextField
                label="organization name"
                onChange={(e: any) => {
                  setName(e.target.value)
                }}
                type="orgnam"
                autoComplete="current-password"
                variant="filled"
                style={{ width: '40%' }}
                value={name}
              />
              <Spacer height={10} />
              <div>
                <FormControl style={{ width: '40%' }}>
                  <InputLabel id="demo-simple-select-label">
                    Organization Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Organization Type"
                    onChange={(e: any) => {
                      setType(e.target.value)
                    }}
                  >
                    <MenuItem value={'Government'}>Government</MenuItem>
                    <MenuItem value={'Political organization'}>
                      Political organization
                    </MenuItem>
                    <MenuItem value={'International organization'}>
                      International organization
                    </MenuItem>
                    <MenuItem value={'Non-governmental organization'}>
                      Non-governmental organization
                    </MenuItem>
                    <MenuItem value={'Educational institution'}>
                      Educational institution
                    </MenuItem>
                    <MenuItem value={'Non-profit media'}>
                      Non-profit media
                    </MenuItem>
                    <MenuItem value={'For-profit media'}>
                      For-profit media
                    </MenuItem>
                    <MenuItem value={'Government linked media'}>
                      Government linked media
                    </MenuItem>
                    <MenuItem value={'Private company/org'}>
                      Private company/org
                    </MenuItem>
                    <MenuItem value={'Government linked company/org'}>
                      Government linked company/org
                    </MenuItem>
                  </Select>
                </FormControl>
                <Spacer height={10} />

                <TextField
                  label="organization URL"
                  onChange={(e: any) => {
                    setOrgLink(e.target.value)
                  }}
                  type="orgnam"
                  autoComplete="current-password"
                  variant="filled"
                  style={{ width: '40%' }}
                  value={orgLink}
                />
                <Spacer height={10} />

                <TextField
                  style={{
                    width: '40%',
                    fontSize: 20,
                    background: '#ffffff',
                  }}
                  size={'small'}
                  label="Description"
                  rows={4}
                  variant={'filled'}
                  onChange={(e: any) => {
                    setDescription(e.target.value)
                  }}
                  InputProps={{ disableUnderline: true }}
                  multiline
                />
              </div>
              <Spacer height={10} />

              <Button variant="contained" component="label">
                Upload Image
                <input
                  type="file"
                  hidden
                  onChange={(event: any) => {
                    setImage(event.target.files[0])
                  }}
                />
              </Button>

              <Spacer height={10} />
            </div>
            {image && (
              <img
                src={URL.createObjectURL(image)}
                style={{ width: '310px', borderRadius: '10px' }}
              />
            )}

            <Spacer height={10} />
            <div>
              <Button
                style={{ backgroundColor: '#5696DB ', width: '40%' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  signUp()
                }}
              >
                CREATE ORGANIZATION
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: '50%', float: 'left' }}>
          <text style={{ fontWeight: 'bold', fontSize: '25px' }}>
            DELETE ORGANIZATION
          </text>

          <div>
            <Spacer height={20} />
            <FormControl style={{ width: '60%' }}>
              <InputLabel id="demo-simple-select-label">
                Organization Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orgNameDelete}
                label="Organization Type"
                onChange={(e: any) => {
                  setOrgNameDelete(e.target.value)
                }}
              >
                {organizations?.map((org: any) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Spacer height={20} />
            <Button
              style={{ backgroundColor: '#FF0000 ', width: '60%' }}
              variant="contained"
              color="primary"
              onClick={() => {
                deleteOrg()
              }}
            >
              DELETE ORGANIZATION
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    organizations: state.organization.organizations,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createOrganizations: (
      name: string,
      imageUrl: string,
      type: string,
      description: string,
      orgLink: string,
      userId: string,
      email: string,
      phone: string
    ) => {
      dispatch(
        createOrganizations(
          name,
          imageUrl,
          type,
          description,
          orgLink,
          userId,
          email,
          phone
        )
      )
    },
    getAllOrganizations: () => {
      dispatch(getAllOrganizations())
    },
    deleteOrganization: (id: any) => {
      dispatch(deleteOrganization(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCreate)
