import React, { useEffect } from 'react'
import PostCard from '../../../components-v2/Post/PostCard'
import { useQuery } from '@tanstack/react-query'
import {
  getNotifications,
  readFromSection,
} from '../../../services/notifications'
import {
  getHowLongAgo,
  parsePostObjToProps,
} from '../../../components-v2/Post/helpers'
import { diffToReactNode } from '../helper'
import { useNotifications } from '../notifications.store'
import Loading from '../../../components-v2/Loading'
import { cn } from '../../../utils/cn'
import EditedEvent from '../EditedEvent'
import DeletedEvent from '../DeletedEvent'

type Props = {}

export default function EditsTab({}: Props) {
  const loadSummary = useNotifications((state: any) => state.loadSummary)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      readFromSection('edits').then(() => {
        loadSummary()
      })
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [])

  const { isLoading, error, data } = useQuery<any[]>({
    queryKey: ['getNotifications', 'edits'],
    queryFn: getNotifications,
    initialData: [],
  })

  return (
    <div className="text-xs flex flex-col gap-5 items-center">
      {isLoading && <Loading />}

      {data.length < 1 && <p className="text-xs text-border">All read.</p>}

      {data.map((x) => {
        switch (x?.eventType) {
          case 'edited':
            return <EditedEvent event={x} />

          case 'deleted':
            return <DeletedEvent event={x} />
        }

        // const time = getHowLongAgo(x?.createdAt)
        // return (
        //   <div className="max-w-[362px] w-full">
        // <p className="">
        //   <span className="font-bold">{x?.actingUser?.name}</span>{' '}
        //   {x?.eventType} your post{' '}
        //   {time === 'Just now' ? time : `${time} ago`}.
        // </p>

        // {x?.activity?.reason && <p>Reason is "{x?.activity?.reason}"</p>}

        // {x?.eventType === 'edited' && (
        //   <PostCard
        //     {...parsePostObjToProps(x?.activity?.archivePost)}
        //     className="opacity-80 mt-2 pointer-events-none"
        //     contentJSX={diffToReactNode(x?.diff || [])}
        //     disableActions
        //   />
        // )}

        // <PostCard
        //   {...parsePostObjToProps(x?.activity?.currentPost)}
        //   className={cn('mt-4', {
        //     'pointer-events-none': x?.eventType === 'deleted',
        //   })}
        // />
        //   </div>
        // )
      })}
    </div>
  )
}
