import './index.css'

import Spacer from '../Spacer'
import { useState } from 'react'

const UserTypeModal = ({ setSelectUserTypeModalVisible, setValue }: any) => {
  //modal role selection logic begin
  const [opinionMaker, setOpinionMaker] = useState(true)
  const [citizen, setCitizen] = useState(false)
  const [professional, setProfessional] = useState(false)

  const selectRole = (role: any) => {
    setValue(role)
    if (role === 'opinion') {
      //if opinion maker selected
      setOpinionMaker(true)
      setCitizen(false)
      setProfessional(false)
    } else if (role === 'citizen') {
      //if citizen
      setOpinionMaker(false)
      setCitizen(true)
      setProfessional(false)
    } else {
      //if professional
      setOpinionMaker(false)
      setCitizen(false)
      setProfessional(true)
    }
    setSelectUserTypeModalVisible(false)
  }
  //modal role selection logic end
  return (
    <div
      style={{
        zIndex: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // background: '#000000bb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => {
        setSelectUserTypeModalVisible(false)
      }}
      className="h-screen"
    >
      <div
        style={{
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          background: '#FBFBFD',
          width: 350,
          // height: 674,
          padding: 26,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="max-h-screen overflow-y-scroll"
      >
        <div
          style={{
            textAlign: 'start',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <p className={'heading-style'}>Choose user type</p>
          <Spacer height={10} />

          <div className="horizontal-line"></div>
          <Spacer height={20} />

          <div
            className={'user-type-box'}
            style={{ background: opinionMaker ? '#D9D9D9' : '#FBFBFD' }}
            onClick={() => {
              selectRole('Opinion maker')
            }}
          >
            <p className={'header-text-style'}>
              Opinion maker <span className="italic">(standard)</span>
            </p>
            <p className={'body-text-style'}>
              Start reading and sharing without identity verification and enjoy
              maximum fun and community feeling. Opinion makers can make posts
              of type Opinion, Rumor and Humor.{' '}
            </p>
          </div>
          <Spacer height={14} />

          <div
            className={'user-type-box'}
            style={{ background: citizen ? '#D9D9D9' : '#FBFBFD' }}
            onClick={() => {
              selectRole('Citizen')
            }}
          >
            <p className={'header-text-style'}>Citizen</p>
            <p className={'body-text-style'}>
              Citizens can make posts of type Research, Opinion, Rumor and
              Humor. They can also contrast and counterbalance existing posts,
              which makes them a cornerstone of our community. We’d like to know
              who you are so there might be some follow-up questions.
              Politicians and policy makers are typically not approved in this
              category.
            </p>
          </div>
          <Spacer height={14} />

          <div
            className={'user-type-box'}
            style={{ background: professional ? '#D9D9D9' : '#FBFBFD' }}
            onClick={() => {
              selectRole('Professional')
            }}
          >
            <p className={'header-text-style'}>Professional</p>
            <p className={'body-text-style'}>
              Professionals can make all type of posts (including Facts). They
              can also contrast and counterbalance existing posts. Whether
              already working in the media industry or not, Professionals commit
              to journalism standards to ensure the quality of Facts, Research
              and other content they post. We’d like to know who you are so
              there might be some follow-up questions.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserTypeModal
