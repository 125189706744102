import { connect } from 'react-redux'

import Radio from '@mui/material/Radio'

import dropDown from '../../assets/icons/drop-down.svg'

import './index.css'

import { getOrganization } from '../../redux/organizations/actions'
import { useEffect, useState } from 'react'
import Spacer from '../../components/Spacer'

const ChannelPicker = ({
  organizations,
  userDetails,
  setOrg,
  width,
  articlePicker = false,
  editPost = false,
  orgName = null,
  orgType = null,
  orgImage = null,
  orgId = null,
}: any) => {
  const [selectDropDown, setSelectDropDown] = useState(false)
  const [selectOrg, setSelectOrg] = useState(true)
  const [organizationsState, setOrganizationState] = useState(organizations)

  //temp states for user selection
  const [selectedOrgName, setOrgName] = useState(orgName)
  const [selectedOrgType, setSelectedOrgType] = useState(orgType)
  const [selectedOrgImage, setSelectedOrgImage] = useState(orgImage)

  const [selectedId, setSelectedId] = useState(orgId)

  useEffect(() => {
    if (editPost) {
      //if the picker in the edit post
      setOrganizationState([
        {
          //we all add user details for list
          name: userDetails?.name,
          id: null,
          imageUrl: userDetails?.imageUrl,
        },
        ...organizationsState,
      ])
    } else {
      //if the picker not in the edit post
      if (!articlePicker) {
        //if this a post channel picker
        setOrganizationState([
          {
            //we all add user details for list
            name: userDetails?.name,
            id: null,
            imageUrl: userDetails?.imageUrl,
          },
          ...organizationsState,
        ])

        if (organizationsState.length === 1) {
          setOrgName(organizationsState[0]?.name)
          setSelectedOrgType(organizationsState[0]?.type)
          setSelectedOrgImage(organizationsState[0]?.imageUrl)
          setSelectedId(organizationsState[0]?.id)
        } else {
        }
      } else {
        //if this is an article channel picker
        const selectedData = organizationsState.filter(function (org: any) {
          //only adding media org
          if (
            org?.type === 'Non-profit media' ||
            org?.type === 'For-profit media' ||
            org?.type === 'Government-linked media'
          ) {
            return org
          }
        })
        if (selectedData?.length > 1) {
          //if there is more than one media org
          setOrganizationState([...selectedData])

          setOrgName(selectedData[0]?.name)
          setSelectedOrgType(selectedData[0]?.type)
          setSelectedOrgImage(selectedData[0]?.imageUrl)
          setSelectedId(selectedData[0]?.id)
        } else if (selectedData?.length === 1) {
          //if there is only one org
          setOrganizationState([...selectedData]) //we don't show list
          setSelectDropDown(!selectDropDown)
          setOrgName(selectedData?.name)
          setSelectedOrgType(selectedData?.type)
          setSelectedOrgImage(selectedData?.imageUrl)
          setSelectedId(selectedData?.id)
        }
      }
    }
  }, [setOrganizationState, articlePicker])

  const handleChange = (event: any) => {
    setSelectDropDown(!selectDropDown)

    setOrgName(event?.name)
    setSelectedOrgType(event?.type)
    setSelectedOrgImage(event?.imageUrl)
    setSelectedId(event?.id)

    event?.id === userDetails?.id ? setSelectOrg(false) : setSelectOrg(true)
  }

  return (
    <>
      {articlePicker ? (
        <>
          {/*channel picker for article*/}
          {organizationsState?.length === 1 ? (
            <>
              {' '}
              {/*for one organizations*/}
              <div
                className={'main-div-channel-picker-select margin-phone'}
                style={{ width: width }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', flex: 90 }}
                >
                  <div className={'flex-row-channel-picker'}>
                    {/*User name drop down image*/}
                    <p className={'bold'}>
                      {organizationsState?.length
                        ? organizationsState[0]?.name
                        : null}
                    </p>
                    <Spacer width={2} />
                    <img className={'image-drop-down'} src={dropDown} />
                  </div>

                  <div className={'flex-row-channel-picker'}>
                    {/*organization and user type*/}

                    <p className={'grey'}>
                      {organizationsState?.length
                        ? organizationsState[0]?.type
                        : null}
                    </p>
                  </div>
                </div>

                {/*images*/}
                <div className={'image-section-selected'}>
                  <img
                    className={'image-size-channel-picker'}
                    src={
                      organizationsState?.length
                        ? organizationsState[0]?.imageUrl
                        : null
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {/*for more than one organization*/}
              {/*auto select the first organization*/}
              {selectDropDown ? ( //when user select the drop down
                <div
                  className={'root-div-channel-picker'}
                  style={{ width: width }}
                >
                  {/*if select from drop down*/}
                  {/*DROP DOWN*/}
                  {organizationsState?.map((org: any) => (
                    <div
                      key={org.id}
                      className={'main-div-channel-picker'}
                      onClick={() => {
                        setOrg(org.id)
                      }}
                    >
                      <div className={'radio-button-section'}>
                        <div style={{ paddingLeft: '8px', paddingTop: '5px' }}>
                          <Radio
                            checked={selectedId === org?.id}
                            //onChange={handleChange}
                            onClick={() => {
                              handleChange(org)
                            }}
                            value={org}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}
                            sx={{
                              color: '#2443B2',
                              '&.Mui-checked': {
                                color: '#2443B2',
                              },
                            }}
                          />
                        </div>
                      </div>

                      {/*member name,organization name and org type*/}
                      <div
                        className={
                          org?.type ? 'names-section' : 'names-section-name'
                        }
                      >
                        <p className={'bold'}>{userDetails?.name}</p>
                        {org?.type ? (
                          <p className={'bold'}>{org?.name}</p>
                        ) : (
                          <p className={'grey'}>{userDetails?.roles?.name}</p>
                        )}
                        {org?.type ? (
                          <p className={'grey'}>{org?.type}</p>
                        ) : null}
                      </div>

                      {/*org image and member profile*/}
                      <div className={'image-section'}>
                        {org?.orgLink ? (
                          <div>
                            <img
                              className={'image-size-channel-picker'}
                              src={org?.imageUrl}
                            />
                          </div>
                        ) : (
                          <div style={{ width: '30px' }}></div>
                        )}
                        <div>
                          <img
                            className={'image-size-channel-picker'}
                            src={userDetails?.imageUrl}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className={'main-div-channel-picker-select margin-phone'}
                  style={{ width: width }}
                  onClick={() => {
                    setSelectDropDown(!selectDropDown)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 90,
                    }}
                  >
                    <div className={'flex-row-channel-picker'}>
                      {/*User name drop down image*/}
                      <p className={'bold'}>{selectedOrgName}</p>
                      <Spacer width={2} />
                      <img className={'image-drop-down'} src={dropDown} />
                    </div>

                    <div className={'flex-row-channel-picker'}>
                      {/*organization and user type*/}

                      <p className={'grey'}>{selectedOrgType}</p>
                    </div>
                  </div>

                  {/*images*/}
                  <div className={'image-section-selected'}>
                    <img
                      className={'image-size-channel-picker'}
                      src={selectedOrgImage}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/*channel picker for post*/}
          {organizationsState?.length === 1 ? (
            <>
              {/*for one organization*/}
              <div
                className={'main-div-channel-picker-select margin-phone'}
                style={{ width: width }}
                onClick={() => {
                  setSelectDropDown(!selectDropDown)
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flex: 90,
                  }}
                >
                  <div className={'flex-row-channel-picker-auto'}>
                    {/*User name drop down image*/}
                    <p className={'bold'}>{userDetails?.name}</p>
                    <Spacer width={5} />
                    <img className={'image-drop-down'} src={dropDown} />
                  </div>

                  <div className={'flex-row-channel-picker-auto'}>
                    {/*organization and user type*/}
                    <p className={'bold'}>{selectedOrgName}</p>
                    <p className={'grey'}>{userDetails?.roles?.name}</p>
                  </div>

                  <div style={{ marginTop: '5px' }}>
                    {/*org type*/}
                    <p className={'grey'}>{selectedOrgType}</p>
                  </div>
                </div>

                {/*images*/}
                <div className={'image-section-selected'}>
                  {selectedOrgImage && (
                    <img
                      className={'image-size-channel-picker'}
                      src={selectedOrgImage}
                    />
                  )}
                  <Spacer width={5} />
                  <img
                    className={'image-size-channel-picker'}
                    src={userDetails?.imageUrl}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {/*for more than one organization*/}
              {selectDropDown ? (
                <div
                  className={'root-div-channel-picker'}
                  style={{ width: width }}
                >
                  {/*if select from drop down*/}
                  {/*DROP DOWN*/}
                  {organizationsState?.map((org: any) => (
                    <div
                      key={org.id}
                      className={'main-div-channel-picker'}
                      onClick={() => {
                        setOrg(org.id)
                      }}
                    >
                      <div className={'radio-button-section'}>
                        <div style={{ paddingLeft: '8px', paddingTop: '5px' }}>
                          <Radio
                            checked={selectedId === org?.id}
                            //onChange={handleChange}
                            onClick={() => {
                              handleChange(org)
                            }}
                            value={org}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}
                            sx={{
                              color: '#2443B2',
                              '&.Mui-checked': {
                                color: '#2443B2',
                              },
                            }}
                          />
                        </div>
                      </div>

                      {/*member name,organization name and org type*/}
                      <div
                        className={
                          org?.type ? 'names-section' : 'names-section-name'
                        }
                      >
                        <p className={'bold'}>{userDetails?.name}</p>
                        {org?.type ? (
                          <p className={'bold'}>{org?.name}</p>
                        ) : (
                          <p className={'grey'}>{userDetails?.roles.name}</p>
                        )}
                        {org?.type ? (
                          <p className={'grey'}>{org?.type}</p>
                        ) : null}
                      </div>

                      {/*org image and member profile*/}
                      <div className={'image-section'}>
                        {org?.orgLink ? (
                          <div>
                            <img
                              className={'image-size-channel-picker'}
                              src={org?.imageUrl}
                            />
                          </div>
                        ) : (
                          <div style={{ width: '30px' }}></div>
                        )}
                        <div>
                          <img
                            className={'image-size-channel-picker'}
                            src={userDetails?.imageUrl}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div
                    className={'main-div-channel-picker-select margin-phone'}
                    style={{ width: width }}
                    onClick={() => {
                      setSelectDropDown(!selectDropDown)
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: 90,
                      }}
                    >
                      <div className={'flex-row-channel-picker-auto'}>
                        {/*User name drop down image*/}
                        <p className={'bold'}>{userDetails?.name}</p>
                        <Spacer width={5} />
                        <img className={'image-drop-down'} src={dropDown} />
                      </div>

                      <div className={'flex-row-channel-picker-auto'}>
                        {/*organization and user type*/}
                        <p className={'bold'}>{selectedOrgName}</p>
                        {selectedOrgName && <Spacer width={5} />}
                        <p className={'grey'}>{userDetails?.roles?.name}</p>
                      </div>

                      <div style={{ marginTop: '5px' }}>
                        {/*org type*/}
                        <p className={'grey'}>{selectedOrgType}</p>
                      </div>
                    </div>

                    {/*images*/}
                    <div className={'image-section-selected'}>
                      {selectedOrgImage && (
                        <img
                          className={'image-size-channel-picker'}
                          src={selectedOrgImage}
                        />
                      )}
                      <Spacer width={5} />
                      <img
                        className={'image-size-channel-picker'}
                        src={userDetails?.imageUrl}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    organizations: state?.login?.user?.organizationUsers,
    userDetails: state?.login?.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOrganization: (orgId: string) => {
      dispatch(getOrganization(orgId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPicker)
