import { Dispatch } from 'react'
import { getAuth } from '../../utils/auth'
import axios from 'axios'

import { ADD_POST, IMPRESS_POST } from './types'

const transport = axios.create({
  withCredentials: false,
})

export const updateAnalytics = (postIds: any) => {
  return (dispatch: Dispatch<any>) => {
    transport
      .post(
        `${process.env.REACT_APP_BASE_URL}/analytics/updateAnalytics`,
        { postIds },
        getAuth()
      )
      .then((res) => {})
      .catch((error) => {
        if (error.response) {
          return
        }
        // dispatch(getMeFailure("something went wrongsds"));
      })
  }
}

export const addImpressionsToRedux = (impressionedPosts: any) => {
  return {
    type: IMPRESS_POST,
    payload: impressionedPosts,
  }
}

export const addPostsToRedux = (post: any) => {
  return {
    type: ADD_POST,
    payload: post,
  }
}
