import {
  GET_ALL_TOPICS,
  CREATE_TOPICS,
  ADD_TOPIC_ID,
  SHOW_CREATE_TOPIC_MODAL,
  CLOSE_CREATE_TOPIC_MODAL,
  GET_SELECTED_TOPICS,
  GET_TOPIC_REQUEST,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_FAILURE,
} from './types'

const initialState = {
  topics: null,
  topicsFailed: false,
  topicsID: null,
  createTopicModal: false,
  selectedTopics: null,

  topic: null,
  topicLoading: false,
  topicError: false,
}

const topicsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_TOPIC_REQUEST:
      return { ...state, topicLoading: true, topicError: false }
    case GET_TOPIC_SUCCESS:
      return {
        ...state,
        topic: action.payload,
        topicLoading: true,
        topicError: false,
      }
    case GET_TOPIC_FAILURE:
      return { ...state, topic: null, topicLoading: false, topicError: true }

    case GET_ALL_TOPICS:
      return { ...state, topics: action.payload, topicsFailed: true }
    // case CREATE_POSTS:
    //     return {...state, posts: action.payload, userLoginError: false};
    case ADD_TOPIC_ID:
      return { ...state, topicsID: action.payload }
    case SHOW_CREATE_TOPIC_MODAL:
      return { ...state, createTopicModal: action.payload }
    case CLOSE_CREATE_TOPIC_MODAL:
      return { ...state, createTopicModal: action.payload }
    case GET_SELECTED_TOPICS:
      return { ...state, selectedTopics: action.payload }
    default:
      return state
  }
}

export default topicsReducer
