import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import text from '../../assets/icons/text.svg'
import axios from 'axios'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete'

import Spacer from '../../components/Spacer'
import { useNavigate } from 'react-router-dom'

import { userCreate, getAllUsers, deleteUser } from '../../redux/login/actions'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import back from '../../assets/icons/back.svg'

const SignUp = ({ userCreate, getAllUsers, users, deleteUser }: any) => {
  const navigate = useNavigate()

  const [userName, setUserName] = useState('')
  const [lastName, setLastName] = useState('')
  const [location, setLocation] = useState('')
  const [password, setPassword] = useState('')
  const [publicchecked, setPublicChecked] = useState(false)
  const [verified, setVerified] = useState(false)
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('') //type
  const [locationsData, setLocationsData] = useState<any>([])
  const [selectlocation, setSelectLocation] = useState<any>()
  const [selectLocationForApi, setSelectLocationForApi] = useState('')

  const [image, setImage] = useState(null)

  const [selectUser, setSelectUser] = useState()

  useEffect(() => {
    getAllUsers()
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData(selectLocationForApi)
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [selectLocationForApi])

  const fetchData = async (location: any) => {
    try {
      const response = await axios.get(
        'https://nominatim.openstreetmap.org/search?q=' +
          location +
          '&format=json&countrycodes=us&accept-language=us&addressdetails=1'
      ) // Replace with your API endpoint
      const data = response.data // Extract the data from the response
      const newLocationData = data.map((location: any) => {
        return {
          id: location.place_id,
          name: location.display_name,
          ...location,
        }
      })

      setLocationsData(newLocationData)
    } catch (error) {
      console.error(error)
    }
  }

  const signUp = () => {
    if (image == null) return
    const imageRef = ref(storage, `user-images/${image['name'] + uuidv4()}`)
    uploadBytes(imageRef, image).then((e) => {
      getDownloadURL(e.ref).then((url) => {
        userCreate(
          userName + ' ' + lastName,
          password,
          email,
          verified,
          publicchecked,
          url,
          type,
          location,
          description,
          selectlocation?.id,
          selectlocation?.name,
          selectlocation?.geojson,
          selectlocation?.address,
          null,
          null
        )

        setUserName('')
        setLastName('')
        setLocation('')
        setEmail('')
        setImage(null)
        setPassword('')
      })
    })
  }

  const deleteUsers = () => {
    deleteUser(selectUser)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <div
        className={'back-button'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
          marginLeft: 10,
        }}
        onClick={() => navigate(-1)}
      >
        <img style={{ height: 21, width: 21 }} alt="" src={back} />
      </div>
      <div style={{ width: '100%', overflow: 'hidden', marginTop: '3%' }}>
        {/*Create User */}
        <div style={{ width: '50%', float: 'left' }}>
          <div /*style={{position:'fixed',top:'30%',left:'30%',right:'30%'}}*/>
            <text style={{ fontWeight: 'bold', fontSize: '25px' }}>
              CREATE USER
            </text>
            <div style={{ marginTop: '5%', marginBottom: '3%' }}>
              <TextField
                label="First Name"
                onChange={(e: any) => {
                  setUserName(e.target.value)
                }}
                type="username"
                autoComplete="current-password"
                variant="filled"
                value={userName}
                style={{ width: '40%' }}
              />
              <Spacer height={10} />
              <TextField
                label="Last name"
                onChange={(e: any) => {
                  setLastName(e.target.value)
                }}
                type="lastname"
                autoComplete="current-password"
                variant="filled"
                value={lastName}
                style={{ width: '40%' }}
              />
              <Spacer height={10} />
              <TextField
                label="Location"
                onChange={(e: any) => {
                  setLocation(e.target.value)
                }}
                type="text"
                autoComplete="current-password"
                variant="filled"
                value={location}
                style={{ width: '40%' }}
              />
              <Spacer height={10} />
              <TextField
                label="Email"
                onChange={(e: any) => {
                  setEmail(e.target.value)
                }}
                type="lastname"
                autoComplete="current-password"
                variant="filled"
                style={{ width: '40%' }}
                value={email}
              />
              <Spacer height={10} />
              <TextField
                rows={4}
                label="Description"
                onChange={(e: any) => {
                  setDescription(e.target.value)
                }}
                type="lastname"
                autoComplete="current-password"
                variant="filled"
                style={{ width: '40%' }}
                value={description}
                multiline
              />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="public"
                      onChange={(e) => {
                        setPublicChecked(e.target.checked)
                      }}
                    />
                  }
                  label="Public"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="verified"
                      onChange={(e) => {
                        setVerified(e.target.checked)
                      }}
                    />
                  }
                  label="Verified"
                />
              </div>
              <Spacer height={10} />
              <div>
                <FormControl style={{ width: '40%' }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="type"
                    onChange={(e: any) => {
                      setType(e.target.value)
                    }}
                  >
                    <MenuItem value={'Professional'}>Professional</MenuItem>
                    <MenuItem value={'Citizen'}>Citizen</MenuItem>
                    <MenuItem value={'Opinion maker'}>Opinion maker</MenuItem>
                    <MenuItem value={'Commentator'}>Commentator</MenuItem>
                    <MenuItem value={'Reader'}>Reader</MenuItem>
                    <MenuItem value={'Check'}>Check</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Spacer height={10} />
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  style={{ width: '265px', borderRadius: '10px' }}
                />
              )}
              <Spacer height={10} />

              <div style={{ marginLeft: '30%' }}>
                <Autocomplete
                  style={{ width: '57%' }}
                  disablePortal
                  id="combo-box-demo"
                  options={locationsData ? locationsData : []}
                  getOptionLabel={(option) =>
                    option.display_name ? option.display_name : ''
                  }
                  defaultValue={locationsData[0]?.name}
                  sx={{ width: 300 }}
                  onInputChange={(e: any) =>
                    setSelectLocationForApi(e?.target?.value)
                  }
                  onChange={(event, value) => setSelectLocation(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />

                <Spacer height={10} />
              </div>
              <Button variant="contained" component="label">
                Upload Image
                <input
                  type="file"
                  hidden
                  onChange={(event: any) => {
                    setImage(event.target.files[0])
                  }}
                />
              </Button>

              <Spacer height={10} />

              <TextField
                id="filled-password-input"
                label="Password"
                onChange={(e: any) => {
                  setPassword(e.target.value)
                }}
                type="password"
                autoComplete="current-password"
                variant="filled"
                style={{ width: '40%' }}
                value={password}
              />
            </div>

            <Spacer height={10} />
            <div>
              <Button
                style={{ backgroundColor: '#5696DB ', width: '40%' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  signUp()
                }}
              >
                CREATE USER
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div style={{ width: '50%', float: 'left' }}>
            <div /*style={{position:'fixed',top:'30%',left:'30%',right:'30%'}}*/
            >
              <text style={{ fontWeight: 'bold', fontSize: '25px' }}>
                DELETE USER
              </text>
              <div style={{ marginTop: '5%', marginBottom: '3%' }}>
                <div>
                  <FormControl style={{ width: '40%' }}>
                    <InputLabel id="demo-simple-select-label">USER</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectUser}
                      label="type"
                      onChange={(e: any) => {
                        setSelectUser(e.target.value)
                      }}
                    >
                      {users?.map((user: any) => (
                        <MenuItem value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Spacer height={10} />
              <div>
                <Button
                  style={{ backgroundColor: '#FF0000 ', width: '40%' }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    deleteUsers()
                  }}
                >
                  DELETE USER
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    users: state.login.users,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    userCreate: (
      name: string,
      password: string,
      email: string,
      verified: boolean,
      publicchecked: boolean,
      imageUrl: any,
      type: any,
      location: any,
      description: any,
      placeID: any,
      locationName: any,
      geoJson: any,
      address: any,
      phoneNumber: any,
      birthday: any
    ) => {
      dispatch(
        userCreate(
          name,
          password,
          email,
          verified,
          publicchecked,
          imageUrl,
          type,
          location,
          description,
          placeID,
          locationName,
          geoJson,
          address,
          phoneNumber,
          birthday
        )
      )
    },
    getAllUsers: () => {
      dispatch(getAllUsers())
    },
    deleteUser: (id: any) => {
      dispatch(deleteUser(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
