import authAxios from '../../utils/authAxios'

export async function upvoteToggle(postId: string) {
  const res = await authAxios.get(`/vote/${postId}`)
  return res.data
}

export async function alarmToggle(postId: string) {
  const res = await authAxios.get(`/alarm/${postId}`)
  return res.data
}

export async function deletePost(
  postId: string,
  isVisible: boolean,
  reason?: string
) {
  const res = await authAxios.post(`/post/${postId}`, {
    isVisible,
    reason,
  })
  return res.data
}

export async function updatePost(values: any) {
  const res = await authAxios.patch(`/post/update/${values.postId}`, {
    postTypeId: values.postTypeId,
    description: values.description,
    topicId: values.topicId,
    organizationId: values.organizationId,
    mediaId: values.mediaId,
    location: values.location,
    reason: values.reason,
  })
  return res.data
}

export async function followUsers(userId: any) {
  try {
    const res = await authAxios.post('v2/user/userFollow', {
      userIdToFollow: userId,
    })
    return res.data
  } catch (error) {
    throw error // Re-throw the error so it can be caught in the calling function
  }
}
