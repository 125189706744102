import authAxios from '../utils/authAxios'

export async function getUserFollowerIds() {
  const res = await authAxios.get(`/v2/helperApis/getUserFollowerIds`)
  return res.data
}

export async function getUserVoteIds() {
  const res = await authAxios.get(`/v2/helperApis/getUserVoteIds`)
  return res.data
}

export async function getUserModeratorStatus() {
  const res = await authAxios.get(`/v2/helperApis/getUserModeratorStatus`)
  return res.data
}

export async function getUserAlarmIds() {
  const res = await authAxios.get(`/v2/helperApis/getUserAlarmIds`)
  return res.data
}
