import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAllArticlesOfATopic } from '../../../services/article'
import ArticleCard from '../../ArticleScreen/ArticleCard'
import DomainScroll from '../DomainScroll'
import { getUniqueDomains, getFilteredArticles } from '../helper'
import Loading from '../../../components-v2/Loading'

type Props = {
  articles: any[]
  isLoading?: boolean
  cb: () => void
  onNoAuthCallback?: () => void
}

export default function ArticlesTab({
  articles,
  cb,
  isLoading,
  onNoAuthCallback,
}: Props) {
  const { topicId } = useParams<{ postId: string; topicId: string }>()
  // const [articles, setArticles] = useState<any[]>([])
  // const [isLoading, setIsLoading] = useState(false)

  const [selectedDomain, setSelectedDomain] = useState('')

  // const pageLoad = useCallback(() => {
  //   if (topicId) {
  //     setIsLoading(true)
  //     getAllArticlesOfATopic(topicId)
  //       .then((data) => {
  //         setArticles(data)
  //       })
  //       .finally(() => {
  //         setIsLoading(false)
  //       })
  //   }
  // }, [topicId])

  // useEffect(() => {
  //   pageLoad()
  // }, [pageLoad])

  const uniqueDomains = useMemo(() => getUniqueDomains(articles), [articles])
  const filteredArticles = useMemo<any[]>(
    () => getFilteredArticles(articles, selectedDomain),
    [selectedDomain, articles]
  )

  const articlesExist = articles.length > 0

  return (
    <>
      <div className="flex flex-col">
        {articlesExist && (
          <DomainScroll
            className="mt-[18px]"
            items={uniqueDomains}
            currentDomain={selectedDomain}
            onClickItem={setSelectedDomain}
          />
        )}

        {!articlesExist && (
          <p className="mt-5 text-border text-sm">No Articles</p>
        )}

        {isLoading && (
          <div className="mt-5">
            <Loading />
          </div>
        )}
        <div className="flex flex-col gap-3 mt-[19px]">
          {filteredArticles &&
            filteredArticles.map((article) => {
              return (
                <ArticleCard
                  disableLable
                  key={article.id}
                  title={article.title}
                  description={`${article?.title?.slice(0, 15)}`}
                  iconSRC={article.favicon}
                  imageSRC={article.imageUrl}
                  name={article.domainName}
                  postCount={article?.posts?.length}
                  href={`/topic/${topicId}/article/${article.id}`}
                  linkUrl={article.url}
                  onNoAuthCallback={onNoAuthCallback}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}
