import React from 'react'
import css from './Loading.module.css'

type Props = {}

export default function Loading({}: Props) {
  return (
    // <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-border" />
    <div className={css.loader} />
  )
}
